/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import DraggableList from 'react-draggable-list';
import { t } from 'react-multi-lang';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import OrderIcon from '../../../assets/Images/drag-icon.svg';
import { ReactComponent as LinkIcon } from '../../../assets/Images/link-icon.svg';
import NoResultIcon from '../../../assets/Images/no-results-icon.svg';
import { isSuperAdmin } from '../../../Components/common/utils';
import { COUNTRY_SPECTIFIC_URLS } from '../../../Components/constants';

const PillarsListing = ({
  data,
  selectedPillar,
  setIsModalOpen,
  isLoading,
  setFormData,
  setIsEdit,
  setDeleteModal,
  setSelectedPillar,
  onHandleUpdateOrder,
  userPermisions,
}) => {
  const containerRef = useRef();
  const [activeDropdownId, setActiveDropdownId] = useState(null);
  const [pillarsData, setPillarsData] = useState([]);

  useEffect(() => {
    if (data?.length) {
      setPillarsData(data);
    }
  }, [data]);

  const toggleDropdown = id => {
    setActiveDropdownId(prevId => (prevId === id ? null : id));
  };

  const handleEdit = editData => {
    const userData = {
      pillar_type: selectedPillar,
      name: editData.name,
      description: editData.description,
      website_url:
        editData.website_url !== null ? editData.website_url : handleRenderUrls(editData),
      latitude: editData.latitude,
      longitude: editData.longitude,
      icon: editData.icon,
      id: editData.id,
      isEditable: editData.is_editable,
      file: editData.file,
    };
    setFormData(userData);
    setIsModalOpen(true);
    setIsEdit(true);
  };

  const handleDelete = data => {
    setSelectedPillar(data);
    setDeleteModal(true);
  };

  const handleRenderUrls = urlData => {
    if (urlData.file) {
      return urlData.file.split('/').pop();
    } else {
      if (urlData?.website_url) {
        return urlData?.website_url;
      } else {
        const filteredData = COUNTRY_SPECTIFIC_URLS.filter(
          country => country.country_name === urlData.country_name,
        );
        return filteredData[0]?.url;
      }
    }
  };

  const _onListChange = (newList, e, old, newIndex) => {
    const payload = {
      order: newIndex + 1,
      id: e.id,
    };

    onHandleUpdateOrder(payload);
    setPillarsData(newList);
  };

  const Item = ({ item, dragHandleProps }) => {
    const { onMouseDown, onTouchStart } = dragHandleProps;

    return (
      <div className=" px-3">
        <img
          src={OrderIcon}
          alt="order-icon"
          className="dms-drag-icon"
          onTouchStart={e => {
            onTouchStart(e);
          }}
          onMouseDown={e => {
            onMouseDown(e);
          }}
        />
        <div className="dms-pillar-list-card-container" key={item.id}>
          <div className="dms-pillar-list-logo-container">
            <img src={item.icon} alt={item.name} height={60} width={60} />
          </div>
          <div className="dms-pillar-list-dropdown-container w-100">
            <div className="dms-pillar-list-details">
              <h2 className="dms-pillar-list-title">{item.name}</h2>
              <p className="dms-pillar-list-desc">{item.description}</p>
              <div
                className="dms-partners-link dms-partners-link-hide"
                onClick={() =>
                  window.open(item.file ? item.file : handleRenderUrls(item), '_blank')
                }
              >
                <LinkIcon height={20} width={20} style={{ marginRight: '10px' }} />
                <span className="trucate-link" title={handleRenderUrls(item)}>
                  {handleRenderUrls(item)}
                </span>
              </div>{' '}
            </div>
            <Dropdown
              isOpen={activeDropdownId === item.id}
              toggle={() => toggleDropdown(item.id)}
              direction="left"
              className=" d-inline w-auto"
            >
              <DropdownToggle
                tag="span"
                data-toggle="dropdown"
                aria-expanded={activeDropdownId === item.id}
                className="dms-pillar-list-dropdown-toggle"
              >
                <i className="fa fa-ellipsis-v dms-toggle-size" aria-hidden="true"></i>
              </DropdownToggle>
              <DropdownMenu className=" p-0">
                {isSuperAdmin() || userPermisions.includes('change_dmspillars') ? (
                  <DropdownItem
                    onClick={() => handleEdit(item)}
                    className="dms-dropdown-options dms-dropdown-options-border"
                  >
                    {t('LANDING_PAGE.EDIT_DETAILS')}
                  </DropdownItem>
                ) : null}
                {isSuperAdmin() || userPermisions.includes('delete_dmspillars') ? (
                  <DropdownItem
                    onClick={() => handleDelete(item)}
                    className="dms-dropdown-options dms-dropdown-options-delete"
                  >
                    {t('LANDING_PAGE.DELETE')}
                  </DropdownItem>
                ) : null}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {!isLoading ? (
        data?.length ? (
          <div ref={containerRef} className="scrollable-list-container">
            <DraggableList
              itemKey="id"
              template={Item}
              list={pillarsData}
              onMoveEnd={(newList, e, oldIndex, newIndex) =>
                _onListChange(newList, e, oldIndex, newIndex)
              }
              container={() => containerRef.current}
            />
          </div>
        ) : (
          <div className=" h-100 d-flex justify-content-center align-items-center flex-column p-5">
            <img src={NoResultIcon} alt="No results to show" className=" d-block" />
            <p className="no-result-text">{t('LANDING_PAGE.NO_RESULTS_FOUND')}</p>
          </div>
        )
      ) : null}
    </>
  );
};

export default PillarsListing;
