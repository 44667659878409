import React from 'react';
import Switch from 'react-switch';
import IndicesInfoIcons from '../../drought-monitor/IndicesInfoIcons'
import {DROUGHT_MONITOR_MODULE,DRY_SPELL, INDICES_PARAMS} from '../../../Components/constants/index'
import {
  Col,
  Input,
  Row,
} from 'reactstrap';

const DroughtStageSwitch = (props) => {
  const handleChange = (item) => {
   props.handleDroughtIndices(item);
  };
  return (
    <>
      {props?.droughtIndicesList?.map((i,index) => {
        return (
          <>
          <div className="switch-label" key={index}>
            <Row>
              <Col sm={7} md={7} lg={7} xs={7}>
                <span>{i.label}</span>
              </Col>
              <Col sm={3} md={3} lg={3} xs={3}>
                <Switch
                  handleDiameter={24}
                  height={14}
                  width={34}
                  offColor="#33699c"
                  onColor="#33699c"
                  offHandleColor="#edeff2"
                  onHandleColor="#2a547c"
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={() => handleChange(i)}
                  checked={i.checked}
                  className="stage-options"
                  boxShadow= "1px 1px 1px 1px #848F9A"
                  disabled={i.disabled}
                />
              </Col>
              <Col sm={2} md={2} lg={2} xs={2}>
                  <IndicesInfoIcons
                    checked={false}
                    indicesName={i.label === DRY_SPELL.DRYSPELL? DRY_SPELL.DRY_SPELL: i.label}
                    contingencyPlan={true}
                    selectedModule = {DROUGHT_MONITOR_MODULE}
                  ></IndicesInfoIcons>
              </Col>
            </Row>
          </div>
           {i.id === INDICES_PARAMS.CONTINGENCY_DRY_SPELL.id && i.checked ? (
            <div className="mx-3 mt-3 mb-3">
              <label htmlFor="quantity">Precipitation Threshold (in mm)</label>
              <Input
                type="number"
                id="quantity"
                name="quantity"
                min="0"
                value={props.precipitationThreshold}
                onChange={e => props.setPrecipitationThreshold(e.target.value)}
              />
            </div>
          ) : (
            ''
          )}
          </>
        );
      })}
    </>
  );
};
export default DroughtStageSwitch;
