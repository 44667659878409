import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { AXIOS_INSTANCE, HOME_URL, LANDING_PAGE_URL, PUBLIC_API_URL } from '../api/apiEndPoint';
import {
  addLandingPageFailed,
  addLandingPageSuccess,
  contactUsFailed,
  contactUsSuccess,
  deleteLandingPageFailed,
  deleteLandingPageSuccess,
  editLandingPageFailed,
  editLandingPageSuccess,
  getBannersFailed,
  getBannersSuccess,
  getDmsEventsFailed,
  getDmsEventsSuccess,
  getDmsNewsfeedFailed,
  getDmsNewsfeedSuccess,
  getDmsPartnersDonorsFailed,
  getDmsPartnersDonorsSuccess,
  getDmsPillarsFailed,
  getDmsPillarsSuccess,
  getDmsStatisticsFailed,
  getDmsStatisticsSuccess,
  getDmsTestimonialsFailed,
  getDmsTestimonialsSuccess,
  getParametersFailed,
  getParametersSuccess,
} from './homeActions';
import HOME_CONST from './homeConsts';

const getBannersApiRequest = async () => {
  let response = {};
  try {
    const responseData = await AXIOS_INSTANCE.get(`${HOME_URL}/get-banners`);
    response = {
      data: responseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getBanners() {
  try {
    const apiResponse = yield call(getBannersApiRequest);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getBannersSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getBannersFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getBannersFailed(errorData));
  }
}

const getParametersApiRequest = async () => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.get(`${PUBLIC_API_URL}/getDroughtIndices`);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getParametersData(action) {
  try {
    const apiResponse = yield call(getParametersApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getParametersSuccess(responseData));
    }
  } catch (error) {
    if (error.response.status === 400) {
      const responseData = {
        data: error.response.data,
      };
      yield put(getParametersFailed(responseData));
    } else {
      const errorData = {
        error: {
          statusText: error,
          netWorkError: true,
        },
      };
      yield put(getParametersFailed(errorData));
    }
  }
}

const getEventsApiRequest = async payload => {
  let response = {};
  const { event_type } = payload;
  try {
    const responseData = await AXIOS_INSTANCE.get(
      `${LANDING_PAGE_URL}/get_dms_events${event_type ? `?event_type=${event_type}` : ''}`,
    );
    response = {
      data: responseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getEvents(action) {
  try {
    const apiResponse = yield call(getEventsApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getDmsEventsSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getDmsEventsFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getDmsEventsFailed(errorData));
  }
}

const getTestimonialsApiRequest = async () => {
  let response = {};
  try {
    const responseData = await AXIOS_INSTANCE.get(`${LANDING_PAGE_URL}/get_dms_testimonials`);
    response = {
      data: responseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getTestimonials() {
  try {
    const apiResponse = yield call(getTestimonialsApiRequest);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getDmsTestimonialsSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getDmsTestimonialsFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getDmsTestimonialsFailed(errorData));
  }
}

const getStatisticsApiRequest = async () => {
  let response = {};
  try {
    const responseData = await AXIOS_INSTANCE.get(`${LANDING_PAGE_URL}/get_dms_statistics`);
    response = {
      data: responseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getStatistics() {
  try {
    const apiResponse = yield call(getStatisticsApiRequest);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getDmsStatisticsSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getDmsStatisticsFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getDmsStatisticsFailed(errorData));
  }
}

const getPartnersDonorsApiRequest = async () => {
  let response = {};
  try {
    const responseData = await AXIOS_INSTANCE.get(
      `${LANDING_PAGE_URL}/get_dms_partners_and_donors`,
    );
    response = {
      data: responseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getPartnersDonors() {
  try {
    const apiResponse = yield call(getPartnersDonorsApiRequest);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getDmsPartnersDonorsSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getDmsPartnersDonorsFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getDmsPartnersDonorsFailed(errorData));
  }
}

const getNewsfeedApiRequest = async payload => {
  let response = {};
  const { category, limit } = payload;
  try {
    const responseData = await AXIOS_INSTANCE.get(
      `${LANDING_PAGE_URL}/get_rss_news?category=${category}&limit=${limit}`,
    );
    response = {
      data: responseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getNewsfeed(action) {
  try {
    const apiResponse = yield call(getNewsfeedApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getDmsNewsfeedSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getDmsNewsfeedFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getDmsNewsfeedFailed(errorData));
  }
}

const getPillarsApiRequest = async () => {
  let response = {};
  try {
    const responseData = await AXIOS_INSTANCE.get(`${LANDING_PAGE_URL}/get_dms_pillars`);
    response = {
      data: responseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getPillars(action) {
  try {
    const apiResponse = yield call(getPillarsApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getDmsPillarsSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getDmsPillarsFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getDmsPillarsFailed(errorData));
  }
}

// COMMON FOR ALL
const addLandingPageApiRequest = async payload => {
  let response = {};
  try {
    const responseData = await AXIOS_INSTANCE.post(
      `${LANDING_PAGE_URL}/${payload.end_point}`,
      payload.submissionData,
    );
    response = {
      data: responseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* addLandingPage(action) {
  try {
    const apiResponse = yield call(addLandingPageApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(addLandingPageSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(addLandingPageFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(addLandingPageFailed(errorData));
  }
}

// delete testimonials and partners & donors
const deleteLandingPageApiRequest = async payload => {
  let response = {};
  try {
    const responseData = await AXIOS_INSTANCE.delete(
      `${LANDING_PAGE_URL}/${payload.end_point}?${payload.query}=${payload?.id}`,
    );
    response = {
      data: responseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* deleteLandingPage(action) {
  try {
    const apiResponse = yield call(deleteLandingPageApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(deleteLandingPageSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(deleteLandingPageFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(deleteLandingPageFailed(errorData));
  }
}

// Common For ALL
const editLandingPageApiRequest = async payload => {
  let response = {};
  try {
    const responseData = await AXIOS_INSTANCE.put(
      `${LANDING_PAGE_URL}/${payload.end_point}`,
      payload.submissionData,
    );
    response = {
      data: responseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* editLandingPage(action) {
  try {
    const apiResponse = yield call(editLandingPageApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(editLandingPageSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(editLandingPageFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(editLandingPageFailed(errorData));
  }
}

const contactUsFormApiRequest = async payload => {
  let response = {};
  try {
    const responseData = await AXIOS_INSTANCE.post(
      `${LANDING_PAGE_URL}/${payload.end_point}`,
      payload.submissionData,
    );
    response = {
      data: responseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* contactUsForm(action) {
  try {
    const apiResponse = yield call(contactUsFormApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(contactUsSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(contactUsFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(contactUsFailed(errorData));
  }
}

export function* watchGetBanners() {
  yield takeEvery(HOME_CONST.GET_BANNERS_REQUEST, getBanners);
}

export function* watchGetParameters() {
  yield takeEvery(HOME_CONST.GET_PARAMETERS_REQUEST, getParametersData);
}

export function* watchGetDmsEvents() {
  yield takeEvery(HOME_CONST.GET_DMS_EVENTS_REQUEST, getEvents);
}

export function* watchGetDmsStatistics() {
  yield takeEvery(HOME_CONST.GET_DMS_STATISTICS_REQUEST, getStatistics);
}

export function* watchGetDmsTestimonials() {
  yield takeEvery(HOME_CONST.GET_DMS_TESTIMONIALS_REQUEST, getTestimonials);
}

export function* watchGetDmsPartnersDonors() {
  yield takeEvery(HOME_CONST.GET_DMS_PARTNERS_DONORS_REQUEST, getPartnersDonors);
}

export function* watchGetDmsNewsfeed() {
  yield takeEvery(HOME_CONST.GET_DMS_NEWSFEED_REQUEST, getNewsfeed);
}

export function* watchGetDmsPillars() {
  yield takeEvery(HOME_CONST.GET_DMS_PILLARS_REQUEST, getPillars);
}

export function* watchAddLandingPage() {
  yield takeEvery(HOME_CONST.ADD_LANDING_PAGE_REQUEST, addLandingPage);
}

export function* watchDeleteLandingPage() {
  yield takeEvery(HOME_CONST.DELETE_LANDING_PAGE_REQUEST, deleteLandingPage);
}

export function* watchEditLandingPage() {
  yield takeEvery(HOME_CONST.EDIT_LANDING_PAGE_REQUEST, editLandingPage);
}

export function* watchContactUsForm() {
  yield takeEvery(HOME_CONST.CONTACT_US_REQUEST, contactUsForm);
}

function* HomeSaga() {
  yield all([
    fork(watchGetBanners),
    fork(watchGetParameters),
    fork(watchGetDmsEvents),
    fork(watchGetDmsStatistics),
    fork(watchGetDmsTestimonials),
    fork(watchGetDmsPartnersDonors),
    fork(watchGetDmsNewsfeed),
    fork(watchGetDmsPillars),
    fork(watchAddLandingPage),
    fork(watchDeleteLandingPage),
    fork(watchEditLandingPage),
    fork(watchContactUsForm),
  ]);
}

export default HomeSaga;
