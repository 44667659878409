import React from 'react';

import 'leaflet-side-by-side';
import 'leaflet/dist/leaflet.css';

import '../../../assets/CSS/LeafletWrapper.css';
import { LayersControlProvider } from './LayerControlContext';
import { Button } from 'reactstrap';
import { t } from 'react-multi-lang';
import DistricProfileIcon from '../../../assets/Images/district-profile.svg';


function LayerControl({
  isHideBar,
  handleDistrictProfileModal,
}) {

  const handleDistrictProfileButton = () => {
    handleDistrictProfileModal(true);
  };

  return (
    <LayersControlProvider>
      <div className={isHideBar ? 'leaflet-left-full' : 'leaflet-left'}>
        {/* <div className="leaflet-control leaflet-bar new-container"> */}
        <div className="leaflet-control leaflet-bar">
          <>
            <div className="params-list">
              <Button className="district-profile-button" onClick={handleDistrictProfileButton}>
                <span>
                  <img src={DistricProfileIcon} alt="" />
                </span>
                <span>{t('CONTINGENCY_PLAN.DISTRICT_PROFILE')}</span>
              </Button>
            </div>
          </>
        </div>
      </div>
    </LayersControlProvider>
  );
}

export default LayerControl;
