import React, { useEffect, useRef, useState } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import _, { isNumber } from 'lodash';
import axios from 'axios';
import TRANSLATIONS from '../../../translations/en.json';
import { t } from 'react-multi-lang';
import { Button, Card, CardBody, Label, InputGroup, InputGroupAddon } from 'reactstrap';
import { BsSearch } from 'react-icons/bs';
import { RxCross2 } from 'react-icons/rx';
import { useDispatch } from 'react-redux';
import { showError } from '../../../Components/Notifications';
import { BiCurrentLocation } from 'react-icons/bi';
import { Field, reduxForm, change } from 'redux-form';
import {parseCoordinate} from "../../../Components/helpers"
import { renderReactSelectInputField } from '../../../Components/common/renderInputField';
import * as turf from '@turf/turf';
import Geocode from 'react-geocode';
import Autocomplete from 'react-google-autocomplete';
import MapMeasures from './MapMeasures';
import {
  CONTINGENCY_FILTER_TABS,
  CONTINGENCY_PLAN,
  ADDRESS_CONSTS,
} from '../../../Components/constants/index';
import ReactTooltip from 'react-tooltip';

const ContingencyPlanFilter = props => {
  const filterRef = useRef(null);
  const { 
    isHideBar,
    setBar,
    isSystemShow,
    handleSubmit,
    countryList,
    stateList,
    districtList,
    riskList,
    monsoonList,
    systemList,
    stageList,
    strategyList,
    droughtIndicesList,
    mapPreferencesData,
    checkedIndicesList,
    isContingencyMeasuresDisabled,
    isMapTabShow,
    dropdownDataLoading,
    precipitationThreshold,
    setPrecipitationThreshold,
    setLocation,
    location,
    setCordinateAndZoom,
    countryJson,
  } = props;
  const dispatch = useDispatch();
  const [isAdvanceSearch] = useState(true);
  const [defaultCountrySelected, setDefaultCountrySelected] = useState(false);
  const [isApplyLayers, setIsApplyLayers] = useState(false);
  const [formButton, setFormButton] = useState();
  const [isStrategyOpen, setIsStrategyOpen] = useState(false);
  const [initialSelectedDate, setInitialSelectedDate] = useState({
    startDate: '',
    endDate: '',
  });
  const req_data = {
    country: '',
    state: '',
    district: '',
    risk: '',
    monsoon: '',
    startDate: '',
    endDate: '',
  };
  const [formData, setFormData] = useState(req_data);
  const [showMapPreferenceTooltip, setShowMapPreferenceTooltip] = useState(false);

  Geocode.setApiKey(process.env.REACT_APP_LOCATION_API_KEY);
  Geocode.setLanguage('en');
  Geocode.setLocationType('ROOFTOP');
  Geocode.enableDebug();

  useEffect(() => {
    if (props.initialValues) {
      if (props.initialValues.country.id) {
        setDefaultCountrySelected(true);
        formData.country = props.initialValues.country.id;
        setFormData(formData);
        props.handleCountry(props.initialValues.country);
      }
    }
  }, [props.initialValues?.country]);
  useEffect(() => {
    if (
      !_.isEmpty(props?.initialValues?.state) &&
      (_.isEmpty(formData.state) || formData?.state !== props.initialValues.state.id)
    ) {
      if (props.initialValues.state.id) {
        formData.state = props.initialValues.state.id;
        setFormData(formData);
      }
    } else if (_.isEmpty(props?.initialValues?.state) && formData?.state) {
      formData.state = '';
      setFormData(formData);
    }
  }, [props?.initialValues?.state]);

  useEffect(() => {
    if (
      !_.isEmpty(props?.initialValues?.district) &&
      (_.isEmpty(formData.district) || formData?.district !== props.initialValues.district.id)
    ) {
      if (props.initialValues.district.id) {
        formData.district = props.initialValues.district.id;
        setFormData(formData);
      }
    } else if (_.isEmpty(props?.initialValues?.district) && formData?.district) {
      formData.district = '';
      setFormData(formData);
    }
  }, [props?.initialValues?.district]);

  useEffect(() => {
    if (mapPreferencesData?.length === 2) setShowMapPreferenceTooltip(true);
    else setShowMapPreferenceTooltip(false);
  }, [mapPreferencesData]);

  useEffect(() => {
    if (!_.isEmpty(props.initialValues?.risk?.id)) {
      handleRiskChange(props.initialValues.risk);
    } else if (_.isEmpty(props.initialValues?.risk?.id) && formData.risk) {
      formData.risk = '';
      setFormData(formData);
    }
  }, [props.initialValues.risk]);

  useEffect(() => {
    if (!_.isEmpty(props.initialValues?.monsoon?.id)) {
      console.log("enter" , props)
      handleMonsoonChange(props.initialValues.monsoon);
    } else if (_.isEmpty(props.initialValues?.monsoon?.id) && formData.monsoon) {
      formData.monsoon = '';
      setFormData(formData);
    }
  }, [props.initialValues.monsoon]);

  useEffect(() => {
    if (!_.isEmpty(props.initialValues?.system?.id)) {
      handleSystemChange(props.initialValues.system);
    } else if (_.isEmpty(props.initialValues?.system?.id) && formData.system) {
      formData.system = '';
      setFormData(formData);
    }
  }, [props.initialValues.system]);

  useEffect(() => {
    if (
      !_.isEmpty(props.initialValues.strategy?.id) &&
      !_.isEmpty(props.initialValues.chooseTimePeriod?.id) &&
      !_.isEmpty(props.initialValues.droughtMeasuresStage?.id) &&
      !_.isEmpty(props.initialValues.risk?.id) &&
      !_.isEmpty(props.initialValues.system?.id)
    ) {
      handleStrategy(props.initialValues.strategy);
    }
  }, [props.initialValues]);

  useEffect(() => {
    if (
      formData?.district &&
      formData?.risk &&
      formData?.monsoon &&
      initialSelectedDate?.startDate &&
      initialSelectedDate?.endDate &&
      formData?.system &&
      checkedIndicesList?.length &&
      mapPreferencesData?.length < 2
    ) {
      setIsApplyLayers(true);
    } else {
      setIsApplyLayers(false);
    }
  }, [formData, checkedIndicesList, initialSelectedDate, mapPreferencesData]);

  useEffect(() => {
    if (!_.isEmpty(props?.defaultDates)) {
      setInitialSelectedDate(prev => {
        return {
          ...prev,
          startDate: props.defaultDates.startDate,
          endDate: props.defaultDates.endDate,
        };
      });
    }
  }, [props?.defaultDates]);

  const onFormSubmit = () => {};

  const renderAPIParameterOptions = (list, action_name) => {
    if (list) {
      let params = [...list];
      let options = [];
      if (params.length) {
        if (
          action_name !== CONTINGENCY_PLAN.Risk &&
          action_name !== CONTINGENCY_PLAN.Monsoon &&
          action_name !== CONTINGENCY_PLAN.System
        ) {
          let default_option = { value: 'select', label: 'Select', id: 'select' };
          options.push(default_option);
        }
        params.map(param => {
          let data = {
            value: param.name,
            label: `${param.name}`,
            id: param.id,
          };
          options.push(data);
          return true;
        });
        return options;
      }
    }
  };

  const handleFormButton = value => {
    setFormButton(value);
    props.handleActiveFilterTab(value);
  };

  const handleCountryChange = () => {};

  const handleStateChange = value => {
    if (value.id === 'select') {
      formData.state = '';
      formData.district = '';
      formData.risk = '';
      formData.monsoon = '';
    } else {
      formData.state = value.id;
      setLocation('');
      setCordinateAndZoom(prev => {
        return {
          ...prev,

          searchMarkerCoordinates: '',
        };
      });
    }
    clearFilterFieldsData([
      'district',
      'risk',
      'monsoon',
      'system',
      'choose-crop',
      'drought-map-stage',
      'drought-measures-stage',
      'choose-time-period',
    ]);
    setFormData({ ...formData });
    props.handleState(value);
  };

  const clearFilterFieldsData = values => {
    values.map(i => {
      dispatch(change('contingency-filter-form', i, ''));
    });
  };

  const handleDistrictChange = value => {
    if (value.id === 'select') {
      formData.district = '';
      formData.risk = '';
      formData.monsoon = '';
    } else {
      formData.district = value.id;
      setLocation('');
      setCordinateAndZoom(prev => {
        return {
          ...prev,

          searchMarkerCoordinates: '',
        };
      });
    }
    clearFilterFieldsData([
      'risk',
      'monsoon',
      'system',
      'choose-crop',
      'drought-map-stage',
      'drought-measures-stage',
      'choose-time-period',
    ]);
    setFormData({ ...formData });
    props.handleDistrict(value);
  };

  const handleRiskChange = value => {
    formData.risk = value.id;
    formData.system = '';
    clearFilterFieldsData([
      'system',
      'drought-map-stage',
      'drought-measures-stage',
      'choose-time-period',
    ]);
    setFormData({ ...formData });
    props.handleRisk(value);
  };

  const handleMonsoonChange = value => {
    formData.monsoon = value.id;
    setFormData({ ...formData });
    props.handleMonsoon(value);
  };

  const handleSystemChange = value => {
    formData.system = value.id;
    setFormData({ ...formData });
    clearFilterFieldsData(['drought-map-stage', 'drought-measures-stage', 'choose-time-period']);
    props.handleSystem(value);
  };

  const handleApplyLayers = () => {
    props.handleApplyLayers(true);
  };

  const handleStartDate = date => {
    formData.startDate = date;
    setInitialSelectedDate(prev => {
      return {
        ...prev,
        startDate: date,
      };
    });
    props.handleStartDate(date);
  };
  const handleEndDate = date => {
    formData.endDate = date;
    setInitialSelectedDate(prev => {
      return {
        ...prev,
        endDate: date,
      };
    });
    props.handleEndDate(date);
  };
  const handleContingencyMeasures = () => {
    props.handleContingencyMeasuresModal();
  };

  const handleMeasureDrought = value => {
    clearFilterFieldsData(['choose-time-period']);
    props.handleMeasureDrought(value);
  };

  const handleTimePeriodChange = value => {
    props.handleTimePeriodChange(value);
  };

  const handleStrategy = value => {
    clearFilterFieldsData(['choose-crop']);
    props.handleStrategy(value);
  };

  const handleButtonMarginTop = data => {
    setIsStrategyOpen(data);
  };

  const handleSearch = places => {
    if (!_.isEmpty(places)) {
      const { formatted_address, geometry, address_components } = places;
      const lat = geometry?.location?.lat();
      const lng = geometry?.location?.lng();
      // if (isNumber(lat) && isNumber(lng)) {
      // setCordinateAndZoom(prev => {
      //   return {
      //     ...prev,
      //     cordinates: [lat, lng],
      //     zoom: 10,
      //     searchMarkerCoordinates: [lat, lng],
      //   };
      // });
      // }
      setLocation(formatted_address);
      // setLocation(formatted_address);
    } else {
      setLocation('');
    }
  };

  const handleOnPlaceChange = event => {
    if (event && event?.target && event?.target?.value === '') {
      setLocation('');
    } else {
      setLocation(event.target.value);
    }
  };

  const isLatLng = input => {
    // Regular expression for standard numerical lat,lng format with optional spaces
    const standardLatLngPattern = /^\s*-?\d+(\.\d+)?\s*,\s*-?\d+(\.\d+)?\s*$/;
    // Regular expression for degrees and direction format with optional spaces
    const degreesLatLngPattern = /^\s*-?\d+(\.\d+)?\s*°?\s*[NS]\s*,\s*-?\d+(\.\d+)?\s*°?\s*[EW]\s*$/;

    return standardLatLngPattern.test(input) || degreesLatLngPattern.test(input);
  };

  const onHandleRemoveLocation = () => {
    setLocation('');
    setCordinateAndZoom(prev => {
      return {
        ...prev,

        searchMarkerCoordinates: '',
      };
    });
  };
  const handleFilter = async loc => {
    const islatlng = isLatLng(loc);
    if (islatlng) {
      const locArray = loc.split(',');
      const lat = parseCoordinate(locArray?.[0]);
      const lng = parseCoordinate(locArray?.[1]);
      if (locArray?.length === 2 && isNumber(parseFloat(lat)) && isNumber(parseFloat(lng))) {
        const point = turf.point([parseFloat(lng), parseFloat(lat)]);
        const isInsideAnyPolygon = countryJson.features.some(feature => {
          return turf.booleanPointInPolygon(point, feature);
        });
        if (isInsideAnyPolygon) {
          // setLocation([parseFloat(lat), parseFloat(lng)]);
          setCordinateAndZoom(prev => {
            return {
              ...prev,
              cordinates: [lat, lng],
              zoom: 10,
              searchMarkerCoordinates: [lat, lng],
            };
          });
          // props.handleCordinates([parseFloat(lat), parseFloat(lng)], 10);
        } else {
          setLocation('');
          showError(`Please select location inside India country only.`);
        }
      } else {
        setLocation();
        showError('Please enter valid data .');
      }
    } else {
      const responseData = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          JSON.stringify(loc),
        )}&key=AIzaSyAzWsZ_PyD0lshvP-sCwWP6d_1wzE0dYA0`,
      );
      if (responseData?.data?.results?.length) {
        const point = turf.point([
          parseFloat(responseData?.data?.results?.[0]?.geometry?.location?.lng),
          parseFloat(responseData?.data?.results?.[0]?.geometry?.location?.lat),
        ]);
        const isInsideAnyPolygon1 = countryJson.features.some(feature => {
          return turf.booleanPointInPolygon(point, feature);
        });

        if (!isInsideAnyPolygon1) {
          setLocation('');
          showError(`Please select location inside India country only.`);
          // setLocation('');
        } else {
          setCordinateAndZoom(prev => {
            return {
              ...prev,
              cordinates: [
                responseData?.data?.results?.[0]?.geometry?.location?.lat,
                responseData?.data?.results?.[0]?.geometry?.location?.lng,
              ],
              zoom: 10,
              searchMarkerCoordinates: [
                responseData?.data?.results?.[0]?.geometry?.location?.lat,
                responseData?.data?.results?.[0]?.geometry?.location?.lng,
              ],
            };
          });
        }
      } else {
        setLocation('');
        showError(`Please select location inside India country only.`);
      }
    }
  };

  const getAddress = (lat, lng, { fromCurrentLocation }) => {
    if (isNumber(lat) && isNumber(lng) && fromCurrentLocation) {
      setCordinateAndZoom(prev => {
        return {
          ...prev,
          cordinates: [lat, lng],
          zoom: 10,
          searchMarkerCoordinates: [lat, lng],
        };
      });
    }
    Geocode.fromLatLng(lat, lng).then(
      response => {
        let country, state, district;
        const { address_components: components } = response.results[0];
        for (let i = 0; i < components.length; i++) {
          for (let j = 0; j < components[i].types.length; j++) {
            if (components[i].types[j] === ADDRESS_CONSTS.COUNTRY) {
              country = components[i].long_name;
              // if (fromCurrentLocation) props.handleCountry(country);
              // else {
              //   setCurrenUserLocation(country);
              //   getUserLocationBasedOnIP(country);
              // }
            }
            if (components[i].types[j] === ADDRESS_CONSTS.STATE) {
              state = components[i].long_name;
            }
            if (components[i].types[j] === ADDRESS_CONSTS.DISTRICT) {
              district = components[i].long_name;
            }
          }
        }

        if (fromCurrentLocation) {
          setLocation(`${district}, ${state}, ${country}`);
        }
      },
      error => {
        console.error(error);
      },
    );
  };

  //map marker on the basis of selected country
  //get current location
  const getCurrentLocation = () => {
    if ('geolocation' in navigator) {
      let latitude = '';
      let longitude = '';
      /* geolocation is available */
      navigator.geolocation.getCurrentPosition(position => {
        latitude = position.coords.latitude;
        longitude = position.coords.longitude;
        getAddress(latitude, longitude, { fromCurrentLocation: true });
        // if (latitude && longitude)
        //   props.handleCordinates([latitude, longitude], 10);
      });
    } else {
      /* geolocation IS NOT available */
    }
  };

  const handleAdvanceSearchRender = () => {
    return (
      <div className="advance-search-fields mb-3">
        <div className="mx-3 mt-2 mb-3">
          <Label htmlFor="country">Select Country</Label>
          <Field
            component={renderReactSelectInputField}
            name="country"
            placeholder="Select Country"
            onChange={e => handleCountryChange(e)}
            required={true}
            isMulti={false}
            options={renderAPIParameterOptions(countryList)}
            className="mt-2 mb-3 cp-country-field"
            isDisabled={defaultCountrySelected}
          ></Field>
          <Label htmlFor="country">Select State</Label>
          <Field
            component={renderReactSelectInputField}
            name="state"
            placeholder="Select State"
            onChange={e => handleStateChange(e)}
            className="mt-2 mb-3 cp-state-field"
            required={true}
            isMulti={false}
            options={renderAPIParameterOptions(stateList)}
            isLoading={dropdownDataLoading.key === 'state' && dropdownDataLoading.isLoading}
          ></Field>
          {districtList?.length ? (
            <>
              <Label htmlFor="country">Select District</Label>
              <Field
                component={renderReactSelectInputField}
                name="district"
                placeholder="Select District"
                onChange={e => handleDistrictChange(e)}
                required={true}
                isMulti={false}
                className="mt-2 mb-3 cp-district-field"
                options={renderAPIParameterOptions(districtList)}
                isLoading={dropdownDataLoading.key === 'district' && dropdownDataLoading.isLoading}
              ></Field>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {isHideBar ? (
        <div className="expand-bar">
          <span className="cursor-ponter navyBlueColor ">
            {t('FILTER.EXPAND_BAR')}
            <span>
              <MdKeyboardArrowLeft
                className="hide-icon expand-margin"
                onClick={() => setBar(!isHideBar)}
                size={25}
              />
            </span>
          </span>
        </div>
      ) : (
        <div className="filter-sidebar-section" ref={filterRef}>
          <div className="filter-div">
            <Card className="card-style">
              <CardBody>
                <form
                  id="contingency-filter-form"
                  onSubmit={handleSubmit(onFormSubmit)}
                  className="filter-control-dp"
                >
                  {' '}
                  <div className="search-container">
                    <div className="d-flex justify-content-end cursor-ponter navyBlueColor mb-3">
                      {' '}
                      <span className="hidebar-show">{TRANSLATIONS.FILTER.HIDE_BAR}</span>{' '}
                      <span>
                        <MdKeyboardArrowRight
                          className="hide-icon hide-margin"
                          onClick={() => setBar(!isHideBar)}
                          size={25}
                        />
                      </span>
                    </div>{' '}
                    <div className="search-tab-wather-forecast">
                      <InputGroup>
                        <Autocomplete
                          apiKey={process.env.REACT_APP_LOCATION_API_KEY}
                          id="search-input-drought"
                          className="static-search-input"
                          value={location}
                          onPlaceSelected={handleSearch}
                          onChange={handleOnPlaceChange}
                          placeholder="Search for a location"
                        />
                        {/* {location && ( */}
                        <div className="wf-search-icon weather-forecat-icons-drought">
                          <InputGroupAddon addonType="append">
                            <BsSearch
                              id="inputIcon"
                              onClick={() =>
                                location && countryJson ? handleFilter(location) : {}
                              }
                              className={countryJson ? "cursor-ponter mr-1 weather-forecat-icons-margin" : " mr-1 serch-opacity weather-forecat-icons-margin"}
                              size={19}
                              color="#2A547C"
                            />

                            {location ? (
                              <RxCross2
                                className="cursor-ponter "
                                size={19}
                                color="#656565"
                                onClick={() => onHandleRemoveLocation()}
                              />
                            ) : (
                              ''
                            )}
                          </InputGroupAddon>
                        </div>
                      </InputGroup>
                    </div>

                    {countryJson ==="" ? <div className="loading-message">
                      Please wait, data is loading
                      <div className="loading-dots">
                        <span>.</span>
                        <span>.</span>
                        <span>.</span>
                      </div>
                    </div> : ""}


                    {countryJson ==="" ? "" : <div className="mb-3 mt-3 d-flex">
                      <div className="cursor-ponter orangeColor mx-3" onClick={getCurrentLocation}>
                        {' '}
                        <BiCurrentLocation size={20} />
                      </div>
                      <div
                        className="mx-1 d-inline-block orangeColor choose-current-location cursor-ponter"
                        onClick={getCurrentLocation}
                      >
                        {TRANSLATIONS.FILTER.CHOOSE_CURRENT_LOCATION}
                      </div>
                    </div>}
                    {isAdvanceSearch ? handleAdvanceSearchRender() : null}
                    <div className="mt-2 mb-3 data-source-field-one">
                      {_.isEmpty(props?.initialValues?.district) ||
                      (!_.isEmpty(props?.initialValues?.district) && riskList?.length) ? (
                        <>
                          <Label htmlFor="country">Select Risk</Label>
                          <Field
                            component={renderReactSelectInputField}
                            name="risk"
                            placeholder="Choose Risk"
                            onChange={e => handleRiskChange(e)}
                            required={true}
                            isMulti={false}
                            className="mt-2 mb-3"
                            options={renderAPIParameterOptions(riskList, 'risk')}
                            isLoading={
                              dropdownDataLoading.key === 'risk' && dropdownDataLoading.isLoading
                            }
                          ></Field>
                        </>
                      ) : (
                        ''
                      )}
                      {_.isEmpty(props?.initialValues?.district) ||
                      (!_.isEmpty(props?.initialValues?.district) && monsoonList?.length) ? (
                        <>
                          <Label htmlFor="country">Select Monsoon</Label>
                          <Field
                            component={renderReactSelectInputField}
                            name="monsoon"
                            placeholder="Select Monsoon"
                            onChange={e => handleMonsoonChange(e)}
                            required={true}
                            isMulti={false}
                            className="mt-2 mb-3"
                            options={renderAPIParameterOptions(monsoonList, 'monsoon')}
                            isLoading={
                              dropdownDataLoading.key === 'monsoon' && dropdownDataLoading.isLoading
                            }
                          ></Field>
                        </>
                      ) : (
                        ''
                      )}

                      {isSystemShow && (
                        <>
                          <Label htmlFor="country">Select System</Label>
                          <Field
                            component={renderReactSelectInputField}
                            name="system"
                            placeholder="Select System"
                            onChange={e => handleSystemChange(e)}
                            required={true}
                            isMulti={false}
                            className="mt-2 mb-3"
                            options={renderAPIParameterOptions(systemList, 'system')}
                            isLoading={
                              dropdownDataLoading.key === 'system' && dropdownDataLoading.isLoading
                            }
                          ></Field>
                        </>
                      )}
                    </div>
                    <div className="mt-2 mb-3 ">
                      <MapMeasures
                        initialValues={props.initialValues}
                        handleFormButton={handleFormButton}
                        stageList={stageList}
                        strategyList={strategyList}
                        droughtIndicesList={droughtIndicesList}
                        checkedIndicesList={checkedIndicesList}
                        dropdownDataLoading={dropdownDataLoading}
                        filterActiveTab={props.filterActiveTab}
                        timePeriodList={props.timePeriodList}
                        handleMapDrought={props.handleMapDrought}
                        handleMeasureDrought={handleMeasureDrought}
                        handleDroughtIndices={props.handleDroughtIndices}
                        precipitationThreshold={precipitationThreshold}
                        setPrecipitationThreshold={setPrecipitationThreshold}
                        handleStrategy={handleStrategy}
                        handleStartDate={handleStartDate}
                        handleEndDate={handleEndDate}
                        handleTimePeriodChange={handleTimePeriodChange}
                        isMapTabShow={isMapTabShow}
                        handleButtonMarginTop={handleButtonMarginTop}
                      />
                    </div>
                  </div>{' '}
                  <div className="contingency-form-buttons">
                    {formButton === CONTINGENCY_FILTER_TABS.MAP ? (
                      <div id="apply_layers" data-tip data-for="apply_layers">
                        <Button
                          className="contingency-plan-button apply-layers-btn"
                          onClick={handleApplyLayers}
                          disabled={!isApplyLayers}
                          type="submit"
                        >
                          {t('CONTINGENCY_PLAN.APPLY_LAYERS')}
                        </Button>
                      </div>
                    ) : (
                      <Button
                        //className="contingency-plan-button contingency-measures-btn"
                        className={
                          isStrategyOpen
                            ? 'contingency-plan-button contingency-measures-btn contingency-measures-btn-margin-top'
                            : 'contingency-plan-button contingency-measures-btn'
                        }
                        onClick={() => handleContingencyMeasures()}
                        disabled={isContingencyMeasuresDisabled}
                      >
                        {t('CONTINGENCY_PLAN.CONTINGENCY_MEASURES')}
                      </Button>
                    )}
                    {showMapPreferenceTooltip ? (
                      <ReactTooltip
                        id="apply_layers"
                        effect="solid"
                        place="top"
                        type="info"
                        className="apply-layer-tooltip-st"
                      >
                        {TRANSLATIONS.FILTER.PLEASE_REMOVE_MAP_PREFERENCE_LAYER}
                      </ReactTooltip>
                    ) : (
                      ''
                    )}
                  </div>
                </form>
              </CardBody>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};
export default reduxForm({
  form: 'contingency-filter-form',
  enableReinitialize: true,
})(ContingencyPlanFilter);
