import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';

import { DATE_FORMAT, SOUTH_ASIA_CONST, SOUTH_ASIA_COUNTRY } from '../../Components/constants';
import { getCurrentYearMonth, renderParametersOptions } from '../../Components/helpers';
import {
  getDistrictByStateRequest,
  getIndicesRequest,
  getStateByCountryRequest,
} from '../../redux/actions';

function CreateBulletinModal({
  isOpenBulletin,
  toggleModal,
  countryList,
  LOCATION,
  dispatch,
  stateList,
  districtList,
  bulletinTypes,
  indicatorList,
  countryData,
  mapIndex,
  userFormData,
  isEditTitle,
}) {
  const history = useHistory();
  const [formData, setFormData] = useState({
    country: '',
    state: '',
    district: '',
    bulletin_type: '',
    indicators: [],
    startDate: '',
    endDate: '',
  });
  const [updatedBulletinTypes, setUpdatedBulletinTypes] = useState([]);
  const [errors, setErrors] = useState({ startDate: '', endDate: '' });
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    // Add a CSS class to the body element when the sideEditor is open
    if (isOpenBulletin) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    // Clean up the effect
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isOpenBulletin]);

  useEffect(() => {
    if (!_.isEmpty(userFormData)) {
      setFormData(prev => {
        return {
          ...prev,
          country: userFormData?.country,
          state: userFormData?.state,
          district: userFormData?.district,
          bulletin_type: userFormData?.bulletin_type,
        };
      });
    }
  }, [userFormData]);

  useEffect(() => {
    if (!_.isEmpty(countryData)) {
      setFormData(prev => {
        return {
          ...prev,
          country: countryData,
        };
      });
    }
  }, [countryData]);

  useEffect(() => {
    if (bulletinTypes?.length) {
      const filteredData = bulletinTypes.filter(type => type.name !== 'All');
      setUpdatedBulletinTypes(filteredData);
    }
  }, [bulletinTypes]);

  useEffect(() => {
    const isValid = () => {
      const requiredFields = [
        formData.country,
        formData.indicators,
        formData.bulletin_type,
        formData.startDate,
      ];

      if (formData.bulletin_type?.name === 'Monthly Bulletin') {
        return requiredFields.every(Boolean) && !Object.values(errors).some(Boolean);
      } else {
        return (
          requiredFields.every(Boolean) && formData.endDate && !Object.values(errors).some(Boolean)
        );
      }
    };

    const validity = isValid();
    setIsFormValid(validity);
  }, [formData, errors]);

  const handleChange = (newValue, action) => {
    let updatedFormData = {
      ...formData,
    };
    if (action.name === 'country') {
      updatedFormData[action.name] = newValue;
      updatedFormData.state = '';
      updatedFormData.district = '';
      dispatch(
        getStateByCountryRequest({
          requestObj: {
            location_type: LOCATION.State,
            parent_id: newValue?.id,
          },
          isCompareLocation: false,
        }),
      );
      dispatch(
        getIndicesRequest({
          requestObj: {
            type: 'country',
            type_id: newValue?.id,
          },
          module: 'drought-monitor',
        }),
      );
    } else if (action.name === 'state') {
      updatedFormData[action.name] = newValue;
      updatedFormData.district = '';
      dispatch(
        getDistrictByStateRequest({
          requestObj: {
            location_type: LOCATION.District,
            parent_id: newValue?.id,
          },
          isCompareLocation: false,
        }),
      );
    } else if (action.name === 'district') {
      updatedFormData[action.name] = newValue;
    } else if (action.name === 'bulletin_type') {
      updatedFormData[action.name] = newValue;
      updatedFormData.startDate = '';
      updatedFormData.endDate = '';
    } else if (action.name === 'indicators') {
      updatedFormData[action.name] = newValue; // newValue is an array for multi-select
    } else {
      updatedFormData[action.name] = newValue;
    }
    setFormData(updatedFormData);
  };

  const handleDates = e => {
    const { name, value } = e.target;

    const isValidYear = date => {
      const year = moment(date).year();
      return year >= 1999;
    };

    let startDate = name === 'start_date' ? moment(value) : moment(formData?.startDate);
    let endDate = name === 'end_date' ? moment(value) : moment(formData?.endDate);

    if (formData.bulletin_type?.name === 'Monthly Bulletin') {
      startDate = moment(value, 'YYYY-MM');
      endDate = moment(startDate).endOf('month');
    }

    let newErrors = { ...errors };

    // Validate StartDate
    if (startDate.isAfter(moment())) {
      newErrors.startDate = 'Start date cannot be in the future';
    } else if (!isValidYear(startDate)) {
      newErrors.startDate = 'Selected year should be within the range of 2000-current year';
    } else {
      newErrors.startDate = '';
    }

    // Validate endDate
    if (!formData.bulletin_type || formData.bulletin_type?.name !== 'Monthly Bulletin') {
      if (endDate.isBefore(startDate)) {
        newErrors.endDate = 'End date cannot be earlier than start date';
      } else if (endDate.isSame(startDate, 'day')) {
        newErrors.endDate = 'End date cannot be the same as start date';
      } else {
        newErrors.endDate = '';
      }
    }

    setErrors(newErrors);
    setFormData(prev => ({
      ...prev,
      [name === 'start_date' ? 'startDate' : 'endDate']: value,
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();

    history.push({
      pathname: '/create-online-bulletin',
      state: {
        formData,
        newMapIndex: mapIndex,
        layersList: indicatorList,
        isTitleEditable: isEditTitle,
      },
    });
    toggleModal();
  };

  return (
    <Modal isOpen={isOpenBulletin} toggle={toggleModal} className="create-bulletin-popup">
      <ModalHeader
        className="create-bulletin-custom-header"
        toggle={toggleModal}
        close={
          <Button className="create-bulletin-close-btn" onClick={() => toggleModal()}>
            <IoMdCloseCircleOutline className="close-svg" />
          </Button>
        }
      >
        <p className="create-bulletin-header mb-0">Create Bulletin</p>
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup row className=" mb-2">
            <Label for="country" className="dms-pillar-label mb-1">
              Country
            </Label>
            <Select
              name="country"
              placeholder="Select Country"
              options={renderParametersOptions(countryList, 'country')}
              onChange={handleChange}
              isMulti={false}
              value={formData.country}
              required={true}
              isDisabled={
                !_.isEmpty(userFormData)
                  ? true
                  : !_.isEmpty(countryData) && countryData?.country_name !== SOUTH_ASIA_COUNTRY
              }
            />
          </FormGroup>
          {formData?.country?.label !== SOUTH_ASIA_CONST.name ? (
            <>
              <FormGroup row className=" mb-2">
                <Label for="state" className="dms-pillar-label mb-1">
                  {countryData?.country_slug !== 'india' ? 'Province' : 'State'}
                </Label>
                <Select
                  name="state"
                  placeholder={countryData?.country_slug !== 'india' ? 'All Regions' : 'All States'}
                  options={renderParametersOptions(stateList)}
                  onChange={handleChange}
                  isMulti={false}
                  value={formData.state}
                  isDisabled={!_.isEmpty(userFormData)}
                />
              </FormGroup>
              <FormGroup row className=" mb-2">
                <Label for="district" className="dms-pillar-label mb-1">
                  District
                </Label>
                <Select
                  name="district"
                  placeholder="All Districts"
                  options={renderParametersOptions(districtList)}
                  onChange={handleChange}
                  isMulti={false}
                  value={formData.district}
                  isDisabled={!_.isEmpty(userFormData)}
                />
              </FormGroup>
            </>
          ) : null}
          <FormGroup row className=" mb-2">
            <Label for="bulletin_type" className="dms-pillar-label mb-1">
              Bulletin type
            </Label>
            <Select
              name="bulletin_type"
              placeholder="Select Bulletin Type"
              options={updatedBulletinTypes}
              onChange={handleChange}
              isMulti={false}
              value={formData.bulletin_type}
              isDisabled={!_.isEmpty(userFormData)}
            />
          </FormGroup>
          <FormGroup row className=" mb-2">
            <Label for="indicators" className="dms-pillar-label mb-1">
              Indicators (Select one or upto 3)
            </Label>
            <Select
              name="indicators"
              placeholder="Select Indicators"
              options={indicatorList}
              onChange={handleChange}
              isMulti={true}
              value={formData.indicators}
              isOptionDisabled={() => formData.indicators.length === 3}
              styles={{
                menu: base => ({
                  ...base,
                  maxHeight: '200px', // Ensure the outer menu container has the correct height
                }),
                menuList: base => ({
                  ...base,
                  maxHeight: '200px', // Set the max height for the dropdown options
                  overflowY: 'auto', // Add scrollbar if options exceed 300px
                }),
              }}
            />
          </FormGroup>
          <FormGroup row className="mb-2">
            <Label for="start_date" className="dms-pillar-label mb-1">
              Start Date
            </Label>
            <Input
              type={formData?.bulletin_type?.name === 'Monthly Bulletin' ? 'month' : 'date'}
              name="start_date"
              placeholder="Start Date"
              bsSize="sm"
              className="input-style-width"
              value={formData?.startDate}
              onChange={e => handleDates(e)}
              min={formData?.bulletin_type?.name === 'Monthly Bulletin' ? '1999-01' : '1999-01-01'}
              max={
                formData?.bulletin_type?.name === 'Monthly Bulletin'
                  ? getCurrentYearMonth()
                  : moment(new Date())
                      .subtract(15, 'days')
                      .format(DATE_FORMAT)
              }
              invalid={!!errors.startDate}
              disabled={!formData?.bulletin_type}
            />
            <FormFeedback>{errors.startDate}</FormFeedback>
          </FormGroup>
          {formData?.bulletin_type?.name !== 'Monthly Bulletin' ? (
            <FormGroup row className="mb-2">
              <Label for="end_date" className="dms-pillar-label mb-1">
                End Date
              </Label>
              <Input
                type="date"
                name="end_date"
                placeholder="End Date"
                bsSize="sm"
                className="input-style-width"
                value={formData?.endDate}
                onChange={e => handleDates(e)}
                min={formData.startDate}
                max={moment(new Date())
                  .subtract(15, 'days')
                  .format(DATE_FORMAT)}
                invalid={!!errors.endDate}
                disabled={!formData?.bulletin_type}
              />
              <FormFeedback>{errors.endDate}</FormFeedback>
            </FormGroup>
          ) : null}

          <FormGroup className="create-bulletin-submit-btn my-2  px-5">
            <Button type="submit" className="view-application-btn" disabled={!isFormValid}>
              Submit
            </Button>
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default CreateBulletinModal;
