import React, { useRef } from 'react';
import IWMI from '../../../assets/Images/iwmi-logo-tools.svg';
import { RxCross2 } from 'react-icons/rx';

function BulletinHeader({ headerIcons, setHeaderIcons }) {
  const fileInputRef = useRef(null);

  /**
   * to upload header icons
   */
  function uploadSingleFile(e) {
    setHeaderIcons([...headerIcons, URL.createObjectURL(e.target.files[0])]);
  }

  const handleUploadHeaderIcon = () => {
    fileInputRef.current.click();
  };

  const removeHeaderIcon = e => {
    const updatedHeaders = headerIcons.filter((item, index) => index !== e);
    setHeaderIcons(updatedHeaders);
  };

  return (
    <div className="bulletin-pdf-header my-3">
      <img src={IWMI} alt="Logo" className="bulletin-header-icon" />
      {headerIcons?.length
        ? headerIcons.map((icon, index) => (
            <div className=" mx-3 bulletin-header-icon-container">
              <img src={icon} alt={icon} className="bulletin-header-icon mx-1" />
              <RxCross2
                className="header-cross-icon"
                fill="#FFFFFF"
                stroke="#FFFFFF"
                onClick={() => removeHeaderIcon(index)}
              />
            </div>
          ))
        : null}
      <button
        onClick={() => handleUploadHeaderIcon()}
        style={headerIcons.length === 2 ? { display: 'none' } : {}}
        className="hide-btn"
      >
        + Add logo{' '}
        <input
          accept="image/*"
          type="file"
          disabled={headerIcons.length === 2}
          name="image"
          onChange={uploadSingleFile}
          style={{ cursor: 'pointer', display: 'none' }}
          ref={fileInputRef}
        />
      </button>
    </div>
  );
}

export default BulletinHeader;
