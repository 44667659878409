import React, { useEffect, useState } from 'react';
import { t } from 'react-multi-lang';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Container, Row } from 'reactstrap';

import Breadcrumb from '../../../Components/common/BreadCrumbsComponent';
import { getDmsPillarsRequest } from '../../../redux/home/homeActions';
import Footer from '../Footer';
// import Preparedness from './Preparedness';
import ToolsData from './ToolsData';

const ToolsManagement = () => {
  const dispatch = useDispatch();
  const URLParams = new URLSearchParams(window.location.search);
  const selectedTool = URLParams.get('selectedTool');
  const [selectedCategory, setSelectedCategory] = useState('monitoring_early_warning');
  const [pillarsData, setPillarsData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  // const [selectedSubRiskMitigation, setSelectedSubMitigation] = useState('');4

  const contentData = {
    monitoring_early_warning: t('LANDING_PAGE.MONITORING_EARLY_WARNING_DESC'),
    risk_impact: t('LANDING_PAGE.RISK_IMPACT_DESC'),
    risk_mitigation: t('LANDING_PAGE.RISK_MITIGATION_DESC'),
  };

  const { LandingPageData } = useSelector(state => ({
    LandingPageData: state.Home,
  }));

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getDmsPillarsRequest());
    if (selectedTool === 'risk_mitigation') {
      setSelectedCategory(selectedTool);
      // const subTool = URLParams.get('selectedSubTool');
      // setSelectedSubMitigation(subTool || 'risk_mitigation_category');
    } else {
      setSelectedCategory(selectedTool || 'monitoring_early_warning');
    }
  }, [selectedTool]);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   if (locationData?.state) setSelectedCategory(locationData?.state?.selected);
  // }, [locationData]);

  useEffect(() => {
    if (LandingPageData.getDmsPillarsData) {
      const { result } = LandingPageData.getDmsPillarsData.data;
      const filteredData = result.filter(pillar => pillar.pillar_type === selectedCategory);
      setCategoryData(filteredData);
      setPillarsData(result);
    }
  }, [LandingPageData.getDmsPillarsData]);

  const renderContent = content => {
    return <p className="tools-content">{content}</p>;
  };

  const handleSelectCategory = categoryName => {
    const filteredData = pillarsData.filter(pillar => pillar.pillar_type === categoryName);
    setCategoryData(filteredData);
    setSelectedCategory(categoryName);
  };
  return (
    <div className="spacing-top">
      <Breadcrumb items={['Home', 'Tools']} />
      <Container fluid className="tools-management-container">
        <Row className="pillars-title">
          <Col>
            <h1>{t('LANDING_PAGE.PILLARS_OF_DISATER')}</h1>
          </Col>
        </Row>
        <Row className="pillars justify-content-between px-0 mx-0">
          <Col xs={12} md={4} className="pl-0" style={{ paddingLeft: '0' }}>
            <Button
              className={`pillar ${
                selectedCategory === 'monitoring_early_warning' ? 'selected' : ''
              }`}
              onClick={() => handleSelectCategory('monitoring_early_warning')}
            >
              {t('LANDING_PAGE.MONITORING_EARLY_WARNING')}
            </Button>
          </Col>
          <Col xs={12} md={4}>
            <Button
              className={`pillar ${selectedCategory === 'risk_impact' ? 'selected' : ''}`}
              onClick={() => handleSelectCategory('risk_impact')}
            >
              {t('LANDING_PAGE.RISK_IMPACT')}
            </Button>
          </Col>
          <Col xs={12} md={4} className="pr-0" style={{ paddingRight: '0' }}>
            <Button
              className={`pillar ${selectedCategory === 'risk_mitigation' ? 'selected' : ''}`}
              onClick={() => handleSelectCategory('risk_mitigation')}
            >
              {t('LANDING_PAGE.RISK_MITIGATION')}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>{renderContent(contentData[selectedCategory])}</Col>
        </Row>
        <Row
          className={
            categoryData?.length ? (categoryData[0].values?.length ? 'tools-grid' : '') : ''
          } // Manage grid system based
        >
          {/* <Col className=" h-100"> */}
          {/* {['monitoring_early_warning', 'risk_impact'].includes(selectedCategory) ? (
              <ToolsData items={categoryData?.length ? categoryData[0].values : []} />
            ) : (
              <Preparedness
                data={categoryData}
                selectedSubRiskMitigation={selectedSubRiskMitigation}
              />
            )} */}
          <ToolsData
            items={categoryData?.length ? categoryData[0].values : []}
            selectedCategory={selectedCategory}
          />
          {/* </Col> */}
        </Row>
      </Container>
      <Footer backgroundColor="bg-white" />
    </div>
  );
};

export default ToolsManagement;
