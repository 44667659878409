import React, { useEffect, useMemo } from 'react';
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table';
import { MdModeEditOutline } from 'react-icons/md';

import { useTranslation } from 'react-multi-lang';
import { Table } from 'reactstrap';
import { checkPermission, isSuperAdmin } from '../../../Components/common/utils';

export const DistrictProfileTable = props => {
  const t = useTranslation();

  const COLUMNS = props?.districtProfileTable?.columns;

  const columns = useMemo(() => COLUMNS);
  const data = useMemo(() => props?.districtProfileTable?.rowData, [
    props?.districtProfileTable?.rowData,
  ]);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: props?.districtProfileTable?.rowData?.length },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const { getTableProps, getTableBodyProps, page, prepareRow } = tableInstance;

  const handleEditClick = (row, data, index) => {
    const list1 = page.slice(0, index).reverse();

    const filteredColumnHeader = list1.find(each => each?.original?.col_type === 'column');
    if (filteredColumnHeader) {
      props?.setColumnHeader(filteredColumnHeader);
    }
    props.setSelectedRow(row);
    const initialValues = row.cells.reduce((acc, cell) => {
      acc[cell.column.id] = cell.value;
      return acc;
    }, {});

    props.setEditValues(initialValues);
  };

  useEffect(() => {
    if (page?.length) {
      props?.setColumnHeader(page?.[0]);
    }
  }, []);

  return (
    <>
      <div className="contingency-plan-section">
        {props?.districtProfileTable?.rowData.length === 0 ? (
          <p>{t('NEWS_FEED.NO_RECORDS_FOUND')}</p>
        ) : (
          <Table {...getTableProps()} className="district-table-style">
            <>
              <tbody
                {...getTableBodyProps()}
                className="body table-wrapper-scroll-y my-custom-scrollbar"
              >
                {page?.map((row, index) => {
                  prepareRow(row);
                  return (
                    <>
                      <tr
                        {...row.getRowProps()}
                        className={
                          row?.original?.col_type === 'column'
                            ? 'ditrict-profile-table-sticky'
                            : 'data-rows'
                        }
                      >
                        {row?.cells?.map(cell => {
                          return (
                            <>
                              <>
                                <td
                                  {...cell.getCellProps()}
                                  className={
                                    row.original.col_type === 'column'
                                      ? 'ditrict-profile-table-column-name'
                                      : ''
                                  }
                                >
                                  {cell.value === '-' || cell.value === ''
                                    ? props.selectedRow?.id === row?.id
                                      ? ''
                                      : ''
                                    : props.selectedRow?.id === row?.id
                                    ? cell?.value
                                    : cell?.value}
                                </td>
                              </>
                            </>
                          );
                        })}

                        {checkPermission(props.userPermissions, 'change_contingency_plan') ||
                        isSuperAdmin() ? (
                          <td
                            className={
                              row.original.col_type === 'column'
                                ? 'ditrict-profile-table-column-name'
                                : ''
                            }
                          >
                            <p
                              onClick={() =>
                                row?.original?.col_type === 'column'
                                  ? {}
                                  : handleEditClick(row, page, index)
                              }
                              className="contigency-plan-edit-icon"
                            >
                              {' '}
                              {row?.original?.col_type === 'column' ? (
                                ''
                              ) : (
                                <MdModeEditOutline />
                              )}{' '}
                            </p>
                          </td>
                        ) : (
                          ''
                        )}
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </>
          </Table>
        )}
      </div>
    </>
  );
};
