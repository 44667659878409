import React from 'react';
import { t } from 'react-multi-lang';
import { useHistory } from 'react-router-dom';

import { Card, CardBody, CardHeader, CardText } from 'reactstrap';
import NoResultIcon from '../../../assets/Images/no-results-icon.svg';
import { isLogedIn } from '../../../Components/common/utils';

function ToolsData({ items, selectedCategory }) {
  const history = useHistory();
  // const [isExpanded, setIsExpanded] = useState(false);

  const handleOpenFile = (evt, data) => {
    evt.preventDefault();
    let result = data.country_slug;
    const countryData = JSON.stringify(data);
    localStorage.setItem('countryData', countryData);
    // localStorage.setItem('selectedCountry', JSON.stringify(result));

    if (data.file) {
      window.open(data.file, '_blank');
    } else {
      if (data.website_url) {
        if (data.website_url.includes('drougth-monitor')) {
          if (!isLogedIn()) {
            history.push({
              pathname: '/login',
              search: `?Country=${result}&prevPath=drought-monitor`,
              state: {
                countryData: data,
              },
            });
          } else {
            history.push({
              pathname: '/drought-monitor',
              search: `?Country=${result}`,
              state: {
                countryData: data,
              },
            });
          }
        } else {
          window.open(data.website_url, '_blank').focus();
        }
      } else {
        if (!isLogedIn()) {
          history.push({
            pathname: '/login',
            search: `?Country=${result}&prevPath=drought-management-system`,
            state: {
              countryData: data,
            },
          });
        } else {
          history.push({
            pathname: `/drought-management-system`,
            search: `?Country=${result}`,
            state: {
              countryData: data,
            },
          });
        }
      }
    }
  };

  // const toggleReadMore = event => {
  //   event.stopPropagation(); // Prevents the card click event
  //   setIsExpanded(!isExpanded);
  // };

  // // Limit description to 300 characters and append "..." when truncated
  // const truncateDescription = description => {
  //   if (description.length > 300) {
  //     return isExpanded ? description : `${description.slice(0, 300)}...`;
  //   }
  //   return description;
  // };

  return items?.length ? (
    items.map(item => {
      return selectedCategory === 'monitoring_early_warning' ? (
        <Card
          className="tools-cards mb-3 cursor-ponter"
          onClick={e => handleOpenFile(e, item)}
          key={item.id}
        >
          <img
            src={item.icon}
            alt={item.name}
            width={84}
            height={84}
            className="tools-card-image mb-3"
          />
          <CardHeader className=" bg-transparent border-0 tools-card-header p-0">
            {item.name}
          </CardHeader>
          <CardBody className=" m-0">
            <CardText className="tools-card-desc">
              {/* {truncateDescription(item.description)}
              {item.description.length > 300 && (
                <span
                  className="read-more-toggle"
                  onClick={e => toggleReadMore(e)} // Pass event to the function
                  style={{ color: 'blue', cursor: 'pointer' }}
                >
                  {isExpanded ? ' Read Less' : ' Read More'}
                </span>
              )} */}
              {item.description}
            </CardText>
          </CardBody>
        </Card>
      ) : (
        <Card
          className="tools-cards tools-card-2 mb-3 cursor-ponter d-flex flex-row"
          onClick={e => handleOpenFile(e, item)}
          key={item.id}
        >
          <img src={item.icon} alt={item.name} className="tools-card-image tools-card-image-size" />
          <CardBody className="py-0 tools-card-body ">
            <CardHeader
              className=" bg-transparent border-0 tools-card-header p-0"
              title={item.name}
            >
              {item.name}
            </CardHeader>
            <CardText className="tools-card-desc tools-card-overflow-text">
              {item.description}
            </CardText>
          </CardBody>
        </Card>
      );
    })
  ) : (
    <div className=" h-100 d-flex justify-content-center align-items-center flex-column p-5">
      <img src={NoResultIcon} alt="No results to show" className=" d-block" />
      <p className="no-result-text">{t('LANDING_PAGE.NO_RESULTS_FOUND')}</p>
    </div>
  );
}

export default ToolsData;
