import React from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { Row, Col } from 'reactstrap';
import * as turf from '@turf/turf';
import { FaTrashCan } from 'react-icons/fa6';
import { showError } from '../Notifications';

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div className="point-modal-paragraph-relative">
    {input?.value ? <p className="dm-point-form-lable point-modal-paragraph-title">{label}</p> : ''}
    <div className="h-100">
      <input {...input} type={type} placeholder={label} className="input-lat" />
      {touched && error && <p className="point-data-error-text">{error}</p>}
    </div>
  </div>
);

const renderMembers = ({ fields, meta: { error } }) => (
  <div>
    <div className="render-members-card">
      {fields.map((member, index) => (
        <div key={index} className="location-card">
          <div className="dm-point-header-text">
            <p className="lat-lng-title">Location {index + 1}</p>
            <FaTrashCan onClick={() => fields.remove(index)} className="delete-icon-color" />
          </div>
          <Row className="dm-point-data-form">
            <Col sm={12} md={6} className="dm-point-data-form">
              <Field
                name={`${member}.latitude`}
                type="text"
                component={renderField}
                label="Latitude"
                className="input-lat"
              />
            </Col>
            <Col sm={12} md={6} className="dm-point-data-form">
              <Field
                name={`${member}.longitude`}
                type="text"
                component={renderField}
                label="Longitude"
              />
            </Col>
          </Row>
          {error && <span className="error-message">{error}</span>}
        </div>
      ))}
    </div>
    {fields?.length < 6 ? (
      <div>
        <p className="add-location-text" onClick={() => fields.push()}>
          + Add Location
        </p>
      </div>
    ) : (
      ''
    )}
  </div>
);

const validate = values => {
  const errors = {};

  if (!values.members || !values.members.length) {
    errors.members = { _error: 'At least one location must be entered' };
  } else {
    const membersArrayErrors = [];

    values.members.forEach((member, memberIndex) => {
      const memberErrors = {};

      if (!member || !member.latitude) {
        memberErrors.latitude = 'Latitude is required';
        membersArrayErrors[memberIndex] = memberErrors;
      } else if (isNaN(Number(member.latitude))) {
        memberErrors.latitude = 'Latitude must be a number';
        membersArrayErrors[memberIndex] = memberErrors;
      }

      if (!member || !member.longitude) {
        memberErrors.longitude = 'Longitude is required';
        membersArrayErrors[memberIndex] = memberErrors;
      } else if (isNaN(Number(member.longitude))) {
        memberErrors.longitude = 'Longitude must be a number';
        membersArrayErrors[memberIndex] = memberErrors;
      }
    });

    if (membersArrayErrors.length) {
      errors.members = membersArrayErrors;
    }
  }

  return errors;
};

const FieldArraysForm = props => {
  const { handleSubmit, pristine, reset, submitting, setManuleMarker, toggle, countryJson } = props;

  // validation for latitide and longitude using json file
  const onSubmit = data => {
    const validDataPoints = [];
    const inValidPoints = [];
    data.members?.map(eachPoint => {
      const point = turf.point([parseFloat(eachPoint.longitude), parseFloat(eachPoint.latitude)]);
      if (turf.booleanPointInPolygon(point, countryJson.features?.[0])) {
        validDataPoints.push(eachPoint);
      } else {
        inValidPoints.push(eachPoint);
      }
      return null;
    });

    if (inValidPoints?.length) {
      const pointsList = inValidPoints
        .map(each => `(${each.latitude}, ${each.longitude})`)
        .join(', ');

      // Construct the error message
      const errorMessage = `The following points are invalid: ${pointsList}`;
      showError(errorMessage);
    }
    if (validDataPoints) {
      setManuleMarker(validDataPoints);
    }
    toggle();
  };

  // const onSubmit = data => {
  //   const validDataPoints = [];
  //   const inValidPoints = [];

  //   data.members.forEach((eachPoint, index) => {
  //     const uniqueId = uuidv4();
  //     const latitude = parseFloat(eachPoint.latitude);
  //     const longitude = parseFloat(eachPoint.longitude);

  //     if (!isNaN(latitude) && !isNaN(longitude)) {
  //       validDataPoints.push({
  //         ...eachPoint,
  //         id: uniqueId,
  //         origin: 'manual',
  //         layerType: 'marker',
  //         polygonCount: '',
  //         latlngs: [latitude, longitude],
  //       });
  //     } else {
  //       inValidPoints.push(index);
  //     }
  //   });

  //   if (inValidPoints.length > 0) {
  //     showError('Invalid latitude or longitude entered in one or more locations.');
  //   }

  //   if (validDataPoints.length > 0) {
  //     setManuleMarker(validDataPoints);
  //     toggle();
  //   }
  // };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FieldArray name="members" component={renderMembers} />
      <div>
        <button
          type="button"
          disabled={pristine || submitting}
          // onClick={reset}
          onClick={() => toggle()}
          className="point-data-cancle-btn mr-3"
        >
          Cancel
        </button>

        <button type="submit" disabled={submitting} className="go-next-btn ml-3">
          Go Next
        </button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'fieldArraysForm', // a unique identifier for this form
  validate,
  initialValues: {
    members: [{ latitude: '', longitude: '' }], // Initial value with one empty member
  },
})(FieldArraysForm);
