import React from 'react';
import { Col, Row } from 'reactstrap';

import CGIAR_NEW from '../../../assets/Images/cgiar_neww.svg';
import IWMI_NEW from '../../../assets/Images/iwmi_neww.svg';

function BulletinFooter() {
  return (
    <>
      <div className="bulletin-footer-container">
        <Row>
          <Col xs={1} className="bulletin-disclaimer-header pr-0">
            Disclaimer :
          </Col>
          <Col xs={11} className="bulletin-disclaimer-text p-0">
            All content within this bulletin is based upon the most current available data. As the
            drought is a dynamic situation, the current realities may differ from what is depicted
            in this document. The product has not been validated and used only the weather forecast
            and remote sensing observation. We welcome the feedback from the end-users and request
            you to provide field observations and any other details which can improve the product
            quality and prediction skills in the near future.
          </Col>
        </Row>
      </div>
      <div className="bulletin-footer">
        <div className="bulletin-footer-data">
          <p className="bulletin-footer-text m-0">
            For further information please contact :
            <a href={`mailto:wrd@iwmi.org`} className="email-font-color ">
              {' '}
              wrd@iwmi.org
            </a>
          </p>
          <div>
            <img src={IWMI_NEW} alt="Logos" className="logos" />
            <img src={CGIAR_NEW} alt="Logos" className="logos" />
          </div>
        </div>
        <div>
          <p className="text-center m-0">
            <span className="copy-icon">&copy;</span>
            <a
              className="copyright-text climber-footer"
              href="https://www.iwmi.cgiar.org/copyright/"
              target="_blank"
              rel="noreferrer"
            >
              2024 International Water Management Institute | Terms of use
            </a>
          </p>
        </div>
      </div>
    </>
  );
}

export default BulletinFooter;
