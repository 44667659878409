import React, { useMemo, useEffect } from 'react';
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table';
import { useTranslation } from 'react-multi-lang';
import { Table } from 'reactstrap';
import { MdModeEditOutline } from 'react-icons/md';
import { checkPermission, isSuperAdmin } from '../../../Components/common/utils';

const ContingencyMeasuresTable = props => {
  const t = useTranslation();

  const handleEditClick = row => {
    props.setSelectedRow(row);
    const initialValues = row.cells.reduce((acc, cell) => {
      acc[cell.column.id] = cell.value;
      return acc;
    }, {});

    props.setEditValues(initialValues);
  };

  const COLUMNS = props?.contingencyMeasuresTable?.columns;

  const columns = useMemo(() => COLUMNS);
  const data = useMemo(() => props?.contingencyMeasuresTable?.rowData, [
    props?.contingencyMeasuresTable?.rowData,
  ]);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: props?.contingencyMeasuresTable?.rowData?.length },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const { getTableProps, getTableBodyProps, page, prepareRow } = tableInstance;
  useEffect(() => {
    if (page?.length) {
      props?.setColumnHeader(page?.[0]);
    }
  }, []);

  // const downloadFile = () => {
  //   // Step 1: Create the data
  //   const data = "This is the content of the text file.";

  //   // Step 2: Create a Blob
  //   const blob = new Blob([data], { type: 'text/plain' });

  //   // Step 3: Create a URL for the Blob
  //   const url = URL.createObjectURL(blob);

  //   // Step 4: Create an anchor element and trigger the download
  //   const a = document.createElement('a');
  //   a.href = url;
  //   a.download = 'example.txt'; // Set the file name
  //   document.body.appendChild(a);
  //   a.click();
    
  //   // Clean up and remove the anchor element
  //   document.body.removeChild(a);
  //   URL.revokeObjectURL(url);
  // };


  return (
    <>
      <div className="contingency-plan-section">
        {props?.contingencyMeasuresTable?.rowData.length === 0 ? (
          <p>{t('NEWS_FEED.NO_RECORDS_FOUND')}</p>
        ) : (
          <Table {...getTableProps()} className="district-table-style">
            <>
              <tbody
                {...getTableBodyProps()}
                className="body table-wrapper-scroll-y my-custom-scrollbar"
              >
                {page?.map((row, index) => {
                  prepareRow(row);
                  return (
                    <>
                      <tr
                        {...row.getRowProps()}
                        className={
                          row.original.col_type === 'column'
                            ? 'ditrict-profile-table-sticky'
                            : 'data-rows'
                        }
                      >
                        {row?.cells?.map(cell => {
                          return (
                            <>
                              <>
                                <td
                                  {...cell.getCellProps()}
                                  className={
                                    row.original.col_type === 'column'
                                      ? 'ditrict-profile-table-column-name '
                                      : ''
                                  }
                                >
                                  {cell.value === '-' || cell.value === ''
                                    ? props.selectedRow?.id === row?.id
                                      ? ''
                                      : ''
                                    : props.selectedRow?.id === row?.id
                                    ? cell?.value
                                    : cell?.value}
                                </td>
                              </>
                            </>
                          );
                        })}

                        {checkPermission(props.userPermissions, 'change_contingency_plan') ||
                        isSuperAdmin() ? (
                          <>
                            <td
                              className={
                                row.original.col_type === 'column'
                                  ? 'ditrict-profile-table-column-name'
                                  : ''
                              }
                            >
                              <p
                                onClick={() =>
                                  row.original.col_type === 'column'
                                    ? {}
                                    : handleEditClick(row, index)
                                }
                                className="contigency-plan-edit-icon"
                              >
                                {' '}
                                {row.original.col_type === 'column' ? (
                                  ''
                                ) : (
                                  <MdModeEditOutline />
                                )}{' '}
                              </p>
                            </td>
                          </>
                        ) : (
                          ''
                        )}
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </>
          </Table>
        )}
      </div>
    </>
  );
};
export default ContingencyMeasuresTable;
