import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { t } from 'react-multi-lang';
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';

import { ReactComponent as DeleteIcon } from '../../assets/Images/edit-icon.svg';
import { ReactComponent as UploadIcon } from '../../assets/Images/upload-image.svg';
import { DATE_FORMAT } from '../../Components/constants';
import { showError } from '../../Components/Notifications';
// import { showError } from '../../Components/Notifications';

const NewEventsModal = ({ modal, setModal, formData, setFormData, isEditable, onHandleEvents }) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [errors, setErrors] = useState({});
  const [imagePreview, setImagePreview] = useState(null);
  const [initialData, setInitialData] = useState({});

  const toggleModal = () => {
    setModal(!modal);
    setFormData({
      name: '',
      location: '',
      from_date: moment(new Date()).format(DATE_FORMAT),
      to_date: moment(new Date())
        .add(6, 'days')
        .format(DATE_FORMAT),
      start_time: '09:00',
      end_time: '18:00',
      event_url: '',
      event_icon: null,
    });
  };

  const handleChange = e => {
    const { name, value, files } = e.target;

    let updatedFormData = {
      ...formData,
    };

    if (name === 'from_date') {
      updatedFormData.from_date = moment(value).format(DATE_FORMAT);
      updatedFormData.to_date = moment(value)
        .add(6, 'days')
        .format(DATE_FORMAT);
      setFormData(updatedFormData);
      validateField(name, updatedFormData);
    } else if (name === 'event_icon' && files[0]) {
      // const file = files[0];
      // updatedFormData[name] = files ? files[0] : null;
      // setImagePreview(URL.createObjectURL(file)); // Create a preview URL for the uploaded image

      // e.target.value = null;

      const file = files[0];

      const img = new Image();
      const objectUrl = URL.createObjectURL(file);

      img.onload = () => {
        // Define acceptable dimensions range and target size for the banner
        const minWidth = 180;
        const minHeight = 109;
        const maxWidth = 1024;
        const maxHeight = 436;

        if (
          img.width >= minWidth &&
          img.width <= maxWidth &&
          img.height >= minHeight &&
          img.height <= maxHeight
        ) {
          // If dimensions are within the acceptable range, update form data and image preview
          updatedFormData[name] = file;
          setImagePreview(objectUrl);
          setFormData(updatedFormData);
          validateField(name, updatedFormData);
        } else {
          showError(
            `Please upload an image with dimensions between ${minWidth}x${minHeight} and ${maxWidth}x${maxHeight} pixels.`,
          );
        }

        // Clean up the original object URL after the image is loaded
        URL.revokeObjectURL(objectUrl);
      };

      img.onerror = () => {
        // Handle image loading error
        showError('Invalid image file. Please try again.');
      };

      // Start loading the image by assigning src
      img.src = objectUrl;

      // Reset input so the same file can be re-uploaded if needed
      e.target.value = null;
    } else {
      updatedFormData[name] = files ? files[0] : value;
      setFormData(updatedFormData);
      validateField(name, updatedFormData);
    }
  };

  const validateField = (name, recentFormData) => {
    let errorMessages = {};

    const currentDate = moment().add(1, 'days');
    const startDateTime = moment(
      `${recentFormData.from_date} ${recentFormData.start_time}`,
      'YYYY-MM-DD HH:mm',
    );
    const endDateTime = moment(
      `${recentFormData.to_date} ${recentFormData.end_time}`,
      'YYYY-MM-DD HH:mm',
    );

    switch (name) {
      case 'from_date':
      case 'start_time':
        if (startDateTime.isBefore(currentDate) && !startDateTime.isSame(currentDate, 'day')) {
          errorMessages.from_date = t('LANDING_PAGE.CURRENT_DATE_ERROR');
          errorMessages.start_time = t('LANDING_PAGE.CURRENT_DATE_ERROR');
        } else if (startDateTime.isAfter(endDateTime)) {
          errorMessages.from_date = t('LANDING_PAGE.NEXT_DATE_ERROR');
          errorMessages.start_time = t('LANDING_PAGE.NEXT_TIME_ERROR');
        } else if (startDateTime.isSame(endDateTime)) {
          errorMessages.start_time = t('LANDING_PAGE.SAME_TIME_ERROR');
          errorMessages.end_time = t('LANDING_PAGE.END_TIME_ERROR');
        } else {
          errorMessages.from_date = '';
          errorMessages.start_time = '';
          errorMessages.to_date = '';
          errorMessages.end_time = '';
        }
        break;

      case 'to_date':
      case 'end_time':
        if (endDateTime.isBefore(startDateTime)) {
          errorMessages.to_date = t('LANDING_PAGE.END_DATE_ERROR');
          errorMessages.end_time = t('LANDING_PAGE.END_TIME_BEFORE_ERROR');
        } else if (endDateTime.isSame(startDateTime)) {
          errorMessages.end_time = t('LANDING_PAGE.END_TIME_ERROR');
          errorMessages.start_time = t('LANDING_PAGE.SAME_TIME_ERROR');
        } else {
          errorMessages.to_date = '';
          errorMessages.end_time = '';
          errorMessages.from_date = '';
          errorMessages.start_time = '';
        }
        break;

      case 'location':
        if (recentFormData.location.length > 120) {
          errorMessages.location = 'Limit exceeded: Maximum 120 characters.';
        } else {
          errorMessages.location = '';
        }
        break;

      default:
        break;
    }

    setErrors(prevErrors => ({
      ...prevErrors,
      ...errorMessages,
    }));
  };

  useEffect(() => {
    if (isEditable) {
      setInitialData(formData); // Store the original data when editing
    }
  }, []);

  useEffect(() => {
    // Check if all required fields are filled
    const isValid =
      formData.name &&
      formData.location &&
      formData.start_time &&
      formData.end_time &&
      formData.from_date &&
      formData.to_date &&
      formData.event_url &&
      formData.event_icon &&
      !Object.values(errors).some(error => error);
    setIsFormValid(isValid);
  }, [formData, errors]);

  const handleImageRemove = () => {
    setFormData({
      ...formData,
      event_icon: null,
    });
    setImagePreview(null); // Remove the image preview
  };

  const getChangedFields = () => {
    const changedFields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== initialData[key]) {
        changedFields[key] = formData[key];
      }
    });

    return changedFields;
  };

  const handleSubmit = e => {
    e.preventDefault();
    const dataToSubmit = isEditable ? getChangedFields() : formData;
    onHandleEvents(dataToSubmit);
    toggleModal();
    setInitialData({});
  };

  return (
    <>
      <div className="client-modal">
        <Modal
          isOpen={modal}
          toggle={toggleModal}
          className="dms-landing-modal"
          backdrop="static"
          keyboard={false}
        >
          <Form onSubmit={handleSubmit}>
            <ModalHeader
              className="client-detail-header justify-content-between"
              toggle={toggleModal}
            >
              {isEditable ? t('LANDING_PAGE.EDIT_EVENT') : t('LANDING_PAGE.ADD_NEW_EVENT')}
            </ModalHeader>
            <ModalBody className="events-modal-container">
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Label for="name" className="dms-pillar-label mb-1">
                      {t('LANDING_PAGE.TITLE')}
                    </Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      className="dms-pillar-select"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Write your text here..."
                      required
                    />
                  </FormGroup>
                </Col>

                <Col xs={6}>
                  <FormGroup>
                    <Label for="from_date" className="dms-pillar-label mb-1">
                      {t('LANDING_PAGE.START_DATE')}
                    </Label>
                    <Input
                      type="date"
                      name="from_date"
                      id="from_date"
                      className="dms-pillar-select number-font-family"
                      value={formData.from_date}
                      onChange={handleChange}
                      required
                      min={moment(new Date())
                        .add(1, 'days')
                        .format(DATE_FORMAT)}
                      invalid={!!errors.from_date}
                    />
                    <FormFeedback tooltip>{errors.from_date}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label for="to_date" className="dms-pillar-label mb-1">
                      {t('LANDING_PAGE.END_DATE')}
                    </Label>
                    <Input
                      type="date"
                      name="to_date"
                      id="to_date"
                      className="dms-pillar-select number-font-family"
                      value={formData.to_date}
                      onChange={handleChange}
                      required
                      min={moment(new Date(formData.from_date)).format(DATE_FORMAT)}
                      invalid={!!errors.to_date}
                    />
                    <FormFeedback tooltip>{errors.to_date}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label for="start_time" className="dms-pillar-label mb-1">
                      {t('LANDING_PAGE.START_TIME')}
                    </Label>
                    <Input
                      type="time"
                      name="start_time"
                      id="start_time"
                      className="dms-pillar-select number-font-family"
                      value={formData.start_time}
                      onChange={handleChange}
                      required
                      invalid={!!errors.start_time}
                    />
                    <FormFeedback tooltip>{errors.start_time}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label for="end_time" className="dms-pillar-label mb-1">
                      {t('LANDING_PAGE.END_TIME')}
                    </Label>
                    <Input
                      type="time"
                      name="end_time"
                      id="end_time"
                      className="dms-pillar-select number-font-family"
                      value={formData.end_time}
                      onChange={handleChange}
                      required
                      invalid={!!errors.end_time}
                    />
                    <FormFeedback tooltip>{errors.end_time}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup>
                    <Label for="event_url" className="dms-pillar-label mb-1">
                      {t('LANDING_PAGE.URL')}
                    </Label>
                    <Input
                      type="text"
                      name="event_url"
                      id="event_url"
                      className="dms-pillar-select"
                      value={formData.event_url}
                      onChange={handleChange}
                      placeholder="https://example.com"
                      required
                    />
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup>
                    <Label for="location" className="dms-pillar-label mb-1">
                      {t('LANDING_PAGE.LOCATION')}
                    </Label>
                    <Input
                      type="text"
                      name="location"
                      id="location"
                      className="dms-pillar-select"
                      value={formData.location}
                      onChange={handleChange}
                      placeholder="Write your location here...(Max: 120 Characters)"
                      required
                      invalid={!!errors.location}
                    />
                    <FormFeedback tooltip>{errors.location}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup>
                    <Label for="coverImage" className="dms-pillar-label mb-1">
                      {t('LANDING_PAGE.UPLOAD_COVER_IMAGE')}
                    </Label>
                    <div className="custom-input-file w-100">
                      {imagePreview || formData.event_icon ? (
                        <div className="image-preview-container">
                          <img
                            src={imagePreview || formData.event_icon}
                            alt="Profile Preview"
                            className="image-preview"
                          />
                          <div className="image-overlay"></div>
                          <button
                            type="button"
                            className="dms-delete-file"
                            onClick={handleImageRemove}
                          >
                            <DeleteIcon width={20} height={20} />
                          </button>
                        </div>
                      ) : (
                        <Label htmlFor="event_icon" className="custom-input-file-label">
                          <UploadIcon width={36} height={36} />
                          <p>{t('LANDING_PAGE.UPLOAD_IMAGE')}</p>
                          <span>Upload an image of max 1024x436 dimensions is recommended</span>
                        </Label>
                      )}
                      <Input
                        type="file"
                        name="event_icon"
                        id="event_icon"
                        accept=".jpg,.jpeg,.png"
                        onChange={handleChange}
                        hidden
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className="modal-viewdeatil-footer">
              <button onClick={() => toggleModal()} className="reject-button">
                {t('LANDING_PAGE.CANCEL')}
              </button>{' '}
              <button onClick={handleSubmit} disabled={!isFormValid} className="approve-button">
                {isEditable ? t('LANDING_PAGE.SAVE') : t('LANDING_PAGE.ADD')}
              </button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default NewEventsModal;
