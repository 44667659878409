import React from 'react';
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import {
  DISPLAY_CHART_DATE,
  DATE_FORMAT_ONE,
  DATE_FORMAT,
  DISPLAY_DATE_FORMAT,
  DATA_SOURCES,
  OPEN_WEATHER_PARAMETER_SLUG,
  OPEN_WEATHER_PARAMETERS_CONST,
  MONTHS,
} from '../constants';
import myConstClass from '../../Constants';
import _ from 'lodash';
import {
  AXIOS_INSTANCE,
  ERPAS_TIMESERIES,
  IRI_TIMESERIES,
  NOAA_TIMESERIES,
  WEATHER_FORECAST_DATA_URL,
  MASTER_SERVER_BASE_URL,
  AXIOS_INSTANCE_MASTER_SERVER,
} from '../../redux/api/apiEndPoint';

//function for date
export const chartDateLabels = date => {
  let formatedDate = new Date(date);
  formatedDate = moment(formatedDate)
    .local()
    .format('M/DD @ hA');
  return formatedDate;
};

export const OpenWeatherChartLabels = date => {
  let formatedDate = new Date(date);
  formatedDate = moment(formatedDate)
    .local()
    .format('DD-MM-YYYY @ hA');
  return formatedDate;
};

export const chartDateLabelsDay = date => {
  let formatedDate = new Date(date);
  formatedDate = moment(formatedDate)
    .local()
    .format('M/DD');
  return formatedDate;
};

export const newsFeedDateFormat = date => {
  let formatedDate = new Date(date);
  formatedDate = moment(formatedDate)
    .local()
    .format('LL');
  return formatedDate;
};

//function for displaying date in charts
export const renderChartTime = date => {
  let formatedDate = new Date(date);
  formatedDate = moment(formatedDate)
    .local()
    .format(DISPLAY_CHART_DATE);
  return formatedDate;
};

//function for displaying date in charts
export const renderFullDateFormat = date => {
  let formatedDate = new Date(date);
  formatedDate = moment(formatedDate)
    .local()
    .format(DATE_FORMAT_ONE);
  return formatedDate;
};

//function for displaying date in charts
export const renderDateFormat = date => {
  let formatedDate = new Date(date);
  formatedDate = moment(formatedDate)
    .local()
    .format(DATE_FORMAT);
  return formatedDate;
};

//function for displaying date in charts
export const renderGenericDateFormat = date => {
  let formatedDate = new Date(date);
  formatedDate = moment(formatedDate)
    .local()
    .format(DISPLAY_DATE_FORMAT);
  return formatedDate;
};

//function to get records of same date
export const renderChartSeriesData = (date, chartArray) => {
  const parsedDateArr = date.split('-');
  const selectedDate = new Date(
    renderDateFormat(new Date(parsedDateArr[2], parsedDateArr[1] - 1, parsedDateArr[0])),
  );
  return chartArray.filter(item => {
    const itemDate = new Date(renderDateFormat(item.dt_txt));
    return selectedDate.getTime() === itemDate.getTime();
  });
};

export const renderReactSelectOptionFormat = options => {
  if (options?.length) {
    let arr = [];
    options?.map(i => {
      let item = MONTHS.filter(x => parseInt(x.value) === i);
      let obj = {};
      if (!_.isEmpty(item)) {
        obj.value = item[0].label;
        obj.label = item[0].label;
        obj.id = item[0].value;
        obj.days = item[0]?.days;
        arr.push(obj);
      }
    });
    return arr;
  }
};

export const returnDSSDateFormat = (selectedYear, selectedMonth) => {
  let startDate = '';
  let endDate = '';
  startDate = selectedYear?.value + '-' + selectedMonth?.id + '-01';
  endDate = selectedYear?.value + '-' + selectedMonth?.id + '-' + selectedMonth?.days;
  return { startDate, endDate };
};

export const renderDSSAlertTableDateFormat = (year, month) => {
  let startYear = { value: year.value - 3, label: year.value - 3 };
  let endYear = year;
  let { startDate } = returnDSSDateFormat(startYear, month);
  let { endDate } = returnDSSDateFormat(endYear, month);
  return { startDate, endDate };
};

export const getYearsListFormat = (min, max) => {
  let minStartYear = min;
  let maxEndYear = max;
  let yearCount = maxEndYear - minStartYear;
  let ERPAS_YEAR_CONST = [];
  for (let i = yearCount; i >= 0; i--) {
    ERPAS_YEAR_CONST.push({ value: minStartYear + i, label: minStartYear + i });
  }
  return ERPAS_YEAR_CONST;
};

export const getERPASValidDates = () => {
  const t =
    new Date().getDate() + (6 - new Date().getDay() - 1) - (new Date().getDay() === 6 ? 0 : 7);
  const lastFriday = new Date();
  lastFriday.setDate(t);
  let endDate = moment(lastFriday)
    .add(28, 'days')
    .format('YYYY-MM-DD');
  return endDate;
};

export const checkDroughtConditon = (threshold, value) => {
  // let isDryspell = parseInt(value.dryspell) > threshold?.dryspell ? 'yes' : 'no';
  // if (isDryspell === 'no' && parseInt(value?.spi) > threshold?.spi) {
  //   return false;
  // } else {
  //   return true;
  // }
  // TODO We may need to change threshold value based on condition
  // const DS_THRESHOLD = 4;
  // const SPI_THRESHOLD = -1
  if (value.dryspell && value.spi) {
    // TODO  We may also need to add SPI condition
    let isDryspell = parseInt(value.dryspell) >= threshold?.dryspell ? 'yes' : 'no';
    if (isDryspell === 'yes') {
      return true;
    } else if (isDryspell === 'no' && parseInt(value?.spi) < -1.5) {
      return true;
    } else {
      return false; // drought condition
    }
  } else {
    return false;
  }
};

//rendering data to select
export const RenderDataInSelect = props => {
  const { options } = props;
  return (
    <>
      {options &&
        options.length &&
        options.map(item => {
          return <option key={item.id}>{item.name}</option>;
        })}
    </>
  );
};

export const RenderDataRangeSelect = props => {
  const { options } = props;
  return (
    <>
      {options &&
        options.length &&
        options.map(item => {
          return (
            <option value={item.value} key={item.value}>
              {item.label}
            </option>
          );
        })}
    </>
  );
};

export const renderCurrentYear = () => {
  const date = new Date().getFullYear();
  return date;
};

export const isOpenWeatherDataSource = dataSources => {
  if (dataSources?.length) {
    if (dataSources?.some(item => item.id === DATA_SOURCES.OPEN_WEATHER.id)) {
      return true;
    }
  }
  return false;
};

export const mapPreferenceGeoJsonAPICall = (boundary, API_URL) => {
  let config = {
    name: boundary?.boundarytype?.slug,
    id: boundary?.boundarytype_id,
    color: boundary?.boundarytype?.color?.code,
    category: boundary?.category,
    country_id: boundary?.country_id,
  };
  API_URL.push(AXIOS_INSTANCE.get(boundary?.boundary_data_url, config));
  return API_URL;
};

export const mapPreferCreateRef = (boundary, mapPreferRefs) => {
  let ref = React.createRef();
  ref.current = {
    boundary_id: boundary?.boundarytype_id,
    name: boundary?.boundarytype?.boundarytype,
    layer: React.createRef(),
  };
  mapPreferRefs.current.push(ref);
  return mapPreferRefs.current;
};

export const filterSelectedGeoJsonData = (geoJsonLayersData, geoJsonData) => {
  let selectedGeoJsonData = [];
  if (geoJsonLayersData?.length && geoJsonData?.length) {
    geoJsonData?.map(item => {
      let filteredObj = geoJsonLayersData?.find(
        layer => layer.config.id === item.id && layer.config.country_id === item.country_id,
      );
      if (!_.isEmpty(filteredObj)) {
        filteredObj.fromMapPreferences = item.fromMapPreferences;
        selectedGeoJsonData.push(filteredObj);
      }
    });
  } else {
    selectedGeoJsonData = [];
  }
  return selectedGeoJsonData;
};

//function to add layers in the map
export const addLayers = (selectedParamRef, mapRef) => {
  if (mapRef.current && selectedParamRef.current) {
    const leafletMapRef = mapRef.current;
    const firstLayer = selectedParamRef.current;
    [firstLayer].forEach((layer, index) => {
      leafletMapRef.addLayer(layer);
    });
  }
  return false;
};

//function to remove layers from map
export const removeLayers = (selectedParamRef, mapRef) => {
  if (mapRef && mapRef?.current && selectedParamRef && selectedParamRef?.current) {
    const map = mapRef.current;
    const firstLayer = selectedParamRef.current;
    [firstLayer].forEach(layer => {
      map.removeLayer(layer);
    });
  }
};

export const getGenericMapPreferencesData = (
  mapPreferRefs,
  mapPreferencesData,
  mapRef,
  mapPreferencesLayerData,
  selectedMapPreferLegendData,
) => {
  if (mapPreferRefs?.current?.length && mapPreferencesData?.length) {
    mapPreferencesData?.map(item => {
      let selectedMapPreferences = mapPreferRefs.current.find(
        x => x.current.boundary_id === item.id,
      );
      let previousParamRef = selectedMapPreferences.current?.layer;
      if (previousParamRef && previousParamRef?.current) {
        removeLayers(previousParamRef, mapRef);
      }
    });
  }
  /** setting map preferences state*/
  let arr = JSON.parse(JSON.stringify(mapPreferencesLayerData));
  let paramData = '';
  let paramArr = '';
  if (selectedMapPreferLegendData?.length) {
    paramData = JSON.parse(JSON.stringify(selectedMapPreferLegendData));
    paramData?.map((item, index) => {
      if (item.add) {
        let legendItem = { ...item };
        legendItem.add = false;
        paramData[index] = legendItem;
      }
    });
    paramArr = [...paramData];
  }
  return { arr, paramArr };
};

export const findDifferenceInDays = (start, end) => {
  const date1 = new Date(start);
  const date2 = new Date(end);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

export const renderValidCommonFilterDates = (
  firstDataSource,
  secondDataSource,
  updatedSelectedDate,
  firstSource,
) => {
  let commonMinDate, commonMaxDate, date1, date2, date, withInRange;
  let minSourceStartDate, maxSourceEndDate, minTargetStartDate, maxTargetEndDate;

  //calculate common min and max
  minSourceStartDate = new Date(firstDataSource?.dateRange?.minStartDate);
  maxSourceEndDate = new Date(firstDataSource?.dateRange?.maxEndDate);
  minTargetStartDate = new Date(secondDataSource?.dateRange?.minStartDate);
  maxTargetEndDate = new Date(secondDataSource?.dateRange?.maxEndDate);

  commonMinDate =
    minSourceStartDate >= minTargetStartDate ? minSourceStartDate : minTargetStartDate;
  commonMaxDate = maxSourceEndDate <= maxTargetEndDate ? maxSourceEndDate : maxTargetEndDate;

  if (commonMinDate && commonMaxDate) {
    withInRange =
      new Date(updatedSelectedDate?.startDate) >= commonMinDate &&
      new Date(updatedSelectedDate?.endDate) <= commonMaxDate;
    if (withInRange && firstSource?.id !== DATA_SOURCES.OPEN_WEATHER.id) {
      date = JSON.parse(JSON.stringify(firstDataSource?.dateRange));
      date.minStartDate = moment(commonMinDate)
        .local()
        .format('YYYY-MM-DD');
      date.maxEndDate = moment(commonMaxDate)
        .local()
        .format('YYYY-MM-DD');
      date.startDate = updatedSelectedDate?.startDate;
      date.endDate = updatedSelectedDate?.endDate;
      return date;
    } else if (
      !_.isEmpty(firstDataSource) &&
      !_.isEmpty(firstDataSource?.dateRange) &&
      !_.isEmpty(secondDataSource) &&
      !_.isEmpty(secondDataSource?.dateRange)
    ) {
      date1 = findDifferenceInDays(
        firstDataSource.dateRange.startDate,
        firstDataSource.dateRange.endDate,
      );
      date2 = findDifferenceInDays(
        secondDataSource.dateRange.startDate,
        secondDataSource.dateRange.endDate,
      );
      if (date1 < date2) {
        return firstDataSource?.dateRange;
      } else {
        return secondDataSource.dateRange;
      }
    }
  }
};

export const handleOpenWeatherCompareRes = layerData => {
  const item = {};
  const result = [];
  result[0] = {
    Open_Weather: [
      {
        map_data: {
          ...layerData,
          code: 200,
          map_url: myConstClass.OPEN_WEATHER_TILE_LAYER_URL.replace(
            '{layername}',
            layerData.layerName,
          ),
          max: layerData.max,
          min: layerData.min,
          units: layerData.measure,
          palette: layerData?.palette || '',
        },
      },
    ],
  };
  item.data = {
    code: 200,
    result,
  };
  return item;
};

export const renderOpenWeatherResponse = (result, config, selectedParam) => {
  let {
    Open_Weather: { daily },
  } = result[0];
  let data = [];
  let Timestamp = [];
  let parameter = OPEN_WEATHER_PARAMETER_SLUG.find(
    item => item.value === selectedParam.parameter.name,
  );
  let { measure } = OPEN_WEATHER_PARAMETERS_CONST.find(item => item.name === parameter?.value);
  let Units = [];
  let layerType = '';
  let location = '';
  Units[0] = parameter.measure;
  if (!_.isEmpty(parameter)) {
    data = daily.map(item => {
      if (parameter.value === 'Temperature') {
        return item[parameter.api_slug]['day'] || 0;
      } else {
        return item[parameter.api_slug] || 0;
      }
    });
    Timestamp = daily?.map(item => item.dt_txt);
    layerType = DATA_SOURCES.OPEN_WEATHER.name;
    location = config?.location || '';
    Units[0] = measure;
  }
  return {
    data,
    Timestamp,
    layerType,
    location,
    Units,
  };
};

export const renderGeometricTimeSeries = async (
  requestObj,
  region,
  dataSources,
  isOpenWeather,
  selectedParam,
) => {
  let API_URL = [];
  let API_CALL = [];
  region.map(item => {
    if (isOpenWeather === false) {
      requestObj.geometry = item.layerType === 'marker' ? [item.latlngs] : item.latlngs;
      API_CALL = dataSources[0]?.master_server
        ? `${MASTER_SERVER_BASE_URL}/${dataSources[0]?.api_url}/timeseries`
        : dataSources[0].id === DATA_SOURCES.GFS_NOAA.id
        ? NOAA_TIMESERIES
        : dataSources[0].id === DATA_SOURCES.IRI.id
        ? IRI_TIMESERIES
        : ERPAS_TIMESERIES;
    } else {
      requestObj.latitude = item.latlngs[1];
      requestObj.longitude = item.latlngs[0];
      requestObj.spatial_aggregation = 'mean';
      API_CALL = dataSources[0]?.master_server
        ? `${MASTER_SERVER_BASE_URL}/${dataSources[0]?.api_url}`
        : WEATHER_FORECAST_DATA_URL;
    }
    let config = {
      layer: item.layerType,
      latlng: item.layerType === 'marker' ? item.latlngs : '',
      polygonCount: item.layerType === 'polygon' ? item.polygonCount : '',
    };
    API_URL.push(
      dataSources[0]?.master_server
        ? AXIOS_INSTANCE_MASTER_SERVER.post(API_CALL, requestObj, config)
        : AXIOS_INSTANCE.post(API_CALL, requestObj, config),
    );
  });
  let resolved = await Promise.all(API_URL)
    .then(response => {
      let res = JSON.parse(JSON.stringify(response));
      let noaaResponse = [];
      res.map(item => {
        if (Array.isArray(item?.data?.result)) {
          let {
            Open_Weather: { daily },
          } = item?.data?.result[0];
          let data = [];
          let Timestamp = [];
          let parameter = OPEN_WEATHER_PARAMETER_SLUG.find(
            item => item.value === selectedParam.parameter.name,
          );
          let Units = [];
          let layerType = '';
          let latlng = '';
          let polygonCount = '';
          Units[0] = parameter.measure;
          if (!_.isEmpty(parameter)) {
            data = daily.map(item => {
              if (parameter.value === 'Temperature') {
                return item[parameter.api_slug]['day'] || 0;
              } else {
                return item[parameter.api_slug] || 0;
              }
            });
            Timestamp = daily?.map(item => item.dt_txt);
            layerType = item.config.layer;
            polygonCount = item.config.polygonCount;
            latlng = item.config.latlng;
          }
          noaaResponse.push({ Timestamp, data, Units, layerType, latlng, polygonCount });
        } else {
          const { layer: layerType, latlng, polygonCount } = item?.config || {};

          for (const value of Object.values(item?.data?.result || {})) {
            const val = {
              ...value,
              layerType,
              latlng,
              polygonCount,
            };

            noaaResponse.push(val);
          }
        }
      });
      return {
        response: true,
        noaaResponse,
      };
    })
    .catch(error => {
      let errorData = { showError: true, data: 'No records found. please try again' };
      return { response: false, errorData };
    });
  return resolved;
};

export function htmlToText(html) {
  var temp = document.createElement('div');
  temp.innerHTML = html;
  return temp.textContent; // Or return temp.innerText if you need to return only visible text. It's slower.
}

export const LEGEND_UNIT_CONVERTER = [
  {
    unit: '°C',
    converter: '°F',
    convert: c => {
      let x = c ? ((9 * c) / 5 + 32).toFixed(1) : c;
      return x;
    },
  },
  {
    unit: '°F',
    converter: '°C',
    convert: f => {
      let x = f ? ((f - 32) * (5 / 9)).toFixed(1) : f;
      return x;
    },
  },
  {
    unit: 'inch/h',
    converter: 'mm/h',
    convert: inch => {
      return inch ? Math.round((25.4 * inch).toFixed(2)) : inch;
    },
  },
  {
    unit: 'mm/h',
    converter: 'inch/h',
    convert: mm => {
      return mm ? (0.0393701 * mm).toFixed(2) : mm;
    },
  },
  {
    unit: 'km/h',
    converter: 'm/s',
    convert: km => {
      return km ? ((5 / 18) * km).toFixed(2) : km;
    },
  },
  {
    unit: 'm/s',
    converter: 'km/h',
    convert: m => {
      return m ? ((18 / 5) * m).toFixed(2) : m;
    },
  },
  {
    unit: 'hPa',
    converter: 'inHg',
    convert: hPa => {
      return hPa ? (0.02953 * hPa).toFixed(1) : hPa;
    },
  },
  {
    unit: 'inHg',
    converter: 'hPa',
    convert: inHg => {
      return inHg ? Math.floor((33.863886666667 * inHg).toFixed(1)) : inHg;
    },
  },
];

export const renderParamList = paramList => {
  if (paramList?.length) {
    let obj = paramList[0];
    if (!_.isEmpty(obj)) {
      let paramItems = [];
      obj.parameterData?.map(item => {
        if (item && item.parameter) {
          item.parameter.map(param => {
            param.dataSource = obj.name;
            param.category = obj.category;
            param.type = obj.type;
            paramItems.push(param);
          });
        }
      });
      return paramItems;
    }
  }
};

/**
 * Url pattern
 */
export const urlRegex =
  '((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)';

/**
 * Url valid or not
 */
export const isValidUrl = url => {
  return urlRegex.test(
    String(url)
      .trim()
      .toLowerCase(),
  );
};

/**
 * Email pattern
 */
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * string special character regex
 */
export const specialCharacterRegex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/;

/**
 * Email valid or not
 */
export const isValidEmail = email => {
  return emailRegex.test(
    String(email)
      .trim()
      .toLowerCase(),
  );
};

/*for Mobile Number*/

export const numberRegex = /^[0-9]+$/;

/**
 * Number valid or not
 */
export const isValidMobileNumber = number => {
  return numberRegex.test(number);
};

// gloabal Utility for handling logout action
export const handleLogoutRedirect = () => {
  Cookies.remove('user', { path: '/' });
  Cookies.remove('user_details', { path: '/' });
  Cookies.remove('token', { path: '/' });
  Cookies.remove('super-admin', { path: '/' });
  Cookies.remove('is_staff', { path: '/' });
  Cookies.remove('loggedin_user_details', { path: '/' });
};

export const getToken = () => {
  let userLoggedInData = [];
  userLoggedInData = Cookies.get('user');
  if (userLoggedInData) {
    return userLoggedInData;
  } else {
    return false;
  }
};

/**
 * Checks if user is authenticated
 */
export const isSuperAdmin = () => {
  let isUserDetails = [];
  isUserDetails = Cookies.get('super-admin');
  return isUserDetails === 'true' ? true : false;
};

export const setWeatherForecastDefaultParam = () => {
  Cookies.set('weather-forecast-default-param', true);
};

export const getUserLocationBasedOnIP = data => {
  if (data) Cookies.set('ip-based-user-location', data, { expires: 1 });
};

export const isUserLocationExists = () => {
  let userLocation = Cookies.get('ip-based-user-location');
  if (userLocation) {
    return userLocation;
  } else return false;
};

export const removeUserLocationBasedOnIP = () => {
  Cookies.remove('ip-based-user-location', { path: '/' });
};

export const isWeatherForecastDefaultParam = () => {
  return Cookies.get('weather-forecast-default-param') === 'true' ? true : false;
};

export const hasPermissions = data => {
  Cookies.set('super-admin', data, { expires: 1 });
};

export const hasStaffUserPermission = data => {
  Cookies.set('is_staff', data, { expires: 1 });
};

export const setShowDroughtMonitorPopup = () => {
  sessionStorage.setItem('show_drought_popup', true);
};

export const removeDroughtMonitorPopup = () => {
  sessionStorage.removeItem('show_drought_popup');
};

export const isStaffUser = () => {
  return Cookies.get('is_staff') === 'true' ? true : false;
};

export const isLogedIn = () => {
  return Cookies.get('loggedin_user_details') ? true : false;
};

export const getUserId = () => {
  return Cookies.get('user_id') ? Cookies.get('user_id') : null;
};

export const checkPermission = (permissionList, slug) => {
  const isPermission = permissionList?.find(each => each?.codename === slug);
  return isPermission;
};

export const signIn = loginData => {
  if (loginData) {
    Cookies.set('user', loginData.token, { expires: 1 });
    Cookies.set('user_id', loginData.userDetails?.[0]?.id, { expires: 1 });
    Cookies.set(
      'user_details',
      loginData.userDetails &&
        loginData.userDetails.length &&
        loginData.userDetails[0].is_superuser,
      { expires: 1 },
    );
    Cookies.set('loggedin_user_details', loginData.userDetails[0].email, { expires: 1 });
  }
};

export const setPermissions = data => {
  return data;
};

//function for displaying Column Names in Pascal Case
export const convertToPascalCase = data => {
  return data
    .split('_')
    .map(word => word[0].toUpperCase().concat(word.slice(1)))
    .join(' ');
};
export const convertTimeFormat = time => {
  if (!_.isEmpty(time) && parseInt(time?.slice(0, 2)) > 12) {
    return `${time.slice(0, 2) - 12} : ${time.slice(3, 5)} PM`;
  } else if (!_.isEmpty(time) && parseInt(time?.slice(0, 2)) < 12) {
    return `${time} AM`;
  } else if (!_.isEmpty(time) && parseInt(time?.slice(0, 2)) === 12) {
    return `${time.slice(0, 2)} : ${time.slice(3, 5)} PM`;
  } else {
    return null;
  }
};

/**
 *
 * Get chart Shape name in Drough Monitor
 */
export const getChartShapeName = (chartData, index, shapeType) => {
  let polygonName,
    markerName = '';
  if (shapeType == 'Polygon') {
    let polygonCountArray = chartData?.filter(data => data.shapeType == 'Polygon');
    if (polygonCountArray?.length > 1) {
      polygonName = `Polygon ${index + 1}`;
      return polygonName;
    }
    return 'Polygon';
  }

  if (shapeType == 'Point Marker') {
    let markerCountArray = chartData?.filter(data => data.shapeType == 'Point Marker');

    if (markerCountArray?.length > 1) {
      markerName = `Marker ${index}`;
      return markerName;
    }
    return 'Marker';
  }

  return false;
};

let regionData = [];
export const setRegionData = data => {
  regionData = data;
};

export const getRegionData = () => {
  return regionData;
};

export const removeRegionTooltip = (layers, elements) => {
  if (!_.isEmpty(layers)) {
    Object.values(layers).map(({ _leaflet_id }) => {
      let regionLayerData = getRegionData()?.length
        ? getRegionData()?.filter(l => l.id === _leaflet_id)
        : [];
      let regionText = '';
      if (regionLayerData?.length) {
        if (regionLayerData[0]?.layerType === 'polygon') {
          regionText = `polygon-${regionLayerData[0]?.polygonCount}`;
        } else {
          regionText = `(${regionLayerData[0].latlngs[0].toFixed(
            4,
          )},${regionLayerData[0].latlngs[1].toFixed(4)})`;
        }
        for (let i = 0; i < elements.length; i++) {
          elements[i].parentNode.removeChild(elements[i]);
        }
      }
    });
  }
};
