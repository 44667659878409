import React, { useEffect, useState } from 'react';
import { t } from 'react-multi-lang';
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';

import { ReactComponent as DeleteIcon } from '../../assets/Images/delete-icon.svg';
import { ReactComponent as UploadIcon } from '../../assets/Images/upload-image.svg';

const NewPartnersModal = ({
  modal,
  setModal,
  formData,
  setFormData,
  isEditable,
  onHandlePartners,
}) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [initialData, setInitialData] = useState({});

  const toggleModal = () => {
    setModal(!modal);
    setFormData({
      name: '',
      partner_donor_url: '',
      icon: null,
      is_fo: false,
    });
  };

  const handleChange = e => {
    const { name, value, files, type, checked } = e.target;

    let updatedFormData = {
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    };

    if (name === 'icon' && files[0]) {
      const file = files[0];
      updatedFormData[name] = files ? files[0] : null;
      setImagePreview(URL.createObjectURL(file)); // Create a preview URL for the uploaded image

      e.target.value = null;
    } else if (name !== 'icon' && name !== 'is_fo') {
      updatedFormData[name] = files ? files[0] : value;
    }

    setFormData(updatedFormData);
  };

  useEffect(() => {
    if (isEditable) {
      setInitialData(formData); // Store the original data when editing
    }
  }, []);

  useEffect(() => {
    // Check if all required fields are filled
    const isValid = formData.name && formData.partner_donor_url && formData.icon;
    setIsFormValid(isValid);
  }, [formData]);

  const handleImageRemove = () => {
    setFormData({
      ...formData,
      icon: null,
    });
    setImagePreview(null); // Remove the image preview
  };

  const getChangedFields = () => {
    const changedFields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== initialData[key]) {
        changedFields[key] = formData[key];
      }
    });

    return changedFields;
  };

  const handleSubmit = e => {
    e.preventDefault();
    const dataToSubmit = isEditable ? getChangedFields() : formData;
    onHandlePartners(dataToSubmit);
    toggleModal();
    setInitialData({});
  };

  return (
    <>
      <div className="client-modal">
        <Modal
          isOpen={modal}
          toggle={toggleModal}
          className="dms-landing-modal"
          backdrop="static"
          keyboard={false}
        >
          <Form onSubmit={handleSubmit}>
            <ModalHeader
              className="client-detail-header justify-content-between"
              toggle={toggleModal}
            >
              {isEditable ? t('LANDING_PAGE.EDIT_PARTNER') : t('LANDING_PAGE.ADD_NEW_PARTNER')}
            </ModalHeader>
            <ModalBody className="events-modal-container">
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Label for="name" className="dms-pillar-label mb-1">
                      {t('LANDING_PAGE.PARTNER_NAME')}
                    </Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      className="dms-pillar-select"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Write your text here..."
                      required
                    />
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup>
                    <Label for="partner_donor_url" className="dms-pillar-label mb-1">
                      {t('LANDING_PAGE.LINK')}
                    </Label>
                    <Input
                      type="text"
                      name="partner_donor_url"
                      id="partner_donor_url"
                      className="dms-pillar-select"
                      value={formData.partner_donor_url}
                      onChange={handleChange}
                      placeholder="https://example.com"
                      required
                    />
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup>
                    <Label for="coverImage" className="dms-pillar-label mb-1">
                      {t('LANDING_PAGE.UPLOAD_LOGO')}
                    </Label>
                    <div className="custom-input-file w-100">
                      {imagePreview || formData.icon ? (
                        <div className="image-preview-container">
                          <img
                            src={imagePreview || formData.icon}
                            alt="Profile Preview"
                            className="image-preview"
                          />
                          <div className="image-overlay"></div>
                          <button
                            type="button"
                            className="dms-delete-file"
                            onClick={handleImageRemove}
                          >
                            <DeleteIcon width={20} height={20} />
                          </button>
                        </div>
                      ) : (
                        <Label htmlFor="icon" className="custom-input-file-label">
                          <UploadIcon width={36} height={36} />
                          <p>{t('LANDING_PAGE.UPLOAD_IMAGE')}</p>
                          <span>{t('LANDING_PAGE.UPLOAD_IMAGE_NOTE')}</span>
                        </Label>
                      )}
                      <Input
                        type="file"
                        name="icon"
                        id="icon"
                        accept=".jpg,.jpeg,.png"
                        onChange={handleChange}
                        hidden
                        required
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup check inline>
                    <Input
                      type="checkbox"
                      name="is_fo"
                      id="is_fo"
                      checked={formData.is_fo || false}
                      onChange={handleChange}
                    />
                    <Label for="is_fo" check>
                      {t('LANDING_PAGE.SHOW_DMS_PAGE')}
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className="modal-viewdeatil-footer">
              <button onClick={() => toggleModal()} className="reject-button">
                {t('LANDING_PAGE.CANCEL')}
              </button>{' '}
              <button onClick={handleSubmit} disabled={!isFormValid} className="approve-button">
                {isEditable ? t('LANDING_PAGE.SAVE') : t('LANDING_PAGE.ADD')}
              </button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default NewPartnersModal;
