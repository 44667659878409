/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Chart from 'react-apexcharts';
import '../../assets/CSS/Charts.css';
import closeIcon from '../../assets/Images/closeIcon.svg';
import { renderGenericDateFormat, renderChartTime } from '../../Components/common/utils';
import { CHART_LABEL_COLORS, POLYGON } from '../../Components/constants';

// demo comment , need to remove later
function DroughtChart(props) {
  const {
    chartData,
    renderCharts,
    temporalAggregation,
    selectedParam,
    compareParam,
    isCompareClicked,
    region,
    initialSelectedDate,
    location,
    isHideBar,
    selectedModule,
    // mapLayers,
  } = props;
  const [series, setSeries] = useState([]);

  const [YAxisOptions, setYAxisOptions] = useState([
    {
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: '#008FFB',
      },
      labels: {
        style: {
          colors: '#008FFB',
          fontSize: '12px',
          fontFamily: 'National',
        },
        formatter: function(value) {
          if (!value == null || !isNaN(value)) {
            if (value % 1 !== 0) {
              if (selectedModule.api_slug === 'drought-prediction') return value.toFixed(3);
              else return value.toFixed(2);
            } else {
              return value;
            }
          }
        },
      },
      title: {
        text: '',
        style: {
          color: '#008FFB',
        },
        offsetX: 60,
      },
      tooltip: {
        enabled: true,
      },
      scaleLabel: {
        display: true,
        labelString: '',
      },
      ticks: {
        callback: function(value) {
          return value;
        },
      },
    },
  ]);
  const [options, setOptions] = useState({
    chart: {
      type: 'column',
      stacked: false,
      zoom: {
        enabled: true,
        type: 'x',
      },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0, 1],
    },
    stroke: {
      // width: [1, 1, 4],
      width: 4,
    },
    title: {
      text: 'Data Sources',
      align: 'left',
      offsetX: 100,
      style: {
        fontSize: '20px',
        fontWeight: 'normal',
        fontFamily: 'National',
        color: '#2A547C',
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '55%',
        barHeight: '70%',
      },
    },
    xaxis: {
      // categories: [],
      labels: {
        showDuplicates: false,
        style: {
          fontSize: '12px',
          fontFamily: 'National',
        },
        crossShair: {
          stroke: {
            curve: 'straight',
          },
        },
      },
      type: 'datetime',
    },
    yaxis: YAxisOptions,
    tooltip: {
      fixed: {
        enabled: true,
        position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60,
      },
    },
    legend: {
      horizontalAlign: 'left',
      offsetX: 40,
    },
  });

  useEffect(() => {
    if (isHideBar) {
      setOptions(prev => {
        return {
          ...prev,
          title: {
            ...prev.title,
            align: 'left',
            offsetX: 700,
          },
        };
      });
    } else {
      setOptions(prev => {
        return {
          ...prev,
          title: {
            ...prev.title,
            align: 'left',
            offsetX: 550,
          },
        };
      });
    }
  }, [isHideBar]);
  useEffect(() => {
    let seriesData = [];
    let YAxisoptionsData = YAxisOptions;
    let formattedDates;
    let dates;
    const startDate = renderChartTime(initialSelectedDate?.startDate);
    const endDate = renderChartTime(initialSelectedDate?.endDate);
    if (!_.isEmpty(chartData) && chartData?.graph_data && startDate && endDate) {
      dates = chartData?.graph_data[0]?.millis;
      if (chartData?.graph_data?.length === 2) {
        dates =
          chartData?.graph_data[0]?.millis.length > chartData?.graph_data[1]?.millis.length
            ? chartData?.graph_data[0]?.millis
            : chartData?.graph_data[1]?.millis;
      }
      if (!_.isEmpty(dates)) {
        formattedDates = dates?.length
          ? dates.map(item => {
              let dateFormat = new Date(item);
              return renderGenericDateFormat(dateFormat);
            })
          : '';
      }

      if (chartData?.graph_data?.length) {
        chartData?.graph_data?.map((data, index) => {
          const graphIndices = Object.values(data)[0];
          const graphIndicesMills = data?.millis;
          let type = selectedParam?.name;

          const graphSeriesData =
            selectedModule.api_slug === 'drought-prediction' // { x: new Date('2024-01-01').getTime(), y: 30 },
              ? graphIndices.map((item, index) => ({
                  x: new Date(graphIndicesMills?.[index]).getTime(),
                  y: item?.toFixed(2),
                }))
              : graphIndices.map((item, index) => ({
                  x: new Date(graphIndicesMills?.[index]).getTime(),
                  y: item?.toFixed(2),
                }));
          if (isCompareClicked && !_.isEmpty(compareParam)) {
            type = `${data?.layerType} (${data?.location})`;
          } else if (region?.length) {
            type = data.layerType
              ? data.layerType === 'marker'
                ? `point(${data.latlng[1].toFixed(2)},${data.latlng[0].toFixed(2)})`
                : data.layerType + '-' + data?.polygonCount
              : type;
          }
          if (graphSeriesData && graphSeriesData?.length) {
            seriesData.push({
              name: type,
              type: 'line',
              data: graphSeriesData,
            });

            if (index === 0) {
              YAxisoptionsData[0] = {
                ...YAxisoptionsData[0],
                labels: {
                  style: {
                    colors: CHART_LABEL_COLORS[index],
                    fontSize: '12px',
                    fontFamily: 'National',
                  },
                },
                title: {
                  // text: type,
                  text: chartData?.graph_data?.length <= 2 ? type : 'Values',
                  align: 'middle',
                  style: {
                    color: CHART_LABEL_COLORS[index],
                  },
                },
              };
            }
            if (index > 0 && chartData?.graph_data?.length <= 2) {
              YAxisoptionsData.push({
                seriesName: type,
                opposite: true,
                axisTicks: {
                  show: true,
                },
                axisBorder: {
                  show: true,
                  color: CHART_LABEL_COLORS[index],
                },
                labels: {
                  style: {
                    colors: CHART_LABEL_COLORS[index],
                    fontSize: '12px',
                    fontFamily: 'National',
                  },
                },
                title: {
                  text: type,
                  align: 'middle',
                  style: {
                    color: CHART_LABEL_COLORS[index],
                  },
                },
              });
            }
          }
        });
      }

      let title = `${selectedParam?.name} (${temporalAggregation?.name})`;
      if (!_.isEmpty(compareParam) && isCompareClicked) {
        title = `${selectedParam?.name}-${compareParam?.name} (${temporalAggregation?.name})`;
      }

      let fileName =
        selectedParam?.name +
        '(' +
        renderChartTime(initialSelectedDate?.startDate) +
        '-' +
        renderChartTime(initialSelectedDate?.endDate) +
        ')';

      if (location?.region && location?.region.length) {
        if (location?.region[0].latlngs.length > 3) {
          fileName = fileName + '-' + POLYGON;
        } else {
          if (location?.region.length == '1') {
            fileName =
              fileName +
              '-' +
              parseFloat(location?.region[0].latlngs[0])
                .toFixed(2)
                .toString() +
              '-' +
              parseFloat(location?.region[0].latlngs[1])
                .toFixed(2)
                .toString();
          }
        }
      } else if (location?.district?.value) {
        fileName = fileName + '-' + location?.district?.name;
      } else if (location?.state?.value) {
        fileName = fileName + '-' + location?.state?.name;
      } else {
        fileName = fileName + '-' + location?.country?.name;
      }

      setOptions(prev => ({
        ...prev,
        title: {
          ...options.title,
          text: title,
          align: 'left',
          offsetX: 600,
        },
        xaxis: {
          ...options.xaxis,

          labels: {
            showDuplicates: false,
            hideOverlappingLabels: true,
            format: 'dd/MM/yyyy',
          },
          type: 'datetime',
          tickPlacement: 'on',
        },
        dataLabels: {
          enabled: chartData?.graph_data?.length < 2,
          enabledOnSeries: [0, 1, 2, 3, 4, 5],
        },
        chart: {
          ...options.chart,
          toolbar: {
            show: true,
            export: {
              csv: {
                filename: fileName,
                columnDelimiter: ',',
                headerCategory: 'Date',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
              svg: {
                filename: fileName,
                columnDelimiter: ',',
                headerCategory: 'Date',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
              png: {
                filename: fileName,
                columnDelimiter: ',',
                headerCategory: 'Date',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
            },
            tools: {
              customIcons: [
                {
                  icon: `<img src=${closeIcon} width="20">`,
                  index: 6,
                  title: 'Close',
                  class: 'chart-close-icon',
                  click: function(chart, options, e) {
                    renderCharts();
                  },
                },
              ],
            },
          },
        },
        subtitle: {
          text: `Time: ${startDate} to ${endDate}`,
          align: 'left',
          margin: 10,
          offsetX: 250,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '16px',
            fontWeight: 'normal',
            fontFamily: 'National',
            color: '#363636',
          },
        },
      }));
      setYAxisOptions(YAxisoptionsData);
      setSeries(seriesData);
    }
  }, [chartData, location, initialSelectedDate?.startDate, initialSelectedDate?.endDate]);
  return (
    <div>
      <div className="drought-chart-row">
        <div className="chart">
          {!_.isEmpty(chartData) && (series.length || !_.isEmpty(options)) ? (
            <>
              <Chart
                id="drought-chart"
                className="drought-chart"
                width={
                  chartData?.graph_data[0]?.millis?.length > 50
                    ? `${chartData?.graph_data[0]?.millis?.length * 30}px`
                    : '100%'
                }
                options={options}
                series={series}
                type="line"
                height="300px"
              />
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}

export default DroughtChart;
