import { GOOGLE_LOCATION_API_KEY, MAP_ACCESS_TOKEN, OPEN_WEATHER_API_KEY } from './redux/api/apiEndPoint';

const myConstClass = {
  WATER_MARK: 'https://upload.wikimedia.org/wikipedia/commons/b/bc/The_official_logo_of_IWMI.jpg',
  ATTRIBUTION: 'http://osm.org/copyright',
  TITLE_LAYER_URL: `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}?access_token=${MAP_ACCESS_TOKEN}`,
  SATELLITE_TILE_LAYER_URL: `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v9/tiles/256/{z}/{x}/{y}?access_token=${MAP_ACCESS_TOKEN}`,
  OPEN_WEATHER_TILE_LAYER_URL: `https://{s}.tile.openweathermap.org/map/{layername}/{z}/{x}/{y}.png?appid=${OPEN_WEATHER_API_KEY}`,
  RAINFALL_LAYER_URL:
    'https://{s}.tile.openweathermap.org/map/rain_cls/{z}/{x}/{y}.png?appid=0311bf19d65592ed5c2fc43faeaaa6d4',
  PERCIPITATION_LAYER_URL:
    'https://{s}.tile.openweathermap.org/map/precipitation_cls/{z}/{x}/{y}.png?appid=0311bf19d65592ed5c2fc43faeaaa6d4',
  TEMP_LAYER_URL:
    'https://{s}.tile.openweathermap.org/map/temp/{z}/{x}/{y}.png?appid=0311bf19d65592ed5c2fc43faeaaa6d4',
  GEO_LOCATION_USER_LOCATION_URL: 'https://www.googleapis.com/geolocation/v1/geolocate?key='+GOOGLE_LOCATION_API_KEY,
  IWMI_LOGO: 'http://www.iwmi.cgiar.org/wp-content/uploads/2019/08/New-IWMI-Logo-trans-white.png',
  IWMI_FB_URL: 'https://www.facebook.com/IWMIonFB',
  IWMI_FLICKR_URL: 'https://www.flickr.com/photos/iwmi',
  IWMI_LINKEDIN_URL:
    'https://www.linkedin.com/company/international-water-management-institute-iwmi/',
  IWMI_RSS_FEEDS: 'https://www.iwmi.cgiar.org/news/rss-feeds/',
  IWMI_TWITTER_LINK: 'https://twitter.com/IWMI_',
  IWMI_YOUTUBE_LINK: 'https://www.youtube.com/user/iwmimedia/videos',
  IWMI_EMAIL: 'iwmi@cgiar.org',
  SADMS_URL: 'http://dms.iwmi.org/',
  IWMI_URL: 'https://www.iwmi.cgiar.org/',
  ICAR_URL: 'https://icar.org.in/',
  CGIAR_RESOURCES_URL: 'https://wle.cgiar.org/',
  CGIAR_URL: 'https://www.cgiar.org/',
  EMAIL_REGEX: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  URL_REGEX: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,

  parameterColors: {
    collapse: '#5389C4',
    expand: '#1797A5',
    Secondary: '#DC9023',
    Black: '#363636',
    White: '#fff',
  },
  menubarColors: {
    active: '#DC9023',
    inActive: '#2A547C',
  },
};

export default myConstClass;