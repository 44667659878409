import React from 'react';
import {
  Col,
  Row,
} from 'reactstrap';

const List = (props) => {
  const renderNestedValues = (values) => {
    return values.map((i) => {
      return (
        <Row>
          <Col>{i.key} 09</Col>
          <Col>{i.value} 78</Col>
        </Row>
      );
    });
  };
  return (
    <>
      {props.districtProfileList.map((i) => {
        return (
          <>
            <Row style={{ marginLeft: '0px', marginRight: '0px' }}>
              <Col sm={6} md={6} lg={6}>
                {i.key} 00
              </Col>
              {typeof i.value === 'string' && (
                <Col sm={6} md={6} lg={6}>
                  {i.value} 00 
                </Col>
              )}
              {typeof i.value === 'object' && <Col>{renderNestedValues(i.value)}</Col>}
            </Row>
            <hr />
          </>
        );
      })}
    </>
  );
};
export default List;
