import moment from 'moment';
import { t } from 'react-multi-lang';
import { ReactComponent as Clouds } from '../../assets/Images/Clouds.svg';
import { ReactComponent as Rain } from '../../assets/Images/Rain.svg';
import { ReactComponent as ClearSky } from '../../assets/Images/ClearSky.svg';
import { NEWS_FEED_URL_DOMAIN_CONST, AMZONS_S3_URL_DOMAIN_CONST } from './urlConstants';

export const LOCATION = {
  Region: 'region',
  Country: 'Country',
  State: 'State',
  District: 'District',
  Province: 'Province',
  Divisions: 'Divisions',
};

export const IDSI_AND_VWI = {
  IDSI: 'IDSI',
  VWI: 'VWI (VCI)',
};

export const SPI = 'SPI';

export const SPI_GPM = 'SPI (GPM)';

export const CHECK_DROUGHT = 'Check Drought';

export const GRAPH = 'Graph';

export const UNDEFINED = 'undefined';

export const DROUGHT_MONITOR_MODULE = { value: 'Drought Monitor', label: 'Drought Monitor' };

export const SPI_GPM_CHIRPS_MIN_DATES = {
  SPI_CHIRPS: '1981-01-01',
  SPI_GPM: '2001-01-01',
};

export const SPI_GPM_CHIRPS_API_SLUG = {
  SPI_CHIRPS_API_SLUG: 'drought-monitor/spi',
  SPI_GPM_API_SLUG: 'drought-monitor/spi_gpm',
};

export const DM_DRY_SPELL_SLUG = {
  DRY_SPELL: 'drought-monitor/dryspell',
};

export const OTHER_INDICES_API_INDICES_SLUGS = {
  CROP_HARVEST: 'drought-monitor-v2/harvested_modis',
  MODIS_FLOOD: 'drought-monitor-v2/modis_flood',
  FLOOD: 'drought-monitor-v2/s1_flood',
  EXTREME_RAINFALL: 'drought-monitor-v2/extreme_rainfall',
  ACCUMULATED_RAINFALL: 'drought-monitor-v2/accumulated_rainfall',
};

export const FINAL_DROUGHT_MAP = 'Final Drought Map';
export const DROUGHT_MANAGEMENT_MODULE_ID = {
  DROUGHT_MONITOR: '3d08cec3-25e8-4f19-ab9e-0d6faf302fc5',
  DSS: 'c9b6404d-0aac-40a8-a561-2c2302f5e8e5',
  DROUGHT_PREDICTION: '60a607ac-2631-422c-8e5b-52d416984f3e',
  OTHER_INDICES: '5d524cef-e5e4-4f32-9186-83bb015a8dc5',
};

export const DDS_DRYSPELL_THRESHOLD_LIST = [
  { id: 1, value: 2, label: '>=7' },
  { id: 2, value: 3, label: '>=11' },
  { id: 3, value: 4, label: '>=15' },
  { id: 4, value: 5, label: '>=21' },
];

export const WEATHER_FORECAST_MODULE = {
  id: '9d7f2518-2ad7-44dd-a866-809aa2722bf5',
};
export const DROUGHT_PREDICTION_API_SLUGS = {
  SPI: 'drought-prediction/spi',
  SRI: 'drought-prediction/sri',
};

export const POLYGON = 'Polygon';

export const POLYGONS = 'Polygons';

export const TALUKAS = 'talukas';

export const SELECT = 'select';

export const DISTRICT_ZOOM_LEVEL = 9;

export const ERPAS = 'ERPAS';

export const NEWS_FEED_SUB_TITLES = {
  BLOG: 'Blog',
  IWMI_EVENTS: 'IWMI Events',
  IWMI_RESOURCES: 'IWMI Resources',
};

export const NEWS_FEED_LENGTH_CONSTANT = {
  SIXTY: 60,
};

export const NEWS_FEED_URL_CONSTANT = {
  // previous url link --> http://feeds.feedburner.com/iwmi-cgspace-sadms-all-iwmi
  RESOURCE_URL:
    NEWS_FEED_URL_DOMAIN_CONST +
    '/v1/api.json?rss_url=https://feeds.feedburner.com/iwmi-cgspace-sadms-all-iwmi',
  EVENTS_URL:
    NEWS_FEED_URL_DOMAIN_CONST + '/v1/api.json?rss_url=http://feeds.feedburner.com/iwmi-events',
  BLOGS_URL: `${NEWS_FEED_URL_DOMAIN_CONST}/v1/api.json?rss_url=https://feeds.feedburner.com/iwmi-blogs-drought`,
};

export const CONTINGENCY_PLAN = {
  Risk: 'risk',
  Monsoon: 'monsoon',
  System: 'system',
  Stage: 'stage',
};

export const weatherForecastIndices = [
  'Open Weather',
  'GFS - NOAA',
  'ERPAS',
  'IRI',
  'ERPAS (Static)',
  'ECMWF (Static)',
  'CPC (Static)',
];

export const DRY_SPELL = {
  DRY_SPELL: 'Dry Spell',
  DRYSPELL: 'Dryspell',
  FINAL_DROUGHT_MAP: 'Final Drought Map',
  DRY_SPELL_SLUG: 'dryspell',
};

export const DDSKEY = '?key=drought-decision-support';

export const MAP_PREFERENCES_LAND_USE = {
  NRSC: 'NRSC LULC',
  PROBA: 'Proba LULC',
};

export const LAND_USE_LAND_COVER = 'Land use land cover';

export const ALERT_TABLE_INDICES = {
  DRY_SPELL: 'Dry Spell (Days)',
  SPI: 'SPI',
};

export const LOGIN = 'login';

export const BULLETIN_TAB_CONSTS = {
  CREATED: 'Created',
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
};

export const NEWS_FEED_CATEGORIES = {
  Project_News: 'Project News',
  IWMI_News: 'IWMI News',
  External_News: 'External News and Feeds',
  Resources: 'Resources',
  Publications: 'Publications',
  Events: 'Events',
};

export const BULLETIN_CODES = [
  'add_bulletin',
  'change_bulletin',
  'delete_bulletin',
  'approve_reject_bulletin',
  'publish_bulletin',
];

export const LOCATION_ZOOM_LEVEL_THRESHOLD_CONSTS = [
  { location_type: 'country', zoom_threshold: [4.5, 5, 6, 7, 8] },
  { location_type: 'states_or_provinces', zoom_threshold: [8] },
  { location_type: 'districts_or_cities', zoom_threshold: [9, 10] },
  { location_type: 'talukas', zoom_threshold: [11, 12] },
];

export const FILE_EXTENSION = {
  MP4: 'mp4',
};

export const USER_LIST_CONST_NA = 'N/A';

export const SOUTH_ASIA_REGION_ID = 'e1eb8a52-2b98-4001-a7c2-7ff332725984';

export const SOUTH_ASIA_CONST = {
  name: 'South Asia',
  id: 'e1eb8a52-2b98-4001-a7c2-7ff332725984',
  zoom_level: 4.5,
};

export const MONTHS = [
  { value: '01', label: 'January', days: 31 },
  { value: '02', label: 'February', days: 28 },
  { value: '03', label: 'March', days: 31 },
  { value: '04', label: 'April', days: 30 },
  { value: '05', label: 'May', days: 31 },
  { value: '06', label: 'June', days: 30 },
  { value: '07', label: 'July', days: 31 },
  { value: '08', label: 'August', days: 31 },
  { value: '09', label: 'September', days: 30 },
  { value: '10', label: 'October', days: 31 },
  { value: '11', label: 'November', days: 30 },
  { value: '12', label: 'December', days: 31 },
];

export const MONTHS_LIST = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const CP_MIN_DATE = '1990-01-01';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DISPLAY_DATE_FORMAT = 'DD-MM-YYYY';
export const DISPLAY_CHART_DATE = 'DD/MM/YYYY';
export const DISPLAY_CHART_DATE_TWO = 'YYYY-MM-DD';
export const DATE_FORMAT_ONE = 'YYYY-MM-DD hh:mm:ss';
export const DATA = {
  data_sources: [
    { key: 0, value: 'NOAA GFS' },
    { key: 1, value: 'ERPAS' },
    { key: 2, value: 'ECMF' },
  ],
};

export const DROUGHT_TEMPORAL_AGGREGATION = [
  { id: 0, name: 'Mean', value: 'mean', label: 'Mean' },
  { id: 1, name: 'Median', value: 'median', label: 'Median' },
  { id: 2, name: 'Minimum', value: 'min', label: 'Minimum' },
  { id: 3, name: 'Maximum', value: 'max', label: 'Maximum' },
];

export const ACCUMULATED_RAINFALL_TEMPORAL_AGGREGATION = [
  { id: 0, name: 'SUM', value: 'sum', label: 'Sum' },
  { id: 1, name: 'Mean', value: 'mean', label: 'Mean' },
  { id: 2, name: 'Median', value: 'median', label: 'Median' },
  { id: 3, name: 'Minimum', value: 'min', label: 'Minimum' },
  { id: 4, name: 'Maximum', value: 'max', label: 'Maximum' },
];

export const DROUGHT_PREDICTION_TEMPORAL_AGGREGATION = [
  { id: 0, name: 'Mean', value: 'mean', label: 'Mean' },
  { id: 1, name: 'Sum', value: 'sum', label: 'Sum' },
  { id: 2, name: 'Minimum', value: 'min', label: 'Minimum' },
  { id: 3, name: 'Maximum', value: 'max', label: 'Maximum' },
];

export const TEMPORAL_AGGREGATION = [
  { id: 0, name: 'Mean', value: 'mean', label: 'Mean' },
  { id: 1, name: 'Median', value: 'median', label: 'Median' },
  { id: 2, name: 'Minimum', value: 'min', label: 'Minimum' },
  { id: 3, name: 'Maximum', value: 'max', label: 'Maximum' },
];

export const FILTER_RANGE_CONST = {
  MIN: 'Min',
  MAX: 'Max',
};

export const ACTIVE_TAB = {
  HOME: 'Home',
  WEATHER_FORECAST: 'WeatherForecast',
  DROUGHT_MONITOR: 'DroughtMonitor',
};

export const ADDRESS_CONSTS = {
  LOCALITY: 'locality',
  STATE: 'administrative_area_level_1',
  DISTRICT: 'administrative_area_level_2',
  COUNTRY: 'country',
};

export const DEFAULT_COUNTRY = {
  name: 'India',
  id: 'e1eb8a52-2b98-4001-a7c2-7ff332725984',
};

export const MAP_PREFERENCES_lABELS = {
  COUNTRY: 'country',
  STATES: 'states_or_provinces',
  DISTRICTS: 'districts_or_cities',
  TALUKAS: 'talukas',
  NPSC_LULC: 'npsc_lulc',
  JRC: 'jrc',
  MOD44: 'mod44',
  BASINS: 'basins',
  RIVERS: 'rivers_or_reservoirs',
  METEOROLOGICAL_LOCATIONS: 'meteorological_locations',
};

export const GEOJSON_LABELS = [
  'country',
  'states_or_provinces',
  'districts_or_cities',
  'talukas',
  'meteorological_locations',
  'basins',
  'rivers_or_reservoirs',
];

export const INDIA_COORDINATE = {
  lat: 20.5937,
  long: 78.9629,
  zoom_level: 6,
};

export const CONTINGENCY_FILTER_TABS = {
  MAP: 'map',
  MEASURE: 'measure',
};

export const menuBarMoreOptions = [
  { name: 'Newsfeed', path: '/news-feed' },
  { name: 'User Guide', path: '/user-guide' },
  { name: 'Online Bulletin', path: '/online-bulletin' },
  // {name: 'Disclaimer'}
];

export const DEFAULT_PARAMETERS_CONST = {
  PRECIPITATION: 'Precipitation',
  CUMULATIVE_ANAMOLY: 'Cumulative anomaly',
};

export const OPEN_WEATHER_PARAMETER_SLUG = [
  { name: 'Precipitation', value: 'Precipitation', api_slug: 'rain', measure: 'mm/h' },
  { name: 'Temperature', value: 'Temperature', api_slug: 'temp', measure: '°C' },
  { name: 'Wind_speed', value: 'Wind speed', api_slug: 'wind_speed', measure: 'm/s' },
  { name: 'Cloud', value: 'Cloud', api_slug: 'clouds', measure: '%' },
  { name: 'Pressure', value: 'Pressure', api_slug: 'pressure', measure: 'hPa' },
];

export const EXTERNAL_RSS_FIELD_CONST = {
  startIndex: 0,
  endIndex: 4,
};

export const WEATHER_STATUS = [
  { label: 'Clear', icon: <ClearSky /> },
  { label: 'Clouds', icon: <Clouds /> },
  { label: 'Rain', icon: <Rain /> },
];

export const EXTERNAL_RSS_FEED_REQUEST_URL =
  NEWS_FEED_URL_DOMAIN_CONST +
  '/v1/api.json?rss_url={link}&api_key=kyzvfcytkrc4vhqgke3gvaepjudbcftmaxichrot&count=25';

export const ALERT_TABLE_INDICES_THRESHOLD_CONSTS = {
  SPI: -1,
  DRY_SPELL: 14,
};

export const INDICES_PARAMS = {
  RAINFALL_ANAMOLY: {
    api_slug: 'drought-monitor-v2/rainfall_anomaly',
    drought_indices_category_id: '81a60ced-0c0d-48b7-bbe7-e890765ceb19',
    drought_indices_category_name: 'Drought',
    id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e40',
    impact: false,
    mandatory: false,
    min_date: '1981-01-01',
    name: 'Rainfall Anomaly',
    status: true,
  },
  EXTREME_RAINFALL: {
    api_slug: 'drought-monitor-v2/extreme_rainfall',
    drought_indices_category_id: 'a6920020-78da-455d-9d39-518de4e2f18d',
    drought_indices_category_name: 'Flood',
    id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e41',
    impact: false,
    mandatory: false,
    min_date: '2000-06-01',
    name: 'Extreme Rainfall',
    status: true,
  },
  DRY_SPELL: {
    api_slug: 'drought-monitor/dryspell',
    drought_indices_category_id: '81a60ced-0c0d-48b7-bbe7-e890765ceb19',
    drought_indices_category_name: 'Drought',
    id: '7dc13e67-2f1f-4788-9783-f15caace7e10',
    impact: false,
    mandatory: true,
    min_date: '2000-06-01',
    name: 'Dry Spell',
    status: true,
  },
  CONTINGENCY_DRY_SPELL: {
    api_url: 'drought-monitor/dryspell',
    checked: true,
    disabled: false,
    id: '0134ea6b-339e-498c-a2ea-b7b14db8e4c5',
    label: 'Dryspell',
    name: 'Dryspell',
  },
};

export const DROUGHT_COMPARE_OPTIONS = [
  { name: 'map', value: 'Map', selected: true },
  { name: 'graph', value: 'Graph', selected: false },
];

const getERPASValidDates = () => {
  const t =
    new Date().getDate() + (6 - new Date().getDay() - 1) - (new Date().getDay() === 6 ? 0 : 7);
  const lastFriday = new Date();
  lastFriday.setDate(t);
  let endDate = moment(lastFriday)
    .add(28, 'days')
    .format('YYYY-MM-DD');
  return endDate;
};

export const DATA_SOURCES = {
  OPEN_WEATHER: {
    id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
    name: 'Open Weather',
    dateRange: {
      endDate: moment()
        .add(6, 'days')
        .format(DATE_FORMAT),
      minStartDate: '',
      startDate: moment().format(DATE_FORMAT),
      maxStartDate: '',
      maxEndDate: '',
    },
    master_server: true,
    api_url: 'forecast/open_weather/timeseries', // /forecast/open_weather/timeseries
    parameter_name: 'precipitation',
  },
  GFS_NOAA: {
    id: 'e2f886a7-3299-4c82-af2e-123fe92faf83',
    name: 'GFS - NOAA',
    dateRange: {
      endDate: moment()
        .add(15, 'days')
        .format(DATE_FORMAT),
      minStartDate: moment('2015-07-01T00:00:00Z').format(DATE_FORMAT),
      startDate: moment().format(DATE_FORMAT),
      maxStartDate: moment()
        .add(15, 'days')
        .format(DATE_FORMAT),
      maxEndDate: moment()
        .add(15, 'days')
        .format(DATE_FORMAT),
    },
  },
  ERPAS: {
    id: 'e2f886a7-3299-4c82-af2e-123fe92faf81',
    name: 'ERPAS',
    dateRange: {
      startDate: moment().format(DATE_FORMAT),
      endDate: getERPASValidDates(),
      minStartDate: moment('2019-05-02T00:00:00Z').format(DATE_FORMAT),
      maxStartDate: getERPASValidDates(),
      maxEndDate: getERPASValidDates(),
    },
  },
  ECMWF: {
    id: 'e2f886a7-3299-4c82-af2e-123fe92faf87',
    name: 'ECMWF (Static)',
  },
  CPC: {
    id: 'e2f886a7-3299-4c82-af2e-123fe92faf88',
    name: 'CPC (Static)',
  },
  IRI: {
    id: 'e2f886a7-3299-4c82-af2e-123fe92faf84',
    name: 'IRI',
    parameterName: 'Probability (%) of Most Likely Category',
  },
};

export const DRAW_SHAPE = {
  MAXIMUM_SHAPE: 2,
  MAXIMUM_DRAW_SHAPE: 3,
  MAXIMUM_SHAPE_SIX: 6,
};

export const NEWS_FEED_KEYWORDS_CONST = [
  'climate change',
  'drought',
  'flood',
  'South Asia',
  'food security',
  'water scarcity',
  'drought severity',
  'drought condition',
  'India drought',
  'India flood',
  'Sri Lanka flood',
  'Sri Lanka drought',
  'South Asia drought',
  'South Asia flood',
];

export const ERPAS_PARAMETER_SLUG = [
  { name: 'Precipitation', value: 'Precipitation', api_slug: 'rain_ind_' },
  /*
   * Changed on: sep-5-2022
    Changed By: Sai krishna thalla
    Reason : for erpas, precipitaion climatology graph data is not coming as rainfall climatology is changed to precipitation climatology.
   */
  {
    name: 'Precipitation_climatology',
    value: 'Precipitation climatology',
    api_slug: 'rain_ind_clim_',
  },
  { name: 'Relative_Humidity', value: 'Relative Humidity', api_slug: 'rh2m' },
  {
    name: 'Relative_Humidity_climatology',
    value: 'Relative Humidity climatology',
    api_slug: 'rh2m_clim_',
  },
  { name: 'Minimum_Temperature', value: 'Minimum Temperature', api_slug: 'tmax' },
  { name: 'Maximum_Temperature', value: 'Maximum Temperature', api_slug: 'tmin' },
  {
    name: 'Minimum_Temperature_climatology',
    value: 'Minimum Temperature climatology',
    api_slug: 'tmax_clim_',
  },
  {
    name: 'Maximum_Temperature_climatology',
    value: 'Maximum Temperature climatology',
    api_slug: 'tmin_clim_',
  },
];

export const IRI_PARAMETER_SLUG = [
  { name: 'Precipitation', value: 'Precipitation', api_slug: 'rain_ind_' },
];

export const NOAA_PARAMETER_SLUG = [
  {
    name: 'Precipitation',
    value: 'Precipitation',
    api_slug: 'precipitable_water_entire_atmosphere',
  },
  { name: 'Temperature', value: 'Temperature', api_slug: 'temperature_2m_above_ground' },
  { name: 'Wind_speed', value: 'Wind speed', api_slug: 'u_component_of_wind_10m_above_ground' },
  { name: 'Humidity', value: 'Humidity', api_slug: 'relative_humidity_2m_above_ground' },
];

export const DSS_DROUGHT_MODULE_INDICATORS = [
  { id: 'Mandatory', name: 'Mandatory', category: 'Mandatory' },
];

export const DROUGHT_MODULE_INDICATORS = [
  { id: 'Mandatory', name: 'Mandatory', category: 'Mandatory' },
  { id: 'Impact', name: 'Impact', category: 'Impact' },
];

export const DSS_MODULE_CONSTS = {
  MANDATORY: 'Mandatory',
  IMPACT: 'Impact',
  FINAL_DROUGHT_MAP: 'Final Drought Map',
  CHECK_DROUGHT_CONDITION: 'Check Drought',
};

export const DSS_INITAL_PARAMS_LIST = [
  { name: 'Impact', phase: 'Active', indicesList: [], impact: true, mandatory: false },
  { name: 'Mandatory', phase: 'Alert', indicesList: [], impact: false, mandatory: true },
];

export const DSS_DROUGHT_PHASES_CONST = [
  {
    value: 'Alert',
    label: 'Alert',
    id: 'Alert',
  },
  {
    value: 'Active',
    label: 'Active',
    id: 'Active',
  },
];
  // {
  //   value: 'drought prediction',
  //   label: 'Drought Prediction',
  //   api_slug: 'drought-prediction',
  // },
export const DROUGHT_MODULES_CONST = [

  {
    value: 'drought monitor',
    label: 'Drought Monitor',
    api_slug: 'drought-monitor',
  },
  {
    value: 'drought decision support',
    label: 'Drought Decision Support',
    api_slug: 'drought-decision-support',
  },
  // changes done due to client request
  {
    value: 'other indices',
    label: 'Other Indices',
    api_slug: 'drought-monitor-v2',
  },
];

export const INDICES_MODULE = {
  PUBLIC_API: 'public_api',
  DROUGHT_MONITOR: 'drought_monitoring',
};

export const OPEN_WEATHER_CHART_PARAMS = [
  { name: 'Temperature', measure: '°C' },
  { name: 'Humidity', measure: '' },
  { name: 'Wind speed', measure: 'm/s' },
  { name: 'Wind Gust', measure: '' },
  { name: 'Precipitation', measure: 'mm/h' },
  { name: 'Pressure', measure: 'hPa' },
];

export const CHART_LABEL_COLORS = [
  '#008ffb',
  '#00e396',
  '#feb019',
  '#ff4560',
  '#775dd0',
  '#008ffb',
  '#00e396',
  '#feb019',
  '#ff4560',
  '#775dd0',
  '#008ffb',
  '#00e396',
  '#feb019',
  '#ff4560',
  '#775dd0',
];

export const FORECAST_LEAD_TIME_LIST = [
  { value: 1, label: 'January', id: 1.0, name: 'January' },
  { value: 2, label: 'February', id: 2.0, name: 'February' },
  { value: 3, label: 'March', id: 3.0, name: 'March' },
  { value: 4, label: 'April', id: 4.0, name: 'April' },
  { value: 5, label: 'May', id: 5.0, name: 'May' },
  { value: 6, label: 'June', id: 6.0, name: 'June' },
  { value: 7, label: 'July', id: 7.0, name: 'July' },
  { value: 8, label: 'August', id: 8.0, name: 'August' },
  { value: 9, label: 'September', id: 9.0, name: 'September' },
  { value: 10, label: 'October', id: 10.0, name: 'October' },
  { value: 11, label: 'November', id: 11.0, name: 'November' },
  { value: 12, label: 'December', id: 12.0, name: 'December' },
];

export const GEOJSON_COUNTRY_DATA = [
  {
    selectedCountry: 'South Asia',
    country: 'NAME_EN',
    basins: 'MAJ_NAME',
    rivers_or_reservoirs: 'river_lake',
  },
  {
    selectedCountry: 'Afghanistan',
    // states_or_provinces: 'Province_N',
    states_or_provinces: 'Prov_name',
    districts_or_cities: 'Dist_name',
    talukas: '',
    meteorological_locations: 'Station_Na',
    basins: 'MJ_BASIN__',
    rivers_or_reservoirs: 'R_NAME',
  },
  {
    selectedCountry: 'Bangladesh',
    states_or_provinces: 'NAME_1',
    districts_or_cities: 'NAME_2',
    talukas: 'NAME_3',
    meteorological_locations: '',
    basins: '',
    rivers_or_reservoirs: '',
  },
  {
    selectedCountry: 'Bhutan',
    states_or_provinces: 'NAME_1',
    districts_or_cities: 'NAME_2',
    talukas: '',
    meteorological_locations: '',
    basins: '',
    rivers_or_reservoirs: '',
  },
  {
    selectedCountry: 'India',
    states_or_provinces: 'State_Name',
    // states_or_provinces: 'stname',
    districts_or_cities: 'dtname',
    talukas: 'sdtname',
    meteorological_locations: '',
    basins: 'MAJ_NAME',
    rivers_or_reservoirs: '',
  },
  {
    selectedCountry: 'Nepal',
    states_or_provinces: 'NAME_1',
    districts_or_cities: 'NAME_2',
    talukas: 'NAME_3',
    meteorological_locations: '',
    basins: '',
    rivers_or_reservoirs: '',
  },
  {
    selectedCountry: 'Pakistan',
    states_or_provinces: 'NAME_1',
    districts_or_cities: 'NAME_2',
    talukas: 'NAME_3',
    meteorological_locations: '',
    basins: '',
    rivers_or_reservoirs: '',
  },
  {
    selectedCountry: 'Sri Lanka',
    states_or_provinces: 'ADM1_EN',
    districts_or_cities: 'ADM2_EN',
    talukas: 'ADM3_EN',
    meteorological_locations: 'ST_Name',
    basins: 'basin_name',
    rivers_or_reservoirs: 'Name',
  },
];

export const GEOJSON_COUNTRY_TOOLTIP_DATA = [
  {
    selectedCountry: 'South Asia',
    country: 'NAME_EN',
    basins: 'MAJ_NAME',
    rivers_or_reservoirs: 'river_lake',
  },
  {
    selectedCountry: 'Afghanistan',
    // states_or_provinces: 'Province_N',
    states_or_provinces: 'NAME_1',
    districts_or_cities: 'Dist_name',
    talukas: '',
    meteorological_locations: 'Station_Na',
    basins: 'MJ_BASIN__',
    rivers_or_reservoirs: 'R_NAME',
  },
  {
    selectedCountry: 'Bangladesh',
    states_or_provinces: 'NAME_1',
    districts_or_cities: 'NAME_2',
    talukas: 'NAME_3',
    meteorological_locations: '',
    basins: '',
    rivers_or_reservoirs: '',
  },
  {
    selectedCountry: 'Bhutan',
    states_or_provinces: 'NAME_1',
    districts_or_cities: 'NAME_2',
    talukas: '',
    meteorological_locations: '',
    basins: '',
    rivers_or_reservoirs: '',
  },
  {
    selectedCountry: 'India',
    states_or_provinces: 'stname',
    districts_or_cities: 'dtname',
    talukas: 'sdtname',
    meteorological_locations: '',
    basins: 'MAJ_NAME',
    rivers_or_reservoirs: '',
  },
  {
    selectedCountry: 'Nepal',
    states_or_provinces: 'NAME_1',
    districts_or_cities: 'NAME_2',
    talukas: 'NAME_3',
    meteorological_locations: '',
    basins: '',
    rivers_or_reservoirs: '',
  },
  {
    selectedCountry: 'Pakistan',
    states_or_provinces: 'NAME_1',
    districts_or_cities: 'NAME_2',
    talukas: 'NAME_3',
    meteorological_locations: '',
    basins: '',
    rivers_or_reservoirs: '',
  },
  {
    selectedCountry: 'Sri Lanka',
    states_or_provinces: 'ADM1_EN',
    districts_or_cities: 'ADM2_EN',
    talukas: 'ADM3_EN',
    meteorological_locations: 'ST_Name',
    basins: 'basin_name',
    rivers_or_reservoirs: 'Name',
  },
];

export const OPEN_WEATHER_PARAMETERS_CONST = [
  {
    id: 'Temperature',
    name: 'Temperature',
    layerName: 'temp_new',
    measure: '°C',
    min: -20,
    max: +40,
    //palette: ['#821692','#821692','#821692','#821692','#8257db','#208cec','#20c4e8','#23dddd','#c2ff28','#fff028','#ffc228','#fc8014'],
    dataSource: 'Open Weather',
    parameter: 'Temperature',
    image: 'temp-background',
  },
  {
    id: 'Rainfall',
    name: 'Rainfall',
    layerName: 'rain_cls',
    measure: 'mm',
    min: 0.1,
    max: 100,
    // palette: ['#000000','#000000','#000000','#000000','#6d6dcd','#5050e1','#1414ff'],
    dataSource: 'Open Weather',
    parameter: 'Rainfall',
    image: 'rain-background',
  },
  {
    id: 'Precipitation',
    name: 'Precipitation',
    layerName: 'precipitation_cls',
    measure: 'mm/h',
    min: 0,
    max: 60,
    palette: [
      '#0000BB',
      '#0000F7',
      '#004FFE',
      '#00ADFE',
      '#07FDF7',
      '#4FFDAF',
      '#9BFE63',
      '#E3FE1B',
      '#FED300',
      '#FE8B00',
      '#FF3F00',
      '#F70000',
      '#BB0000',
    ],
    dataSource: 'Open Weather',
    parameter: 'Precipitation',
    image: '',
  },
  {
    id: 'Wind speed',
    name: 'Wind speed',
    layerName: 'wind_new',
    measure: 'm/s',
    min: 0,
    max: 100,
    //palette: ['#000000','#edcdcd','#b264bc','#b264bc','#3e203b','#734bac','#4600af','#0d1126'],
    dataSource: 'Open Weather',
    parameter: 'Wind speed',
    image: 'wind-background',
  },
  {
    id: 'Cloud',
    name: 'Cloud',
    //layerName: 'clouds_new',
    layerName: 'clouds_cls',
    measure: '%',
    min: 0,
    max: 100,
    //palette: ['#000000','#edcdcd','#b264bc','#b264bc','#3e203b','#734bac','#4600af','#0d1126'],
    dataSource: 'Open Weather',
    parameter: 'Cloud',
    image: 'cloud-background',
  },
  {
    id: 'Pressure',
    name: 'Pressure',
    layerName: 'pressure_new',
    measure: 'hPa',
    min: 950,
    max: 1070,
    //palette: ['#000000','#edcdcd','#b264bc','#b264bc','#3e203b','#734bac','#4600af','#0d1126'],
    dataSource: 'Open Weather',
    parameter: 'Pressure',
    image: 'pressure-background',
  },
];

export const SWAGGER_JSON_FILE_URL_LIST = [
  {
    id: '1',
    name: 'Agriculture',
    url: AMZONS_S3_URL_DOMAIN_CONST + '/uat/swagger/agriculture.json',
  },
  {
    id: '2',
    name: 'Drought',
    url: AMZONS_S3_URL_DOMAIN_CONST + '/uat/swagger/drought.json',
  },
  {
    id: '3',
    name: 'Flood',
    url: AMZONS_S3_URL_DOMAIN_CONST + '/uat/swagger/flood.json',
  },
];

export const REQUEST_STATUS = {
  NEW: 'new',
  APPROVE: 'approve',
  REJECT: 'reject',
  DELETE: 'delete',
};

export const STATIC_RESOURCES_CATEGORY = [
  {
    id: 'e2f886a7-3299-4c82-af2e-123fe92faf67',
    name: 'Seasonal',
    status: true,
    static_resource_sub_category: [
      {
        id: 'e2f886a7-3299-4c82-af2e-123fe92faf11',
        name: 'ECMWF (Static)',
        status: true,
        static_resource_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf67',
      },
      {
        id: 'e2f886a7-3299-4c82-af2e-123fe92faf12',
        name: 'CPC (Static)',
        status: true,
        static_resource_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf67',
      },
      {
        id: 'e2f886a7-3299-4c82-af2e-123fe92faf13',
        name: 'IRI',
        status: true,
        static_resource_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf67',
      },
    ],
  },
  {
    id: 'e2f886a7-3299-4c82-af2e-123fe92faf68',
    name: 'Sub - Seasonal',
    status: true,
    static_resource_sub_category: [
      // {
      //   id: 'e2f886a7-3299-4c82-af2e-123fe92faf14',
      //   name: 'ERPAS',
      //   status: true,
      //   static_resource_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf68',
      // },
      // {
      //   id: 'e2f886a7-3299-4c82-af2e-123fe92faf15',
      //   name: 'ERPAS (Static)',
      //   status: true,
      //   static_resource_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf68',
      // },
      {
        id: 'e2f886a7-3299-4c82-af2e-123fe92faf16',
        name: 'ECMWF (Static)',
        status: true,
        static_resource_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf68',
      },
    ],
  },
];

export const COUNTRIES_JSON_LIST = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Åland Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'AndorrA', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: "Cote D'Ivoire", code: 'CI' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: "Korea, Democratic People'S Republic of", code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: "Lao People'S Democratic Republic", code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'RWANDA', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia and Montenegro', code: 'CS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States', code: 'US' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' },
];

export const STATIC_RESOURCE_GET_CATEGORY_LIS = [
  {
    category: 'Medium Term',
    data: [
      {
        id: 'e2f886a7-3299-4c82-af2e-123fe92fa100',
        country_id: null,
        forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf81',
        forecast_data_source: {
          id: 'e2f886a7-3299-4c82-af2e-123fe92faf81',
          name: 'ERPAS',
          summary: '',
          forecast_data_source_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf70',
          forecast_data_source_category: {
            id: 'e2f886a7-3299-4c82-af2e-123fe92faf70',
            name: 'Medium Term',
          },
          forecast_data_source_with_parameter: [
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf26',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf81',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab01',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab01',
                name: 'Rainfall',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Rainfall.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf28',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf81',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
                name: 'Temperature',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Temperature.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
          ],
        },
      },
    ],
  },
  {
    category: 'Seasonal',
    data: [
      {
        id: 'e2f886a7-3299-4c82-af2e-123fe92fa107',
        country_id: null,
        forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
        forecast_data_source: {
          id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
          name: 'ERPAS (Static)',
          summary: '',
          forecast_data_source_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf67',
          forecast_data_source_category: {
            id: 'e2f886a7-3299-4c82-af2e-123fe92faf67',
            name: 'Seasonal',
          },
          forecast_data_source_with_parameter: [
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf75',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab01',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab01',
                name: 'Rainfall',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Rainfall.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf77',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
                name: 'Temperature',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Temperature.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf78',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab04',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab04',
                name: 'Wind speed',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Wind_speed.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
          ],
        },
      },
    ],
  },
  {
    category: 'Short Term',
    data: [
      {
        id: 'e2f886a7-3299-4c82-af2e-123fe92fa101',
        country_id: null,
        forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf83',
        forecast_data_source: {
          id: 'e2f886a7-3299-4c82-af2e-123fe92faf83',
          name: 'GFS - NOAA',
          summary: '',
          forecast_data_source_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf69',
          forecast_data_source_category: {
            id: 'e2f886a7-3299-4c82-af2e-123fe92faf69',
            name: 'Short Term',
          },
          forecast_data_source_with_parameter: [
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf34',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf83',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab02',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab02',
                name: 'Precipitation',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Precipitation.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf35',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf83',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
                name: 'Temperature',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Temperature.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf36',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf83',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab04',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab04',
                name: 'Wind speed',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Wind_speed.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf38',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf83',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab06',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab06',
                name: 'Humidity',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Humidity.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
          ],
        },
      },
      {
        id: 'e2f886a7-3299-4c82-af2e-123fe92fa103',
        country_id: null,
        forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
        forecast_data_source: {
          id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
          name: 'Open Weather',
          summary: '',
          forecast_data_source_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf69',
          forecast_data_source_category: {
            id: 'e2f886a7-3299-4c82-af2e-123fe92faf69',
            name: 'Short Term',
          },
          forecast_data_source_with_parameter: [
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf48',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab02',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab02',
                name: 'Precipitation',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Precipitation.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf49',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
                name: 'Temperature',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Temperature.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf50',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab04',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab04',
                name: 'Wind speed',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Wind_speed.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf51',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab05',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab05',
                name: 'Wind direction',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Wind_direction.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf52',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab06',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab06',
                name: 'Humidity',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Humidity.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf53',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab07',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab07',
                name: 'Wind Gust',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Wind_Gust.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
          ],
        },
      },
    ],
  },
  {
    category: 'Sub - Seasonal',
    data: [
      {
        id: 'e2f886a7-3299-4c82-af2e-123fe92fa105',
        country_id: null,
        forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf87',
        forecast_data_source: {
          id: 'e2f886a7-3299-4c82-af2e-123fe92faf87',
          name: 'ECMWF (Static)',
          summary: '',
          forecast_data_source_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf68',
          forecast_data_source_category: {
            id: 'e2f886a7-3299-4c82-af2e-123fe92faf68',
            name: 'Sub - Seasonal',
          },
          forecast_data_source_with_parameter: [
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf61',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf87',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab01',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab01',
                name: 'Rainfall',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Rainfall.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf63',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf87',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
                name: 'Temperature',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Temperature.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
          ],
        },
      },
      {
        id: 'e2f886a7-3299-4c82-af2e-123fe92fa106',
        country_id: null,
        forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf88',
        forecast_data_source: {
          id: 'e2f886a7-3299-4c82-af2e-123fe92faf88',
          name: 'CPC (Static)',
          summary: '',
          forecast_data_source_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf68',
          forecast_data_source_category: {
            id: 'e2f886a7-3299-4c82-af2e-123fe92faf68',
            name: 'Sub - Seasonal',
          },
          forecast_data_source_with_parameter: [
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf68',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf88',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab01',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab01',
                name: 'Rainfall',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Rainfall.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf70',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf88',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
                name: 'Temperature',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Temperature.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
          ],
        },
      },
    ],
  },
];

export const ERPAS_STATIC_CONST = {
  id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
  name: 'ERPAS (Static)',
};

export const STATIC_RESOURCE_GET_CATEGORY_LIST = [
  {
    category: 'Weather Forecast',
    data: [
      {
        id: 'e2f886a7-3299-4c82-af2e-123fe92fa103',
        country_id: null,
        forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
        forecast_data_source: {
          id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
          name: 'Open Weather',
          summary: '',
          type: 'non-static',
          forecast_data_source_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf72',
          forecast_data_source_category: {
            id: 'e2f886a7-3299-4c82-af2e-123fe92faf72',
            name: 'Weather Forecast',
          },
          forecast_data_source_with_parameter: [
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf48',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab02',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab02',
                name: 'Precipitation',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Precipitation.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf49',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
                name: 'Temperature',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Temperature.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf50',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab04',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab04',
                name: 'Wind speed',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Wind_speed.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf51',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab05',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab05',
                name: 'Wind direction',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Wind_direction.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf52',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab06',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab06',
                name: 'Humidity',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Humidity.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf53',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab07',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab07',
                name: 'Wind Gust',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Wind_Gust.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
          ],
        },
      },
      {
        id: 'e2f886a7-3299-4c82-af2e-123fe92fa101',
        country_id: null,
        forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf83',
        forecast_data_source: {
          id: 'e2f886a7-3299-4c82-af2e-123fe92faf83',
          name: 'GFS - NOAA',
          summary: '',
          type: 'non-static',
          forecast_data_source_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf72',
          forecast_data_source_category: {
            id: 'e2f886a7-3299-4c82-af2e-123fe92faf72',
            name: 'Weather Forecast',
          },
          forecast_data_source_with_parameter: [
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf34',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf83',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab02',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab02',
                name: 'Precipitation',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Precipitation.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf35',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf83',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
                name: 'Temperature',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Temperature.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf36',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf83',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab04',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab04',
                name: 'Wind speed',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Wind_speed.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf38',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf83',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab06',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab06',
                name: 'Humidity',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Humidity.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
          ],
        },
      },
    ],
  },
  {
    category: 'Sub - Seasonal',
    data: [
      // {
      //   id: 'e2f886a7-3299-4c82-af2e-123fe92fa100',
      //   country_id: null,
      //   forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf81',
      //   forecast_data_source: {
      //     id: 'e2f886a7-3299-4c82-af2e-123fe92faf81',
      //     name: 'ERPAS',
      //     summary: '',
      //     type: 'non-static',
      //     forecast_data_source_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf68',
      //     forecast_data_source_category: {
      //       id: 'e2f886a7-3299-4c82-af2e-123fe92faf68',
      //       name: 'Sub - Seasonal',
      //     },
      //     forecast_data_source_with_parameter: [
      //       {
      //         id: 'e2f886a7-3299-4c82-af2e-123fe92faf26',
      //         forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf81',
      //         parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab01',
      //         parameter: {
      //           id: 'e2f886a7-3299-4c82-af2e-123fe92fab01',
      //           name: 'Rainfall',
      //           icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Rainfall.png',
      //           status: true,
      //           drought_indices_category_id: null,
      //         },
      //         status: true,
      //       },
      //       {
      //         id: 'e2f886a7-3299-4c82-af2e-123fe92faf28',
      //         forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf81',
      //         parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
      //         parameter: {
      //           id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
      //           name: 'Temperature',
      //           icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Temperature.png',
      //           status: true,
      //           drought_indices_category_id: null,
      //         },
      //         status: true,
      //       },
      //     ],
      //   },
      // },
      {
        id: 'e2f886a7-3299-4c82-af2e-123fe92fa105',
        country_id: null,
        forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf87',
        forecast_data_source: {
          id: 'e2f886a7-3299-4c82-af2e-123fe92faf87',
          name: 'ECMWF (Static)',
          summary: '',
          type: 'static',
          forecast_data_source_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf68',
          forecast_data_source_category: {
            id: 'e2f886a7-3299-4c82-af2e-123fe92faf68',
            name: 'Sub - Seasonal',
          },
          forecast_data_source_with_parameter: [
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf61',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf87',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab01',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab01',
                name: 'Rainfall',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Rainfall.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
            {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf63',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf87',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
                name: 'Temperature',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Temperature.png',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
          ],
        },
      },
      // {
      //   id: 'e2f886a7-3299-4c82-af2e-123fe92fa107',
      //   country_id: null,
      //   forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
      //   forecast_data_source: {
      //     id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
      //     name: 'ERPAS (Static)',
      //     summary: '',
      //     type: 'static',
      //     forecast_data_source_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf68',
      //     forecast_data_source_category: {
      //       id: 'e2f886a7-3299-4c82-af2e-123fe92faf68',
      //       name: 'Sub - Seasonal',
      //     },
      //     forecast_data_source_with_parameter: [
      //       {
      //         id: 'e2f886a7-3299-4c82-af2e-123fe92faf75',
      //         forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
      //         parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab01',
      //         parameter: {
      //           id: 'e2f886a7-3299-4c82-af2e-123fe92fab01',
      //           name: 'Rainfall',
      //           icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Rainfall.png',
      //           status: true,
      //           drought_indices_category_id: null,
      //         },
      //         status: true,
      //       },
      //       {
      //         id: 'e2f886a7-3299-4c82-af2e-123fe92faf77',
      //         forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
      //         parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
      //         parameter: {
      //           id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
      //           name: 'Temperature',
      //           icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Temperature.png',
      //           status: true,
      //           drought_indices_category_id: null,
      //         },
      //         status: true,
      //       },
      //       {
      //         id: 'e2f886a7-3299-4c82-af2e-123fe92faf78',
      //         forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
      //         parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab04',
      //         parameter: {
      //           id: 'e2f886a7-3299-4c82-af2e-123fe92fab04',
      //           name: 'Wind speed',
      //           icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Wind_speed.png',
      //           status: true,
      //           drought_indices_category_id: null,
      //         },
      //         status: true,
      //       },
      //     ],
      //   },
      // },
    ],
  },

  {
    category: 'Seasonal',
    data: [
      // {
      //   id: 'e2f886a7-3299-4c82-af2e-123fe92fa106',
      //   country_id: null,
      //   forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf88',
      //   forecast_data_source: {
      //     id: 'e2f886a7-3299-4c82-af2e-123fe92faf88',
      //     name: 'CPC (Static)',
      //     summary: '',
      //     type: 'static',
      //     forecast_data_source_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf67',
      //     forecast_data_source_category: {
      //       id: 'e2f886a7-3299-4c82-af2e-123fe92faf67',
      //       name: 'Seasonal',
      //     },
      //     forecast_data_source_with_parameter: [
      //       {
      //         id: 'e2f886a7-3299-4c82-af2e-123fe92faf68',
      //         forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf88',
      //         parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab01',
      //         parameter: {
      //           id: 'e2f886a7-3299-4c82-af2e-123fe92fab01',
      //           name: 'Rainfall',
      //           icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Rainfall.png',
      //           status: true,
      //           drought_indices_category_id: null,
      //         },
      //         status: true,
      //       },
      //       {
      //         id: 'e2f886a7-3299-4c82-af2e-123fe92faf70',
      //         forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf88',
      //         parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
      //         parameter: {
      //           id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
      //           name: 'Temperature',
      //           icon:
      //             AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/Temperature.png',
      //           status: true,
      //           drought_indices_category_id: null,
      //         },
      //         status: true,
      //       },
      //     ],
      //   },
      // },

      {
        id: 'e2f886a7-3299-4c82-af2e-123fe92fa126',
        country_id: 'e2f886a7-3299-4c82-af2e-987fe92fab40',
        forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf84',
        forecast_data_source: {
          id: 'e2f886a7-3299-4c82-af2e-123fe92faf84',
          name: 'IRI',
          type: 'non-static',
          forecast_data_source_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf67',
          forecast_data_source_category: {
            id: 'e2f886a7-3299-4c82-af2e-123fe92faf67',
            name: 'Seasonal',
          },
          forecast_data_source_with_parameter: [
            {
              id: '',
              forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf84',
              parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab02',
              parameter: {
                id: 'e2f886a7-3299-4c82-af2e-123fe92fab02',
                name: 'Precipitation',
                icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/precipitation.svg',
                status: true,
                drought_indices_category_id: null,
              },
              status: true,
            },
          ],
        },
      },
    ],
  },
];

export const ForeCast_DataSources = {
  result: [
    {
      category: 'Seasonal',
      data: [
        {
          id: 'e2f886a7-3299-4c82-af2e-123fe92fa156',
          country_id: 'e1eb8a52-2b98-4001-a7c2-7ff332725984',
          forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf81',
          forecast_data_source: {
            id: 'e2f886a7-3299-4c82-af2e-123fe92faf81',
            name: 'ERPAS',
            summary: '',
            type: 'non-static',
            forecast_data_source_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf67',
            forecast_data_source_category: {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf67',
              name: 'Seasonal',
            },
            forecast_data_source_with_parameter: [
              {
                type: '',
                parameter: [
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf26',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf81',
                    parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab02',
                    parameter: {
                      id: 'e2f886a7-3299-4c82-af2e-123fe92fab02',
                      name: 'Precipitation',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/precipitation.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST +
                        '/uat/parameter_icons/precipitation_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: 'rain_ind_',
                      parameter_type: '',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf27',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf81',
                    parameter_id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e25',
                    parameter: {
                      id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e25',
                      name: 'Rainfall climatology',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/rainfall.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/rainfall_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: 'rain_ind_clim_',
                      parameter_type: '',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf28',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf81',
                    parameter_id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e26',
                    parameter: {
                      id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e26',
                      name: 'Relative humidity',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/humidity.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/humidity_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: 'rh2m',
                      parameter_type: '',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf29',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf81',
                    parameter_id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e27',
                    parameter: {
                      id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e27',
                      name: 'Relative humidity climatology',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/humidity.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/humidity_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: 'rh2m_clim_',
                      parameter_type: '',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf30',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf81',
                    parameter_id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e29',
                    parameter: {
                      id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e29',
                      name: 'Minimum temperature climatology',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/temperature.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST +
                        '/uat/parameter_icons/temperature_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: 'tmin_clim_',
                      parameter_type: '',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf32',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf81',
                    parameter_id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e28',
                    parameter: {
                      id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e28',
                      name: 'Maximum temperature climatology',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/temperature.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST +
                        '/uat/parameter_icons/temperature_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: 'tmax_clim_',
                      parameter_type: '',
                    },
                    status: true,
                  },
                ],
              },
              {
                type: 'temperature',
                parameter: [
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf31',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf81',
                    parameter_id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e23',
                    parameter: {
                      id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e23',
                      name: 'Minimum temperature',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/temperature.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST +
                        '/uat/parameter_icons/temperature_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: 'tmin',
                      parameter_type: 'temperature',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf81',
                    parameter_id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e23',
                    parameter: {
                      id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e23',
                      name: 'Minimum temperature',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/temperature.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST +
                        '/uat/parameter_icons/temperature_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: 'tmin',
                      parameter_type: 'temperature',
                    },
                    status: true,
                  },
                ],
              },
            ],
          },
        },
        {
          id: 'e2f886a7-3299-4c82-af2e-123fe92fa157',
          country_id: 'e1eb8a52-2b98-4001-a7c2-7ff332725984',
          forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf83',
          forecast_data_source: {
            id: 'e2f886a7-3299-4c82-af2e-123fe92faf83',
            name: 'GFS - NOAA',
            summary: '',
            type: 'non-static',
            forecast_data_source_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf67',
            forecast_data_source_category: {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf67',
              name: 'Seasonal',
            },
            forecast_data_source_with_parameter: [
              {
                type: '',
                parameter: [
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf34',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf83',
                    parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab02',
                    parameter: {
                      id: 'e2f886a7-3299-4c82-af2e-123fe92fab02',
                      name: 'Precipitation',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/precipitation.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST +
                        '/uat/parameter_icons/precipitation_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: 'rain_ind_',
                      parameter_type: '',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf35',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf83',
                    parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
                    parameter: {
                      id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
                      name: 'Temperature',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/temperature.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST +
                        '/uat/parameter_icons/temperature_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: 'temp',
                      parameter_type: '',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf36',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf83',
                    parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab04',
                    parameter: {
                      id: 'e2f886a7-3299-4c82-af2e-123fe92fab04',
                      name: 'Wind speed',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/wind_speed.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/wind_speed_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: 'wind_speed',
                      parameter_type: '',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf38',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf83',
                    parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab06',
                    parameter: {
                      id: 'e2f886a7-3299-4c82-af2e-123fe92fab06',
                      name: 'Humidity',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/humidity.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/humidity_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: 'humdity',
                      parameter_type: '',
                    },
                    status: true,
                  },
                ],
              },
            ],
          },
        },
        {
          id: 'e2f886a7-3299-4c82-af2e-123fe92fa158',
          country_id: 'e1eb8a52-2b98-4001-a7c2-7ff332725984',
          forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf84',
          forecast_data_source: {
            id: 'e2f886a7-3299-4c82-af2e-123fe92faf84',
            name: 'IRI',
            summary: '',
            type: 'non-static',
            forecast_data_source_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf67',
            forecast_data_source_category: {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf67',
              name: 'Seasonal',
            },
            forecast_data_source_with_parameter: [
              {
                type: '',
                parameter: [
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf41',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf84',
                    parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab02',
                    parameter: {
                      id: 'e2f886a7-3299-4c82-af2e-123fe92fab02',
                      name: 'Precipitation',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/precipitation.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST +
                        '/uat/parameter_icons/precipitation_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: 'rain_ind_',
                      parameter_type: '',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf42',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf84',
                    parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
                    parameter: {
                      id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
                      name: 'Temperature',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/temperature.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST +
                        '/uat/parameter_icons/temperature_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: 'temp',
                      parameter_type: '',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf43',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf84',
                    parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab04',
                    parameter: {
                      id: 'e2f886a7-3299-4c82-af2e-123fe92fab04',
                      name: 'Wind speed',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/wind_speed.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/wind_speed_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: 'wind_speed',
                      parameter_type: '',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf44',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf84',
                    parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab05',
                    parameter: {
                      id: 'e2f886a7-3299-4c82-af2e-123fe92fab05',
                      name: 'Wind direction',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/wind_direction.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST +
                        '/uat/parameter_icons/wind_direction_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: '',
                      parameter_type: '',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf45',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf84',
                    parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab06',
                    parameter: {
                      id: 'e2f886a7-3299-4c82-af2e-123fe92fab06',
                      name: 'Humidity',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/humidity.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/humidity_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: 'humdity',
                      parameter_type: '',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf46',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf84',
                    parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab07',
                    parameter: {
                      id: 'e2f886a7-3299-4c82-af2e-123fe92fab07',
                      name: 'Wind gust',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/wind_gust.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/wind_gust_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: '',
                      parameter_type: '',
                    },
                    status: true,
                  },
                ],
              },
            ],
          },
        },
        {
          id: 'e2f886a7-3299-4c82-af2e-123fe92fa159',
          country_id: 'e1eb8a52-2b98-4001-a7c2-7ff332725984',
          forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
          forecast_data_source: {
            id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
            name: 'Open Weather',
            summary: '',
            type: 'non-static',
            forecast_data_source_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf67',
            forecast_data_source_category: {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf67',
              name: 'Seasonal',
            },
            forecast_data_source_with_parameter: [
              {
                type: '',
                parameter: [
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf48',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
                    parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab02',
                    parameter: {
                      id: 'e2f886a7-3299-4c82-af2e-123fe92fab02',
                      name: 'Precipitation',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/precipitation.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST +
                        '/uat/parameter_icons/precipitation_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: 'rain_ind_',
                      parameter_type: '',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf49',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
                    parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
                    parameter: {
                      id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
                      name: 'Temperature',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/temperature.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST +
                        '/uat/parameter_icons/temperature_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: 'temp',
                      parameter_type: '',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf50',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
                    parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab04',
                    parameter: {
                      id: 'e2f886a7-3299-4c82-af2e-123fe92fab04',
                      name: 'Wind speed',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/wind_speed.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/wind_speed_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: 'wind_speed',
                      parameter_type: '',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf87',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
                    parameter_id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e30',
                    parameter: {
                      id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e30',
                      name: 'Cloud',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/rainfall.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/rainfall_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: '',
                      parameter_type: '',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf88',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
                    parameter_id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e31',
                    parameter: {
                      id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e31',
                      name: 'Pressure',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/humidity.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/humidity_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: '',
                      parameter_type: '',
                    },
                    status: true,
                  },
                ],
              },
            ],
          },
        },
        {
          id: 'e2f886a7-3299-4c82-af2e-123fe92fa160',
          country_id: 'e1eb8a52-2b98-4001-a7c2-7ff332725984',
          forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf86',
          forecast_data_source: {
            id: 'e2f886a7-3299-4c82-af2e-123fe92faf86',
            name: 'Govt. API',
            summary: '',
            type: 'non-static',
            forecast_data_source_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf67',
            forecast_data_source_category: {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf67',
              name: 'Seasonal',
            },
            forecast_data_source_with_parameter: [
              {
                type: '',
                parameter: [
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf55',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf86',
                    parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab02',
                    parameter: {
                      id: 'e2f886a7-3299-4c82-af2e-123fe92fab02',
                      name: 'Precipitation',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/precipitation.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST +
                        '/uat/parameter_icons/precipitation_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: 'rain_ind_',
                      parameter_type: '',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf56',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf86',
                    parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
                    parameter: {
                      id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
                      name: 'Temperature',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/temperature.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST +
                        '/uat/parameter_icons/temperature_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: 'temp',
                      parameter_type: '',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf57',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf86',
                    parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab04',
                    parameter: {
                      id: 'e2f886a7-3299-4c82-af2e-123fe92fab04',
                      name: 'Wind speed',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/wind_speed.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/wind_speed_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: 'wind_speed',
                      parameter_type: '',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf58',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf86',
                    parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab05',
                    parameter: {
                      id: 'e2f886a7-3299-4c82-af2e-123fe92fab05',
                      name: 'Wind direction',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/wind_direction.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST +
                        '/uat/parameter_icons/wind_direction_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: '',
                      parameter_type: '',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf59',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf86',
                    parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab06',
                    parameter: {
                      id: 'e2f886a7-3299-4c82-af2e-123fe92fab06',
                      name: 'Humidity',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/humidity.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/humidity_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: 'humdity',
                      parameter_type: '',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf60',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf86',
                    parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab07',
                    parameter: {
                      id: 'e2f886a7-3299-4c82-af2e-123fe92fab07',
                      name: 'Wind gust',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/wind_gust.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/wind_gust_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: '',
                      parameter_type: '',
                    },
                    status: true,
                  },
                ],
              },
            ],
          },
        },
      ],
    },
    {
      category: 'Sub - Seasonal',
      data: [
        {
          id: 'e2f886a7-3299-4c82-af2e-123fe92fa161',
          country_id: 'e1eb8a52-2b98-4001-a7c2-7ff332725984',
          forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf87',
          forecast_data_source: {
            id: 'e2f886a7-3299-4c82-af2e-123fe92faf87',
            name: 'ECMWF (Static)',
            summary: '',
            type: 'static',
            forecast_data_source_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf68',
            forecast_data_source_category: {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf68',
              name: 'Sub - Seasonal',
            },
            forecast_data_source_with_parameter: [
              {
                type: '',
                parameter: [
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf62',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf87',
                    parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab02',
                    parameter: {
                      id: 'e2f886a7-3299-4c82-af2e-123fe92fab02',
                      name: 'Precipitation',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/precipitation.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST +
                        '/uat/parameter_icons/precipitation_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: 'rain_ind_',
                      parameter_type: '',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf63',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf87',
                    parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
                    parameter: {
                      id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
                      name: 'Temperature',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/temperature.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST +
                        '/uat/parameter_icons/temperature_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: 'temp',
                      parameter_type: '',
                    },
                    status: true,
                  },
                ],
              },
            ],
          },
        },
        {
          id: 'e2f886a7-3299-4c82-af2e-123fe92fa162',
          country_id: 'e1eb8a52-2b98-4001-a7c2-7ff332725984',
          forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf88',
          forecast_data_source: {
            id: 'e2f886a7-3299-4c82-af2e-123fe92faf88',
            name: 'CPC (Static)',
            summary: '',
            type: 'static',
            forecast_data_source_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf68',
            forecast_data_source_category: {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf68',
              name: 'Sub - Seasonal',
            },
            forecast_data_source_with_parameter: [
              {
                type: '',
                parameter: [
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf69',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf88',
                    parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab02',
                    parameter: {
                      id: 'e2f886a7-3299-4c82-af2e-123fe92fab02',
                      name: 'Precipitation',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/precipitation.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST +
                        '/uat/parameter_icons/precipitation_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: 'rain_ind_',
                      parameter_type: '',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf70',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf88',
                    parameter_id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
                    parameter: {
                      id: 'e2f886a7-3299-4c82-af2e-123fe92fab03',
                      name: 'Temperature',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/temperature.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST +
                        '/uat/parameter_icons/temperature_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: 'temp',
                      parameter_type: '',
                    },
                    status: true,
                  },
                ],
              },
            ],
          },
        },
        {
          id: 'e2f886a7-3299-4c82-af2e-123fe92fa163',
          country_id: 'e1eb8a52-2b98-4001-a7c2-7ff332725984',
          forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
          forecast_data_source: {
            id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
            name: 'ERPAS (Static)',
            summary: '',
            type: 'static',
            forecast_data_source_category_id: 'e2f886a7-3299-4c82-af2e-123fe92faf68',
            forecast_data_source_category: {
              id: 'e2f886a7-3299-4c82-af2e-123fe92faf68',
              name: 'Sub - Seasonal',
            },
            forecast_data_source_with_parameter: [
              {
                type: 'rainfall',
                parameter: [
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf81',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
                    parameter_id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e19',
                    parameter: {
                      id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e19',
                      name: 'Cumulative rainfall',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/rainfall.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/rainfall_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: '',
                      parameter_type: 'rainfall',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf82',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
                    parameter_id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e20',
                    parameter: {
                      id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e20',
                      name: 'Cumulative rainfall anomaly',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/rainfall.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/rainfall_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: '',
                      parameter_type: 'rainfall',
                    },
                    status: true,
                  },
                ],
              },
              {
                type: 'temperature',
                parameter: [
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf83',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
                    parameter_id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e21',
                    parameter: {
                      id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e21',
                      name: 'Maximum temperature',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/temperature.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST +
                        '/uat/parameter_icons/temperature_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: 'tmax',
                      parameter_type: 'temperature',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf84',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
                    parameter_id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e22',
                    parameter: {
                      id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e22',
                      name: 'Maximum temperature anomaly',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/temperature.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST +
                        '/uat/parameter_icons/temperature_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: '',
                      parameter_type: 'temperature',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf85',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
                    parameter_id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e23',
                    parameter: {
                      id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e23',
                      name: 'Minimum temperature',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/temperature.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST +
                        '/uat/parameter_icons/temperature_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: 'tmin',
                      parameter_type: 'temperature',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf86',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
                    parameter_id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e24',
                    parameter: {
                      id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e24',
                      name: 'Minimum temperature anomaly',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/temperature.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST +
                        '/uat/parameter_icons/temperature_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: '',
                      parameter_type: 'temperature',
                    },
                    status: true,
                  },
                ],
              },
              {
                type: 'wind',
                parameter: [
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf75',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
                    parameter_id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e13',
                    parameter: {
                      id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e13',
                      name: 'Wind actual (200 hpa)',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/wind_speed.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/wind_speed_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: '',
                      parameter_type: 'wind',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf76',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
                    parameter_id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e14',
                    parameter: {
                      id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e14',
                      name: 'Wind anomaly (200 hpa)',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/wind_speed.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/wind_speed_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: '',
                      parameter_type: 'wind',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf77',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
                    parameter_id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e15',
                    parameter: {
                      id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e15',
                      name: 'Wind actual (500 hpa)',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/wind_speed.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/wind_speed_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: '',
                      parameter_type: 'wind',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf78',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
                    parameter_id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e16',
                    parameter: {
                      id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e16',
                      name: 'Wind anomaly (500 hpa)',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/wind_speed.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/wind_speed_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: '',
                      parameter_type: 'wind',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf79',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
                    parameter_id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e17',
                    parameter: {
                      id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e17',
                      name: 'Wind actual (850 hpa)',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/wind_speed.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/wind_speed_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: '',
                      parameter_type: 'wind',
                    },
                    status: true,
                  },
                  {
                    id: 'e2f886a7-3299-4c82-af2e-123fe92faf80',
                    forecast_data_source_id: 'e2f886a7-3299-4c82-af2e-123fe92faf89',
                    parameter_id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e18',
                    parameter: {
                      id: 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e18',
                      name: 'Wind anomaly (850 hpa)',
                      icon: AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/wind_speed.svg',
                      icon_selected:
                        AMZONS_S3_URL_DOMAIN_CONST + '/uat/parameter_icons/wind_speed_selected.svg',
                      status: true,
                      drought_indices_category_id: null,
                      mandatory: true,
                      impact: true,
                      api_slug: '',
                      slug: '',
                      parameter_type: 'wind',
                    },
                    status: true,
                  },
                ],
              },
            ],
          },
        },
      ],
    },
  ],
  code: 200,
};

export const BASE_TIME_ECMWF = 'base_time';
export const FORECAST_LIST_ECMWF = 'Forecast type';
export const SOIL_MOISTURE_LAYER_ECMWF = 'Level';

export const PARAMETER_SLUGS = {
  PRECIPITATION: 'efi_precipitation_erf',
  TEMPERATURE: 'efi_temperature_erf',
  TCA: 'total_accumulated_precipitation',
  LEFT_AREA_INDEX: 'leaf_area_index',
  SOIL_MOISTURE: 'soil_moisture',
  EFI_WIND_GUEST: 'efi_wind_gust',
  EFI_WIND_SPEED: 'efi_wind_speed',
  EFI_PRECEIPITATION: 'efi_precipitation',
  PRECEIPITATION_SEAS5: 'precipitation_ses_5',
};

export const SHOW_VALIDTIME_AND_LAYERS = [
  PARAMETER_SLUGS.PRECIPITATION,
  PARAMETER_SLUGS.TEMPERATURE,
  PARAMETER_SLUGS.TCA,
  PARAMETER_SLUGS.LEFT_AREA_INDEX,
  PARAMETER_SLUGS.SOIL_MOISTURE,
  PARAMETER_SLUGS.PRECEIPITATION_SEAS5,
];

export const SHOW_DAY_AND_QUANTILE = [
  PARAMETER_SLUGS.EFI_WIND_GUEST,
  PARAMETER_SLUGS.EFI_WIND_SPEED,
  PARAMETER_SLUGS.EFI_PRECEIPITATION,
];

export const RISK_MANAGEMENT_DESC = {
  monitoring_early_warning: () => <p>{t('LANDING_PAGE.MONITORING_EARLY_WARNING_DESC')}</p>,
  risk_impact: () => <p>{t('LANDING_PAGE.RISK_IMPACT_DESC')}</p>,
  risk_mitigation: () => <p>{t('LANDING_PAGE.RISK_MITIGATION_DESC')}</p>,
};

export const MANAGE_DMS_LANDING_PAGE = [
  {
    label: 'Manage Pillars',
    key: 'pillars',
  },
  {
    label: 'Manage Events',
    key: 'events',
  },
  {
    label: 'Manage Testimonials',
    key: 'testimonials',
  },
  // Hiding for now
  // {
  //   label: 'Manage Partners',
  //   key: 'partners',
  // },
  {
    label: 'Manage Impacts',
    key: 'impacts',
  },
];

export const loginInHidden = [
  '/home',
  '/login',
  '/tools',
  '/news-events-reports',
  '/partners-donors',
  '/register',
  '/contact-us',
  '/about-us',
];

export const countrySpecificScreenRoute = '/drought-management-system';

export const SOUTH_ASIA_COUNTRY = 'South Asia';
export const RISK_MITIGATION_CATEGORY = [
  { label: 'Risk Mitigation', value: 'risk_mitigation_category' },
  { label: 'Preparedness', value: 'preparedness_category' },
  { label: 'Response', value: 'response_category' },
];

export const COUNTRY_SPECTIFIC_URLS = [
  {
    country_name: 'India',
    url: 'https://65.2.44.143/drought-management-system?Country=India',
  },
  {
    country_name: 'Sri Lanka',
    url: 'https://65.2.44.143/drought-management-system?Country=Sri_Lanka',
  },
  {
    country_name: 'South Asia',
    url: 'https://65.2.44.143/drought-management-system?Country=South_Asia',
  },
];

export const BOUNDS_COUNTRY = [
  {
    country_name: 'southasia',
    bounds: [
      [6.745827, 68.144227], // Bottom left (Southwest)
      [35.505618, 97.380536], // Top right (Northeast)
    ],
  },
  {
    country_name: 'india',
    bounds: [
      [-0.641664, 60.504163], // Bottom left (Southwest)
      [38.471982, 97.380536], // Top right (Northeast)
    ],
  },
  {
    country_name: 'srilanka',
    bounds: [
      [5.918054, 79.696091], // Bottom left (Southwest)
      [9.828191, 81.891663], // Top right (Northeast)
    ],
  },
];

export const MAX_FILE_SIZE = 5 * 1048576;

export const MonthsArray = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const BULLET_IN_HOW_TO_USE = `1. The bulletin gives information on the biophysical conditions in the selected region over the defined time period. 
2. Familiarise yourself with the key indicators listed in the bulletin, which illustrate the extent and severity of the drought. Indicators basic info would provide this information.
3. The indicator summary section illustrates the affected regions. Look for colour-coded areas that indicate the severity of drought conditions. Make sure you understand the map legend for correct interpretation.
4. The 'summary of current condition' section includes the author's analysis, expert insights, and other relevant information.
5. Ensure that you are seeing the correct time frequency and period, as patterns and intensity change over time. Other recent bulletins can be viewed by visiting the 'All Published Bulletins' section.`;

export const BULLETIN_INDICATORS_INFO = {
  VHI: 'VHI',
  'NDVI-VIIRS': 'NDVI-VIIRS',
  'NDVI-MODIS': 'NDVI-MODIS',
  IDSI: 'IDSI',
  SRI: 'SRI',
  NPSMI: 'NPSMI',
  'Rainfall Anomaly': 'Rainfall Anomaly',
  'Accumulated Rainfall': 'Accumulated Rainfall',
  'SPI-Predicted': 'SPI-Predicted',
  Dryspell: 'Dryspell',
  'SPI-GPM': 'SPI-GPM',
};

export const DROUGHT_PREDICTION_PARAMETER_IDS = {
  'drought-prediction/sri': 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e46',
  'drought-prediction/npsmi': 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e43',
  'drought-prediction/spi': 'f45fe68f-4136-4e3c-ac3c-88b93d6b3e45',
};
