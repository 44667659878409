import React, { useMemo } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Table, Alert } from 'reactstrap';
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table';
import _ from 'lodash';
import { t } from 'react-multi-lang';
import { ALERT_TABLE_INDICES_THRESHOLD_CONSTS } from '../../../Components/constants';

const AlertIndicesModal = props => {
  const { isOpen, toggle, tableData } = props;
  const columns = useMemo(() => tableData?.columns);
  const data = useMemo(() => tableData?.rowData, [tableData?.rowData]);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: tableData?.rowData?.length },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;
  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="alert-table-modal"
        backdrop="static"
        keyboard={false}
      >
        <ModalBody className=''>
          {' '}
          <br />
          <div className='alert-table-design-overflow'>
            {tableData?.columns.length === 1 &&
            tableData?.rowData[0]?.slug === "dryspell" &&
            tableData?.rowData?.length === 1 ? (
              <div className="drought-error-container">
                <div className="drought-error-style">
                  <Alert color="warning" style={{ textAlign: 'center' }}>
                    <span style={{fontWeight: 'bold'}}>Dry Spell (Days)</span>{t('DROUGHT_MONITORING.DRYSPELL_MESSAGE')}
                  </Alert>
                </div>
              </div>
            ) : tableData?.columns.length === 1 &&
              tableData?.rowData[0]?.slug === 'spi' &&
              tableData?.rowData?.length === 1 ? (
              <div className="drought-error-container">
                <div className="drought-error-style">
                  <Alert color="warning" style={{ textAlign: 'center' }}>
                  <span style={{fontWeight: 'bold'}}>SPI</span>{t('DROUGHT_MONITORING.DRYSPELL_MESSAGE')}
                  </Alert>
                </div>
              </div>
              
            ) : tableData?.columns?.length === 1 ? (
              <div className="drought-error-container">
                <div className="drought-error-style">
                  <Alert color="warning" style={{ textAlign: 'center' }}>
                  {t('DROUGHT_MONITORING.DRYSPELL_SPI_MESSAGE')}
                  </Alert>
                </div>
              </div>
            ) : (
              <>
                <Table {...getTableProps()} className="alert-table-design">
                  <thead>
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                          <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map(cell => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className={
                                  !isNaN(+cell.value)
                                    ? row.original?.indices_name === 'SPI'
                                      ? cell.value > ALERT_TABLE_INDICES_THRESHOLD_CONSTS.SPI
                                        ? 'text-success text-center'
                                        : 'text-danger text-center'
                                      : row?.original?.indices_name === 'Dry spell'
                                      ? cell.value < ALERT_TABLE_INDICES_THRESHOLD_CONSTS.DRY_SPELL
                                        ? 'text-success text-center'
                                        : 'text-danger text-center'
                                      : ''
                                    : 'text-dark text-center'
                                }
                              >
                                {cell?.value === undefined ? (
                                  <div className="text-center">-</div>
                                ) : (
                                  <div className="text-center">{cell.render('Cell')}</div>
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </>
            )}
          </div>{' '}
          <br />
        </ModalBody>
        <ModalFooter>
          <div className="contingency-form-buttons mr-5">
            <Button className="contingency-plan-button px-3 py-2" type="submit" onClick={toggle}>
              {t('DROUGHT_DSS.CLOSE')}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AlertIndicesModal;