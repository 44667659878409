import HOME_CONST from './homeConsts';

const HOME = (state = {}, action) => {
  switch (action.type) {
    case HOME_CONST.GET_BANNERS_REQUEST:
      return {
        ...state,
        isGetBannersDataRequesting: true,
        getBannersData: false,
        getBannersDataError: false,
        getBannersDataNetworkError: false,
      };
    case HOME_CONST.GET_BANNERS_SUCCESS:
      return {
        ...state,
        isGetBannersDataRequesting: false,
        getBannersData: action.payload.response,
        getBannersDataError: false,
        getBannersDataNetworkError: false,
      };
    case HOME_CONST.GET_BANNERS_FAILED:
      return {
        ...state,
        isGetBannersDataRequesting: false,
        getBannersData: false,
        getBannersDataError: action.payload ? action.payload : false,
        getBannersDataNetworkError: action.payload.netWorkError ? action.payload.statusText : false,
      };
    case HOME_CONST.GET_PARAMETERS_REQUEST:
      return {
        ...state,
        isGetParametersDataRequesting: true,
        getParametersData: false,
        getParametersDataError: false,
        getParametersDataNetworkError: false,
      };
    case HOME_CONST.GET_PARAMETERS_SUCCESS:
      return {
        ...state,
        isGetParametersDataRequesting: false,
        getParametersData: action.payload.response,
        getParametersDataError: false,
        getParametersDataNetworkError: false,
      };
    case HOME_CONST.GET_PARAMETERS_FAILED:
      return {
        ...state,
        isGetParametersDataRequesting: false,
        getParametersData: false,
        getParametersDataError: action.payload ? action.payload : false,
        getParametersDataNetworkError: action.payload.netWorkError
          ? action.payload.statusText
          : false,
      };

    // Events
    case HOME_CONST.GET_DMS_EVENTS_REQUEST:
      return {
        ...state,
        isGetDmsEventsRequesting: true,
        getDmsEventsData: false,
        getDmsEventsDataError: false,
        getDmsEventsDataNetworkError: false,
      };
    case HOME_CONST.GET_DMS_EVENTS_SUCCESS:
      return {
        ...state,
        isGetDmsEventsRequesting: false,
        getDmsEventsData: action.payload.response,
        getDmsEventsDataError: false,
        getDmsEventsDataNetworkError: false,
      };
    case HOME_CONST.GET_DMS_EVENTS_FAILED:
      return {
        ...state,
        isGetDmsEventsRequesting: false,
        getDmsEventsData: false,
        getDmsEventsDataError: action.payload ? action.payload : false,
        getDmsEventsDataNetworkError: action.payload.netWorkError
          ? action.payload.statusText
          : false,
      };

    // STATISTICS
    case HOME_CONST.GET_DMS_STATISTICS_REQUEST:
      return {
        ...state,
        isGetDmsStatisticsRequesting: true,
        getDmsStatisticsData: false,
        getDmsStatisticsDataError: false,
        getDmsStatisticsDataNetworkError: false,
      };
    case HOME_CONST.GET_DMS_STATISTICS_SUCCESS:
      return {
        ...state,
        isGetDmsStatisticsRequesting: false,
        getDmsStatisticsData: action.payload.response,
        getDmsStatisticsDataError: false,
        getDmsStatisticsDataNetworkError: false,
      };
    case HOME_CONST.GET_DMS_STATISTICS_FAILED:
      return {
        ...state,
        isGetDmsStatisticsRequesting: false,
        getDmsStatisticsData: false,
        getDmsStatisticsDataError: action.payload ? action.payload : false,
        getDmsStatisticsDataNetworkError: action.payload.netWorkError
          ? action.payload.statusText
          : false,
      };

    // TESTIMONIALS
    case HOME_CONST.GET_DMS_TESTIMONIALS_REQUEST:
      return {
        ...state,
        isGetDmsTestimonialsRequesting: true,
        getDmsTestimonialsData: false,
        getDmsTestimonialsDataError: false,
        getDmsTestimonialsDataNetworkError: false,
      };
    case HOME_CONST.GET_DMS_TESTIMONIALS_SUCCESS:
      return {
        ...state,
        isGetDmsTestimonialsRequesting: false,
        getDmsTestimonialsData: action.payload.response,
        getDmsTestimonialsDataError: false,
        getDmsTestimonialsDataNetworkError: false,
      };
    case HOME_CONST.GET_DMS_TESTIMONIALS_FAILED:
      return {
        ...state,
        isGetDmsTestimonialsRequesting: false,
        getDmsTestimonialsData: false,
        getDmsTestimonialsDataError: action.payload ? action.payload : false,
        getDmsTestimonialsDataNetworkError: action.payload.netWorkError
          ? action.payload.statusText
          : false,
      };

    // PARTNERS & DONORS
    case HOME_CONST.GET_DMS_PARTNERS_DONORS_REQUEST:
      return {
        ...state,
        isGetDmsPartnersDonorsRequesting: true,
        getDmsPartnersDonorsData: false,
        getDmsPartnersDonorsDataError: false,
        getDmsPartnersDonorsDataNetworkError: false,
      };
    case HOME_CONST.GET_DMS_PARTNERS_DONORS_SUCCESS:
      return {
        ...state,
        isGetDmsPartnersDonorsRequesting: false,
        getDmsPartnersDonorsData: action.payload.response,
        getDmsPartnersDonorsDataError: false,
        getDmsPartnersDonorsDataNetworkError: false,
      };
    case HOME_CONST.GET_DMS_PARTNERS_DONORS_FAILED:
      return {
        ...state,
        isGetDmsPartnersDonorsRequesting: false,
        getDmsPartnersDonorsData: false,
        getDmsPartnersDonorsDataError: action.payload ? action.payload : false,
        getDmsPartnersDonorsDataNetworkError: action.payload.netWorkError
          ? action.payload.statusText
          : false,
      };

    // NEWSFEED
    case HOME_CONST.GET_DMS_NEWSFEED_REQUEST:
      return {
        ...state,
        isGetDmsNewsfeedRequesting: true,
        getDmsNewsfeedData: false,
        getDmsNewsfeedDataError: false,
        getDmsNewsfeedDataNetworkError: false,
      };
    case HOME_CONST.GET_DMS_NEWSFEED_SUCCESS:
      return {
        ...state,
        isGetDmsNewsfeedRequesting: false,
        getDmsNewsfeedData: action.payload.response,
        getDmsNewsfeedDataError: false,
        getDmsNewsfeedDataNetworkError: false,
      };
    case HOME_CONST.GET_DMS_NEWSFEED_FAILED:
      return {
        ...state,
        isGetDmsNewsfeedRequesting: false,
        getDmsNewsfeedData: false,
        getDmsNewsfeedDataError: action.payload ? action.payload : false,
        getDmsNewsfeedDataNetworkError: action.payload.netWorkError
          ? action.payload.statusText
          : false,
      };

    // PILLARS
    case HOME_CONST.GET_DMS_PILLARS_REQUEST:
      return {
        ...state,
        isGetDmsPillarsRequesting: true,
        getDmsPillarsData: false,
        getDmsPillarsDataError: false,
        getDmsPillarsDataNetworkError: false,
      };
    case HOME_CONST.GET_DMS_PILLARS_SUCCESS:
      return {
        ...state,
        isGetDmsPillarsRequesting: false,
        getDmsPillarsData: action.payload.response,
        getDmsPillarsDataError: false,
        getDmsPillarsDataNetworkError: false,
      };
    case HOME_CONST.GET_DMS_PILLARS_FAILED:
      return {
        ...state,
        isGetDmsPillarsRequesting: false,
        getDmsPillarsData: false,
        getDmsPillarsDataError: action.payload ? action.payload : false,
        getDmsPillarsDataNetworkError: action.payload.netWorkError
          ? action.payload.statusText
          : false,
      };

    // USING IT AS COMMON REDUCER FOR LANDING PAGE
    case HOME_CONST.ADD_LANDING_PAGE_REQUEST:
      return {
        ...state,
        isAddLandingPageRequesting: true,
        addLandingPageData: false,
        addLandingPageDataError: false,
        addLandingPageDataNetworkError: false,
      };
    case HOME_CONST.ADD_LANDING_PAGE_SUCCESS:
      return {
        ...state,
        isAddLandingPageRequesting: false,
        addLandingPageData: action.payload.response,
        addLandingPageDataError: false,
        addLandingPageDataNetworkError: false,
      };
    case HOME_CONST.ADD_LANDING_PAGE_FAILURE:
      return {
        ...state,
        isAddLandingPageRequesting: false,
        addLandingPageData: false,
        addLandingPageDataError: action.payload ? action.payload : false,
        addLandingPageDataNetworkError: action.payload.netWorkError
          ? action.payload.statusText
          : false,
      };

    // DELETE LANDING PAGE DETAILS REDUCER
    case HOME_CONST.DELETE_LANDING_PAGE_REQUEST:
      return {
        ...state,
        isDeleteLandingPageRequesting: true,
        deleteLandingPageData: false,
        deleteLandingPageDataError: false,
        deleteLandingPageDataNetworkError: false,
      };
    case HOME_CONST.DELETE_LANDING_PAGE_SUCCESS:
      return {
        ...state,
        isDeleteLandingPageRequesting: false,
        deleteLandingPageData: action.payload.response,
        deleteLandingPageDataError: false,
        deleteLandingPageDataNetworkError: false,
      };
    case HOME_CONST.DELETE_LANDING_PAGE_FAILURE:
      return {
        ...state,
        isDeleteLandingPageRequesting: false,
        deleteLandingPageData: false,
        deleteLandingPageDataError: action.payload ? action.payload : false,
        deleteLandingPageDataNetworkError: action.payload.netWorkError
          ? action.payload.statusText
          : false,
      };

    // EDIT LANDING PAGE DETAILS REDUCER
    case HOME_CONST.EDIT_LANDING_PAGE_REQUEST:
      return {
        ...state,
        iseditLandingPageRequesting: true,
        editLandingPageData: false,
        editLandingPageDataError: false,
        editLandingPageDataNetworkError: false,
      };
    case HOME_CONST.EDIT_LANDING_PAGE_SUCCESS:
      return {
        ...state,
        iseditLandingPageRequesting: false,
        editLandingPageData: action.payload.response,
        editLandingPageDataError: false,
        editLandingPageDataNetworkError: false,
      };
    case HOME_CONST.EDIT_LANDING_PAGE_FAILURE:
      return {
        ...state,
        iseditLandingPageRequesting: false,
        editLandingPageData: false,
        editLandingPageDataError: action.payload ? action.payload : false,
        editLandingPageDataNetworkError: action.payload.netWorkError
          ? action.payload.statusText
          : false,
      };

    // EDIT LANDING PAGE DETAILS REDUCER
    case HOME_CONST.CONTACT_US_REQUEST:
      return {
        ...state,
        isContactUsRequesting: true,
        contactUsData: false,
        contactUsDataError: false,
        contactUsDataNetworkError: false,
      };
    case HOME_CONST.CONTACT_US_SUCCESS:
      return {
        ...state,
        isContactUsRequesting: false,
        contactUsData: action.payload.response,
        contactUsDataError: false,
        contactUsDataNetworkError: false,
      };
    case HOME_CONST.CONTACT_US_FAILURE:
      return {
        ...state,
        isContactUsRequesting: false,
        contactUsData: false,
        contactUsDataError: action.payload ? action.payload : false,
        contactUsDataNetworkError: action.payload.netWorkError ? action.payload.statusText : false,
      };

    default:
      return state;
  }
};

export default HOME;
