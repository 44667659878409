import React, { useEffect, useState } from 'react';
import { t } from 'react-multi-lang';
import { Col, Container, Row } from 'reactstrap';

const CountrySpecificContent = () => {
  const URLParams = new URLSearchParams(window.location.search);
  const queryCountry = URLParams.get('Country');
  const [selectedCountry, setSelectedCountry] = useState('');

  useEffect(() => {
    if (queryCountry) {
      setSelectedCountry(queryCountry);
    } else {
      setSelectedCountry('DMS'); // Default value when no country is present
    }
  }, [queryCountry]);

  const getTitleKey = () => {
    switch (selectedCountry) {
      case 'india':
        return t('LANDING_PAGE.UNVEILING_IDMS');
      case 'srilanka':
        return t('LANDING_PAGE.UNVEILING_SLDMS');
      case 'southasia':
        return t('LANDING_PAGE.UNVEILING_SADMS');
      default:
        return t('LANDING_PAGE.UNVEILING_COUNTRY_DMS');
    }
  };

  const getDescription = () => {
    switch (selectedCountry) {
      case 'india':
        return (
          <>
            <p className="content-text">{t('LANDING_PAGE.IDMS_DESCRIPTION_1')}</p>
            <p className="content-text">{t('LANDING_PAGE.IDMS_DESCRIPTION_2')}</p>
          </>
        );
      case 'srilanka':
        return (
          <>
            <p className="content-text">{t('LANDING_PAGE.SLDMS_DESCRIPTION')}</p>
          </>
        );
      case 'southasia':
        return (
          <>
            <p className="content-text">{t('LANDING_PAGE.SADMS_DESCRIPTION_1')}</p>
            {/* <p className="content-text">{t('LANDING_PAGE.SADMS_DESCRIPTION_2')}</p> */}
          </>
        );
      default:
        return (
          <>
            <p className="content-text">{t('LANDING_PAGE.SADMS_DESCRIPTION_1')}</p>
          </>
        );
    }
  };

  return (
    <Container fluid className="landing-page-content-section">
      <Row>
        <Col md={12}>
          <div className="title-section">
            <h2 className="title">
              {/* {t('LANDING_PAGE.EMPOWERING_DROUGHT')}{' '} */}
              <span className="title-highlight">{getTitleKey()}</span>
            </h2>
          </div>
        </Col>
        <Col md={12} className=" mt-3">
          {getDescription()}
        </Col>
      </Row>
    </Container>
  );
};

export default CountrySpecificContent;
