import React, { useEffect, useRef, useState } from 'react';
import { t } from 'react-multi-lang';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap';

import moment from 'moment';
import { ReactComponent as AddIcon } from '../../assets/Images/add-icon.svg';
import dateIcon from '../../assets/Images/date-icon.svg';
import locationIcon from '../../assets/Images/location-icon.svg';
import NoResultIcon from '../../assets/Images/no-results-icon.svg';
import timeIcon from '../../assets/Images/timeline-icon.svg';
import Loader from '../../Components/common/TextLoader';
import { isSuperAdmin } from '../../Components/common/utils';
import { DATE_FORMAT } from '../../Components/constants';
import { EventDateFormatter, EventTimeFormatter } from '../../Components/helpers';
import { showError, showSuccess } from '../../Components/Notifications';
import {
  addLandingPageRequest,
  addLandingPageSuccess,
  deleteLandingPageRequest,
  deleteLandingPageSuccess,
  editLandingPageRequest,
  editLandingPageSuccess,
  getDmsEventsRequest,
  getDmsEventsSuccess,
} from '../../redux/home/homeActions';
import DeleteModal from './DeleteModal';
import NewEventsModal from './NewEventsModal';

function ManageEvents({ title, userPermisions }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [upcomingEventsData, setUpcomingEventsData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeDropdownId, setActiveDropdownId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    location: '',
    from_date: moment(new Date())
      .add(1, 'days')
      .format(DATE_FORMAT),
    to_date: moment(new Date())
      .add(6, 'days')
      .format(DATE_FORMAT),
    start_time: '09:00',
    end_time: '18:00',
    event_url: '',
    event_icon: null,
  });
  const [selectedEvent, setSelectedEvents] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);

  const { LandingPageData } = useSelector(state => ({
    LandingPageData: state.Home,
  }));

  useEffect(() => {
    dispatch(getDmsEventsRequest({ event_type: 'upcoming' }));

    return () => {
      dispatch(getDmsEventsSuccess({}));
      dispatch(editLandingPageSuccess({}));
      dispatch(addLandingPageSuccess({}));
      dispatch(deleteLandingPageSuccess({}));
    };
  }, [dispatch]);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (LandingPageData.isGetDmsEventsRequesting) {
      setIsLoading(true);
    } else if (LandingPageData.getDmsEventsData) {
      const { result } = LandingPageData.getDmsEventsData.data;
      setUpcomingEventsData(result);
      setIsLoading(false);
    } else if (
      LandingPageData.getDmsEventsDataError ||
      LandingPageData.getDmsEventsDataNetworkError
    ) {
      showError(t('LANDING_PAGE.SOMETHING_WENT_WORNG'));
      setIsLoading(false);
    }
  }, [
    LandingPageData.getDmsEventsData,
    LandingPageData.getDmsEventsDataError,
    LandingPageData.getDmsEventsDataNetworkError,
    LandingPageData.isGetDmsEventsRequesting,
  ]);

  const isAddRef = useRef(true);
  useEffect(() => {
    if (isAddRef.current) {
      isAddRef.current = false;
      return;
    }
    if (LandingPageData.isAddLandingPageRequesting) {
      setIsLoading(true);
    } else if (LandingPageData?.addLandingPageData?.data) {
      showSuccess(LandingPageData?.addLandingPageData?.data?.result);
      dispatch(getDmsEventsRequest({ event_type: 'upcoming' }));
      setIsLoading(false);
      handleResetFormData();
    } else if (
      LandingPageData.addLandingPageDataError ||
      LandingPageData.addLandingPageDataNetworkError
    ) {
      const errorMessageArray =
        LandingPageData.addLandingPageDataError?.response?.data?.response?.data?.message || [];
      const [errorMessage] = errorMessageArray;

      showError(errorMessage);
      setIsLoading(false);
    }
  }, [
    LandingPageData?.addLandingPageData,
    LandingPageData.isAddLandingPageRequesting,
    LandingPageData.addLandingPageDataError,
    LandingPageData.addLandingPageDataNetworkError,
    dispatch,
  ]);

  const isEditRef = useRef(true);
  useEffect(() => {
    if (isEditRef.current) {
      isEditRef.current = false;
      return;
    }
    if (LandingPageData.iseditLandingPageRequesting) {
      setIsLoading(true);
      setIsEdit(false);
    } else if (LandingPageData?.editLandingPageData?.data) {
      showSuccess(LandingPageData?.editLandingPageData?.data?.result);
      dispatch(getDmsEventsRequest({ event_type: 'upcoming' }));
      setIsLoading(false);
      handleResetFormData();
      setIsEdit(false);
    } else if (
      LandingPageData.editLandingPageDataError ||
      LandingPageData.editLandingPageDataNetworkError
    ) {
      const errorMessageArray =
        LandingPageData.editLandingPageDataError?.response?.data?.response?.data?.message || [];
      const [errorMessage] = errorMessageArray;

      showError(errorMessage);
      setIsLoading(false);
      setIsEdit(false);
    }
  }, [
    LandingPageData.editLandingPageData,
    LandingPageData.iseditLandingPageRequesting,
    LandingPageData.editLandingPageDataError,
    LandingPageData.editLandingPageDataNetworkError,
    dispatch,
  ]);

  const isDeleteRef = useRef(true);
  useEffect(() => {
    if (isDeleteRef.current) {
      isDeleteRef.current = false;
      return;
    }
    if (LandingPageData.isDeleteLandingPageRequesting) {
      setIsLoading(true);
    } else if (LandingPageData?.deleteLandingPageData?.data) {
      showSuccess(LandingPageData?.deleteLandingPageData?.data?.result);
      dispatch(getDmsEventsRequest({ event_type: 'upcoming' }));
      setIsLoading(false);
      handleResetFormData();
      setIsEdit(false);
    } else if (
      LandingPageData.deleteLandingPageDataError ||
      LandingPageData.deleteLandingPageDataNetworkError
    ) {
      showError(LandingPageData.deleteLandingPageDataError?.response?.data?.message);
      setIsLoading(false);
    }
  }, [
    LandingPageData?.deleteLandingPageData,
    LandingPageData.isDeleteLandingPageRequesting,
    LandingPageData.deleteLandingPageDataError,
    LandingPageData.deleteLandingPageDataNetworkError,
    dispatch,
  ]);

  const handleResetFormData = () => {
    setFormData({
      name: '',
      location: '',
      from_date: moment(new Date())
        .add(1, 'days')
        .format(DATE_FORMAT),
      to_date: moment(new Date())
        .add(6, 'days')
        .format(DATE_FORMAT),
      start_time: '09:00',
      end_time: '18:00',
      event_url: '',
      event_icon: null,
    });

    setIsEdit(false);
  };

  const toggleDropdown = id => {
    setActiveDropdownId(prevId => (prevId === id ? null : id));
  };

  const handleEdit = editData => {
    const userData = {
      name: editData.name,
      location: editData.location,
      from_date: moment(editData.from_date).format(DATE_FORMAT),
      to_date: moment(editData.to_date).format(DATE_FORMAT),
      start_time: editData.start_time,
      end_time: editData.end_time,
      event_url: editData.event_url,
      event_icon: editData.file_url,
      id: editData.id,
    };
    setFormData(userData);
    setIsModalOpen(true);
    setIsEdit(true);
  };

  const handleDelete = data => {
    setSelectedEvents(data);
    setDeleteModal(true);
  };

  const onHandleEvents = data => {
    const submissionData = new FormData();

    // Append form fields to FormData
    if (data.name) submissionData.append('name', data.name);
    if (data.location) submissionData.append('location', data.location);
    if (data.from_date) submissionData.append('from_date', data.from_date);
    if (data.to_date) submissionData.append('to_date', data.to_date);
    if (data.start_time) submissionData.append('start_time', data.start_time);
    if (data.end_time) submissionData.append('end_time', data.end_time);
    if (data.event_url) submissionData.append('event_url', data.event_url);

    if (data.event_icon) {
      submissionData.append('event_icon', data.event_icon);
    }

    if (isEdit) {
      // * MAKE SURE TO SEND IT AS submissionData
      submissionData.append('event_id', formData.id);
      submissionData.append('event_url', formData.event_url);
      dispatch(editLandingPageRequest({ submissionData, end_point: 'update_events' }));
      setIsEdit(false);
    } else {
      dispatch(addLandingPageRequest({ submissionData, end_point: 'add_events' }));
    }
  };

  const onHandleDelete = () => {
    setDeleteModal(false);
    dispatch(
      deleteLandingPageRequest({
        id: selectedEvent?.id,
        end_point: 'delete_events',
        query: 'id',
      }),
    );
  };

  return (
    <>
      {isLoading && <Loader />}
      {isModalOpen ? (
        <NewEventsModal
          modal={isModalOpen}
          setModal={setIsModalOpen}
          setFormData={setFormData}
          formData={formData}
          onHandleEvents={onHandleEvents}
          isEditable={isEdit}
        />
      ) : null}
      {deleteModal ? (
        <DeleteModal
          isOpen={deleteModal}
          toggle={() => setDeleteModal(!deleteModal)}
          message={`This will delete “${selectedEvent?.name}” event and will not be able to see it.`}
          onHandleDelete={onHandleDelete}
        />
      ) : null}
      <div className="dms-title-container">
        <h1 className="dms-title">{title.label}</h1>
        {isSuperAdmin() || userPermisions.includes('add_dmsevents') ? (
          <Button
            className="dms-add-edit-btn"
            onClick={() => {
              setIsModalOpen(!isModalOpen);
              setIsEdit(false);
            }}
          >
            {t('LANDING_PAGE.ADD_NEW')} <AddIcon height={20} width={20} />
          </Button>
        ) : null}
      </div>
      <Container fluid>
        <Row>
          {!isLoading ? (
            upcomingEventsData?.length ? (
              upcomingEventsData.map(dataItem => (
                <Col xs={12} md={4} key={dataItem.id}>
                  <Card className="news-card flex-grow-1 events-img-container" key={dataItem.name}>
                    <CardImg top src={dataItem.file_url} alt={dataItem.name} loading="lazy" />
                    <CardBody>
                      <CardTitle
                        tag="h5"
                        className=" d-flex justify-content-between align-items-center"
                      >
                        {dataItem.name}{' '}
                        {isSuperAdmin() || userPermisions.includes('change_dmsevents') ? (
                          <Dropdown
                            isOpen={activeDropdownId === dataItem.id}
                            toggle={() => toggleDropdown(dataItem.id)}
                            direction="left"
                          >
                            <DropdownToggle
                              tag="span"
                              data-toggle="dropdown"
                              aria-expanded={activeDropdownId === dataItem.id}
                              className="dms-pillar-list-dropdown-toggle"
                            >
                              <i
                                className="fa fa-ellipsis-v dms-toggle-size"
                                aria-hidden="true"
                              ></i>
                            </DropdownToggle>
                            <DropdownMenu className=" p-0">
                              {isSuperAdmin() || userPermisions.includes('change_dmsevents') ? (
                                <DropdownItem
                                  className="dms-dropdown-options dms-dropdown-options-border"
                                  onClick={() => handleEdit(dataItem)}
                                >
                                  {t('LANDING_PAGE.EDIT_DETAILS')}
                                </DropdownItem>
                              ) : null}
                              {isSuperAdmin() || userPermisions.includes('delete_dmsevents') ? (
                                <DropdownItem
                                  className="dms-dropdown-options dms-dropdown-options-delete"
                                  onClick={() => handleDelete(dataItem)}
                                >
                                  {t('LANDING_PAGE.DELETE')}
                                </DropdownItem>
                              ) : null}
                            </DropdownMenu>
                          </Dropdown>
                        ) : null}
                      </CardTitle>
                      <CardText>
                        <img src={dateIcon} alt="Date" className="icon" />{' '}
                        <span>
                          {EventDateFormatter(dataItem.from_date)} -{' '}
                          {EventDateFormatter(dataItem.to_date)}
                        </span>
                      </CardText>
                      <CardText>
                        <img src={timeIcon} alt="Time" className="icon" />{' '}
                        <span>
                          {EventTimeFormatter(dataItem.start_time)} -{' '}
                          {EventTimeFormatter(dataItem.end_time)}
                        </span>{' '}
                        {dataItem.time}
                      </CardText>
                      <CardText className=" d-flex">
                        <img src={locationIcon} alt="Location" className="icon" />{' '}
                        <span>{dataItem.location}</span>
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              ))
            ) : (
              <div className=" h-100 d-flex justify-content-center align-items-center flex-column p-5">
                <img src={NoResultIcon} alt="No results to show" className=" d-block" />
                <p className="no-result-text">{t('LANDING_PAGE.NO_RESULTS_FOUND')}</p>
              </div>
            )
          ) : null}
        </Row>
      </Container>
    </>
  );
}

export default ManageEvents;
