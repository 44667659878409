import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import { t } from 'react-multi-lang';
import Drought from '../../assets/Images/droughtPopupImageCroped.png';
import Arrow from '../../assets/Images/right-arrow.png';
import DroughtImage2 from '../../assets/Images/decisionFramework.png';
import DroughtImage3 from '../../assets/Images/otherDroughtIndices.png';
import DroughtImage4 from '../../assets/Images/digital-survay.png'
import { DROUGHT_MODULES_CONST } from '../../Components/constants';

const Droughtmodal = props => {
  const {modal,handleModal}= props

  const viewtoggle = () => handleModal(!modal);
  useEffect(() => {
    handleModal(!modal);
  }, []);

  const handleStartDroughtMonitorLink = () => {
    handleModal(!modal);
  };

  const handleModule = data => {
    props.moduleHandler(data);
    handleModal(!modal);
  };

  return (
    <div className="drought-modal global-top-new">
      <Modal
        isOpen={modal}
        toggle={viewtoggle}
        className="drought-modal-section"
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader toggle={viewtoggle} className="drought-mod-header justify-content-between">
          {t('DROUGHT_MONITORING.WELCOME_TO_DROUGHT')}
        </ModalHeader>
        <ModalBody className="drought-modal-body">
          <Row>
            <Col lg="9" md="9" className="drought-mod">
              <div className="drought-mod-1">
                <img src={Drought} alt="drought" />
              </div>
              <div className="drought-mod-2">
                <p>
                  {t('DROUGHT_MONITORING.DROUGHT_MODAL_DESC_ONE')}
                  {<br />}
                  {t('DROUGHT_MONITORING.DROUGHT_MODAL_DESC_TWO')}
                  <span
                    className="drought-mod-text"
                    onClick={() => handleStartDroughtMonitorLink()}
                  >
                    {t('DROUGHT_MONITORING.DROUGHT_TEXT')}
                    <img src={Arrow} className="m-2" alt="arrow" />
                  </span>
                </p>
              </div>
            </Col>
            <Col lg="3" md="3" className="drought-mod-pl">
              {/* <div
                className="drought-popup-features"
                onClick={() => {
                  handleModule(DROUGHT_MODULES_CONST[0]);
                }}
              >
                <div className="drought-img-section">
                  <object
                    data={DroughtImage1}
                    className="drought-img"
                    alt="Image"
                    aria-label="drought-image"
                    hover="true"
                  />
                </div>
                <p className="drought-img-text">{t('DROUGHT_MONITORING.DROUGHT_PREDICTION')}</p>
              </div> */}
              <div
                className="drought-popup-features"
                onClick={() => {
                  handleModule(DROUGHT_MODULES_CONST[0]);
                }}
              >
                <div className="drought-img-section">
                  <object
                    data={DroughtImage2}
                    className="drought-img"
                    alt="Image"
                    aria-label="drought-image"
                  />
                </div>
                <p className="drought-img-text">{t('DROUGHT_MONITORING.DROUGHT_MONITOR')}</p>
              </div>
              <div
                className="drought-popup-features"
                onClick={() => {
                  handleModule(DROUGHT_MODULES_CONST[1]);
                }}
              >
                <div className="drought-img-section" >
                  <object
                    data={DroughtImage4}
                    className="drought-img"
                    alt="Image"
                    aria-label="drought-image"
                  />
                </div>
                <p className="drought-img-text">{t('DROUGHT_MONITORING.DSS')}</p>
              </div>
              <div
                className="drought-popup-features"
                onClick={() => {
                  handleModule(DROUGHT_MODULES_CONST[2]);
                }}
              >
                <div className="drought-img-section">
                  <object
                    data={DroughtImage3}
                    className="drought-img"
                    alt="Image"
                    aria-label="drought-image"
                  />
                </div>
                <p className="drought-img-text">{t('DROUGHT_MONITORING.DROUGHT_INDICES')}</p>
              </div>

            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Droughtmodal;
