/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Chart from 'react-apexcharts';
import '../assets/CSS/Charts.css';
import { OpenWeatherChartLabels, renderChartTime } from './common/utils';
import { OPEN_WEATHER_CHART_PARAMS, CHART_LABEL_COLORS } from '../Components/constants/index';
import closeIcon from '../assets/Images/closeIcon.svg';
import GEECharts from './common/GEEChart';

function Charts(props) {
  const { chartData, parametersData, renderCharts, dataSource, initialSelectedDate,country,state,district,region } = props;
  const [series, setSeries] = useState([]);
  const [fileNameParameteres,setFileNameParameters] = useState('');
  const [location,setLocation] = useState('')

  const [YAxisOptions, setYAxisOptions] = useState([
    {
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: (CHART_LABEL_COLORS && CHART_LABEL_COLORS[0]) || '#008FFB',
      },
      labels: {
        show: true,
        style: {
          colors: (CHART_LABEL_COLORS && CHART_LABEL_COLORS[0]) || '#008FFB',
          fontSize: '12px',
          fontFamily: 'National',
        },
        formatter: function (value) {
          if (!value === null || !isNaN(value)) {
            if (value % 1 != 0) {
              return value.toFixed(2)
            } else {
              return value
            }
          }
        },
      },
      title: {
        text: parametersData
          ? parametersData[0]?.name + ' (' + parametersData[0]?.measure + ')'
          : '',
        style: {
          color: (CHART_LABEL_COLORS && CHART_LABEL_COLORS[0]) || '#008FFB',
        },
      },
      tooltip: {
        enabled: true,
      },
      scaleLabel: {
        display: true,
        labelString: parametersData ? parametersData[0]?.name + parametersData[0]?.measure : '',
      },
      ticks: {
        callback: function (value) {
          return value;
        },
      },
    },
  ]);
  const [options, setOptions] = useState({
    chart: {
      type: 'column',
      stacked: false,
      zoom: {
        enabled: true,
        type: 'x',
      },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
    },
    stroke: {
      // width: [1, 1, 4],
      width: 4,
    },
    title: {
      text: 'Data Sources',
      align: 'left',
      offsetX: 110,
      style: {
        fontSize: '14px',
        fontWeight: 'normal',
        fontFamily: 'National',
        color: '#2A547C',
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '55%',
        barHeight: '70%',
      },
    },
    xaxis: {
      categories: [],
      labels: {
        showDuplicates: false,
        style: {
          fontSize: '12px',
          fontFamily: 'National',
        },
        crossShair: {
          stroke: {
            curve: 'straight',
          },
        },
      },
    },
    yaxis: YAxisOptions,
    tooltip: {
      fixed: {
        enabled: true,
        position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60,
      },
    },
    legend: {
      horizontalAlign: 'left',
      offsetX: 40,
    },
  });

  useEffect(() => {
    if (dataSource.length && initialSelectedDate) {
      const startDate = renderChartTime(initialSelectedDate?.graph?.startDate);
      const endDate = renderChartTime(initialSelectedDate?.graph?.endDate);
      setOptions((prev) => ({
        ...prev,
        chart:{
          ...options.chart,
          toolbar: {
            show: true,
            export: {
              csv: {
                filename: dataSource[0]?.name +'-'+ location +'-' + fileNameParameteres,
                columnDelimiter: ',',
                headerCategory: 'Date',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
              svg: {
                filename: dataSource[0]?.name +'-'+ location +'-' + fileNameParameteres,
                columnDelimiter: ',',
                headerCategory: 'Date',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
              png: {
                filename: dataSource[0]?.name +'-'+ location +'-' + fileNameParameteres,
                columnDelimiter: ',',
                headerCategory: 'Date',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
            },
            tools: {
              customIcons: [
                {
                  icon: `<img src=${closeIcon} width="20">`,
                  index: 6,
                  title: 'Close',
                  class: 'chart-close-icon',
                  click: function () {
                    renderCharts();
                  },
                },
              ],
            },
          },
        },
        title: {
          ...options.title,
          text: `Data Source: ${dataSource[0].name}`,
          align: 'middle',
          floating: false,
          offsetX:20
        },
        subtitle: {
          text: `Time: ${startDate} to ${endDate}`,
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '16px',
            fontWeight: 'normal',
            fontFamily: 'National',
            color: '#363636',
          },
        },
      }));
    }
  }, [dataSource, initialSelectedDate,fileNameParameteres,location,dataSource[0].name]);


  useEffect(() => {
    if (props.isOpenWeather) {handleFileName()}
    if (props.isOpenWeather){setLocation(csvFileNameLocationSetter(region,district,state,country))}
    let seriesData = [];
    let YAxisoptionsData = YAxisOptions;
    if (chartData &&  chartData?.length && props.isOpenWeather) {
      const dates = chartData?.map((item) => OpenWeatherChartLabels(item.dt_txt));
      const tempValues = chartData?.map((item) => item.temp);
      const humidityValues = chartData?.map((item) => item.humidity);
      const windSpeedValues = chartData?.map((item) => item.wind_speed);
      const windGustValues = chartData?.map((item) => item.wind_gust);
      const rainValues = chartData?.map((item) => (item.rain ? item.rain["1h"] : 0));
      const cloudValues = chartData?.map((item) => item?.clouds);
      const pressureValues = chartData?.map((item) => item?.pressure);

      setOptions((prev) => ({
        ...prev,
        xaxis: {
          ...options.xaxis,
          type: 'datetime',
          categories: dates,
          tickPlacement: 'on',
          min: dates && dates[0],
          max: dates && dates[dates.length - 1],
          labels: {
            showDuplicates: false,
            hideOverlappingLabels: true,
            format: 'M/DD @ hA',
          },
          //range: 30,
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1, 2, 3],
        },
      }));

      const YAxisOption1 = parametersData.find((item) => item.checked === true);
      if (!_.isEmpty(YAxisOption1) && YAxisoptionsData.length) {
        if (!_.isEmpty(YAxisoptionsData[0])) {
          YAxisoptionsData[0] = {
            ...YAxisoptionsData[0],
            labels: {
              style: {
                colors: (CHART_LABEL_COLORS && CHART_LABEL_COLORS[0]) || '#008FFB',
                fontSize: '12px',
                fontFamily: 'National',
              },
              formatter: function (value) {
                if (!value === null || !isNaN(value)) { 
                  if (value % 1 !== 0) {
                    return value.toFixed(2)
                  } else {
                    return value
                  }
                }
              },
            },
            title: {
              text: YAxisOption1.name + ' (' + YAxisOption1.measure + ')',
              style: {
                color: (CHART_LABEL_COLORS && CHART_LABEL_COLORS[0]) || '#008FFB',
              },
            },
            scaleLabel: {
              display: true,
              labelString: YAxisOption1.name + YAxisOption1.measure,
            },
          };
        }

        const duplicateYAxisIndex = YAxisoptionsData.findIndex((item) =>
          item?.seriesName?.includes(YAxisOption1?.name),
        );

        if (duplicateYAxisIndex >= 0) {
          YAxisoptionsData.splice(duplicateYAxisIndex, 1);
        }
      }

      const renderYAxisValues = (param) => {
        switch (param) {
          case OPEN_WEATHER_CHART_PARAMS[0].name:
            return tempValues;
          case OPEN_WEATHER_CHART_PARAMS[1].name:
            return humidityValues;
          case OPEN_WEATHER_CHART_PARAMS[2].name:
            return windSpeedValues;
          case OPEN_WEATHER_CHART_PARAMS[3].name:
            return windGustValues;
          case OPEN_WEATHER_CHART_PARAMS[4].name:
            return rainValues;
          case OPEN_WEATHER_CHART_PARAMS[5].name:
            return pressureValues;
          case OPEN_WEATHER_CHART_PARAMS[6].name:
            return cloudValues;
          default:
            return [];
        }
      };
      if (parametersData) { 
        const chartParamData = _.uniqBy(parametersData, 'name');
        chartParamData.map((item, itemIndex) => {
          if (OPEN_WEATHER_CHART_PARAMS.some((data) => data.name === item.name)) {
            const index = seriesData?.findIndex((data) => data.name?.includes(item.name));
            const yIndex = YAxisoptionsData.findIndex(
              (findItem) =>
                findItem?.title?.text?.includes(item.name) ||
                findItem?.seriesName?.includes(item.name),
            );
            const checkedParams = parametersData.filter((i) => i.checked === true);
            let checkedIndex = _.findIndex(checkedParams, { name: item.name });
            if (!seriesData.length || index === -1) {
              if (item.checked) {
                seriesData.push({
                  name: item.name,
                  type: item.name === 'Precipitation' ? 'column' : 'line',
                  data: renderYAxisValues(item.name),
                });
              }
            }
            if (itemIndex > 0) {
              if (!YAxisoptionsData.length || yIndex === -1) {
                YAxisoptionsData.push({
                  seriesName: item.name + ' (' + item.measure + ')',
                  opposite: true,
                  axisTicks: {
                    show: true,
                  },
                  axisBorder: {
                    show: true,
                    color: CHART_LABEL_COLORS[checkedIndex],
                  },
                  labels: {
                    style: {
                      colors: CHART_LABEL_COLORS[checkedIndex],
                    },
                    formatter: function (value) {
                      if (!value == null || !isNaN(value)) {
                        if (value % 1 != 0) {
                          return value.toFixed(2)
                        } else {
                          return value
                        }
                      }
                    },
                  },
                  title: {
                    text: item.name + ' (' + item.measure + ')',
                    style: {
                      color: CHART_LABEL_COLORS[checkedIndex],
                    },
                  },
                });
              }
            }
          }
        });
        chartParamData?.map((items) => {
          const unCheckedIndex = YAxisoptionsData?.findIndex(
            (data) => data?.seriesName?.includes(items.name) && items.checked === false,
          );
          if (unCheckedIndex >= 0) {
            YAxisoptionsData.splice(unCheckedIndex, 1);
            parametersData.map((paramdata) => {
              if (paramdata.checked === true) {
                // const paramIndex = _.findIndex(YAxisoptionsData, { seriesName: paramdata.name });
                const paramIndex = YAxisoptionsData.findIndex((x) =>
                  x?.seriesName?.includes(paramdata.name),
                );
                if (paramIndex >= 0) {
                  YAxisoptionsData[paramIndex] = {
                    ...YAxisoptionsData[paramIndex],
                    axisBorder: {
                      ...YAxisoptionsData[paramIndex].axisBorder,
                      color: CHART_LABEL_COLORS[paramIndex],
                    },
                    labels: {
                      ...YAxisoptionsData[paramIndex].labels,
                      style: {
                        colors: CHART_LABEL_COLORS[paramIndex],
                      },
                    },
                    title: {
                      ...YAxisoptionsData[paramIndex].title,
                      style: {
                        color: CHART_LABEL_COLORS[paramIndex],
                      },
                    },
                  };
                }
              }
            });
          }
        });

        setSeries(seriesData);
        setYAxisOptions(YAxisoptionsData);
      } else {
        //setSeries([]);
      }
    } else {
      seriesData.push({
        name: 'Precipitation(mm)',
        // type: item.name === 'Precipitation' ? 'column' : 'line',
        data: chartData,
      });
      setSeries(seriesData);
    }
  }, [chartData, parametersData,country,state,district,region]);


  const csvFileNameLocationSetter=(region,district,state,country) =>{
    if(region.length == 1){
      return "(" + parseFloat(region[0].latlngs[1]).toFixed(2).toString() + "," + parseFloat(region[0].latlngs[0  ]).toFixed(2).toString() + ")"
    }
    else if (region.length == 2){
      return ;
    }
    else{
      if(district){
        return district;
      }
      else if (state){
        return state
      }
      else{
        return country
      }
    }
  }

  useEffect(() => {
    setOptions((prev) => ({
      ...prev,
      yaxis: YAxisOptions,
    }));
  }, [YAxisOptions]);


  useEffect(()=>{
    if(parametersData?.length && parametersData?.every(i=>!i.checked)){
      setYAxisOptions([
        {
          seriesName: '',
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: (CHART_LABEL_COLORS && CHART_LABEL_COLORS[0]) || '#008FFB',
          },
          labels: {
            show: true,
            style: {
              colors: (CHART_LABEL_COLORS && CHART_LABEL_COLORS[0]) || '#008FFB',
              fontSize: '12px',
              fontFamily: 'National',
            },
            formatter: function (value) {
          if (!value == null || !isNaN(value)) {
            if (value % 1 != 0) {
              return value.toFixed(2)
            } else {
              return value
            }
          }
        },
          },
          title: {
            text: ''
          },
          tooltip: {
            enabled: true,
          },
          scaleLabel: {
            display: true,
          },
          ticks: {
            callback: function (value, index, values) {
              return value;
            },
          },
        },
      ])
    }
  },[parametersData])
  
  if (!props.isOpenWeather) {
    return (
      <GEECharts
        renderCharts={renderCharts}
        setIsHideToolBar={props.setIsHideToolBar}
        chartData={props.geeTimeSeriesData}
        parametersData={parametersData}
        dataSource={dataSource}
        isOpenWeather={props.isOpenWeather}
        initialSelectedDate={initialSelectedDate}
        country = {country}
        state = {state}
        district = {district}
        region = {region}
      />
    );
  }

  const handleFileName = () =>{
    let a = []
    if(parametersData){
    parametersData.forEach(element => {
      if (element.checked == true) {
          a.push(element.name)
      }
    });
    setFileNameParameters(a.join(' '))
  }
  }

  return (
    <div>
      <div className="row">
        <div className="chart">
          {chartData?.length && (series.length || !_.isEmpty(options)) ? (
            <>
              <Chart
                id="chart"
                options={options}
                series={series}
                type="line"
                width="100%"
                height="300px"
              />
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}

export default Charts;