import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDmsEventsRequest,
  getDmsNewsfeedRequest,
  getDmsPillarsRequest,
  getDmsStatisticsRequest,
  getDmsTestimonialsRequest,
} from '../../redux/home/homeActions';

import { NEWS_FEED_URL_CONSTANT } from '../../Components/constants';

const ContactUs = lazy(() => import('./ContactUs'));
const ContentSection = lazy(() => import('./ContentSection'));
const Footer = lazy(() => import('./Footer'));
const HeroSection = lazy(() => import('../../Components/common/HeroSection'));
const MaximizingImpact = lazy(() => import('./MaximizingImpact'));
const NewsCarousel = lazy(() => import('./NewsCarousel'));
const Pillars = lazy(() => import('./RiskManagement'));
const SourceSection = lazy(() => import('./SourceSection'));
const Testimonials = lazy(() => import('./Testimonials'));
const WhatWeOffer = lazy(() => import('./WhatWeOfferSection'));
const VideoSection = lazy(() => import('./VideoSection'));

function HomePage() {
  const dispatch = useDispatch();
  const [statisticsData, setStatisticsData] = useState([]);
  const [testimonialsData, setTestimonialsData] = useState([]);
  const [upcomingEventsData, setUpcomingEventsData] = useState([]);
  const [newsfeedData, setNewsfeedData] = useState([]);
  const [pillarsData, setPillarsData] = useState([]);
  const [reportsData, setReportsData] = useState([]);
  const [blogsData, setBlogsData] = useState([]);

  const { LandingPageData } = useSelector(state => ({
    LandingPageData: state.Home,
  }));

  useEffect(() => {
    dispatch(getDmsStatisticsRequest());
    dispatch(getDmsTestimonialsRequest());
    dispatch(getDmsEventsRequest({ event_type: 'upcoming' }));
    dispatch(getDmsNewsfeedRequest({ category: 'south-asia', limit: 10 }));
    dispatch(getDmsPillarsRequest());
    const axios = require('axios');

    // Publications
    const fetchPublications = async () => {
      try {
        const response = await axios.get(NEWS_FEED_URL_CONSTANT.RESOURCE_URL);
        const data = response.data.items;
        setReportsData(data);
      } catch (err) {
        setReportsData([]);
      }
    };

    fetchPublications();

    const fetchBlogs = async () => {
      try {
        const response = await axios.get(NEWS_FEED_URL_CONSTANT.BLOGS_URL);
        const data = response.data.items;
        setBlogsData(data);
      } catch (err) {
        setBlogsData([]);
      }
    };

    fetchBlogs();

    const countryData = JSON.stringify({});
    localStorage.setItem('countryData', countryData);
    localStorage.setItem('selectedCountry', JSON.stringify(''));
    // window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (LandingPageData.getDmsStatisticsData) {
      const { result } = LandingPageData.getDmsStatisticsData.data;
      setStatisticsData(result);
    }
  }, [LandingPageData.getDmsStatisticsData]);

  useEffect(() => {
    if (LandingPageData.getDmsTestimonialsData) {
      const { result } = LandingPageData.getDmsTestimonialsData.data;
      setTestimonialsData(result);
    }
  }, [LandingPageData.getDmsTestimonialsData]);

  useEffect(() => {
    if (LandingPageData.getDmsEventsData) {
      const { result } = LandingPageData.getDmsEventsData.data;
      setUpcomingEventsData(result);
    }
  }, [LandingPageData.getDmsEventsData]);

  useEffect(() => {
    if (LandingPageData.getDmsNewsfeedData) {
      const { result } = LandingPageData.getDmsNewsfeedData.data;
      if (result?.items?.length) {
        setNewsfeedData(result?.items);
      }
    }
  }, [LandingPageData.getDmsNewsfeedData]);

  useEffect(() => {
    if (LandingPageData.getDmsPillarsData) {
      const { result } = LandingPageData.getDmsPillarsData.data;
      setPillarsData(result);
    }
  }, [LandingPageData.getDmsPillarsData]);

  return (
    <Suspense fallback={<div>...</div>}>
      <HeroSection />
      <ContentSection />
      <SourceSection />
      <Pillars componentData={pillarsData} />
      {/* News Section */}
      <NewsCarousel
        cardData={newsfeedData}
        title="News"
        dataType="news"
        backgroundColor=""
      />
      {/* Upcoming events */}
      <NewsCarousel
        cardData={upcomingEventsData}
        title="Upcoming Events"
        dataType="upcoming-events"
        backgroundColor="bg-white"
      />
      {/* Blogs*/}
      <NewsCarousel
        cardData={blogsData}
        title="Blogs"
        dataType="news"
        backgroundColor=""
      />
      {/* Publications */}
      <NewsCarousel
        cardData={reportsData}
        title="Publications"
        dataType="reports"
        backgroundColor="bg-white"
      />
      <VideoSection />
      {/* Maximizing Impact */}
      <MaximizingImpact statisticsData={statisticsData} />
      {/* Testimonials */}
      <Testimonials testimonials={testimonialsData} visibleCards={1} />
      <WhatWeOffer />
      <ContactUs />
      <Footer backgroundColor="" />
    </Suspense>
  );
}

export default HomePage;
