import React, { useState } from 'react';
import LeafletWrapper from './WeatherForecast/index';

export default function Dashboard() {
  const [location, setLocation] = useState({
    message: '',  
    coOrdinates: [17.355, -3.518],
    zoom: 4.4,
  });

  return <LeafletWrapper cordinates={location.coOrdinates} zoom={location.zoom} />;
}
