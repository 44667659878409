import { pdf, PDFDownloadLink } from '@react-pdf/renderer';
import domtoimage from 'dom-to-image-more';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { FiCheck } from 'react-icons/fi';
import { GrFormClose } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Col, Container, FormFeedback, Input, Row } from 'reactstrap';

import RightArrow from '../../../assets/Images/back-button.svg';
import IWMI from '../../../assets/Images/iwmi-pdf-header.png';
import Loader from '../../../Components/common/loader';
import {
  BULLET_IN_HOW_TO_USE,
  BULLETIN_INDICATORS_INFO,
  DROUGHT_PREDICTION_PARAMETER_IDS,
  LOCATION,
} from '../../../Components/constants';
import {
  getBulletinDateFormatwithOutSubScript,
  getBulletinMonthFormat,
  getCoordinatesAndZoom,
} from '../../../Components/helpers';
import { showError, showSuccess } from '../../../Components/Notifications';
import {
  addBulletinRequest,
  addBulletinSuccess,
  approveRejectBulletinRequest,
  approveRejectBulletinSuccess,
  bulletinPublishRequest,
  bulletinPublishSuccess,
} from '../../../redux/actions';
import {
  API_BASE_URL,
  AXIOS_INSTANCE,
  AXIOS_INSTANCE_MASTER_SERVER,
  MASTER_SERVER_BASE_URL,
} from '../../../redux/api/apiEndPoint';
import { userDetailRequest } from '../../../redux/user-list/userListAction';
import Footer from '../../new-landing-page/Footer';
import CreateBulletinModal from '../CreateBulletinModal';
import DownloadPdfComponent from '../DownloadPdfComponent';
import BulletinFooter from './BulletinFooter';
import BulletinHeader from './BulletinHeader';
import RenderMapContainer from './RenderMapContainer';

function CreateOnlineBulletin() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const layerRef = useRef({});
  const downloadLinkRef = useRef(null);
  const [isOpenBulletin, setIsOpenBulletin] = useState(false);
  const [bulletinData, setBulletinData] = useState({});
  const [isTitleEdit, setIsTitleEdit] = useState(false);
  const [title, setTitle] = useState('');
  const [defaultTitle, setDefaultTitle] = useState('');
  const [bulletInContent, setBulletinContent] = useState(BULLET_IN_HOW_TO_USE);
  const [indicatorsList, setIndicatorList] = useState({});
  const [mapIndex, setMapIndex] = useState(0);
  const [cordinateAndZoom, setCordinateAndZoom] = useState({
    center: '',
    zoom: 6,
  });
  const [mapInstances, setMapInstances] = useState({});
  const [isLayersLoading, setIsLayersLoading] = useState(false);
  const [mapLayersList, setMapLayersList] = useState([]);
  const [mapResponses, setMapResponses] = useState({});
  const [headerIcons, setHeaderIcons] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [mapImageUrls, setMapImageUrls] = useState({});
  const [summaryData, setSummaryData] = useState({});
  const [bulletinId, setBulletinId] = useState('');
  const [addMapImageUrls, setAddMapImageUrls] = useState({});
  const [summaryError, setSummaryError] = useState('');

  const nextProps = useSelector(state => ({
    userDetailData: state.userList.userDetailData || null,
    userPermissionsData: state.userList.userPermissionsData || null,
    contgencyLogsData: state?.ContingencyPlan?.contgencyLogsData,
    Bulletin: state.Bulletin,
    approveRejectData: state.Bulletin?.approveRejectData,
    bulletinPublishData: state.Bulletin?.bulletinPublishData,
  }));

  useEffect(() => {
    // Add a CSS class to the body element when the sideEditor is open
    if (isOpenBulletin || isLayersLoading) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    // Clean up the effect
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isOpenBulletin, isLayersLoading]);

  useEffect(() => {
    return () => {
      setMapInstances({});
      dispatch(addBulletinSuccess({}));
      dispatch(approveRejectBulletinSuccess({}));
      dispatch(bulletinPublishSuccess({}));
    };
  }, []);

  useEffect(() => {
    const { formData, newMapIndex, layersList, isTitleEditable } = location.state || {};
    setMapIndex(newMapIndex);
    if (!_.isEmpty(formData)) {
      setBulletinData(formData);
      const bulletinTitle =
        formData?.bulletin_type.value === 'Monthly Bulletin'
          ? `${getBulletinMonthFormat(formData.startDate)}`
          : `${getBulletinDateFormatwithOutSubScript(
              formData.startDate,
            )} -${getBulletinDateFormatwithOutSubScript(formData.endDate)}`;
      if (isTitleEditable) {
        setTitle(bulletinTitle);
        setDefaultTitle(bulletinTitle);
      } else {
        setTitle(''); // or set it to some default value if not editable
      }

      const { latitude, longitude, zoom } = getCoordinatesAndZoom(formData);

      setCordinateAndZoom({
        center: [latitude, longitude],
        zoom: zoom,
      });
      if (formData?.indicators?.length) {
        setIndicatorList(prev => ({
          ...prev,
          [newMapIndex]: formData?.indicators,
        }));
      }
      if (layersList?.length) {
        setMapLayersList(layersList);
      }
    }
    dispatch(userDetailRequest({}));
  }, [location]);

  useEffect(() => {
    if (nextProps.userDetailData) {
      const {
        data: { result },
      } = nextProps.userDetailData;
      if (result?.user) {
        setUserDetails(result?.user);
      }
    }
  }, [nextProps.userDetailData]);

  useEffect(() => {
    const fetchMapData = async () => {
      if (!Object.keys(indicatorsList)?.length) return;
      setIsLayersLoading(true);
      const { country, startDate, endDate, state, district } = bulletinData || {};
      const createPayload = item => {
        let payload = {
          api_url: item.api_url,
          country_name: country?.country_name,
          start_date: '',
          end_date: '',
          temporal_aggregation: 'mean',
        };

        if (bulletinData?.bulletin_type?.name === 'Monthly Bulletin') {
          payload.start_date = `${startDate}-01`;
          payload.end_date = `${startDate}-${moment(startDate, 'YYYY-MM').daysInMonth()}`;
        } else {
          payload.start_date = startDate;
          payload.end_date = endDate;
        }

        if (state) payload.state_name = state.name;
        if (district) payload.district_name = district.name;

        return payload;
      };

      const MAP_API_URLS = indicatorsList[mapIndex]?.map(async (item, index) => {
        layerRef.current[`${mapIndex}-${index}`] = React.createRef();
        let payload = createPayload(item);
        let instance;
        if (item.master_server) {
          instance = AXIOS_INSTANCE_MASTER_SERVER.post(
            `${MASTER_SERVER_BASE_URL}/${item.api_url}map`,
            payload,
          );
        } else {
          payload.parameter_id = DROUGHT_PREDICTION_PARAMETER_IDS[item.slug];
          if (['drought-prediction/sri', 'drought-prediction/spi'].includes(item.slug)) {
            payload.month = '03';
          }

          instance = AXIOS_INSTANCE.post(`${API_BASE_URL}/${item.api_url}map`, payload);
        }

        try {
          return await instance;
        } catch (err) {
          return err.response;
        } // Handle errors for individual requests
      });
      try {
        const responses = await Promise.all(MAP_API_URLS);
        const responseCopy = JSON.parse(JSON.stringify(responses));
        const responsesList = responseCopy?.reduce((acc, res) => {
          acc.push(res?.data);
          return acc;
        }, []);

        setMapResponses(prev => ({
          ...prev,
          [mapIndex]: responsesList,
        }));
      } catch (err) {
        const errorMessage = _.get(err, 'response.data.message');
        showError(errorMessage);
      } finally {
        setIsLayersLoading(false);
      }
    };

    fetchMapData();
  }, [indicatorsList]);

  useEffect(() => {
    if (!_.isEmpty(mapResponses)) {
      Object.keys(mapResponses).forEach(responsesIndex => {
        mapResponses[responsesIndex].forEach((mapData, index) => {
          const { result, code, message } = mapData;
          if (code === 200) {
            if (layerRef?.current[`${responsesIndex}-${index}`]?.current !== null) {
              const currentParamRef = layerRef?.current[`${responsesIndex}-${index}`];
              if (currentParamRef && currentParamRef.current !== null) {
                currentParamRef.current?.eachLayer(layer => {
                  if (result?.map_data?.map_url) {
                    layer.setUrl(result?.map_data?.map_url);
                    layer.setOpacity(1);
                  }
                });
              }

              let legendObj = {};
              legendObj = { ...result?.map_data };
              legendObj.parameter = indicatorsList[responsesIndex][index].name;
              legendObj.measure = '';
              legendObj.dataSource = indicatorsList[responsesIndex][index].category;
              legendObj.isCompare = false;
              legendObj.id = indicatorsList[responsesIndex][index].name;
              legendObj.fromMapPreferences = false;
              legendObj.descret_legend = result?.map_data?.descret_legend;
              legendObj.selectedDates = {
                startDate: moment(bulletinData?.startDate).format('DD-MM-YYYY'),
                endDate: moment(bulletinData?.endDate).format('DD-MM-YYYY'),
              };
              legendObj.add = true;

              if (!mapInstances[`legendObj${responsesIndex}${index}`]) {
                setMapInstances(prev => ({
                  ...prev,
                  [`legendObj${responsesIndex}${index}`]: legendObj,
                }));
              }

              if (!_.isEmpty(currentParamRef) && currentParamRef?.current !== null) {
                addLayers(currentParamRef, `${responsesIndex}${index}`);
              }
            }
          } else {
            showError(message);
          }
        });
      });
    }
  }, [mapResponses]);

  useEffect(() => {
    if (mapImageUrls && Object.keys(mapImageUrls).length > 0) {
      // Wait for state update to reflect before triggering download pdf
      if (downloadLinkRef.current) {
        setTimeout(() => {
          if (downloadLinkRef.current) {
            downloadLinkRef.current.click();
          }
        }, 2000);
      }
    }
    setIsLayersLoading(false);
  }, [mapImageUrls]);

  useEffect(() => {
    if (addMapImageUrls && Object.keys(addMapImageUrls).length > 0) {
      // Wait for state update to reflect before triggering download pdf
      // Delay to ensure images are loaded
      setTimeout(async () => {
        // Step 2: Generate the PDF blob
        const pdfBlob = await generatePdfBlob();

        // Create a FileReader instance
        const reader = new window.FileReader();
        const geo_location = bulletinData?.district
          ? bulletinData?.district?.name
          : bulletinData?.state
          ? bulletinData?.state?.name
          : bulletinData?.country?.country_name;

        reader.readAsDataURL(pdfBlob);
        reader.onloadend = () => {
          const pdfResult = reader.result;
          const slicedResult = pdfResult.slice(28);

          if (pdfResult) {
            const form = new FormData();
            form.append('topic', `${geo_location} ${title}`);
            form.append('bulletin_content', slicedResult);
            form.append('category_id', bulletinData?.bulletin_type?.id);
            dispatch(addBulletinRequest(form));
          }
        };
      }, 2000); // 2-second delay to ensure images are loaded
      handleHideAndShowIcons('initial');
    }
  }, [addMapImageUrls]);

  useEffect(() => {
    const { addBulletinDataError, updateBulletinDataError, addBulletinData } =
      nextProps.Bulletin || {};

    // Check for error messages
    const message =
      addBulletinDataError?.data?.response?.data?.message ||
      updateBulletinDataError?.data?.response?.data?.message;

    if (message) {
      showError(message);
      setIsLayersLoading(false);
    }

    // Check if addBulletinData is successful
    const addBulletinDataResponse = addBulletinData?.data;
    if (addBulletinDataResponse) {
      if (addBulletinDataResponse?.data?.id) {
        setBulletinId(addBulletinDataResponse?.data?.id);
        dispatch(
          approveRejectBulletinRequest({
            id: addBulletinDataResponse?.data?.id,
            bulletin_status: '1',
          }),
        );
      }
      setIsLayersLoading(false);
    }
  }, [
    nextProps.Bulletin.addBulletinDataError,
    nextProps.Bulletin.updateBulletinDataError,
    nextProps.Bulletin.addBulletinData,
    dispatch,
  ]);

  const isApproveorrejectRef = useRef(true);
  useEffect(() => {
    if (isApproveorrejectRef.current) {
      isApproveorrejectRef.current = false;
      return;
    }
    if (nextProps.approveRejectData?.data?.status === 200) {
      dispatch(bulletinPublishRequest({ id: bulletinId, is_publish: true }));
    }
  }, [nextProps.approveRejectData]);

  const isPublishref = useRef(true);
  useEffect(() => {
    if (isPublishref.current) {
      isPublishref.current = false;
      return;
    }
    if (nextProps.bulletinPublishData?.data?.status === 200) {
      showSuccess(nextProps.bulletinPublishData?.data?.data?.result);
      history.push('/online-bulletin');
    }
  }, [nextProps.bulletinPublishData]);

  const addLayers = (selectedParamRef, instanceValue) => {
    if (mapInstances[`mapInstance${instanceValue}`] && selectedParamRef.current) {
      const leafletMapRef = mapInstances[`mapInstance${instanceValue}`];
      const firstLayer = selectedParamRef.current;
      [firstLayer].forEach(layer => {
        leafletMapRef.addLayer(layer);
      });
    }
    return false;
  };

  const handleBackToSettings = e => {
    e.preventDefault();
    history.push('/online-bulletin');
  };

  const changeTitle = () => {
    if (title === '') {
      showError('Title should not be empty!');
    } else {
      setIsTitleEdit(false);
    }
  };

  const onChangeBulletin = e => {
    const {
      target: { value: currentValue },
    } = e;
    const currentRows = currentValue.split('\n');
    const wordCount = currentValue.length;
    if (wordCount < 1200) {
      currentRows?.length < 11 && setBulletinContent(currentValue);
    }
  };

  const handleCreateBulletin = () => {
    setIsOpenBulletin(!isOpenBulletin);
  };

  const onHandleSummary = (value, keyValue) => {
    const wordCount = value.trim().split(/\s+/).length; // Count words

    if (wordCount > 500) {
      setSummaryError('Maximum 500 words allowed');
    } else {
      setSummaryError('');
      setSummaryData(prev => ({
        ...prev,
        [keyValue]: value,
      }));
    }
  };

  const handleHideAndShowIcons = value => {
    const icons = document.getElementsByClassName('hide-btn');

    if (value) {
      for (let i = 0; i < icons?.length; i += 1) {
        icons[i].style.display = value;
      }
    }
  };

  /**
   * to download as image based on selected element
   */
  const downloadAsImage = () => {
    setIsLayersLoading(true);
    const options = { backgroundColor: '#fcfcfc' };
    const element = document.getElementById('contentToPrint');
    element.style = 'background-color:#fcfcfc';
    handleHideAndShowIcons('none');
    domtoimage
      .toBlob(element, options)
      .then(blob => {
        setIsLayersLoading(false);
        window.saveAs(
          blob,
          `${bulletinData?.bulletin_type?.value}-${
            bulletinData?.district
              ? bulletinData?.district?.name
              : bulletinData?.state
              ? bulletinData?.state?.name
              : bulletinData?.country?.country_name
          }-${title}`,
        );
        element.style = 'background-color:unset';
        handleHideAndShowIcons('initial');
      })
      .catch(() => {
        setIsLayersLoading(false);
        handleHideAndShowIcons('initial');
        showError('Something went wrong');
      });
  };

  const getImageUrl = async elementId => {
    const element = document.getElementById(elementId);
    if (element) {
      try {
        const { width, height } = element.getBoundingClientRect(); // Get actual dimensions
        /**
         *Rounding it up in terms of 100
         
         * Height = 100 → Rounded Height = 100
         * Height = 150 → Rounded Height = 200
         */
        const roundedHeight = Math.ceil(height / 100) * 100;
        const imageUrl = await domtoimage.toPng(element, {
          width: Math.ceil(width), // Ensure width is a whole number
          height: roundedHeight, // Ensure height is a whole number
          style: {
            transform: null, // Reset transformation styles
          },
        });
        return imageUrl;
      } catch (error) {
        console.error('Error converting element to image:', error);
        return ''; // Handle error
      }
    }
    return ''; // Handle no element found
  };

  const fetchImageUrls = async () => {
    let urls = {};
    let imageData = [];

    // Ensure indicatorsList is defined and has keys
    if (indicatorsList && Object.keys(indicatorsList).length > 0) {
      imageData = await Promise.all(
        Object.keys(indicatorsList).map(async indicatorKey => {
          const elementId = `map-component[${indicatorKey}]`;
          return await getImageUrl(elementId);
        }),
      );
    } else {
      console.error('indicatorsList is either undefined or empty');
    }
    if (imageData?.length) {
      urls = Object.assign({}, imageData);
    }

    return urls;
  };

  const handleDownloadPdf = async () => {
    setIsLayersLoading(true);
    const imageUrls = await fetchImageUrls();

    if (imageUrls && Object.keys(imageUrls).length > 0) {
      setMapImageUrls(imageUrls); // Ensure image URLs are set
    } else {
      console.error('No maps available to export');
    }
  };

  const generatePdfBlob = async () => {
    // eslint-disable-next-line no-return-await
    return await pdf(
      <DownloadPdfComponent
        headerLogos={[IWMI, ...headerIcons]}
        title={title}
        bulletinType={bulletinData?.bulletin_type?.value}
        createdDate={moment(new Date()).format('Do MMM YYYY')}
        userDetails={userDetails}
        bulletUsageContent={bulletInContent}
        mapImageUrls={addMapImageUrls}
        indicatorsList={indicatorsList}
        summaryData={summaryData}
      />,
    ).toBlob();
  };

  const handlePublishPdf = async () => {
    setIsLayersLoading(true);

    const imageUrls = await fetchImageUrls();
    if (imageUrls && Object.keys(imageUrls).length > 0) {
      setAddMapImageUrls(imageUrls); // Ensure image URLs are set
    } else {
      console.error('No maps available to export');
    }
  };

  const renderMapContainer = (
    indicatorData,
    indicatorIndex,
    indicatorKey,
    cordinateAndZoom,
    setCordinateAndZoom,
    setMapInstances,
    layerRef,
    mapInstances,
    bulletinData,
  ) => {
    return (
      <RenderMapContainer
        indicatorData={indicatorData}
        indicatorIndex={indicatorIndex}
        cordinateAndZoom={cordinateAndZoom}
        setCordinateAndZoom={setCordinateAndZoom}
        setMapInstances={setMapInstances}
        layerRef={layerRef}
        indicatorKey={indicatorKey}
        mapInstances={mapInstances}
        bulletinData={bulletinData}
      />
    );
  };

  return (
    <>
      {isLayersLoading && <Loader />}
      {isOpenBulletin && (
        <CreateBulletinModal
          setIsOpenBulletin={setIsOpenBulletin}
          isOpenBulletin={isOpenBulletin}
          toggleModal={handleCreateBulletin}
          LOCATION={LOCATION}
          indicatorList={mapLayersList}
          mapIndex={mapIndex + 1}
          userFormData={bulletinData}
          isEditTitle={false}
        />
      )}
      <Container fluid className=" spacing-online-bulletin news-feed-header border-0">
        <div className="d-none">
          <PDFDownloadLink
            document={
              <DownloadPdfComponent
                headerLogos={[IWMI, ...headerIcons]}
                title={title}
                bulletinType={bulletinData?.bulletin_type?.value}
                createdDate={moment(new Date()).format('Do MMM YYYY')}
                userDetails={userDetails}
                bulletUsageContent={bulletInContent}
                mapImageUrls={mapImageUrls}
                indicatorsList={indicatorsList}
                summaryData={summaryData}
              />
            }
          >
            {({ blob, url, loading: pdfLoading, error }) => (
              <a
                href={url}
                download={`${
                  bulletinData?.district
                    ? bulletinData?.district?.name
                    : bulletinData?.state
                    ? bulletinData?.state?.name
                    : bulletinData?.country?.country_name
                }-${title || defaultTitle}`}
                ref={downloadLinkRef}
                className="d-none"
              >
                <button type="button" disabled={pdfLoading}>
                  {pdfLoading ? 'Preparing document...' : 'Download PDF'}
                </button>
              </a>
            )}
          </PDFDownloadLink>
        </div>
        <Row>
          <Col xs={6}>
            <p className="dms-back" onClick={e => handleBackToSettings(e)}>
              <img src={RightArrow} alt="back-arrow" height={24} width={24} />{' '}
              <span className="online-bulletin-header">Create Bulletin</span>
            </p>
          </Col>
          <Col xs={6} className=" d-flex justify-content-between">
            <Button className="create-bulletin-btns" onClick={() => handleCreateBulletin()}>
              + Add more indicators
            </Button>
            <Button className="create-bulletin-btns" onClick={() => downloadAsImage()}>
              Export PNG
            </Button>
            <Button className="create-bulletin-btns" onClick={() => handleDownloadPdf()}>
              Export PDF
            </Button>
            <Button
              className="create-bulletin-btns create-bulletin-btns-bkg"
              onClick={() => handlePublishPdf()}
            >
              Publish
            </Button>
          </Col>
        </Row>
        <Row className=" px-0 mb-4">
          <div className="bulletin-pdf px-0" id="contentToPrint">
            <BulletinHeader setHeaderIcons={setHeaderIcons} headerIcons={headerIcons} />
            <div className="bulletin-bkg-img">
              <div>
                <p className="pdf-bulletin-text">{bulletinData?.bulletin_type?.value}</p>
                <h2 className="pdf-bulletin-text pdf-bulletin-text-font mb-1 text-div1">
                  {isTitleEdit ? (
                    <div className="d-flex align-items-center">
                      <Input
                        value={title || defaultTitle}
                        title="Title"
                        placeholder="Enter Title"
                        onChange={e => setTitle(e.target.value)}
                        defaultValue={defaultTitle}
                        maxLength={50}
                      />
                      <FiCheck
                        size={30}
                        onClick={() => changeTitle()}
                        className="cursor-pointer green-icon mx-2 mt-1"
                      />
                      <GrFormClose
                        size={30}
                        color="#e86933"
                        className="cursor-pointer danger-icon mt-1"
                        onClick={() => {
                          setTitle(title);
                          setIsTitleEdit(false);
                        }}
                      />
                    </div>
                  ) : (
                    <span>
                      Bulletin Report : {title || defaultTitle}
                      <FaEdit
                        color="#dd9103"
                        size={18}
                        className=" cursor-ponter mx-2 indicator-icon hide-btn"
                        onClick={() => setIsTitleEdit(true)}
                      />
                    </span>
                  )}
                </h2>
              </div>
              <div>
                <p className="pdf-bulletin-text pdf-bulletin-text-size m-0">
                  Prepared on : {moment(new Date()).format('Do MMM YYYY')}
                </p>
                <p className="pdf-bulletin-text pdf-bulletin-text-size">
                  Prepared by : {userDetails?.username}
                </p>
              </div>
            </div>
            <div className="bulletin-banners-text mb-5">
              <p className="bulletin-banners-text-p">How to use the bulletin</p>

              <textarea
                className="pdf-bulletin-textarea"
                rows={7}
                value={bulletInContent}
                onChange={onChangeBulletin}
              />
            </div>
            {Object.keys(indicatorsList)?.length
              ? Object.keys(indicatorsList)?.map(indicatorKey => {
                  return (
                    <>
                      <div className="bulletin-banners-text mb-5" key={indicatorKey}>
                        <p className="bulletin-banners-text-p">Indicator summary</p>
                        <div
                          style={{ position: 'relative', width: '100%', height: '100%' }}
                          id={`map-component[${indicatorKey}]`}
                        >
                          <div className="bulliten-map-grid">
                            {indicatorsList[indicatorKey]?.map((indicators, index) => {
                              return (
                                <React.Fragment key={index}>
                                  {renderMapContainer(
                                    indicators,
                                    index,
                                    indicatorKey,
                                    cordinateAndZoom,
                                    setCordinateAndZoom,
                                    setMapInstances,
                                    layerRef,
                                    mapInstances,
                                    bulletinData,
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="bulletin-indicators-info-container mb-2">
                        <p className="indicator-info-text">
                          <span>Indicator</span> Basic Info:
                        </p>
                        {indicatorsList[indicatorKey]?.map(indicators => {
                          return (
                            <p
                              className="bulliten-indicator-text m-0"
                              key={`${indicatorKey}-${indicators.name}`}
                            >
                              {indicators.name} :{' '}
                              <span className="bulletin-indicator-text-info">
                                {BULLETIN_INDICATORS_INFO[indicators.name]}
                              </span>
                              {/* <FaEdit
                                id={`${indicatorKey}.${indicators.name}`}
                                color="#dd9103"
                                size={18}
                                className="cursor-pointer mx-2 indicator-icon"
                                onClick={() => editInfo(`${indicatorKey}.${indicators.name}`)}
                              /> */}
                            </p>
                          );
                        })}
                      </div>
                      <div className="bulletin-indicators-info-container mb-2">
                        <p className="indicator-info-text">
                          <span>Summary</span> of current condition :
                        </p>
                        <div className="hr-lines"> </div>
                        {isLayersLoading ? (
                          <p>{summaryData[indicatorKey]}</p>
                        ) : (
                          <>
                            <textarea
                              className="summary-bulletin-textarea"
                              rows={7}
                              value={summaryData[indicatorKey]}
                              onChange={e => onHandleSummary(e.target.value, indicatorKey)}
                              placeholder="Write down something... (Max: 500 words)"
                              invalid={!!summaryError}
                            />
                            {summaryError && (
                              <FormFeedback style={{ display: 'block' }}>
                                {summaryError}
                              </FormFeedback>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  );
                })
              : null}
            <BulletinFooter />
          </div>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default CreateOnlineBulletin;
