import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { t } from 'react-multi-lang';
import _ from 'lodash';
import {
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Nav,
  Label,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import {
  renderTextField,
  renderReactSelectInputField,
} from '../../../Components/common/renderInputField';
import {
  CONTINGENCY_FILTER_TABS,
  CONTINGENCY_PLAN,
  DATE_FORMAT,
  CP_MIN_DATE
} from '../../../Components/constants/index';
import DroughtStageSwitch from './DroughtStageOptions';

const MapMeasures = props => {
  const [activeTab, setActiveTab] = useState(null);
  useEffect(() => {
    if (!_.isEmpty(props.initialValues?.droughtMapStage?.id)) {
      handleMapDroughtChange(props.initialValues.droughtMapStage);
      let startDate = { target: { value: props.initialValues.startDate } };
      let endDate = { target: { value: props.initialValues.endDate } };
      handleStartDate(startDate);
      handleEndDate(endDate);
    }
  }, [props.initialValues.droughtMapStage]);

  useEffect(() => {
    if (!_.isEmpty(props.initialValues?.droughtMeasuresStage?.id)) {
      handleMeasuresDroughtChange(props.initialValues.droughtMeasuresStage);
    }
  }, [props.initialValues.droughtMeasuresStage]);

  useEffect(() => {
    if (!_.isEmpty(props.initialValues?.chooseTimePeriod?.id)) {
      handleTimePeriodChange(props.initialValues.chooseTimePeriod);
    }
  }, [props.initialValues.chooseTimePeriod]);

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    if (props.filterActiveTab) {
      setActiveTab(props.filterActiveTab);
    }
  }, [props.filterActiveTab]);

  const [initialSelectedDate, setInitialSelectedDate] = useState({
    startDate: '',
    endDate: '',
    maxEndDate: moment(new Date()).format('YYYY-MM-DD'),
  });

  useEffect(() => {
    props.handleFormButton(activeTab);
  }, [activeTab]);

  const renderAPIParameterOptions = (list, action_name) => {
    if (list) {
      let params = [...list];
      let options = [];
      if (params.length) {
        if (action_name !== CONTINGENCY_PLAN.Stage) {
          let default_option = { value: 'select', label: 'select', id: 'select' };
          options.push(default_option);
        }
        params.map(param => {
          let data = {
            value: param.name,
            label: `${param.name}`,
            id: param.id,
          };
          options.push(data);
          return true;
        });
        return options;
      }
    }
  };

  const handleMapDroughtChange = value => {
    props.handleMapDrought(value);
  };

  const handleMeasuresDroughtChange = value => {
    props.handleMeasureDrought(value);
  };

  const handleStrategyChange = value => {
    props.handleStrategy(value);
  };

  const handleStartDate = e => {
    setInitialSelectedDate(prev => {
      return {
        ...prev,
        startDate: e.target.value,
        minEndDate: moment(e.target.value)
          .add(1, 'days')
          .format(DATE_FORMAT),
      };
    });
    props.handleStartDate(e.target.value);
  };

  const handleTimePeriodChange = value => {
    props.handleTimePeriodChange(value);
  };

  const handleEndDate = e => {
    setInitialSelectedDate(prev => {
      return {
        ...prev,
        endDate: e.target.value,
        maxStartDate: moment(e.target.value)
          .subtract(1, 'days')
          .format(DATE_FORMAT),
      };
    });
    props.handleEndDate(e.target.value);
  };

  const onMenuOpen = () => {
    props.handleButtonMarginTop(true);
  };
  const onMenuClose = () => {
    props.handleButtonMarginTop(false);
  };


  /**
   * added by sai krishna thalla
   * reason: min dates added to cp 
   * date: 20-sep-2022
   */
  const minDate = () =>{
    if (props?.checkedIndicesList[0]?.min_date && props?.checkedIndicesList[1]?.min_date){
      return CP_MIN_DATE;
    }
    else if (props?.checkedIndicesList[0]?.min_date && !props?.checkedIndicesList[1]?.min_date){
      return props?.checkedIndicesList[0]?.min_date
    }
    else{
      return CP_MIN_DATE
    }
  }
  

  return (
    <div className="map-measures" id="map-measures-form">
      {/* <form id="map-measures-form"> */}
        <Nav tabs>
          {props.isMapTabShow && (
            <NavItem>
              <NavLink
                className={activeTab === CONTINGENCY_FILTER_TABS.MAP ? 'active' : ''}
                onClick={() => toggle(CONTINGENCY_FILTER_TABS.MAP)}
              >
                {t('CONTINGENCY_PLAN.MAP')}
              </NavLink>
            </NavItem>
          )}
          <NavItem>
            <NavLink
              className={activeTab === CONTINGENCY_FILTER_TABS.MEASURE ? 'active' : ''}
              onClick={() => toggle(CONTINGENCY_FILTER_TABS.MEASURE)}
            >
              {t('CONTINGENCY_PLAN.MEASURES')}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          {props.isMapTabShow && (
            <TabPane tabId={CONTINGENCY_FILTER_TABS.MAP}>
              {props?.stageList?.length !== 0 && (
                <div className="advance-search-fields">
                  <div className="data-source-field-one">
                    <Label htmlFor="country">Select Drought Stage  </Label>
                    <Field
                      component={renderReactSelectInputField}
                      name="droughtMapStage"
                      placeholder="Select Drought Stage"
                      onChange={e => handleMapDroughtChange(e)}
                      required={true}
                      isMulti={false}
                      className="mt-2 mb-3"
                      options={renderAPIParameterOptions(props.stageList, 'stage')}
                      isLoading={
                        props.dropdownDataLoading.key === 'stage' &&
                        props.dropdownDataLoading.isLoading
                      }
                    ></Field>
                    {props?.droughtIndicesList?.length !== 0 && (
                      <DroughtStageSwitch
                        droughtIndicesList={props.droughtIndicesList}
                        checkedIndicesList={props.checkedIndicesList}
                        handleDroughtIndices={props.handleDroughtIndices}
                        precipitationThreshold={props.precipitationThreshold}
                        setPrecipitationThreshold={props.setPrecipitationThreshold}
                      />
                    )}
                  </div>
                </div>
              )}
              <div className="data-source-field-one">
                <Label htmlFor="country">Select Start Date</Label>
                <Field
                  component={renderTextField}
                  name="startDate"
                  bsSize="sm"
                  className="mt-1 my-3 input-style-width filter-select-placeholder margin-date"
                  placeholder="Select Start Date"
                  type="date"
                  isMulti={false}
                  onChange={e => handleStartDate(e)}
                  min = {minDate()}
                  max={initialSelectedDate.maxStartDate || initialSelectedDate.maxEndDate}
                ></Field>
                <Label htmlFor="country">Select End Date</Label>
                <Field
                  component={renderTextField}
                  name="endDate"
                  bsSize="sm"
                  className="mt-1 my-3 input-style-width filter-select-placeholder  margin-date"
                  placeholder="Select End Date"
                  type="date"
                  required={true}
                  isMulti={false}
                  onChange={e => handleEndDate(e)}
                  //min={initialSelectedDate.minEndDate}
                  min = {minDate()}
                  max={initialSelectedDate.maxEndDate}
                ></Field>
              </div>
            </TabPane>
          )}
          <TabPane tabId={CONTINGENCY_FILTER_TABS.MEASURE}>
            {props?.stageList?.length !== 0 && (
              <div className="advance-search-fields">
                <div className="data-source-field-one">
                  <Label htmlFor="country">Select Drought Stage</Label>
                  <Field
                    component={renderReactSelectInputField}
                    name="droughtMeasuresStage"
                    placeholder="Select Drought Stage"
                    required={true}
                    isMulti={false}
                    className="mt-2 mb-3"
                    onChange={e => handleMeasuresDroughtChange(e)}
                    options={renderAPIParameterOptions(props.stageList, 'stage')}
                    isLoading={
                      props.dropdownDataLoading.key === 'stage' &&
                      props.dropdownDataLoading.isLoading
                    }
                  ></Field>
                </div>
              </div>
            )}
            <div className="data-source-field-one">
              {props?.timePeriodList?.length !== 0 && (
                <>
                  <Label htmlFor="country">Select Time Period</Label>
                  <Field
                    component={renderReactSelectInputField}
                    name="chooseTimePeriod"
                    placeholder="Choose Time Period"
                    required={true}
                    isMulti={false}
                    className="mt-2 mb-3"
                    onChange={e => handleTimePeriodChange(e)}
                    options={renderAPIParameterOptions(props.timePeriodList, 'stage')}
                    isLoading={
                      props.dropdownDataLoading.key === 'time_period' &&
                      props.dropdownDataLoading.isLoading
                    }
                  ></Field>
                </>
              )}
              <Label htmlFor="country">Select Strategy</Label>
              <Field
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
                component={renderReactSelectInputField}
                name="strategy"
                placeholder="choose Strategy"
                required={true}
                isMulti={false}
                className="mt-2 mb-3"
                onChange={e => handleStrategyChange(e)}
                options={renderAPIParameterOptions(props.strategyList, 'stage')}
                isLoading={
                  props.dropdownDataLoading.key === 'strategy' &&
                  props.dropdownDataLoading.isLoading
                }
              ></Field>
            </div>
          </TabPane>
        </TabContent>
      {/* </form> */}
    </div>
  );
};

//export default MapMeasures;

export default reduxForm({
  form: 'map-measures-form',
  enableReinitialize: true,
})(MapMeasures);
