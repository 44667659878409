import React from 'react';
import _ from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import {
  DSS_DROUGHT_PHASES_CONST,
} from '../../../Components/constants';
import { useSelector } from 'react-redux';
import { t } from 'react-multi-lang';
import AlertIndicesModal from './AlertIndicesTableModal';
import DSSDroughtIndicatorsCollapse from './DSSDroughtIndicators';
import DroughtPhaseInfoModal from './DroughtPhaseInfoModal';
import DroughtCheckModal from './DroughtCheckModal';
import WeatherForecastModal from './WeatherForecastModal';
import ReactTooltip from 'react-tooltip';

function DSSDroughtSidebar(props) {
  const {
    selectedLocation,
    isOpenAlertIndicesTable,
    isOpenWeatherForecastDataModal,
    setIsOpenWeatherForecastDataModal,
    weatherForecastData,
    isShowDroughtCheckModal,
    setIsShowDroughtCheckModal,
    selectedModule,
    thresholdDataList,
    setThresholdDataList,
  } = props;
 
  const {
    isGetDroughtAllSeasonRequesting,
  } = useSelector(state => ({
    isGetDroughtAllSeasonRequesting: state.DroughtMonitor.isGetDroughtAllSeasonRequesting,
    getDroughtAllSeasonData: state.DroughtMonitor.getDroughtAllSeasonData,
    getDroughtAllSeasonDataError: state.DroughtMonitor.getDroughtAllSeasonDataError,
    getDSSIndicesData: state.DroughtMonitor.getDSSIndicesData,
    getDSSIndicesDataError: state.DroughtMonitor.getDSSIndicesDataError,
    checkDroughtCriteriaData: state.DroughtMonitor.checkDroughtCriteriaData,
    checkDroughtCriteriaDataError: state.DroughtMonitor.checkDroughtCriteriaDataError,
    getDroughtTimeseriesData: state.DroughtMonitor.getDroughtTimeseriesData,
  }));

  return (
    <>
      {isOpenAlertIndicesTable && !_.isEmpty(props.alertTableData) ? (
        <AlertIndicesModal
          isOpen={isOpenAlertIndicesTable}
          toggle={props.handleAlertToggle}
          handleAlertToggle={props.handleAlertToggle}
          tableData={props.alertTableData}
          selectedYear={props.selectedYear}
        />
      ) : (
        ''
      )}
      {props.isShowDroughtPhaseModal ? (
        <DroughtPhaseInfoModal isOpen={props.isShowDroughtPhaseModal} toggle={props.handleDroughtPhaseModal} />
      ) : (
        ''
      )}
      {isShowDroughtCheckModal ? (
        <DroughtCheckModal
          isOpen={isShowDroughtCheckModal}
          isDrought={props.isDrought}
          selectedLocation={selectedLocation}
          toggle={() => setIsShowDroughtCheckModal(!isShowDroughtCheckModal)}
          defaultDistrict = {props.defaultDistrict}
          selectedYear = {props.selectedYear}
          selectedMonth = {props.selectedMonth}
        />
      ) : (
        ''
      )}

      {isOpenWeatherForecastDataModal ? (
        <WeatherForecastModal
          isOpen={isOpenWeatherForecastDataModal}
          cordinateAndZoom={props.cordinateAndZoom}
          toggle={() => setIsOpenWeatherForecastDataModal(!isOpenWeatherForecastDataModal)}
          weatherForecastData={weatherForecastData}
          countryFor={props?.country}
          stateFor={props?.state}
          districtFor={props?.district}
        />
      ) : (
        ''
      )}
      <>
        {selectedModule.api_slug === 'drought-decision-support' ? (
          <>
            <p className="sidebar-location bg-white pb-0">{t('DROUGHT_DSS.SELECT_MONSOON')}</p>
            <div className="mx-3 mb-3">
              <Select
                id="moonsoon"
                name="moonsoon"
                placeholder="Select Monsoon"
                options={props.monsoonList}
                onChange={props.handleMonsoonChange}
                isMulti={false}
                required={true}
                value={props.selectedMonsoon}
                isLoading={isGetDroughtAllSeasonRequesting}
              />
            </div>
            <div
              className="sidebar-location bg-white pt-0 pb-0 cursor-ponter"
              onClick={props.handleDroughtPhaseModal}
            >
              <span>{t('DROUGHT_DSS.SELECT_DROUGHT_PHASE')}</span>
              <span className="info-style">
                <i
                  className="cursor-ponter fa fa-info info-icon-edit-btn"
                  id="info-icon-tool-tip"
                  data-tip
                  data-for="info-icon-tool-tip"
                />
                <ReactTooltip id="info-icon-tool-tip" effect="solid" place="top">
                  {t('DROUGHT_MONITORING.CLICK_HERE_TO_KNOW_MORE')}
                </ReactTooltip>
              </span>
            </div>
            <div className="mx-3 mb-2">
              <Select
                id="drought-phase"
                name="drought-phase"
                placeholder="Select Drought Phase"
                options={DSS_DROUGHT_PHASES_CONST}
                onChange={props.handleSelectedDroughtPhase}
                isMulti={false}
                required={true}
                value={props.selectedDroughtPhase}
              />
            </div>

            <div className="advance-search-fields mb-2">
              <p className="sidebar-location">{t('DROUGHT_DSS.SELECT_TIMEFRAME')}</p>

              <div className="mx-3 mb-2">
                <Select
                  id="month"
                  name="month"
                  placeholder="Select Month"
                  options={props.selectedMonthList}
                  onChange={props.handleSelectedMonth}
                  isMulti={false}
                  required={true}
                  value={props.selectedMonth}
                />
              </div>
              {props.yearsList?.length ? (
                <div className="mx-3 mb-2">
                  <Select
                    id="year"
                    name="year"
                    placeholder="Select Year"
                    options={props.yearsList}
                    onChange={props.handleOnYearChange}
                    isMulti={false}
                    required={true}
                    value={props.selectedYear}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
            <p className="sidebar-indicator">Select Indicators</p>
            <DSSDroughtIndicatorsCollapse
              droughtModuleIndicators={props.DroughtModuleConsts}
              setMinStartDate={props.setMinStartDate}
              finalDroughtMap={props.finalDroughtMap}
              handleCategory={props.handleCategory}
              impactMonthsDropdownList={props.impactMonthsDropdownList}
              drySpellPrecipitationThreshold={props.drySpellPrecipitationThreshold}
              setDrySpellPrecipitationThreshold={props.setDrySpellPrecipitationThreshold}
              setIsDrought={props.setIsDrought}
              DroughtIndicators={props.DroughtIndicators}
              checkedIndicesList={props.checkedIndicesList}
              setIsShowImpactIndicators={props.setIsShowImpactIndicators}
              handleApplyLayers={props.handleApplyLayers}
              handleFinalDroughtParamMonthChange={props.handleFinalDroughtParamMonthChange}
              isDrought={props.isDrought}
              finalDroughtParameterMonthsList={props.finalDroughtParameterMonthsList}
              isSubCategory={false}
              isCategory={true}
              isCompare={false}
              selectedModule={selectedModule}
              CompareDroughtIndicators={props.CompareDroughtIndicators}
              handleCompareCategory={props.handleCompareCategory}
              handleIndices={props.handleIndices}
              handleCompareIndices={props.handleCompareIndices}
              handleCategoryToggle={props.handleCategoryToggle}
              handleImpactDropdownChange={props.handleImpactDropdownChange}
              handleDroughtIndices={props.handleDroughtIndices}
              thresholdDataList={thresholdDataList}
              setThresholdDataList={setThresholdDataList}
              handleDryspellThreshold={props.handleDryspellThreshold}
              handleSPIThreshold={props.handleSPIThreshold}
            />
            <div className="contingency-form-buttons mt-4">
              <Button
                className="contingency-plan-button apply-layers-btn"
                onClick={() => props.handleApplyLayers(true)}
                type="submit"
                disabled={!props.isApplyLayers}
              >
                {t('CONTINGENCY_PLAN.APPLY_LAYERS')}
              </Button>
            </div>

            {/* {isDrought && !isShowImpactIndicators ? (
        <>
          <div className="contingency-form-buttons mt-4 mb-2">
            <div className="apply-layers">
              <Button
                className="navyBlueBgColor h3 drought-check-style"
                onClick={() => handleImpactIndicators(!isShowImpactIndicators)}
              >
                {t('DROUGHT_DSS.CHECK_IMPACT_INDICATORS')}
              </Button>
            </div>
          </div>
        </>
      ) : (
        ''
      )} */}
            {!props.isDrought && (props?.checkDroughtConditonToggle?.length && props?.checkDroughtConditonToggle[0]?.checked) ? (
              <div className="contingency-form-buttons mt-4 mb-2">
                <div className="apply-layers">
                  <Button
                    className="navyBlueBgColor h2 drought-check-style"
                    onClick={props.handleDroughtCheck}
                    disabled={!_.isEmpty(props.selectedYear) && !_.isEmpty(props.selectedMonth) && props.selectedDroughtPhase.id === 'Active'  ? false : true}
                  >
                    {t('DROUGHT_DSS.CHECK_DROUGHT_CONDITION')}
                  </Button>
                </div>
              </div>
            ) : (
              ''
            )}
            <div className="contingency-form-buttons mt-3 ">
              <div className="dss-apply-layers">
                <Button
                  className="navyBlueBgColor h2 drought-check-style"
                  onClick={() => props.handleModalToggle()}
                  disabled={!props.showPreviewData}
                >
                  {t('DROUGHT_DSS.PREVIEW_INDICES')}
                </Button>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    </>
  );
}

export default DSSDroughtSidebar;
