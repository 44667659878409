import React, { useEffect, useState } from 'react';
import { t } from 'react-multi-lang';
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
// import { RISK_MITIGATION_CATEGORY } from '../../../Components/constants';
import CloseIcon from '../../../assets/Images/closeIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/Images/edit-icon.svg';
import { ReactComponent as UploadIcon } from '../../../assets/Images/upload-image.svg';

import { showError } from '../../../Components/Notifications';
import { MAX_FILE_SIZE } from '../../../Components/constants';

const NewPillarModal = ({
  modal,
  setModal,
  formData,
  setFormData,
  isEditable,
  onHandlePillars,
  // selectedRiskMitigationTab,
}) => {
  const [isFormValid, setIsFormValid] = useState(false);
  // const [selectedCategory, setSelectedCategory] = useState('risk_mitigation_category');
  const [imagePreview, setImagePreview] = useState(null);
  const [initialData, setInitialData] = useState({});
  const [errors, setErrors] = useState({});
  const [filename, setFilename] = useState('');

  const toggleModal = () => {
    setModal(!modal);
    setFormData({
      pillar_type: 'monitoring_early_warning',
      name: '',
      description: '',
      website_url: '',
      latitude: '',
      longitude: '',
      icon: null,
      file: null,
    });
    setImagePreview(null);
    // setSelectedCategory('risk_mitigation_category');
    setFilename('');
  };

  // useEffect(() => {
  //   if (selectedRiskMitigationTab) {
  //     setSelectedCategory(selectedRiskMitigationTab);
  //   }
  // }, [selectedRiskMitigationTab]);

  useEffect(() => {
    let isValid;
    if (['risk_impact', 'risk_mitigation'].includes(formData.pillar_type)) {
      isValid =
        formData.pillar_type &&
        formData.name &&
        formData.description &&
        formData.file &&
        !Object.values(errors).some(error => error);
    } else {
      isValid =
        formData.pillar_type &&
        formData.name &&
        formData.description &&
        formData.website_url &&
        formData.icon &&
        formData.latitude &&
        formData.longitude &&
        !Object.values(errors).some(error => error);
    }
    setIsFormValid(isValid);
  }, [formData, errors]);

  useEffect(() => {
    if (isEditable) {
      setInitialData(formData);
    }

    if (formData.file) {
      setFilename(formData.file.split('/').pop());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = e => {
    const { name, value, files } = e.target;
    let updatedFormData = {
      ...formData,
    };

    if (name === 'icon' && files && files.length) {
      const file = files[0];
      updatedFormData[name] = file;
      setImagePreview(URL.createObjectURL(file)); // Create a preview URL for the uploaded image

      e.target.value = null;
    } else if (name === 'file' && files && files.length) {
      const file = files[0];
      // 6 mb
      if (file.size > MAX_FILE_SIZE) {
        showError('File size should not exceed 5MB.');
        return;
      }
      updatedFormData[name] = file;
      setFilename(file.name);

      e.target.value = null;
    } else {
      updatedFormData[name] = value;
    }

    setFormData(updatedFormData);
    validateField(name, updatedFormData);
  };

  const validateField = (name, recentFormData) => {
    let errorMessages = {};

    switch (name) {
      case 'description':
        if (recentFormData.description.length > 600) {
          errorMessages.description = 'Limit exceeded: Maximum 600 characters.';
        } else {
          errorMessages.description = '';
        }
        break;

      default:
        break;
    }

    setErrors(prevErrors => ({
      ...prevErrors,
      ...errorMessages,
    }));
  };

  const handleRemove = type => {
    setFormData(prevData => ({
      ...prevData,
      [type]: null,
    }));

    if (type === 'icon') {
      setImagePreview(null);
    } else if (type === 'file') {
      setFilename('');
    }
  };

  const getChangedFields = () => {
    const changedFields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== initialData[key]) {
        changedFields[key] = formData[key];
      }
    });
    return changedFields;
  };

  const handleSubmit = e => {
    e.preventDefault();
    const dataToSubmit = isEditable ? getChangedFields() : formData;
    onHandlePillars(dataToSubmit);
    toggleModal();
    setInitialData({});
  };

  // const handleSelecteCategory = riskValue => {
  //   setSelectedCategory(riskValue.value);
  //   setFormData(prevFormData => ({
  //     ...prevFormData,
  //     category: riskValue.value,
  //   }));
  // };

  return (
    <div className="client-modal">
      <Modal
        isOpen={modal}
        toggle={toggleModal}
        className="dms-landing-modal"
        backdrop="static"
        keyboard={false}
      >
        <Form onSubmit={handleSubmit}>
          <ModalHeader
            className="client-detail-header justify-content-between"
            toggle={toggleModal}
          >
            {isEditable ? t('LANDING_PAGE.EDIT_PILLAR') : t('LANDING_PAGE.ADD_NEW')}
          </ModalHeader>
          <ModalBody className="events-modal-container">
            <Row>
              <Col xs={12}>
                <Label className="dms-pillar-label" style={{ fontWeight: '650' }}>
                  {t('LANDING_PAGE.CONTENT_DETAILS')}
                </Label>
                <hr style={{ border: '1px solid #E8E7E7', opacity: 1 }} className=" my-2" />
              </Col>
              <Col xs={12}>
                <FormGroup>
                  <Label for="pillar_type" className="dms-pillar-label">
                    Pillars
                    {t('LANDING_PAGE.PILLARS')}
                  </Label>
                  <Input
                    id="pillar_type"
                    name="pillar_type"
                    type="select"
                    className="dms-pillar-select"
                    onChange={handleChange}
                    value={formData.pillar_type}
                    required
                  >
                    <option value="monitoring_early_warning">
                      {' '}
                      {t('LANDING_PAGE.MONITORING_EARLY_WARNING')}
                    </option>
                    <option value="risk_impact"> {t('LANDING_PAGE.RISK_IMPACT')}</option>
                    <option value="risk_mitigation">{t('LANDING_PAGE.RISK_MITIGATION')}</option>
                  </Input>
                </FormGroup>
                {/* {formData.pillar_type === 'risk_mitigation' ? (
                  <FormGroup>
                    <Label className="dms-pillar-label">Select Category</Label>
                    <div className=" my-2">
                      {RISK_MITIGATION_CATEGORY.map(risk => (
                        <span
                          key={risk.value}
                          className={`dms-landing-page-event-catgeory ${
                            risk.value === selectedCategory ? 'dms-selected-category' : ''
                          }`}
                          onClick={() => handleSelecteCategory(risk)}
                        >
                          {risk.label}
                        </span>
                      ))}
                    </div>
                  </FormGroup>
                ) : null} */}
              </Col>
              <Col xs={12}>
                <FormGroup>
                  <Label for="name" className="dms-pillar-label">
                    {t('LANDING_PAGE.TITLE')}
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    className="dms-pillar-select"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    placeholder="Write your text here..."
                  />
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup>
                  <Label for="description" className="dms-pillar-label">
                    {t('LANDING_PAGE.DESCRIPTION')}
                  </Label>
                  <Input
                    type="textarea"
                    name="description"
                    id="description"
                    className="dms-pillar-select"
                    value={formData.description}
                    onChange={handleChange}
                    required
                    placeholder="Write your text here...(Max: 600 Characters)"
                    invalid={!!errors.description}
                  />
                  <FormFeedback tooltip>{errors.description}</FormFeedback>
                </FormGroup>
              </Col>
              {formData.pillar_type === 'monitoring_early_warning' ? (
                <>
                  <Col xs={12}>
                    <FormGroup>
                      <Label for="website_url" className="dms-pillar-label">
                        {t('LANDING_PAGE.LINK')}
                      </Label>
                      <Input
                        type="url"
                        name="website_url"
                        id="website_url"
                        className="dms-pillar-select"
                        value={formData.website_url}
                        onChange={handleChange}
                        placeholder="https://example.com"
                        disabled={isEditable ? !formData.isEditable : false}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    <Label className="dms-pillar-label" style={{ fontWeight: '650' }}>
                      {t('LANDING_PAGE.PIN_DETAILS')}
                    </Label>
                    <hr style={{ border: '1px solid #E8E7E7', opacity: 1 }} className=" my-2" />
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="latitude" className="dms-pillar-label">
                        {t('LANDING_PAGE.LATITUDE')}
                      </Label>
                      <Input
                        type="number"
                        name="latitude"
                        id="latitude"
                        className="dms-pillar-select"
                        value={formData.latitude}
                        onChange={handleChange}
                        placeholder="Ex: 37.7749"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="longitude" className="dms-pillar-label">
                        {t('LANDING_PAGE.LONGITUDE')}
                      </Label>
                      <Input
                        type="number"
                        name="longitude"
                        id="longitude"
                        className="dms-pillar-select"
                        value={formData.longitude}
                        onChange={handleChange}
                        placeholder="Ex: -122.4194"
                      />
                    </FormGroup>
                  </Col>
                </>
              ) : null}
              {['risk_impact', 'risk_mitigation'].includes(formData.pillar_type) ? (
                <Col xs={12}>
                  <FormGroup>
                    <Label for="image" className="dms-pillar-label mb-1">
                      {t('LANDING_PAGE.UPLOAD_FILE')}
                    </Label>
                    <div className="custom-input-file-container w-100">
                      {filename ? (
                        <>
                          <span>{filename}</span>
                          <img
                            src={CloseIcon}
                            alt="remove-file-icon"
                            height={16}
                            width={16}
                            className=" cursor-ponter"
                            onClick={() => handleRemove('file')}
                          />
                        </>
                      ) : (
                        <Label htmlFor="file" className="custom-input-file-label">
                          <UploadIcon width={36} height={36} />
                          <p>{t('LANDING_PAGE.UPLOAD_FILE')}</p>
                          <span>{t('LANDING_PAGE.UPLOAD_FILE_NOTE')}</span>
                        </Label>
                      )}
                      <Input
                        type="file"
                        name="file"
                        id="file"
                        accept=".pdf, .docx"
                        onChange={handleChange}
                        hidden
                      />
                    </div>
                  </FormGroup>
                </Col>
              ) : null}
              <Col
                xs={12}
                className={
                  ['risk_impact', 'risk_mitigation'].includes(formData.pillar_type) ? ' d-none' : ''
                }
              >
                <FormGroup>
                  <Label for="image" className="dms-pillar-label mb-1">
                    {t('LANDING_PAGE.UPLOAD_ICON')}
                  </Label>
                  <div className="custom-input-file w-100">
                    {imagePreview || formData.icon ? (
                      <div className="image-preview-container">
                        <img
                          src={imagePreview || formData.icon}
                          alt="Profile Preview"
                          className="image-preview"
                        />
                        <div className="image-overlay"></div>
                        <button
                          type="button"
                          className="dms-delete-file"
                          onClick={() => handleRemove('icon')}
                        >
                          <DeleteIcon width={20} height={20} />
                        </button>
                      </div>
                    ) : (
                      <Label htmlFor="icon" className="custom-input-file-label">
                        <UploadIcon width={36} height={36} />
                        <p>{t('LANDING_PAGE.UPLOAD_IMAGE')}</p>
                        <span>{t('LANDING_PAGE.UPLOAD_IMAGE_NOTE')}</span>
                      </Label>
                    )}
                    <Input
                      type="file"
                      name="icon"
                      id="icon"
                      accept=".jpg,.jpeg,.png"
                      onChange={handleChange}
                      hidden
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="modal-viewdeatil-footer">
            <button onClick={toggleModal} className="reject-button">
              {t('LANDING_PAGE.CANCEL')}
            </button>{' '}
            <button onClick={handleSubmit} disabled={!isFormValid} className="approve-button">
              {isEditable ? t('LANDING_PAGE.SAVE') : t('LANDING_PAGE.ADD')}
            </button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default NewPillarModal;
