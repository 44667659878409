import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { FaRegTrashAlt } from 'react-icons/fa';
import { GrFormClose } from 'react-icons/gr';
import { t } from 'react-multi-lang';

const DeleteModal = ({ isOpen, toggle, message , onHandleDelete}) => {
  return (
    <div className="backoffice-delete-modal" id="backoffice-delete-modal">
      <Modal
        className="modal-backoffice"
        isOpen={isOpen}
        toggle={() => toggle()}
        centered
        size="md"
      >
        <ModalBody className="text-center delete-modal-body">
          <div style={{ display: 'flex', justifyContent: 'flex-end' }} className="cursor-pointer">
            <GrFormClose size={25} onClick={() => toggle()} />
          </div>
          <FaRegTrashAlt size={30} color="red" />

          <div className="mt-3 text-dark">
            <p className="delete-modal-title">{t('BULLET_IN.ARE_YOU_SURE')}</p>
          </div>
          <div className="mt-3 delete-modal-message">{message}</div>
          <div className="  mt-3">
            <button className="delete-modal-cancle mx-2" onClick={() => toggle()}>
              {t('USER_GUIDE.CANCEL')}
            </button>
            <button className="delete-modal-submit mx-2" onClick={() => onHandleDelete()}>Delete</button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default DeleteModal;
