// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, Fragment } from 'react';
import { Route, Switch, Redirect, withRouter, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container } from 'reactstrap';
import NewHeader from '../Components/common/Navbar';
import Routes from '../routes/routes';
import { ToastContainer } from 'react-toastify';
import ReactGA from 'react-ga4';

if (window.location.hostname === 'dmsdemo.iwmi.org') ReactGA.initialize('G-52F4LBVC73');

const Layout = () => {
  // eslint-disable-next-line no-unused-vars
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const setLoader = isLoading => {
    setIsLoaderVisible(isLoading);
  };
  const location = useLocation();

  // Dismiss all notifications when the route changes
  useEffect(() => {
    toast.dismiss();
  }, [location]);

  useEffect(() => {
    setIsLoaderVisible(false);
    setLoader();
  }, []);

  useEffect(() => {
    if (window.location.hostname === 'dmsdemo.iwmi.org')
      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname + window.location.search,
      });
  });

  const getRoutes = routeItems => {
    const routes = routeItems.map((prop, key) => {
      return (
        <Route
          path={prop.path}
          // render={() => <prop.component loader={setLoader} />}
          component={prop.component}
          // eslint-disable-next-line react/no-array-index-key
          key={key}
        />
      );
    });
    return routes;
  };
  const containerStyle = {
    display: 'block',
    padding: '0px',
  };
  return (
    <>
      <Fragment>
        {isLoaderVisible ? <span>loader</span> : ''}
        <Container className="container-layout" fluid style={containerStyle}>
          <NewHeader />
          <Switch>
            {getRoutes(Routes)}
            <Redirect from="/" to="/drought-management-system?Country=mali" />
          </Switch>
        </Container>
      </Fragment>
      <ToastContainer />
    </>
  );
};

export default withRouter(Layout);