import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RightArrow from "../../assets/Images/back-button.svg";
import ManagesidebarCrops from '../Managecrops/sidebar';
import Addmodalcrop from '../Managecrops/Addmodalcrop';
import { useHistory } from 'react-router-dom';
import {
  manageCropsListRequest,
  manageCropsSeasonsRequest,
  manageCropsListByIdRequest,
  manageCropsDeleteRequest,
  manageCropsListByIdSuccess
} from '../../redux/manageCrops/manageCropsAction';
import { TableList } from './tableList';
import Loader from '../../Components/common/loader';
import { t } from 'react-multi-lang';
import { getCountryRequest } from '../../redux/actions';
import {userDetailRequest } from "../../redux/user-list/userListAction"
import {
  manageCropsAddRequest,
  manageCropsAddSuccess,
  manageCropsDeleteSuccess,
  manageCropsUpdateRequest,
  manageCropsUpdateSuccess
} from '../../redux/manageCrops/manageCropsAction';
import { isSuperAdmin, isStaffUser } from '../../Components/common/utils';
import DeleteConfirmationModal from './DeleteConfirmation';
import { LOCATION, MONTHS } from '../../Components/constants';
import { showSuccess } from '../../Components/Notifications';

const ManageCrops = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [manageCropsListData, setManageCropsList] = useState();
  const [manageCropsSeasonsList, setManageCropsSeasonsList] = useState();
  const [countryList, setCountryList] = useState();
  const [stateList, setStateList] = useState();
  const [districtList, setDistrictList] = useState();
  const [userDetailData, setUserDetailData] = useState();
  //const [updatedPermissions,setUpdatedPermissions] = useState();
  const [selectedDropdown, setSelectedDropdown] = useState('country');
  const [addModalDisabled, setAddModalDisabled] = useState(true);
  const [modal, setModal] = useState(false);
  const [recordsCount, setRecordsCount] = useState();
  const [hideAddButton,setHideAddButton] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [selectedDistrict, setSelectedDistrict] = useState();
  const [addButtonDisabled, setAddButtonDisabled] = useState(true);
  const [breadCrumbsData, setBreadCrumbsData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [pageLimit, setPageLimit] = useState(10);
  const [modalData, setModalData] = useState();
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [editButtonClicked, setEditButtonClicked] = useState(false);
  const [selectedDeleteRowId, setSelectedDeleteRowId] = useState();
  const [editButtonDisabled, setEditButtonDisabled] = useState(true);
  const [sideBarInitialValues,setSideBarInitialValues] = useState();
  const [updateClicked, setUpdateClicked] = useState(false);
  const [formData, setFormData] = useState({
    season: '',
    type: '',
    type_id: '',
    start_month: '',
    start_day: '',
    end_month: '',
    end_day: ''
  });
  const nextProps = useSelector((state) => ({
    manageCropsList: state.ManageCrops.manageCropsListData || null,
    isManageCropsListRequesting: state.ManageCrops.isManageCropsListRequesting,
    manageCropsSeasonsData: state.ManageCrops.manageCropsSeasonsData || null,
    CountryListData: state.Location.countryList || null,
    isManageCropsAddRequesting: state.ManageCrops.isManageCropsAddRequesting,
    manageCropsAddData: state.ManageCrops.manageCropsAddData || null,
    manageCropsDataById: state.ManageCrops.manageCropsListByIdData || null,
    manageCropsDeleteData: state.ManageCrops.manageCropsDeleteData || null,
    manageCropsUpdatedata: state.ManageCrops.manageCropsUpdateData || null,
    manageCropsListError: state.ManageCrops.manageCropsListDataError || null,
    manageCropsAddDataError: state.ManageCrops.manageCropsAddDataError,
    userDetailData: state.userList.userDetailData || null,
  }));

  useEffect(() => {
    if (isSuperAdmin() || isStaffUser()) {
      dispatch(getCountryRequest({ location_type: LOCATION.Country, parent_id: '' }));
      dispatch(manageCropsListRequest({ page: pageNumber, limit: pageLimit }));
      dispatch(manageCropsSeasonsRequest());
    } else{
      history.push('/drought-management-system?Country=mali');
    }
    dispatch(userDetailRequest());
  }, []);

  useEffect(() => {
   if(nextProps.userDetailData){
    let a = [];
     const {
      data: { result },
    } = nextProps.userDetailData;
    if(result){
      result.user_permissions.map((i) => {
        a.push(i.codename);
      })
      if(isSuperAdmin() || a?.includes("add_crop")){
        setHideAddButton(true);
      }
      setUserDetailData(a);
    }
   }
  },[nextProps.userDetailData])

  useEffect(() => {
    if(nextProps.manageCropsListError){
        const {
            data: { response },
          } = nextProps.manageCropsListError;
          if(response){
            setRecordsCount(0);
            setManageCropsList([]);
            setPageCount(0);
          }
    }
  },[nextProps.manageCropsListError])

  useEffect(() => {
    if (nextProps.manageCropsAddData) {
      const {
        data: { result },
      } = nextProps.manageCropsAddData;
      if(result){
      showSuccess(result);
      setModal(false);
      setBreadCrumbsData({country:"",state:"",district:""})
      setSelectedCountry('');
      setSelectedState('');
      setSelectedDistrict('');
      setStateList([]);
      setDistrictList([]);
      let sideBarInitialValuesList= {
        country:"",state:"",district:""
      }
      setSideBarInitialValues(sideBarInitialValuesList);
      let filterInitialValues = {
        season: {
          value: '',
          label: '',
          id: '',
        },
        breadCrumbs: {},
      };
      setModalData(filterInitialValues)
      setFormData({
        season: '',
        type: '',
        type_id: '',
        start_month: '',
        start_day: '',
        end_month: '',
        end_day: ''
      })
      dispatch(manageCropsListRequest({ page: pageNumber, limit: 10 }));
    }
    }
  }, [nextProps.manageCropsAddData]);


  useEffect(() => {
    if (nextProps.manageCropsDeleteData) {
      const {
        data: { result },
      } = nextProps.manageCropsDeleteData;
      if(result){
      showSuccess(result);
      setPageNumber(1);
      dispatch(manageCropsListRequest({page: 1, limit: 10 }));
      }
    }
  }, [nextProps.manageCropsDeleteData]);

  useEffect(() => {
    if (nextProps.manageCropsUpdatedata) {
      const {
        data: { result },
      } = nextProps.manageCropsUpdatedata;
      if(result){
      showSuccess(result);
      setModal(false);
      setSelectedCountry('');
      setSelectedState('');
      setSelectedDistrict('');
      setStateList([]);
      setDistrictList([]);
      setAddModalDisabled(true);
      let sideBarInitialValuesList= {
        country:"",state:"",district:""
      }
      setSideBarInitialValues(sideBarInitialValuesList);
      setFormData({
        season: '',
        type: '',
        type_id: '',
        start_month: '',
        start_day: '',
        end_month: '',
        end_day: ''
      })
      dispatch(manageCropsListRequest({ page: pageNumber, limit: 10 }));
      }
    }
  }, [nextProps.manageCropsUpdatedata]);

  useEffect(() => {
    return () => {
      dispatch(manageCropsAddSuccess({}));
      dispatch(manageCropsDeleteSuccess({}));
      dispatch(manageCropsListByIdSuccess({}));
      dispatch(manageCropsUpdateSuccess({}));
    }
  },[])

  useEffect(() => {
    if (nextProps.manageCropsDataById) {
      if (nextProps.manageCropsDataById.data.result) {
        const {
          data: { result },
        } = nextProps.manageCropsDataById;
        if (result) {
          let filterInitialValues = {
            season: {
              value: result[0].season_name,
              label: result[0].season_name,
              id: result[0].season_id,
            },
            from_month: {
              value:result[0].start_month,
              label: MONTHS.find((i)=> i.value === result[0].start_month)?.label,
              id: result[0].start_month,
            },
            from_day: {
              value:result[0].start_day, 
              label:result[0].start_day,
              id:result[0].start_day,
            },
            to_month: {
              value:result[0].end_month,
              label:MONTHS.find((i)=> i.value === result[0].end_month)?.label,
              id:result[0].end_month,
            },
            to_day: {
              value:result[0].end_day ,
              label:result[0].end_day,
              id:result[0].end_day
            },
            breadCrumbs: result[0].location_name,
          };
          formData.season = result[0].season_id;
          formData.start_day = result[0].start_day;
          formData.start_month = result[0].start_month;
          formData.end_day = result[0].end_day;
          formData.end_month = result[0].end_month;
          formData.type = result[0].type;
          formData.type_id = result[0].type_id;
          formData.id = result[0].id;
          setFormData({ ...formData });
          setModal(true);
          setModalData(filterInitialValues);
        }
      }
    }
  }, [nextProps.manageCropsDataById]);

  useEffect(() => {
    if (nextProps.CountryListData) {
      const { result } = nextProps.CountryListData;
      if (result) {
        if (selectedDropdown === 'country') {
          setCountryList(result);
        } else if (selectedDropdown === 'state') {
          setStateList(result);
        } else if (selectedDropdown === 'district') {
          setDistrictList(result);
        }
      }
    }
  }, [nextProps.CountryListData]);
  useEffect(() => {
    if (nextProps.manageCropsList) {
      const {
        data: { result },
      } = nextProps.manageCropsList;
      if (result) {
        setManageCropsList(result.results);
        setRecordsCount(result.results.length);
        setPageCount(result.count);
      }
    }
  }, [nextProps.manageCropsList]);

  useEffect(() => {
    if (nextProps.manageCropsSeasonsData) {
      const {
        data: { result },
      } = nextProps.manageCropsSeasonsData;
      if (result) {
        let list = [];
        result.map((i) => {
          list.push({
            value: i.id,
            label: i.name,
          });
        });
        setManageCropsSeasonsList(list);
      }
    }
  }, [nextProps.manageCropsSeasonsData]);

  useEffect(() => {
    if (formData.season !== '' && formData.start_day !== '' && formData.end_day !== '') {
      setAddButtonDisabled(false);
    }
  }, [formData]);

  useEffect(() => {
    if(formData.type_id === ''){
      dispatch(manageCropsListRequest({ page: pageNumber, limit: pageLimit }));
    }
  }, [pageLimit, pageNumber]);

  const handleSelectedSeason = (item) => {
    if (editButtonClicked) {
      setEditButtonDisabled(false);
    }
    formData.season = item.id;
    setFormData({ ...formData });
  };

   const handleFromMonth = (item) => {
    if (editButtonClicked) {
      setEditButtonDisabled(false);
    }
    formData.start_month = item.id;
    setFormData({ ...formData });
  };

  const handleFromDay = (item) => {
    if (editButtonClicked) {
      setEditButtonDisabled(false);
    }
    formData.start_day = item.id;
    setFormData({ ...formData });
  };

  const handleToMonth = (item) => {
    if (editButtonClicked) {
      setEditButtonDisabled(false);
    }
    formData.end_month = item.id;
    setFormData({ ...formData });
  };

  const handleToDay = (item) => {
    if (editButtonClicked) {
      setEditButtonDisabled(false);
    }
    formData.end_day = item.id;
    setFormData({ ...formData });
  };

  const handlePageSize = (value) => {
    setPageLimit(value);
    setPageNumber(1);
  };

  const handleUpdate = () => {
    setUpdateClicked(true);
    setEditButtonDisabled(true);
    dispatch(manageCropsUpdateRequest(formData));
  };

  const handleDeleteConfirm = (bool_value, value) => {
    setDeleteConfirmModal(bool_value);
    if (value === 'yes') {
      dispatch(manageCropsDeleteRequest({ id: selectedDeleteRowId }));
    }
  };
  const handleSelectedCountry = (newValue, action) => {
      setSelectedState("");
      setSelectedDistrict("");
      if(newValue.value === 'select'){
        setSelectedCountry('');
        setStateList([]);
        setDistrictList([]);
        formData.type = '';
        formData.type_id = '';
        dispatch(
          manageCropsListRequest({
            type: '',
            type_id: '',
            page: 1,
            limit: pageLimit,
          }),
        );
        setAddModalDisabled(true);
      }else{
    setSelectedCountry(newValue);
    setSelectedDropdown('state');
    formData.type = 'country';
    formData.type_id = newValue.value;
    setFormData({ ...formData });
    dispatch(
      manageCropsListRequest({
        type: 'country',
        type_id: newValue.value,
        page: 1,
        limit: pageLimit,
      }),
    );
    dispatch(getCountryRequest({ location_type: LOCATION.State, parent_id: newValue.value }));
    setAddModalDisabled(false);
    setPageNumber(1);
  }
  };

  const handlePageNumber = (value) => {
    formData.type = '';
    formData.type_id = '';
    setFormData(formData);
    setPageNumber(value);
  };

  const handleBreadCrumbsData = (values) => {
    setBreadCrumbsData(values);
  };

  const handleSelectedState = (newValue, action) => {
    setSelectedDistrict("");
    if(newValue.value === 'select'){
      setSelectedState('');
      formData.type = 'country';
      formData.type_id = selectedCountry.value;
      setFormData({ ...formData });
      setDistrictList([]);
      dispatch(
        manageCropsListRequest({
          type: 'country',
          type_id: selectedCountry.value,
          page: pageNumber,
          limit: pageLimit,
        }),
      );
    }else{
    setSelectedState(newValue);
    setSelectedDropdown('district');
    formData.type = 'state';
    formData.type_id = newValue.value;
    setFormData({ ...formData });
    dispatch(
      manageCropsListRequest({
        type: 'state',
        type_id: newValue.value,
        page: pageNumber,
        limit: pageLimit,
      }),
    );
    dispatch(getCountryRequest({ location_type: LOCATION.District, parent_id: newValue.value }));
    setAddModalDisabled(false);
  }
  };

  const handleSelectedDistrict = (newValue, action) => {
    if(newValue.value === 'select'){
      setSelectedDistrict('');
      formData.type = 'state';
      formData.type_id = selectedState.value;
      setFormData({ ...formData });
      dispatch(
        manageCropsListRequest({
          type: 'state',
          type_id: selectedState.value,
          page: pageNumber,
          limit: pageLimit,
        }),
      );
    }
    else{
    setSelectedDistrict(newValue);
    formData.type = 'district';
    formData.type_id = newValue.value;
    setFormData({ ...formData });
    dispatch(
      manageCropsListRequest({
        type: 'district',
        type_id: newValue.value,
        page: pageNumber,
        limit: pageLimit,
      }),
    );
    setAddModalDisabled(false);
    }
  };

  const handleAddClick = (value) => {
    if (value === 'add') {
      dispatch(manageCropsAddRequest(formData));
      setAddModalDisabled(true);
    } else if (value === 'cancel') {
      setAddModalDisabled(true);
    }
  };

  const handleSetModal = (value) => {
    setModal(value);
    setEditButtonClicked(false);
    setEditButtonDisabled(true);
    setAddButtonDisabled(true);
  };

  const handleResetInitialValues = () => {
    let filterInitialValues = {
      season: { value: '', label: '', id: '' },
      breadCrumbs: '',
      from_month: '',
      from_day: '',
      to_month: '',
      to_day: '',
    };
    setModalData(filterInitialValues);
  };

  const handleRowClick = (value) => {
    setEditButtonClicked(true);
    dispatch(manageCropsListByIdRequest({ id: value.id }));
  };

  const handleDeleteById = (value) => {
    setDeleteConfirmModal(true);
    setSelectedDeleteRowId(value.id);
  };

  const handleManageCropsBackButton = () => {
      history.push("/settingsDashboard");
  }


  return (
    <div className="crop-top">
      <div className="crop-header">
        <img src={RightArrow} className="manage-crops-header-back-button" onClick={() => handleManageCropsBackButton()}/>
        <h3 className="crops-heading">{t('MANAGE_CROP.MANAGE_CROP')}</h3>
        <Addmodalcrop
          handleResetInitialValues={handleResetInitialValues}
          seasonsList={manageCropsSeasonsList && manageCropsSeasonsList}
          handleSelectedSeason={handleSelectedSeason}
          handleFromMonth={handleFromMonth}
          handleFromDay={handleFromDay}
          handleToMonth={handleToMonth}
          handleToDay={handleToDay}
          addModalDisabled={addModalDisabled}
          handleAddClick={handleAddClick}
          modal={modal}
          handleSetModal={handleSetModal}
          isManageCropsAddRequesting={nextProps.isManageCropsAddRequesting}
          addButtonDisabled={addButtonDisabled}
          editButtonDisabled={editButtonDisabled}
          breadCrumbsData={breadCrumbsData}
          initialValues={modalData}
          editButtonClicked={editButtonClicked}
          handleUpdate={handleUpdate}
          hideAddButton={hideAddButton}
        />
      </div>
      <div>
        <ManagesidebarCrops
          countryList={countryList && countryList}
          handleSelectedCountry={handleSelectedCountry}
          stateList={stateList && stateList}
          handleSelectedState={handleSelectedState}
          districtList={districtList && districtList}
          handleSelectedDistrict={handleSelectedDistrict}
          selectedCountry={selectedCountry}
          selectedState={selectedState}
          selectedDistrict={selectedDistrict}
          handleBreadCrumbsData={handleBreadCrumbsData}
          initialValues={sideBarInitialValues}
        />
        {nextProps.isManageCropsListRequesting && <Loader />}
        {manageCropsListData && (
          <TableList
            req_data={manageCropsListData && manageCropsListData}
            pageNumber={pageNumber}
            pageCount={pageCount}
            recordsCount={recordsCount}
            handlePageSize={handlePageSize}
            handlePageNumber={handlePageNumber}
            handleRowClick={handleRowClick}
            handleDeleteById={handleDeleteById}
            userDetailData={userDetailData}
          />
        )}
        {deleteConfirmModal && (
          <DeleteConfirmationModal
            isDeleteModalOpen={deleteConfirmModal}
            handleDeleteConfirm={handleDeleteConfirm}
          />
        )}
      </div>
    </div>
  );
};
export default ManageCrops;