import classnames from 'classnames';
import L from 'leaflet';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import {
  LayerGroup,
  LayersControl,
  MapContainer,
  Marker,
  TileLayer,
  useMap,
  useMapEvents,
} from 'react-leaflet';
import { t } from 'react-multi-lang';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';

import timeIcon from '../../../assets/Images/dis-timline-icon.svg';
import loader from '../../../assets/Images/green-loading.gif';
import { ReactComponent as RightArrow } from '../../../assets/Images/right-arrow.svg';
import updateIcon from '../../../assets/Images/update-icon.svg';
import { isLogedIn } from '../../../Components/common/utils';
import { DATE_FORMAT } from '../../../Components/constants';
import { EventDateFormatter } from '../../../Components/helpers';
import { showError } from '../../../Components/Notifications';
import myConstClass from '../../../Constants';
import {
  getDroughtMapFailed,
  getDroughtMapRequest,
  getDroughtMapSuccess,
  getIndicesRequest,
  getIndicesSuccess,
} from '../../../redux/actions';
import CustomLandingPageLegend from './CustomLandingPageLegend';
import DiscreteLandingPageLegend from './DiscreteLandingPageLegend';
import GeoJsonMapContainer from './GeoJsonMapContainer';

function DroughtInsights({
  cordinateAndZoom,
  countryData,
  indicesData,
  isLoading,
  setCordinateAndZoom,
  setIsLoading,
  geoJsonData,
}) {
  const { Overlay } = LayersControl;
  const history = useHistory();
  const dispatch = useDispatch();
  const layerRef = useRef([]);
  const wrap = useRef();
  const [selectedInsight, setSelectedInsight] = useState('drought-monitor');
  const [mapDates, setMapDates] = useState({ startDate: new Date(), endDate: new Date() });
  const [mapData, setMapData] = useState('');
  const [legend, setLegend] = useState([]);
  const [paramName, setParamName] = useState('');
  const [indexLayers, setIndexLayers] = useState([]);
  const [mapes, setMapes] = useState(null);
  const [opacityControl, setOpacityControl] = useState('');

  const { DroughtMonitor } = useSelector(state => ({
    DroughtMonitor: state.DroughtMonitor,
  }));

  // for loader
  const LoaderIcon = new L.Icon({ iconUrl: loader, iconSize: [80, 80] });

  useEffect(() => {
    return () => {
      dispatch(getDroughtMapFailed({}));
      dispatch(getDroughtMapSuccess({}));
      dispatch(getIndicesSuccess({}));
    };
  }, []);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    if (!_.isEmpty(indicesData)) {
      const ref2 = React.createRef();
      layerRef.current.push(ref2);
      if (selectedInsight === 'drought-monitor') {
        const { data } = indicesData;
        const { result } = data;
        const filteredCategory = result.filter(category => category.name === 'Drought');
        const { parameter } = filteredCategory[0];
        const filteredParamter = parameter.filter(param => param.name === 'Dry Spell');
        const { max_date, api_url, master_server } = filteredParamter?.[0];
        setParamName('Dry Spell');
        if (max_date && api_url) {
          setMapDates(prev => ({
            ...prev,
            endDate: moment(max_date).format(DATE_FORMAT),
            startDate: moment(max_date)
              .subtract(30, 'days')
              .format(DATE_FORMAT),
          }));

          const payload = {
            // country_name: countryData.country_name,
            end_date: moment(max_date).format(DATE_FORMAT),
            start_date: moment(max_date)
              .subtract(30, 'days')
              .format(DATE_FORMAT),
            temporal_aggregation: 'mean',
            precipitation_threshold: 2.5,
          };
          if (countryData?.country_name !== 'South Asia') {
            payload.country_name = countryData.country_name;
          } else {
            payload.region_id = 'e1eb8a52-2b98-4001-a7c2-7ff332725984';
          }
          let isDroughtModule = true;
          if (api_url && !_.isEmpty(countryData)) {
            dispatch(getDroughtMapRequest(api_url, payload, isDroughtModule, master_server));
          }
        }
      } else {
        const { data } = indicesData;
        const { result } = data;
        const { parameter } = result[0];
        const filteredParamter = parameter.filter(param => param.name === 'SPI');
        const { max_date, api_url, master_server, name, id } = filteredParamter?.[0];
        setParamName(name);
        if (max_date && api_url) {
          setMapDates(prev => ({
            ...prev,
            endDate: moment(max_date)
              .add(9, 'days')
              .format(DATE_FORMAT),
            startDate: moment(new Date()).format(DATE_FORMAT),
          }));

          const payload = {
            // country_name: countryData.country_name,
            end_date: moment(max_date).format(DATE_FORMAT),
            start_date: moment(max_date)
              .subtract(30, 'days')
              .format(DATE_FORMAT),
            temporal_aggregation: 'mean',
            month: '03',
            parameter_id: id,
          };

          if (countryData?.country_name !== 'South Asia') {
            payload.country_name = countryData.country_name;
          } else {
            payload.region_id = 'e1eb8a52-2b98-4001-a7c2-7ff332725984';
          }

          let isDroughtModule = true;
          if (api_url && !_.isEmpty(countryData)) {
            dispatch(getDroughtMapRequest(api_url, payload, isDroughtModule, master_server));
          }
        }
      }
    }

    return () => {
      dispatch(getDroughtMapSuccess({}));
    };
  }, [dispatch, indicesData]);

  const removeLayers = selectedParamRef => {
    if (wrap && wrap?.current && selectedParamRef && selectedParamRef?.current) {
      const map1 = wrap.current;

      const firstLayer = selectedParamRef.current;

      [firstLayer].forEach(layer => map1.removeLayer(layer));
    }
  };

  const removeMapLayers = selectedParamLegendData => {
    let previousParamRef = '';
    if (layerRef?.current?.length) {
      layerRef.current.map((i, index) => {
        previousParamRef = layerRef.current[index];
        removeLayers(previousParamRef);
        return null;
      });
    }

    const legendArray = [...selectedParamLegendData];
    if (selectedParamLegendData.length) {
      // eslint-disable-next-line no-shadow
      legendArray.forEach(value => {
        if (value.add) {
          value.add = false;
        }
      });
    }
    setLegend(legendArray);
  };

  const addLayers = selectedParamRef => {
    if (wrap.current && selectedParamRef.current) {
      const leafletMapRef = wrap.current;
      const firstLayer = selectedParamRef.current;
      [firstLayer].forEach(layer => {
        leafletMapRef.addLayer(layer);
      });
    }
    return false;
  };

  const isMapData = useRef(true);
  useEffect(() => {
    if (isMapData.current) {
      isMapData.current = false;
      return;
    }
    if (DroughtMonitor.isGetDroughtMapDataRequesting) {
      setIsLoading(true);
    }
    if (DroughtMonitor.getDroughtMapData) {
      removeMapLayers(legend);
      const { response } = DroughtMonitor.getDroughtMapData;
      if (Array.isArray(response?.data)) {
        if (response?.isDroughtModule) {
          setMapData(response?.data);
        }
      } else {
        const { result } = response?.isDroughtModule ? response?.data : { result: '' };
        setMapData(result);
      }
      setIsLoading(false);
    } else if (DroughtMonitor.getDroughtMapDataError) {
      const {
        data: { response },
      } = DroughtMonitor.getDroughtMapDataError?.response;
      if (
        response === undefined &&
        DroughtMonitor.getDroughtMapDataError?.response?.data?.message === 'Network Error'
      ) {
        showError(t('DROUGHT_MONITORING.API_FAILURE_ERROR'));
      }
      if (response?.data?.exception) {
        showError(response?.data?.exception);
      } else if (response?.data?.code === 400) {
        removeMapLayers(legend);
        if (!_.isEmpty(response?.data?.errors)) {
          let errorsObj = response?.data?.errors;
          if (!_.isEmpty(errorsObj)) {
            const values = Object.values(errorsObj);
            showError(values[0]);
          }
        } else {
          showError(response?.data?.message);
        }
      }
      setIsLoading(false);
    } else if (DroughtMonitor.getDroughtMapDataNetworkError) {
      setIsLoading(false);
    }
  }, [
    DroughtMonitor.getDroughtMapData,
    DroughtMonitor.isGetDroughtMapDataRequesting,
    DroughtMonitor.getDroughtMapDataError,
    DroughtMonitor.getDroughtMapDataNetworkError,
  ]);

  useEffect(() => {
    if (!_.isEmpty(mapData)) {
      const currentParamRef = layerRef.current[0];
      if (currentParamRef && currentParamRef.current !== null) {
        currentParamRef.current?.eachLayer(layer => {
          if (mapData?.map_data?.map_url) {
            layer.setUrl(mapData?.map_data?.map_url);
            layer.setOpacity(1);
            const updatedIndexLayer = _.cloneDeep(indexLayers);
            updatedIndexLayer.push({ isCompare: false, layer: layer });
            setIndexLayers(updatedIndexLayer);
          }
        });
      }

      const updateLegend = [...legend];
      let legendObj = {};
      legendObj = { ...mapData?.map_data };
      legendObj.parameter = paramName;
      legendObj.measure = '';
      legendObj.dataSource = 'Drought';
      legendObj.isCompare = false;
      legendObj.id = paramName;
      legendObj.fromMapPreferences = false;
      legendObj.descret_legend = mapData?.map_data?.descret_legend;
      legendObj.selectedDates = {
        startDate: moment(mapDates.startDate).format('DD-MM-YYYY'),
        endDate: moment(mapDates.endDate).format('DD-MM-YYYY'),
      };

      legendObj.add = true;
      updateLegend.push(legendObj);
      setLegend(updateLegend);

      if (!_.isEmpty(currentParamRef) && currentParamRef?.current !== null) {
        addLayers(currentParamRef);
      }
    }
  }, [mapData]);

  function addOpacitySlider() {
    if (indexLayers?.length) {
      L.Control.OpacitySlider = L.Control.extend({
        sliding: false,
        updateLayerOpacity(opacity) {
          const lastIndex = indexLayers.length - 1;
          if (indexLayers?.length >= 2) {
            const secondLastIndex = indexLayers.length - 2;
            if (indexLayers[lastIndex]?.isCompare && indexLayers[secondLastIndex]?.isCompare) {
              indexLayers.forEach((item, i) => {
                if (i === lastIndex || i === secondLastIndex) item?.layer.setOpacity(opacity / 100);
              });
            } else {
              indexLayers.forEach((item, i) => {
                if (i === lastIndex) {
                  item?.layer.setOpacity(opacity / 100);
                }
              });
            }
          } else {
            indexLayers.forEach((item, i) => {
              if (i === lastIndex) {
                item?.layer?.setOpacity(opacity / 100);
              }
            });
          }
        },
        onAdd(map) {
          if (opacityControl) {
            map?.removeControl(opacityControl);
          }
          const container = L.DomUtil.create('div', 'lp-opacity-container');
          container.innerHTML =
            '<div id=layer-opacity-control><input type="range" min="1" max="100" value="100" defaultValue="100" class="opacityslider"></div>';
          if (!L.Browser.touch) {
            L.DomEvent.disableClickPropagation(container);
            L.DomEvent.on(container, 'mousewheel', L?.DomEvent?.stopPropagation);
          } else {
            L.DomEvent.on(container, 'mousedrag click', L?.DomEvent?.stopPropagation);
          }
          const slider = container.querySelector('.opacityslider');
          slider.onclick = e => {
            // eslint-disable-next-line react/no-this-in-sfc
            this.updateLayerOpacity(e.target.value);
          };
          slider.onmousedown = e => {
            // eslint-disable-next-line react/no-this-in-sfc
            this.sliding = true;
            // eslint-disable-next-line react/no-this-in-sfc
            this.updateLayerOpacity(e.target.value);
          };
          slider.ontouchmove = e => {
            // eslint-disable-next-line react/no-this-in-sfc
            this.sliding = true;
            // eslint-disable-next-line react/no-this-in-sfc
            this.updateLayerOpacity(e.target.value);
          };
          slider.onmousemove = e => {
            e?.stopPropagation();
            // eslint-disable-next-line react/no-this-in-sfc
            if (this.sliding) this.updateLayerOpacity(e.target.value);
          };
          slider.onmouseup = () => {
            // eslint-disable-next-line react/no-this-in-sfc
            this.sliding = false;
          };
          return container;
        },
      });
      L.control.opacitySlider = opts => {
        return new L.Control.OpacitySlider(opts);
      };
      const customOpacityControl = L.control.opacitySlider({
        position: 'topright',
      });

      // eslint-disable-next-line no-undef, no-underscore-dangle
      if (!_.isEmpty(mapes) && !_.isEmpty(mapes?._layers)) {
        customOpacityControl?.addTo(mapes);
      }
      setOpacityControl(customOpacityControl);
    }
  }

  const resetOpacity = () => {
    indexLayers.map(eachLayer => {
      eachLayer?.layer.setOpacity(1);
      return null;
    });
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (!_.isEmpty(indexLayers)) {
      resetOpacity(indexLayers);
      addOpacitySlider();
      if (indexLayers?.length) {
        indexLayers.map(l => l?.layer?.bringToFront());
      }
    }
  }, [indexLayers]);

  function ChangeView({ center, zoom }) {
    const mapEvents = useMapEvents({
      zoomend: () => {
        setCordinateAndZoom(prev => ({
          ...prev,
          cordinates: mapEvents.getCenter(),
          zoom: mapEvents.getZoom(),
        }));
      },
      dragend: () => {
        setCordinateAndZoom(prev => ({
          ...prev,
          zoom: mapEvents.getZoom(),
          cordinates: mapEvents.getCenter(),
        }));
      },
    });

    const map = useMap();
    setInterval(() => {
      if (!_.isEmpty(map) && !_.isEmpty(map?._layers)) map.invalidateSize();
    }, 500);
    map.setView(center, zoom);
    return null;
  }

  useEffect(() => {
    setMapes(wrap.current);
  }, [wrap.current]);

  const setMapReference = mapInstance => {
    wrap.current = mapInstance;
  };

  const renderParamLayer = () => {
    if (mapData?.map_data) {
      return (
        <>
          {/* TODO: need to create separate component */}
          <Overlay>
            <LayerGroup ref={layerRef.current[0]}>
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url=""
              />
            </LayerGroup>
          </Overlay>
        </>
      );
    }
  };

  const handleSelectInsight = (evt, name) => {
    evt.preventDefault();
    setSelectedInsight(name);
    removeMapLayers(legend);
    dispatch(
      getIndicesRequest({
        requestObj: {
          type: 'country',
          // type_id:  countryData?.country_id
          type_id:
            countryData?.country_name !== 'South Asia'
              ? countryData?.country_id
              : 'e1eb8a52-2b98-4001-a7c2-7ff332725984', // ID of Bali
        },
        module: name,
      }),
    );
  };

  const handleRedirectToApp = () => {
    const result = countryData?.country_slug;

    if (isLogedIn()) {
      history.push({
        pathname: `/drought-monitor`,
        search: `?Country=${result}`,
        state: {
          countryData: countryData,
        },
      });
    } else {
      history.push({
        pathname: `/login`,
        search: `?Country=${result}&prevPath=drought-monitor`,
        state: {
          countryData: countryData,
        },
      });
    }

    const country = JSON.stringify(countryData);
    localStorage.setItem('countryData', country);
    localStorage.setItem('selectedCountry', JSON.stringify(result));
  };

  return (
    <Container fluid className="drought-insights-bg" id="drought-insigths">
      <Row className=" mb-3 align-items-center">
        <Col xs={6}>
          <h2 className="title">{t('LANDING_PAGE.DROUGHT_INSIGHTS')}</h2>
        </Col>
        <Col xs={6}>
          {/* Hiding for small devices */}
          <Button
            className="view-application-btn float-end d-none d-md-block"
            onClick={() => handleRedirectToApp()}
          >
            {t('LANDING_PAGE.VIEW_APPLICATION')} <RightArrow />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <div className=" w-100 d-flex align-items-center justify-content-between mb-5">
            <Button
              className={classnames('w-100 drought-insights-btn drought-insights-margin-right', {
                'drought-insights-btn-selected': selectedInsight === 'drought-monitor',
              })}
              disabled={isLoading}
              onClick={e => handleSelectInsight(e, 'drought-monitor')}
            >
              {t('LANDING_PAGE.DROUGHT_MONITORING')}
            </Button>
            <Button
              className={classnames('w-100 drought-insights-btn drought-insights-margin-left', {
                'drought-insights-btn-selected': selectedInsight === 'drought-prediction',
              })}
              disabled={true}
              onClick={e => handleSelectInsight(e, 'drought-prediction')}
            >
              {t('LANDING_PAGE.DROUGHT_PREDICTION')}
            </Button>
          </div>
          <p className="dorught-insights-desc ">
            {selectedInsight === 'drought-monitor'
              ? t('LANDING_PAGE.DROUGHT_MONITORING_DESC', { country: countryData?.country_name })
              : t('LANDING_PAGE.DROUGHT_PREDICTION_DESC')}
          </p>
          <hr className="ds-nr-line" />
          <div className=" mb-4 d-flex align-items-center">
            <img
              src={timeIcon}
              alt="Time"
              className="drought-insights-margin-right drought-insigths-icon"
              width={24}
              height={24}
            />{' '}
            <span className="dorught-insights-desc ">
              {t('LANDING_PAGE.MAP_RELEASED')}
              {EventDateFormatter(mapDates.endDate)}
            </span>{' '}
          </div>
          <div className="d-flex align-items-center">
            <img
              src={updateIcon}
              alt="Time"
              className="drought-insights-margin-right  drought-insigths-icon"
              width={24}
              height={24}
            />{' '}
            <span className="dorught-insights-desc ">
              {t('LANDING_PAGE.MAP_DURATION')} {EventDateFormatter(mapDates.startDate)} -{' '}
              {EventDateFormatter(mapDates.endDate)}
            </span>{' '}
          </div>
        </Col>
        <Col xs={12} md={6} className="landing-page-map-container">
          <MapContainer
            center={cordinateAndZoom.cordinates}
            className="landing-page-map drought-insights-map-container"
            zoom={cordinateAndZoom.zoom}
            scrollWheelZoom={false}
            whenCreated={setMapReference}
            // Mali bounds
            maxBounds={[
              [10.021562, -12.595476], // Bottom left (Southwest)
              [25.381503, 4.645891], // Top right (Northeast)
            ]}
            maxBoundsViscosity={1.0}
          >
            <ChangeView center={cordinateAndZoom.cordinates} zoom={cordinateAndZoom.zoom} />
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url={myConstClass.TITLE_LAYER_URL}
            />
            {isLoading ? (
              <Marker
                position={[countryData?.latitude, countryData?.longitude]}
                icon={LoaderIcon}
              />
            ) : (
              ''
            )}
            <LayersControl>{renderParamLayer()}</LayersControl>
            {legend.length
              ? legend.map(item => {
                  return (
                    <LayersControl key="layer3" position="topleft">
                      {item?.descret_legend ? (
                        <DiscreteLandingPageLegend
                          map={wrap.current}
                          add={item.add}
                          layer={item}
                          position="bottomright"
                        />
                      ) : (
                        <CustomLandingPageLegend
                          map={wrap.current}
                          add={item.add}
                          layer={item}
                          position="bottomright"
                        />
                      )}
                    </LayersControl>
                  );
                })
              : ''}

            {geoJsonData?.length ? (
              <GeoJsonMapContainer geoJsonData={geoJsonData} countryData={countryData} />
            ) : (
              ''
            )}
          </MapContainer>
        </Col>
      </Row>
    </Container>
  );
}

export default DroughtInsights;
