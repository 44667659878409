import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _, { isNumber } from 'lodash';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Geocode from 'react-geocode';
import Autocomplete from 'react-google-autocomplete';
import Button from 'reactstrap-button-loader';
import ReactTooltip from 'react-tooltip';
import {
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap';
import { BiCurrentLocation, BiPlus, BiMinus } from 'react-icons/bi';
import { BsSearch } from 'react-icons/bs';
import { RxCross2 } from 'react-icons/rx';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { ReactComponent as AdvancedSearch } from '../../assets/Images/AdvancedSearch.svg';
import moment from 'moment';
import Select from 'react-select';
import * as turf from '@turf/turf';
import {
  BASE_TIME_ECMWF,
  FORECAST_LIST_ECMWF,
  SHOW_VALIDTIME_AND_LAYERS,
  PARAMETER_SLUGS,
  SOIL_MOISTURE_LAYER_ECMWF,
  SHOW_DAY_AND_QUANTILE,
  SOUTH_ASIA_COUNTRY,
} from '../../Components/constants';
import { parseCoordinate } from '../../Components/helpers';

import {
  getCountryRequest,
  getStateByCountryRequest,
  getDistrictByStateRequest,
  getWeatherForecastSources,
  getMapPreferenceRequest,
} from '../../redux/actions';
import TRANSLATIONS from '../../translations/en.json';
import {
  LOCATION,
  DATE_FORMAT,
  TEMPORAL_AGGREGATION,
  ADDRESS_CONSTS,
  DATA_SOURCES,
  DISPLAY_DATE_FORMAT,
  DEFAULT_COUNTRY,
  INDIA_COORDINATE,
  SOUTH_ASIA_CONST,
  weatherForecastIndices,
  DISTRICT_ZOOM_LEVEL,
  GRAPH,
  OPEN_WEATHER_CHART_PARAMS,
} from '../constants';
import { t } from 'react-multi-lang';
import {
  getUserLocationBasedOnIP,
  isOpenWeatherDataSource,
  isUserLocationExists,
  renderValidCommonFilterDates,
} from './utils';
import { showError } from '../Notifications';
import { WEATHER_FORECAST_MODULE } from '../../Components/constants/index';
import axios from 'axios';
import myConstClass from '../../Constants';
import SAJson from '../../Components/JsonFiles/SA.json';
function Filter(props) {
  const {
    initialValues: {
      isHideBar,
      staticResourceSelectedLocation,
      fromStaticResources,
      dataSourceId,
      years,
      dates,
      isDefaultSelectedSourceId,
      region,
      selectedParam,
      mapPreferencesData,
    },
    clearForecastData,
    clearDatesHandler,
    forecastLeadTimeHandler,
    forecastCompareYearMonthHandler,
    forecastCompareLeadTimeHandler,
    forecastTimeYearHandler,
    forecastTimeMonthHandler,
    compareForecastTimeYearHandler,
    compareForecastTimeMonthHandler,
    compareIRIAvailableYearList,
    compareIRIForecastTime,
    compareIRIAvailableMonthList,
    compareIRIAvailableLeadTimeList,
    IRIAvailableYearList,
    IRIForecastTime,
    IRIAvailableMonthList,
    IRIAvailableLeadTimeList,
    setCompareIRIForecastTime,
    setCurrenUserLocation,
    ecmwfProducts,
    selectedEcmwfProducts,
    setSelectedEcmwfProducts,
    staticResourceBaseTimeData,
    validTimeOptions,
    validLayerOptions,
    selectedValidTime,
    selectedValidLayer,
    daysData,
    quantileData,
    forecastType,
    selectedBasetime,

    setSelectedBaseTime,
    setValidTimeOptions,
    setSelectedValidTime,
    setValidLayerOptions,
    setSelectedValidLayer,
    setForecastType,
    setDaysData,
    setQuantileData,
    staticResourceData,
    onHandleEcmwf,
    setSelectedDataSource,
    selectedDataSource,
    setSearchMarker,
    countryJson,
    // setSelectedLocationEcmwf,
    // selectedLocationEcmwf,
  } = props;
  const dispatch = useDispatch();

  const autoCompleteRef = useRef(null);
  const history = useHistory();
  const [location, setLocation] = useState('');
  const [cordinates, setCordinates] = useState({
    lat: '',
    lng: '',
  });
  const [isAdvanceSearch, setAdvanceSearch] = useState(false);
  const [isClickedOnPlus, setIsClickedOnPlus] = useState(false);
  const [initialSelectedDate, setSelectedDate] = useState({
    graph: {
      startDate: moment().format(DATE_FORMAT),
      endDate: moment()
        .add(6, 'days')
        .format(DATE_FORMAT),
      minStartDate: '',
      maxStartDate: '',
      maxEndDate: '',
    },
    map: {
      startDate: moment().format(DATE_FORMAT),
      endDate: moment()
        .add(6, 'days')
        .format(DATE_FORMAT),
      minStartDate: '',
      maxStartDate: '',
      maxEndDate: '',
    },
  });
  const [updatedSelectedDate, setUpdatedSelectedDate] = useState({
    startDate: moment().format(DATE_FORMAT),
    endDate: moment()
      .add(6, 'days')
      .format(DATE_FORMAT),
    minStartDate: '',
    maxStartDate: '',
    maxEndDate: '',
  });
  const [finalSelectedDate, setFinalSelectedDate] = useState({
    startDate: moment().format(DATE_FORMAT),
    endDate: moment()
      .add(6, 'days')
      .format(DATE_FORMAT),
    minStartDate: '',
    maxStartDate: '',
    maxEndDate: '',
  });
  const [updatedSelectedCompareDate, setUpdatedSelectedCompareDate] = useState({
    startDate: moment().format(DATE_FORMAT),
    endDate: moment()
      .add(6, 'days')
      .format(DATE_FORMAT),
    minStartDate: '',
    maxStartDate: '',
    maxEndDate: '',
  });
  const [selectedLocation, setSelectedLocation] = useState({
    country: '',
    state: '',
    District: '',
  });
  const [compareLocation, setCompareLocation] = useState({
    country: '',
    state: '',
    District: '',
  });
  const [country, setCountry] = useState('');
  const [compareCountry, setCompareCountry] = useState('');
  const [dataSources, setDataSources] = useState({
    firstDataSources: [],
    secondDataSources: [],
  });
  const [countryList, setCountryList] = useState();
  const [stateList, setStateList] = useState([]);
  const [compareStateList, setCompareStateList] = useState([]);
  // const [selectedDataSource, setSelectedDataSource] = useState({
  //   firstDataSource: [],
  //   secondDataSource: [],
  // });
  const [temporalAggregation, setTemporalAggregation] = useState(TEMPORAL_AGGREGATION);
  const [range] = useState({
    min: null,
    max: null,
  });
  const [isCompare, setIsCompare] = useState([
    { name: 'map', value: 'Map', selected: true },
    { name: 'graph', value: 'Graph', selected: false },
  ]);
  const [compareData, setCompareData] = useState('Map');
  const [firstDataSourceSelected, setFirstDataSourceSelected] = useState({});

  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedOption, setSelectedOption] = useState({});
  const [defaultSelectedOption, setDefaultSelectedOption] = useState({});
  const [compareSelectedOption, setCompareSelectedOption] = useState({});
  const [defaultCompareSelectedOption, setDefaultCompareSelectedOption] = useState({});
  const [defaultRegionName, setDefaultRegionName] = useState();
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [selectedDefaultDateRange, setSelectedDefaultDateRange] = useState({});
  const [selectedYearList, setSelectedYearList] = useState([]);
  const [selectedDefaultYear, setSelectedDefaultYear] = useState(null);
  const [state, setState] = useState();
  const [compareState, setCompareState] = useState();
  const [districtList, setDistrictList] = useState();
  const [compareDistrictList, setCompareDistrictList] = useState();
  const [selectedDisctrict, setSelectedDistrict] = useState(null);
  const [selectedCompareDistrict, setSelectedCompareDistrict] = useState(null);
  const [isOpenWeather, setIsOpenWeather] = useState(false);
  const [isDefaultDataSource, setIsDefaultDataSource] = useState('');
  const [isUpdateGraph, setIsUpdateGraph] = useState(true);
  const [isApplyLayer, setIsApplyLayer] = useState(true);
  const [showToolTip, setShowToolTip] = useState(false);
  const [showMapPreferenceTooltip, setShowMapPreferenceTooltip] = useState(false);
  const [isDataSourceOpen, setIsDataSourceOpen] = useState();
  const [isIriNotSelected, setIsIriNotSelected] = useState(true);
  const [hasSelectedParamChart, setHasSelectedParamChart] = useState(true);
  const [parentCountryObj, setParentCountryObj] = useState({});
  const [parentStateObj, setParentStateObj] = useState({});
  Geocode.setApiKey(process.env.REACT_APP_LOCATION_API_KEY);
  Geocode.setLanguage('en');
  Geocode.setLocationType('ROOFTOP');
  Geocode.enableDebug();
  //NOAA GFS start and end dates
  const [noaaMaxEndDate, setNoaaMaxEndDate] = useState(false);
  const [finalNoaaEndDate, setFinalNoaaEndDate] = useState('');
  const [finalNoaaMaxEndDate, setFinalNoaaMaxEndDate] = useState('');

  //NOAA GFS compare start and end dates
  const [noaaCompareMaxEndDate, setNoaaCompareMaxEndDate] = useState(false);
  const [finalNoaaCompareEndDate, setFinalNoaaCompareEndDate] = useState('');
  const [finalNoaaCompareMaxEndDate, setFinalNoaaCompareMaxEndDate] = useState('');
  const [countryData, setCountryData] = useState({});
  const handleInfo = e => {
    e?.stopPropagation();
    window.open(
      `/user-guide-details?topicId=${WEATHER_FORECAST_MODULE.id}&&sub-topic-name=${selectedDataSource.firstDataSource[0].name}&topic_name=Weather Forecast`,
    );
  };
  //getting all country ,state ,district from store
  const { Location, Weather, isEcmwfControlsLoading } = useSelector(state => ({
    Location: state.Location,
    Weather: state.Weather,
    isEcmwfControlsLoading: state?.Weather?.isEcmwfControlsLoading,
  }));

  // !setting selected region for south asia so that all the required API's are called
  useEffect(() => {
    const countryInfo = localStorage.getItem('countryData');
    // Convert the JSON string back to an object
    const countrySpecificData = JSON.parse(countryInfo);
    if (countrySpecificData?.country_slug === 'southasia') {
      setSelectedRegion({
        name: SOUTH_ASIA_CONST.name,
        label: SOUTH_ASIA_CONST.name,
        value: SOUTH_ASIA_CONST.id,
        id: SOUTH_ASIA_CONST.id,
      });
    }
  }, []);

  useEffect(() => {
    let defaultSelectedAggregation = TEMPORAL_AGGREGATION?.find(item => item.name === 'Mean');
    let data = isUserLocationExists();
    if (data) {
      setCurrenUserLocation(data);
    } else {
      getUserLocationData();
    }
    if (!_.isEmpty(defaultSelectedAggregation)) {
      setTemporalAggregation(defaultSelectedAggregation);
    }
    if (autoCompleteRef && !_.isEmpty(autoCompleteRef?.current)) {
      autoCompleteRef.current.autocomplete = false;
    }
    // let currentUserLocation = Cookies.get('ip-based-user-location');
    // if(currentUserLocation) setCurrenUserLocation(currentUserLocation)
    const countryInfo = localStorage.getItem('countryData');

    // Convert the JSON string back to an object
    const countrySpecificData = JSON.parse(countryInfo);

    setCountryData(countrySpecificData);
  }, []);

  //for getting country
  useEffect(() => {
    if (!fromStaticResources) {
      dispatch(getCountryRequest({ location_type: LOCATION.Country, parent_id: '' }));
    } else {
      dispatch(getWeatherForecastSources({ region_id: SOUTH_ASIA_CONST.id }));
    }
  }, [fromStaticResources]);

  useEffect(() => {
    if (!_.isEmpty(staticResourceSelectedLocation)) {
      setDefaultRegionName(staticResourceSelectedLocation);
    } else {
      setDefaultRegionName(null);
    }
  }, [staticResourceSelectedLocation]);

  useEffect(() => {
    if (selectedLocation) props.handleSelectedLocation(selectedLocation);
  }, [selectedLocation]);

  useEffect(() => {
    props.handleCompareView(isCompare);
  }, [isCompare]);

  //global function to compare props
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const getUserLocationData = async () => {
    const response = await axios.post(myConstClass.GEO_LOCATION_USER_LOCATION_URL, {
      considerIp: true,
    });
    if (response?.status === 200) {
      const cordinates = response?.data?.location;
      getAddress(cordinates?.lat, cordinates?.lng, { fromCurrentLocation: false });
    }
  };

  useEffect(() => {
    if (firstDataSourceSelected?.length) {
      let secondDataSourceList = dataSources?.firstDataSources?.filter(
        i => i.type === 'non-static' || i.value === 'IRI',
      );
      let filteredList = secondDataSourceList;
      if (compareData === 'Graph') {
        filteredList =
          firstDataSourceSelected[0].id === DATA_SOURCES.IRI.id
            ? secondDataSourceList?.filter(i => i.id === DATA_SOURCES.IRI.id)
            : secondDataSourceList?.filter(i => i.id !== DATA_SOURCES.IRI.id);
        setSelectedDataSource(prev => ({
          ...prev,
          secondDataSource: [],
        }));
        setDataSources(prev => ({
          ...prev,
          secondDataSources: filteredList,
        }));
      } else if (compareData === 'Map') {
        let secondDataSourceFilteredList = dataSources?.firstDataSources?.filter(
          i => i.type === 'non-static' || i.value === 'IRI',
        );
        setDataSources(prev => ({
          ...prev,
          secondDataSources: secondDataSourceFilteredList,
        }));
        setSelectedDataSource(prev => ({
          ...prev,
          secondDataSource: [],
        }));
        clearForecastData();
      }
    }
  }, [compareData, firstDataSourceSelected]);

  useEffect(() => {
    if (!_.isEmpty(location)) {
      setIsClickedOnPlus(false);
    }
  }, [location]);

  useEffect(() => {
    if (mapPreferencesData?.length === 2) {
      setShowMapPreferenceTooltip(true);
    } else setShowMapPreferenceTooltip(false);
  }, [mapPreferencesData]);

  useEffect(() => {
    if (Location.countryList) {
      const { result } = Location.countryList;
      setAdvanceSearch(true);
      if (result) {
        let defaultCountry = result?.find(data => data.id === countryData.country_id);
        if (defaultCountry) {
          defaultCountry.label = defaultCountry.name;
          defaultCountry.value = defaultCountry.name;
          setSelectedOption(defaultCountry);
          setParentCountryObj(defaultCountry);
          setDefaultSelectedOption(defaultCountry);
          props.handleCompareSearch({ country: defaultCountry.value });

          setCompareSelectedOption(defaultCountry);
          setDefaultCompareSelectedOption(defaultCountry);

          setCountry(defaultCountry.name);
          setCompareCountry(defaultCountry.name);
          setSelectedLocation(prev => {
            return {
              ...prev,
              country: defaultCountry,
            };
          });
          setCompareLocation(prev => {
            return {
              ...prev,
              country: defaultCountry,
            };
          });
        }
      }
      if (result?.[0]) {
        let arr = [];
        result.map(i => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            region_id: i.region_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
          });
        });
        setCountryList(arr);

        if (fromStaticResources) {
          props.handleRegionId(result[0].id);
          //setDefaultRegionName(result[0].name);
          dispatch(getWeatherForecastSources({ region_id: result[0].id }));
        }
      }
    }
  }, [Location.countryList]);

  useEffect(() => {
    if (Object.keys(selectedOption).length && selectedOption?.latitude) {
      const { latitude, longitude, zoom_level } = selectedOption;
      return props.handleCordinates(
        [latitude ? latitude : INDIA_COORDINATE.lat, longitude ? longitude : INDIA_COORDINATE.long],
        zoom_level ? zoom_level : INDIA_COORDINATE.zoom_level,
      );
    } else {
      return props.handleCordinates(
        [INDIA_COORDINATE.lat, INDIA_COORDINATE.long],
        SOUTH_ASIA_CONST.zoom_level,
      );
    }
  }, [selectedOption]);

  useEffect(() => {
    if (Object.keys(compareSelectedOption).length) {
      const { latitude, longitude, zoom_level } = compareSelectedOption;
      return props.handleCompareCordinates(
        [latitude ? latitude : INDIA_COORDINATE.lat, longitude ? longitude : INDIA_COORDINATE.long],
        zoom_level ? zoom_level : INDIA_COORDINATE.zoom_level,
      );
    } else {
      return props.handleCompareCordinates(
        [INDIA_COORDINATE.lat, INDIA_COORDINATE.long],
        SOUTH_ASIA_CONST.zoom_level,
      );
    }
  }, [compareSelectedOption]);

  function updateDate(dataSource) {
    if (dataSource?.id === DATA_SOURCES?.OPEN_WEATHER?.id) {
      return DATA_SOURCES?.OPEN_WEATHER?.dateRange;
    } else if (dataSource?.id === DATA_SOURCES?.GFS_NOAA.id) {
      return DATA_SOURCES.GFS_NOAA.dateRange;
    } else if (dataSource?.id === DATA_SOURCES?.ERPAS.id) {
      return DATA_SOURCES.ERPAS.dateRange;
    } else {
      return {
        startDate: moment().format(DATE_FORMAT),
        endDate: moment()
          .add(6, 'days')
          .format(DATE_FORMAT),
        minStartDate: '',
        maxStartDate: '',
        maxEndDate: '',
      };
    }
  }

  useEffect(() => {
    if (selectedDataSource?.firstDataSource?.length) {
      let dataSource = selectedDataSource?.firstDataSource[0];
      let date = updateDate(dataSource);
      setSelectedDate(prev => {
        return {
          ...prev,
          map: date,
          graph: date,
        };
      });
      setUpdatedSelectedDate(date);
    }
  }, [selectedDataSource?.firstDataSource]);

  useEffect(() => {
    if (selectedDataSource?.secondDataSource?.length) {
      let dataSource = selectedDataSource?.secondDataSource[0];
      let date = updateDate(dataSource);
      setFinalSelectedDate(date);
      setUpdatedSelectedCompareDate(date);
    }
  }, [selectedDataSource?.secondDataSource]);

  useEffect(() => {
    if (selectedLocation.country && selectedLocation.country.name !== SOUTH_ASIA_CONST.name) {
      dispatch(
        getStateByCountryRequest({
          requestObj: {
            location_type: LOCATION.State,
            parent_id: selectedLocation.country?.id,
          },
          isCompareLocation: false,
        }),
      );
      dispatch(getWeatherForecastSources({ country_id: selectedLocation.country?.id }));
      dispatch(getMapPreferenceRequest({ country_id: selectedLocation.country?.id }));
    }
  }, [selectedLocation.country]);

  //for getting state by country-id
  useEffect(() => {
    if (selectedLocation.state && selectedLocation?.state?.id) {
      dispatch(
        getDistrictByStateRequest({
          requestObj: {
            location_type: LOCATION.District,
            parent_id: selectedLocation.state?.id,
          },
          isCompareLocation: false,
        }),
      );
    }
  }, [selectedLocation.state]);

  useEffect(() => {
    if (!_.isEmpty(selectedRegion)) {
      if (
        selectedLocation.country?.name === SOUTH_ASIA_CONST.name &&
        selectedRegion.value === SOUTH_ASIA_CONST.id
      ) {
        dispatch(getWeatherForecastSources({ region_id: SOUTH_ASIA_CONST.id }));
        dispatch(getMapPreferenceRequest({ region_id: SOUTH_ASIA_CONST.id }));
      } else if (
        selectedRegion.value === '' &&
        selectedLocation.country?.name !== SOUTH_ASIA_CONST.name
      ) {
        dispatch(getWeatherForecastSources({ country_id: selectedLocation.country?.id }));
        dispatch(getMapPreferenceRequest({ country_id: selectedLocation.country?.id }));
      }
    }
  }, [selectedRegion, selectedLocation.country]);

  //for getting state by country-id
  useEffect(() => {
    if (compareLocation.country && compareLocation.country.name !== SOUTH_ASIA_CONST.name) {
      dispatch(
        getStateByCountryRequest({
          requestObj: {
            location_type: LOCATION.State,
            parent_id: compareLocation.country?.id,
          },
          isCompareLocation: true,
        }),
      );
    }
  }, [compareLocation.country]);

  useEffect(() => {
    if (compareLocation?.state && compareLocation?.state?.id) {
      dispatch(
        getDistrictByStateRequest({
          requestObj: {
            location_type: LOCATION.District,
            parent_id: compareLocation.state?.id,
          },
          isCompareLocation: true,
        }),
      );
    }
  }, [compareLocation.state]);

  useEffect(() => {
    let arr = [];
    if (Location.stateList) {
      let result = '';
      let config = Location?.stateList?.config;
      if (Location?.stateList?.data) result = Location?.stateList?.data?.result;
      if (result) {
        result.map(i => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            country_id: i.country_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
          });
        });
      }
      if (!config?.isCompareLocation) {
        setStateList(arr);
      } else {
        setCompareStateList(arr);
      }
    }
  }, [Location.stateList]);

  useEffect(() => {
    let arr = [];
    if (Location.districtList) {
      let result = '';
      let config = Location?.districtList?.config;
      if (Location?.districtList?.data) result = Location?.districtList?.data?.result;
      if (result) {
        result.map(i => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            state_id: i.state_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
          });
        });
      }
      if (!config?.isCompareLocation) {
        setDistrictList(arr);
      } else {
        setCompareDistrictList(arr);
      }
    }
  }, [Location.districtList]);

  useEffect(() => {
    if (selectedDataSource?.firstDataSource?.length) {
      const selectedItem = selectedDataSource?.firstDataSource?.some(
        i => i.id === DATA_SOURCES.OPEN_WEATHER.id,
      );
      setIsOpenWeather(selectedItem);
      props.handleProps({
        dataSources: selectedDataSource?.firstDataSource,
        initialSelectedDate,
        temporalAggregation,
        range,
      });
      if (isClickedOnPlus) {
        props.handleProps({
          compareDataSource: selectedDataSource?.secondDataSource,
          initialSelectedDate,
          finalSelectedDate: finalSelectedDate,
          temporalAggregation,
          range,
        });
      }
    }
  }, [
    initialSelectedDate,
    selectedDataSource.firstDataSource,
    selectedDataSource.secondDataSource,
    isClickedOnPlus,
    finalSelectedDate,
    temporalAggregation,
    range,
  ]);

  useEffect(() => {
    props.handleIsOpenWeather(isOpenWeather);
  }, [isOpenWeather]);

  useEffect(() => {
    if (!isClickedOnPlus) {
      let compareData = JSON.parse(JSON.stringify(isCompare));
      const nextCompare = compareData.map(item => {
        if (item.name === 'map') {
          return {
            ...item,
            selected: true,
          };
        } else {
          return {
            ...item,
            selected: false,
          };
        }
      });
      setIsCompare(nextCompare);
      setCompareData('Map');
      const { secondDataSource } = selectedDataSource;
      if (secondDataSource?.length) {
        setSelectedDataSource(prev => ({
          ...prev,
          secondDataSource: [],
        }));
        setFinalSelectedDate({
          startDate: moment().format(DATE_FORMAT),
          endDate: moment()
            .add(6, 'days')
            .format(DATE_FORMAT),
          minStartDate: '',
          maxStartDate: '',
          maxEndDate: '',
        });
        props.handleProps({
          compareDataSource: [],
          initialSelectedDate,
          finalSelectedDate: '',
        });
        props.handleCompareParamsData('', selectedDataSource?.firstDataSource[0]);
      }
    }
    props.handleIsCompare(isClickedOnPlus);
    setCompareIRIForecastTime({
      year: null,
      month: null,
      lead_time: null,
    });
  }, [isClickedOnPlus]);

  useEffect(() => {
    let isMap = '';
    let isGraph = '';
    let mapPreferencesCount = mapPreferencesData?.length;
    let hasSelectedParamChart = true;
    if (!_.isEmpty(selectedParam) && isOpenWeather) {
      hasSelectedParamChart = OPEN_WEATHER_CHART_PARAMS.find(
        x => x.name === selectedParam.parameter.name,
      )
        ? true
        : false;
    }
    setHasSelectedParamChart(hasSelectedParamChart);
    if (isClickedOnPlus) {
      isMap = isCompare.some(item => item.name === 'map' && item.selected);
      isGraph = isCompare.some(item => item.name === 'graph' && item.selected);

      if (
        (!_.isEmpty(temporalAggregation) && temporalAggregation?.value === 'median') ||
        isMap ||
        (!selectedDataSource.secondDataSource?.length && !region?.length) ||
        _.isEmpty(initialSelectedDate.graph.startDate) ||
        _.isEmpty(initialSelectedDate.graph.endDate) ||
        _.isEmpty(selectedParam)
      ) {
        if (selectedParam?.forecast_data_source_id === DATA_SOURCES.IRI.id) {
          if (
            _.isEmpty(IRIForecastTime?.year) ||
            _.isEmpty(IRIForecastTime?.month) ||
            _.isEmpty(IRIForecastTime?.lead_time) ||
            mapPreferencesCount === 2
          ) {
            setIsApplyLayer(false);
          } else {
            setIsApplyLayer(true);
          }
        } else if (mapPreferencesCount < 2) {
          setIsApplyLayer(true);
        } else {
          setIsApplyLayer(false);
        }
        setIsUpdateGraph(false);
      } else {
        if (selectedDataSource?.secondDataSource[0]?.id === DATA_SOURCES.IRI.id) {
          if (
            _.isEmpty(IRIForecastTime?.year) ||
            _.isEmpty(IRIForecastTime?.month) ||
            _.isEmpty(IRIForecastTime?.lead_time)
          ) {
            setIsUpdateGraph(false);
          } else {
            setIsUpdateGraph(true);
          }
        } else if (hasSelectedParamChart) {
          setIsUpdateGraph(true);
        } else {
          setIsUpdateGraph(false);
        }
      }
      if (
        isGraph ||
        !selectedDataSource.secondDataSource?.length ||
        _.isEmpty(initialSelectedDate.map.startDate) ||
        _.isEmpty(initialSelectedDate.map.endDate) ||
        _.isEmpty(selectedParam) ||
        region.length ||
        mapPreferencesCount === 2
      ) {
        setIsApplyLayer(false);
      } else {
        if (selectedDataSource?.secondDataSource[0]?.id === DATA_SOURCES.IRI.id) {
          if (
            _.isEmpty(compareIRIForecastTime?.year) ||
            _.isEmpty(compareIRIForecastTime?.month) ||
            _.isEmpty(compareIRIForecastTime?.lead_time) ||
            mapPreferencesCount === 2
          ) {
            setIsApplyLayer(false);
          } else {
            if (selectedParam?.weather_forecast_source_id === DATA_SOURCES.IRI.id) {
              if (
                _.isEmpty(IRIForecastTime?.year) ||
                _.isEmpty(IRIForecastTime?.month) ||
                _.isEmpty(IRIForecastTime?.lead_time) ||
                mapPreferencesCount === 2
              ) {
                setIsApplyLayer(false);
              } else {
                setIsApplyLayer(true);
              }
            } else if (mapPreferencesCount < 2) {
              setIsApplyLayer(true);
            } else {
              setIsApplyLayer(false);
            }
          }
        } else if (mapPreferencesCount < 2) {
          setIsApplyLayer(true);
        } else {
          setIsApplyLayer(false);
        }
      }
    } else {
      if (region?.length) setShowToolTip(true);
      if (region.length || _.isEmpty(selectedParam) || mapPreferencesCount === 2) {
        setIsApplyLayer(false);
      } else {
        if (selectedParam?.forecast_data_source_id === DATA_SOURCES.IRI.id) {
          if (
            _.isEmpty(IRIForecastTime?.year) ||
            _.isEmpty(IRIForecastTime?.month) ||
            _.isEmpty(IRIForecastTime?.lead_time) ||
            mapPreferencesCount === 2
          ) {
            setIsApplyLayer(false);
          } else {
            setIsApplyLayer(true);
          }
        } else if (mapPreferencesCount < 2) {
          setIsApplyLayer(true);
        } else {
          setIsApplyLayer(false);
        }
        if (!region?.length) setShowToolTip(false);
      }
      //let isUpdate = temporalAggregation?.value === 'median' || _.isEmpty(selectedParam) ? true : false;
      let isUpdate;
      if (
        (temporalAggregation?.value === 'median' || _.isEmpty(selectedParam)) &&
        selectedDataSource?.firstDataSource[0]?.id !== DATA_SOURCES.OPEN_WEATHER.id
      ) {
        if (isOpenWeather) {
          if (hasSelectedParamChart) {
            isUpdate = true;
          } else {
            isUpdate = false;
          }
        } else {
          isUpdate = true;
        }
      } else {
        if (selectedParam?.forecast_data_source_id === DATA_SOURCES.IRI.id) {
          if (
            _.isEmpty(IRIForecastTime?.year) ||
            _.isEmpty(IRIForecastTime?.month) ||
            _.isEmpty(IRIForecastTime?.lead_time)
          ) {
            isUpdate = true;
          } else {
            isUpdate = false;
          }
        } else if (hasSelectedParamChart) {
          isUpdate = false;
        } else {
          isUpdate = true;
        }
      }
      setIsUpdateGraph(!isUpdate);
    }
  }, [
    isCompare,
    isClickedOnPlus,
    temporalAggregation,
    selectedDataSource.secondDataSource,
    region,
    initialSelectedDate,
    finalSelectedDate,
    selectedParam,
    IRIForecastTime,
    compareIRIForecastTime,
    mapPreferencesData,
    selectedDataSource.firstDataSource,
  ]);
  //for getting weather forecast data sources
  useEffect(() => {
    if (country) {
      props.handleCountry(country);
    }
  }, [country]);

  useEffect(() => {
    if (years) {
      setSelectedYearList(years);
      setSelectedDefaultYear(years[0]);
    }
  }, [years]);

  useEffect(() => {
    setSelectedDateRange([]);
    setSelectedDefaultDateRange(null);
    if (dates && dates?.length) {
      let arr = [];
      if (selectedDataSource) {
        if (selectedDataSource?.firstDataSource[0]?.name) {
          dates?.map(item => {
            arr.push({
              value: item.group_date
                ? selectedDataSource?.firstDataSource[0]?.name === 'ERPAS (Static)'
                  ? moment(item.group_date).format(DISPLAY_DATE_FORMAT)
                  : item.group_date
                : '',
              label: item.group_date
                ? selectedDataSource?.firstDataSource[0]?.name === 'ERPAS (Static)'
                  ? moment(item.group_date).format(DISPLAY_DATE_FORMAT)
                  : item.group_date
                : '',
            });
          });
        }
      }
      setSelectedDefaultDateRange(arr[0]);
      setSelectedDateRange(arr);
      props.handleSelectedDate(arr[0]?.label);
    }
  }, [dates, selectedDataSource.firstDataSource]);

  useEffect(() => {
    props.handleDefaultDataSource(isDefaultDataSource);
  }, [isDefaultDataSource]);

  useEffect(() => {
    if (Weather.weatherForecastSourcesData) {
      if (Weather.weatherForecastSourcesData.data) {
        const { result } = Weather.weatherForecastSourcesData.data;
        let options = [];
        if (result) {
          result?.map(item => {
            item?.data?.map(data_source => {
              const {
                forecast_data_source: {
                  id,
                  name,
                  type,
                  master_server,
                  api_url,
                  forecast_data_source_with_parameter: parameter,
                },
              } = data_source;
              return options.push({
                id,
                name,
                value: name,
                label: name,
                type,
                master_server,
                api_url,
                parameterData: parameter,
                category: item.category,
              });
            });
          });

          let secondDataSourceList = options?.filter(i => i.type === 'non-static');
          options.map(i => {
            if (i?.id === DATA_SOURCES.IRI.id) {
              secondDataSourceList.push(i);
            }
          });
          let optionsCopy = options;
          options = changeorder(optionsCopy);
          setDataSources(prev => ({
            ...prev,
            firstDataSources: options,
            secondDataSources: secondDataSourceList,
          }));
        } else {
          setDataSources(prev => ({
            ...prev,
            firstDataSources: [],
            secondDataSources: [],
          }));
        }
      }
    }
  }, [Weather.weatherForecastSourcesData]);
  useEffect(() => {
    if (dataSources?.firstDataSources?.length) {
      let selectedDataSourceParamList = dataSources?.firstDataSources?.filter(
        i => i.id === dataSourceId,
      );
      if (selectedDataSourceParamList[0]) {
        setSelectedDataSource(prev => ({
          ...prev,
          firstDataSource: selectedDataSourceParamList,
        }));
        setFirstDataSourceSelected(selectedDataSourceParamList);
        if (selectedDataSourceParamList[0].id !== DATA_SOURCES.OPEN_WEATHER.id) {
          if (isDefaultDataSource) setIsDefaultDataSource(false);
        }
        props.handleParamData({ values: selectedDataSourceParamList[0], fromCompare: false });
      }
    }
  }, [dataSources.firstDataSources, dataSourceId, isDefaultSelectedSourceId]);

  useEffect(() => {
    if (!isDefaultSelectedSourceId) {
      if (dataSources?.firstDataSources?.length) {
        const filterdefaultSource = dataSources?.firstDataSources?.filter(
          data => data.id === DATA_SOURCES.OPEN_WEATHER.id,
        );
        if (!selectedDataSource?.firstDataSource?.length && filterdefaultSource.length) {
          filterdefaultSource[0].parameterData.forEach(value => {
            value.type = filterdefaultSource[0].type;
            value.dataSource = filterdefaultSource[0].name;
          });
          props.handleParamData({ values: filterdefaultSource[0], fromCompare: false });
          setSelectedDataSource(prev => ({
            ...prev,
            firstDataSource: filterdefaultSource,
          }));
          setFirstDataSourceSelected(filterdefaultSource);
          if (!isDefaultDataSource) {
            setIsDefaultDataSource(true);
          }
          // let countryName = countryData.country_name ? countryData.country_name.split(' ') : '';
          let result = countryData.country_slug;
          // if (countryName.length > 1) {
          //   result = countryName.join('_');
          // } else {
          //   result = countryData.country_name;
          // }

          history.push({
            pathname: '/weather-forecast',
            search: `?Country=${result}&dataSourceId=${filterdefaultSource[0].id}`,
            state: {
              countryData: countryData,
            },
          });

          const country = {
            id: 'e1eb8a52-2b98-4001-a7c2-7ff332725984',
            name: 'Mali Drought Management System',
            description:
              'Mali Drought Management System , developed under IWMI’s drought program with Indian Council of Agricultural Research (ICAR) and other partners, aids stakeholders in India in mitigating drought risks. It enables government authorities and development organizations to take timely action These features help monitor drought propagation and implement effective adaptation strategies for drought management.',
            latitude: 17.355,
            longitude: -3.518,
            website_url: null,
            created_by_id: 20,
            country_id: 'e1eb8a52-2b98-4001-a7c2-7ff332725984',
            order: 6,
            is_editable: false,
            file: null,
            icon:
              'https://sadms-iwmi.s3.ap-south-1.amazonaws.com/uat/landing_page/pillars/icon/IDMS1727522384007.png',
            country_name: 'Mali',
            country_zoom_level: 6,
            country_slug: 'mali',
          }
          localStorage.setItem('countryData', country);
        }
      }
    }
  }, [dataSources.firstDataSources]);

  useEffect(() => {
    if (region?.length) {
      //setIsClickedOnPlus(false);
      setIsUpdateGraph(true);
    }
  }, [region]);

  const setCompareToggle = () => {
    if (!region.length && _.isEmpty(location)) {
      setIsClickedOnPlus(!isClickedOnPlus);
    }
  };

  useEffect(() => {
    if (isCompare.some(item => item.name === 'graph' && item.selected)) {
      if (selectedDataSource.secondDataSource?.length) {
        let firstSource = selectedDataSource.firstDataSource[0];
        let secondSource = selectedDataSource.secondDataSource[0];
        let firstDataSource = '';
        let secondDataSource = '';
        for (const [key, value] of Object.entries(DATA_SOURCES)) {
          if (value.id === firstSource.id) {
            firstDataSource = value;
          }
          if (value.id === secondSource.id) {
            secondDataSource = value;
          }
        }
        if (firstSource?.id === secondSource?.id) {
          return;
        } else {
          /*
          Changed By: sai krishna thalla
          Reason: on selectin erpas and in compare selecting open weather and changing map to graph the dates of erpas are changing.
          Date:   sep-5-2022
          */
          if (
            firstSource?.id !== DATA_SOURCES.IRI.id &&
            secondSource?.id !== DATA_SOURCES.IRI.id &&
            secondDataSource?.id !== DATA_SOURCES.OPEN_WEATHER.id &&
            secondDataSource?.id !== DATA_SOURCES.GFS_NOAA.id &&
            secondDataSource?.id !== DATA_SOURCES.ERPAS.id
          ) {
            let date = renderValidCommonFilterDates(
              firstDataSource,
              secondDataSource,
              updatedSelectedDate,
              firstSource,
            );
            if (!_.isEmpty(date)) {
              setSelectedDate(prev => {
                return {
                  ...prev,
                  graph: date,
                };
              });
            }
          }
        }
      }
    }
  }, [
    isCompare,
    selectedDataSource.secondDataSource,
    selectedDataSource.firstDataSource,
    updatedSelectedDate,
  ]);

  useEffect(() => {
    if (
      selectedDataSource?.firstDataSource[0]?.id === DATA_SOURCES.IRI.id &&
      selectedDataSource?.secondDataSource[0]?.id === DATA_SOURCES.IRI.id
    ) {
      if (
        _.isEmpty(IRIForecastTime?.year) ||
        _.isEmpty(IRIForecastTime?.month) ||
        _.isEmpty(IRIForecastTime?.lead_time) ||
        _.isEmpty(compareIRIForecastTime?.year) ||
        _.isEmpty(compareIRIForecastTime?.month) ||
        _.isEmpty(compareIRIForecastTime?.lead_time)
      ) {
        setIsApplyLayer(false);
      } else {
        setIsApplyLayer(true);
      }
    } else if (
      selectedDataSource?.firstDataSource[0]?.id === DATA_SOURCES.IRI.id &&
      selectedDataSource?.secondDataSource[0]?.id
    ) {
      if (
        _.isEmpty(IRIForecastTime?.year) ||
        _.isEmpty(IRIForecastTime?.month) ||
        _.isEmpty(IRIForecastTime?.lead_time)
      ) {
        setIsApplyLayer(false);
      } else {
        setIsApplyLayer(true);
      }
    } else if (
      selectedDataSource?.secondDataSource[0]?.id === DATA_SOURCES.IRI.id &&
      selectedDataSource?.firstDataSource[0]?.id
    ) {
      if (
        _.isEmpty(compareIRIForecastTime?.year) ||
        _.isEmpty(compareIRIForecastTime?.month) ||
        _.isEmpty(compareIRIForecastTime?.lead_time)
      ) {
        setIsApplyLayer(false);
      } else {
        setIsApplyLayer(true);
      }
    }
  }, [IRIForecastTime, compareIRIForecastTime]);

  useEffect(() => {
    if (!_.isEmpty(region)) {
      if (selectedDataSource?.firstDataSource[0]?.id === DATA_SOURCES.IRI.id) {
        if (
          _.isEmpty(IRIForecastTime?.year) ||
          _.isEmpty(IRIForecastTime?.month) ||
          _.isEmpty(IRIForecastTime?.lead_time)
        ) {
          setIsUpdateGraph(false);
        } else {
          setIsUpdateGraph(true);
        }
      }
    }
  }, [region, IRIForecastTime]);

  useEffect(() => {
    if (!_.isEmpty(location)) {
      // const currentLoc = location.toLocaleLowerCase();
      // // const val1 = false;
      // if (
      //   location.length > 9 &&
      //   // val1 &&
      //   currentLoc.indexOf('india') === -1 &&
      //   currentLoc.indexOf('pakistan') === -1 &&
      //   currentLoc.indexOf('nepal') === -1 &&
      //   currentLoc.indexOf('sri lanka') === -1 &&
      //   currentLoc.indexOf('afganistan') === -1 &&
      //   currentLoc.indexOf('bhutan') === -1 &&
      //   currentLoc.indexOf('bangladesh') === -1
      // ) {
      //   showError('Please select location inside South Asia countries only.');
      //   setLocation('');
      // }
      setAdvanceSearch(false);
    }
  }, [location]);

  useEffect(() => {
    if (isAdvanceSearch) {
      if (!_.isEmpty(location)) {
        if (autoCompleteRef && !_.isEmpty(autoCompleteRef?.current)) {
          autoCompleteRef.current.value = '';
        }
        //setSelectedOption(parentCountryObj);
        //handleOptions(countryList, parentCountryObj, 'country');
        props.handleCordinates(
          [
            selectedOption?.latitude ? selectedOption.latitude : INDIA_COORDINATE.lat,
            selectedOption?.longitude ? selectedOption.longitude : INDIA_COORDINATE.long,
          ],
          selectedOption?.zoom_level ? selectedOption.zoom_level : INDIA_COORDINATE.zoom_level,
        );

        setLocation('');
        setSearchMarker('');
        props.handleCountry(country);
        props.handleAdvancedSearch({
          state: state?.value || '',
          district: selectedDisctrict?.value || '',
        });
      }
    }
  }, [isAdvanceSearch]);

  useEffect(() => {
    if (isAdvanceSearch && _.isEmpty(location)) {
      if (!_.isEmpty(selectedOption)) {
        const { latitude, longitude, zoom_level } = selectedOption;
        return props.handleCordinates(
          [
            latitude ? latitude : INDIA_COORDINATE.lat,
            longitude ? longitude : INDIA_COORDINATE.long,
          ],
          zoom_level ? zoom_level : INDIA_COORDINATE.zoom_level,
        );
      }
    }
  }, [isAdvanceSearch, location]);

  useEffect(() => {
    if (!_.isEmpty(selectedRegion) && country === SOUTH_ASIA_CONST.name) {
      props.handleSelectedRegion(selectedRegion?.value);
    } else {
      props.handleSelectedRegion('');
    }
  }, [selectedRegion, location, country]);

  const handleDateSelectionChange = selectedItem => {
    setSelectedDefaultDateRange(selectedItem);
    props.handleSelectedDate(selectedItem.label);
  };

  useEffect(() => {
    if (!_.isEmpty(selectedDefaultYear)) {
      props.handleSelectedYear(selectedDefaultYear.label);
    } else {
      props.handleSelectedYear(null);
    }
  }, [selectedDefaultYear]);

  const changeorder = dynamicIndices => {
    let indices = [];
    for (let i = 0; i < weatherForecastIndices.length; i++) {
      let a = weatherForecastIndices[i];
      for (let j = 0; j < dynamicIndices.length; j++) {
        if (a == dynamicIndices[j].name) {
          indices.push(dynamicIndices[j]);
        }
      }
    }
    return indices;
  };

  const handleYearSelectionChange = selectedItem => {
    setSelectedDefaultYear(selectedItem);
  };

  const handleEcmwfProducts = e => {
    setSelectedEcmwfProducts(e);
  };

  const handleSearch = places => {
    if (!_.isEmpty(places)) {
      const { formatted_address, geometry, address_components } = places;
      const lat = geometry?.location?.lat();
      const lng = geometry?.location?.lng();

      address_components?.map(item => {
        const { types, long_name } = item;

        if (types[0] === ADDRESS_CONSTS.COUNTRY) {
          props.handleAdvancedSearch({ country: long_name });
        } else if (types[0] === ADDRESS_CONSTS.STATE) {
          props.handleAdvancedSearch({ state: long_name });
        } else if (types[0] === ADDRESS_CONSTS.DISTRICT) {
          props.handleAdvancedSearch({ district: long_name });
        }
      });
      if (isNumber(lat) && isNumber(lng)) {
        setCordinates({ lat, lng });
      }
      setLocation(formatted_address);
    } else {
      setLocation('');
    }
  };

  const isLatLng = input => {
    // Regular expression for standard numerical lat,lng format with optional spaces
    const standardLatLngPattern = /^\s*-?\d+(\.\d+)?\s*,\s*-?\d+(\.\d+)?\s*$/;
    // Regular expression for degrees and direction format with optional spaces
    const degreesLatLngPattern = /^\s*-?\d+(\.\d+)?\s*°?\s*[NS]\s*,\s*-?\d+(\.\d+)?\s*°?\s*[EW]\s*$/;

    return standardLatLngPattern.test(input) || degreesLatLngPattern.test(input);
  };

  const handleFilter = async (loc, cordinates) => {
    const islatlng = isLatLng(loc);
    if (islatlng) {
      const locArray = loc.split(',');
      const lat = parseCoordinate(locArray?.[0]);
      const lng = parseCoordinate(locArray?.[1]);

      if (locArray?.length === 2 && isNumber(parseFloat(lat)) && isNumber(parseFloat(lng))) {
        const point = turf.point([parseFloat(lng), parseFloat(lat)]);
        const isInsideAnyPolygon = countryJson.features.some(feature => {
          return turf.booleanPointInPolygon(point, feature);
        });
        if (isInsideAnyPolygon) {
          setSearchMarker([parseFloat(lat), parseFloat(lng)]);
          props.handleCordinates([parseFloat(lat), parseFloat(lng)], DISTRICT_ZOOM_LEVEL);
        } else {
          setSearchMarker();
          showError(
            `Please select location inside ${selectedLocation?.country?.name} country only.`,
          );
        }
      } else {
        setSearchMarker();
        showError('Please enter valid data .');
      }
    } else {
      const responseData = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          JSON.stringify(loc),
        )}&key=AIzaSyAzWsZ_PyD0lshvP-sCwWP6d_1wzE0dYA0`,
      );
      if (responseData?.data?.results?.length) {
        const point = turf.point([
          parseFloat(responseData?.data?.results?.[0]?.geometry?.location?.lng),
          parseFloat(responseData?.data?.results?.[0]?.geometry?.location?.lat),
        ]);
        const isInsideAnyPolygon1 = countryJson.features.some(feature => {
          return turf.booleanPointInPolygon(point, feature);
        });

        if (!isInsideAnyPolygon1) {
          setSearchMarker();
          showError(
            `Please select location inside ${selectedLocation?.country?.name} country only.`,
          );
          setLocation('');
        } else {
          setSearchMarker([
            responseData?.data?.results?.[0]?.geometry?.location?.lat,
            responseData?.data?.results?.[0]?.geometry?.location?.lng,
          ]);
          props.handleCordinates(
            [
              responseData?.data?.results?.[0]?.geometry?.location?.lat,
              responseData?.data?.results?.[0]?.geometry?.location?.lng,
            ],
            DISTRICT_ZOOM_LEVEL,
          );
        }
      } else {
        setSearchMarker();
        showError(`Please select location inside ${selectedLocation?.country?.name} country only.`);
      }
    }

    setCordinates({ lat: '', lng: '' });
  };

  //map marker on the basis of selected country
  //get current location
  const getCurrentLocation = () => {
    if ('geolocation' in navigator) {
      let latitude = '';
      let longitude = '';
      /* geolocation is available */
      navigator.geolocation.getCurrentPosition(position => {
        latitude = position.coords.latitude;
        longitude = position.coords.longitude;
        getAddress(latitude, longitude, { fromCurrentLocation: true });
        if (latitude && longitude)
          props.handleCordinates([latitude, longitude], DISTRICT_ZOOM_LEVEL);
      });
    } else {
      /* geolocation IS NOT available */
    }
  };

  const getAddress = (lat, lng, { fromCurrentLocation }) => {
    if (isNumber(lat) && isNumber(lng) && fromCurrentLocation) {
      setCordinates({ lat, lng });
    }
    Geocode.fromLatLng(lat, lng).then(
      response => {
        let country, state, district;
        const { address_components: components } = response.results[0];
        for (let i = 0; i < components.length; i++) {
          for (let j = 0; j < components[i].types.length; j++) {
            if (components[i].types[j] === ADDRESS_CONSTS.COUNTRY) {
              country = components[i].long_name;
              if (fromCurrentLocation) props.handleCountry(country);
              else {
                setCurrenUserLocation(country);
                getUserLocationBasedOnIP(country);
              }
            }
            if (components[i].types[j] === ADDRESS_CONSTS.STATE) {
              state = components[i].long_name;
              if (fromCurrentLocation) props.handleAdvancedSearch({ state: state });
            }
            if (components[i].types[j] === ADDRESS_CONSTS.DISTRICT) {
              district = components[i].long_name;
              if (fromCurrentLocation) props.handleAdvancedSearch({ district: district });
            }
          }
        }
        if (fromCurrentLocation) {
          setLocation(`${district}, ${state}, ${country}`);
          props.handleAdvancedSearch({ state: state });
          props.handleAdvancedSearch({ district: district });
        }
      },
      error => {
        console.error(error);
      },
    );
  };
  const handleOnChange = selectedOptions => {
    setNoaaMaxEndDate(false);
    clearForecastData();
    const {
      category,
      id,
      label,
      name,
      parameterData,
      type,
      value,
      api_url,
      master_server,
    } = Array.isArray(selectedOptions) ? selectedOptions[0] : selectedOptions;
    let selectedDataArray = [];
    const selectedObj = {
      category,
      id,
      label,
      name,
      parameterData,
      type,
      value,
      api_url,
      master_server,
    };
    selectedDataArray.push(selectedObj);
    if (id) {
      // let countryName = countryData.country_name ? countryData.country_name.split(' ') : '';
      let result = countryData.country_slug;
      // if (countryName.length > 1) {
      //   result = countryName.join('_');
      // } else {
      //   result = countryData.country_name;
      // }

      history.push({
        pathname: '/weather-forecast',
        search: `?Country=${result}&dataSourceId=${id}`,
        state: {
          countryData: countryData,
        },
      });

      const country = {
        id: 'e1eb8a52-2b98-4001-a7c2-7ff332725984',
        name: 'Mali Drought Management System',
        description:
          'Mali Drought Management System , developed under IWMI’s drought program with Indian Council of Agricultural Research (ICAR) and other partners, aids stakeholders in India in mitigating drought risks. It enables government authorities and development organizations to take timely action These features help monitor drought propagation and implement effective adaptation strategies for drought management.',
        latitude: 17.355,
        longitude: -3.518,
        website_url: null,
        created_by_id: 20,
        country_id: 'e1eb8a52-2b98-4001-a7c2-7ff332725984',
        order: 6,
        is_editable: false,
        file: null,
        icon:
          'https://sadms-iwmi.s3.ap-south-1.amazonaws.com/uat/landing_page/pillars/icon/IDMS1727522384007.png',
        country_name: 'Mali',
        country_zoom_level: 6,
        country_slug: 'mali',
      }
      localStorage.setItem('countryData', country);
    }
    if (isDefaultDataSource) {
      setIsDefaultDataSource(false);
    }

    let isNonStaticDataSource = selectedObj.type === 'non-static';
    if (isNonStaticDataSource) {
      let secondDataSourceList = dataSources?.firstDataSources?.filter(
        i => i.type === 'non-static',
      );
      dataSources?.firstDataSources.map(i => {
        if (i?.id === DATA_SOURCES.IRI.id) {
          secondDataSourceList.push(i);
        }
      });
      setDataSources(prev => ({
        ...prev,
        secondDataSources: secondDataSourceList,
      }));
    } else {
      //let secondDataSourceList = dataSources?.firstDataSources;
      let isNonStaticDataSource = selectedObj.type === 'non-static';
      if (isNonStaticDataSource) {
        let secondDataSourceList = dataSources?.firstDataSources?.filter(
          i => i.type === 'non-static',
        );
        dataSources?.firstDataSources.map(i => {
          if (i?.id === DATA_SOURCES.IRI.id) {
            secondDataSourceList.push(i);
          }
        });

        setDataSources(prev => ({
          ...prev,
          secondDataSources: secondDataSourceList,
        }));
      }
    }
    setSelectedDataSource(prev => ({
      ...prev,
      firstDataSource: selectedDataArray,
    }));
    setFirstDataSourceSelected(selectedDataArray);
    props.handleParamData({ values: selectedDataArray[0], fromCompare: false });
    props.handleProps({
      dataSources: selectedDataArray,
      initialSelectedDate,
    });

    setSelectedDefaultYear(null);
    clearDatesHandler();
    setCompareIRIForecastTime({
      year: null,
      month: null,
      lead_time: null,
    });

    if (selectedOptions.name === DATA_SOURCES.IRI.name) {
      setIsIriNotSelected(false);
      let defaultSelectedAggregation = TEMPORAL_AGGREGATION?.find(item => item.name === 'Mean');
      if (!_.isEmpty(defaultSelectedAggregation)) {
        setTemporalAggregation(defaultSelectedAggregation);
      }
    }
  };

  // const handleFinalForecastYearMonth = type => {
  //   return moment(selectedForecastCompareYearMonth?.map[type]).format('YYYY-MM');
  // };

  const handleCompareDataSourceChange = selectedOptions => {
    setNoaaCompareMaxEndDate(false);
    const {
      category,
      id,
      label,
      name,
      parameterData,
      type,
      value,
      api_url,
      master_server,
    } = Array.isArray(selectedOptions) ? selectedOptions[0] : selectedOptions;
    let selectedDataArray = [];
    const selectedObj = {
      category,
      id,
      label,
      name,
      parameterData,
      type,
      value,
      api_url,
      master_server,
    };
    selectedDataArray.push(selectedObj);
    setSelectedDataSource(prev => ({
      ...prev,
      secondDataSource: selectedDataArray,
    }));
    props.handleProps({
      compareDataSource: selectedDataArray,
      finalSelectedDate,
    });
    props.handleCompareParamsData(selectedDataArray[0], selectedDataSource?.firstDataSource[0]);
    setCompareIRIForecastTime({
      year: null,
      month: null,
      lead_time: null,
    });
  };

  const onSubmitHandler = e => {
    e.preventDefault();
  };

  const handleForecastTimeYear = e => {
    forecastTimeYearHandler(e);
    setIsIriNotSelected(false);
  };

  const handleForecastTimeMonth = e => {
    forecastTimeMonthHandler(e);
    setIsIriNotSelected(false);
  };

  const handleCompareForecastTimeYear = e => {
    compareForecastTimeYearHandler(e);
  };

  const handleCompareForecastTimeMonth = e => {
    compareForecastTimeMonthHandler(e);
  };

  const handleForecastLeadTimeChange = e => {
    // formData.forecast_lead_time = e;
    //setFormData({ ...formData });
    forecastLeadTimeHandler(e);
    setIsIriNotSelected(true);
  };

  const handleCompareForecastLeadTimeChange = e => {
    forecastCompareLeadTimeHandler(e);
  };

  //handle apply layers onclick
  const handleApplyLayers = () => {
    props.showCharts(false);
    props.handleApplyLayers(true);
  };

  //display chart
  const displayChart = () => {
    props.handleTimeSeries();
    props.showCharts(true);
  };

  const handleTemporalAggregation = selectedOption => {
    setTemporalAggregation(selectedOption);
  };
  const handleCompareLocationRender = () => {
    let isGraphCompare = isCompare.find(item => item.name === 'graph' && item.selected);
    if (isGraphCompare) {
      return (
        <>
          <div className="pt-2 pb-2 mb-3">
            <span className="orangeColor mt-2">
              {!_.isEmpty(countryData) && countryData?.country_name !== SOUTH_ASIA_COUNTRY
                ? 'Select State'
                : 'Select Region'}
            </span>
            <div
              className="mt-2 mb-3 margin-country wf-compare-country"
              style={
                !_.isEmpty(countryData) && countryData?.country_name !== SOUTH_ASIA_COUNTRY
                  ? { display: 'none' }
                  : {}
              }
            >
              <Select
                id="multi-select"
                name="country"
                placeholder={TRANSLATIONS.FILTER.SELECT_COUNTRY}
                options={renderAPIParameterOptions(countryList, 'country')}
                onChange={handleCompareSelectedItem}
                isMulti={false}
                required={true}
                value={defaultCompareSelectedOption}
                //defaultValue={defaultCompareSelectedOption}
                isDisabled={
                  !_.isEmpty(countryData) && countryData?.country_name !== SOUTH_ASIA_COUNTRY
                }
              />
            </div>
            {compareCountry && compareLocation?.country?.name !== SOUTH_ASIA_CONST.name && (
              <div className="mt-2 mb-3 margin-country wf-compare-state">
                <Select
                  id="multi-select"
                  name="state"
                  placeholder="State"
                  options={renderAPIParameterOptions(compareStateList)}
                  onChange={handleCompareSelectedItem}
                  isMulti={false}
                  required={true}
                  value={compareState}
                />
              </div>
            )}
            {compareState && compareDistrictList?.length ? (
              <div className="mt-2 mb-3 margin-country wf-compare-district">
                <Select
                  id="multi-select"
                  name="district"
                  placeholder="District"
                  options={renderAPIParameterOptions(compareDistrictList)}
                  onChange={handleCompareSelectedItem}
                  isMulti={false}
                  required={true}
                  value={selectedCompareDistrict}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </>
      );
    }
  };

  const renderAPIParameterOptions = (list, action) => {
    if (list) {
      let params = [...list];
      let options = [];
      if (params.length) {
        let default_option =
          action === 'country'
            ? {
                name: SOUTH_ASIA_CONST.name,
                label: SOUTH_ASIA_CONST.name,
                value: SOUTH_ASIA_CONST.id,
                id: SOUTH_ASIA_CONST.id,
              }
            : { value: '', label: 'Select', id: 'select' };
        if (
          action !== 'forecast_year_list' &&
          action !== 'forecast_month_list' &&
          action !== 'forecast_lead_time_list' &&
          action !== 'compare_forecast_year_list' &&
          action !== 'compare_forecast_month_list' &&
          action !== 'compare_forecast_lead_time_list'
        ) {
          options.push(default_option);
        }
        params.map(param => {
          options.push(param);
          return true;
        });
        return options;
      }
    }
  };

  const renderIRIParameterOptions = (list, action) => {
    if (list) {
      let params = [...list];
      let options = [];
      if (params.length) {
        // let default_option = action === "country" ? { name: 'South Asia', label: 'South Asia', value: SOUTH_ASIA_CONST.id, id: SOUTH_ASIA_CONST.id } : { value: '', label: 'select', id: 'select' };
        // if(action !== "forecast_year_list" && action !== "forecast_month_list" && action !== "forecast_lead_time_list" && action !== "compare_forecast_year_list" && action !== "compare_forecast_month_list" && action !== "compare_forecast_lead_time_list"){
        //   options.push(default_option);
        // }
        params.map(param => {
          if (!_.isEmpty(param)) {
            let data = {
              value: param?.name,
              label: `${param?.name}`,
              id: param?.id,
              monthValue: param?.indexValue,
            };
            options.push(data);
          }
          return true;
        });
        return options;
      }
    }
  };

  const handleAdvanceSearchRender = () => {
    if (fromStaticResources) return <></>;
    return (
      <div className="advance-search-fields mb-3">
        <div className="d-flex justify-content-between mx-3">
          <span className="orangeColor mt-2">{TRANSLATIONS.FILTER.ADVANCE_SEARCH}</span>
          <span className="adv-hide cursor-ponter mt-1" onClick={() => setAdvanceSearch(false)}>
            {t('FILTER.HIDE')}
          </span>
        </div>
        {/* <div className="mx-3 mt-2 mb-3">
          <Select
            id="multi-select"
            name="Select Region"
            placeholder="Select Region"
            options={regionOptions}
            value={selectedRegion}
            onChange={setSelectedRegion}
            isMulti={false}
            required={true}
            isDisabled={true}
          />
        </div> */}
        <div
          className="mx-3 mt-2 mb-3 wf-country"
          style={
            !_.isEmpty(countryData) && countryData?.country_name !== SOUTH_ASIA_COUNTRY
              ? { display: 'none' }
              : {}
          }
        >
          <Select
            id="multi-select-county"
            name="country"
            placeholder={TRANSLATIONS.FILTER.SELECT_COUNTRY}
            options={renderAPIParameterOptions(countryList, 'country')}
            onChange={handleSelectedItem}
            isMulti={false}
            required={true}
            value={defaultSelectedOption}
            //defaultValue={defaultSelectedOption}
            isDisabled={!_.isEmpty(countryData) && countryData?.country_name !== SOUTH_ASIA_COUNTRY}
          />
        </div>
        {country && selectedLocation?.country?.name !== SOUTH_ASIA_CONST.name && (
          <div className="mx-3 mt-2 mb-3 wf-state">
            <Select
              id="multi-select-state"
              name="state"
              placeholder="State"
              options={renderAPIParameterOptions(stateList)}
              onChange={handleSelectedItem}
              isMulti={false}
              required={true}
              value={state}
            />
          </div>
        )}
        {state && districtList?.length ? (
          <div className="mx-3 mt-2 mb-3 wf-district">
            <Select
              id="multi-select-district"
              name="district"
              placeholder="District"
              options={renderAPIParameterOptions(districtList)}
              onChange={handleSelectedItem}
              isMulti={false}
              required={true}
              value={selectedDisctrict}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    );
  };

  const handleOptions = (options, newValue, action) => {
    if (options && options.length) {
      let selectedOption = options.find(item => item.name === newValue?.value);
      if (selectedOption || action.name === 'country') {
        if (action.name === 'country') {
          if (newValue.name === SOUTH_ASIA_CONST.name) {
            setDefaultSelectedOption(newValue);
            setSelectedOption(newValue);
            setSelectedLocation(prev => {
              return {
                ...prev,
                [action.name]: newValue || '',
              };
            });
          } else {
            setDefaultSelectedOption(selectedOption);
            setSelectedOption(selectedOption);
          }
        } else {
          setSelectedOption(selectedOption);
        }
      } else {
        if (action.name === 'country') {
          setDefaultSelectedOption('');
        }
        setSelectedOption('');
      }
      if (
        (newValue?.name !== SOUTH_ASIA_CONST.name && !_.isEmpty(selectedOption)) ||
        newValue?.id === 'select'
      )
        setSelectedLocation(prev => {
          return {
            ...prev,
            country: action.name === 'country' ? selectedOption : prev.country,
            state:
              action.name === 'country'
                ? ''
                : action.name === 'state'
                ? selectedOption
                : prev.state,
            district: action.name === 'country' || action.name === 'state' ? '' : selectedOption,
          };
        });
    }
  };

  const handleCompareOptions = (options, newValue, action) => {
    if (options && options.length) {
      if (newValue.value) {
        let selectedOption = options.find(item => item.name === newValue.value);
        if (!_.isEmpty(selectedOption)) {
          if (action.name === 'country') {
            setDefaultCompareSelectedOption(selectedOption);
          }
          setCompareSelectedOption(selectedOption);
          setCompareLocation(prev => {
            return {
              ...prev,
              [action.name]: selectedOption,
            };
          });
        }
      } else {
        if (action.name === 'country') {
          setDefaultCompareSelectedOption('');
        }
        setCompareSelectedOption('');
        setCompareLocation(prev => {
          return {
            ...prev,
            [action.name]: '',
          };
        });
      }
    }
  };

  //handling selected data from selector and setting to state
  const handleSelectedItem = (newValue, action) => {
    if (action.name === 'country') {
      props.handleCountry(newValue.value);
      setCountry(newValue.value);

      setParentCountryObj(newValue);
      handleOptions(countryList, newValue, action);
      setState(null);
      props.handleAdvancedSearch({ state: '', district: '' });
      if (newValue.name === SOUTH_ASIA_CONST.name) {
        setSelectedRegion(newValue);
        setCountry(newValue.name);
        setStateList([]);
      }
      handleCompareSelectedItem(newValue, action);
    } else if (action.name === 'state') {
      setState(newValue);
      setSelectedDistrict(null);
      props.handleAdvancedSearch({ state: newValue.value, district: '' });
      if (newValue.value) {
        setParentStateObj(newValue);
        handleOptions(stateList, newValue, action);
      } else {
        // handleOptions(countryList, parentCountryObj, 'country');
        setSelectedOption(parentCountryObj);
        setSelectedLocation(prev => {
          return {
            ...prev,
            state: {},
          };
        });
      }

      if (newValue.value === '') setDistrictList([]);
      if (_.isEmpty(compareCountry) || country === compareCountry) {
        handleCompareSelectedItem(newValue, action);
      }
    } else if (action.name === 'district') {
      setSelectedDistrict(newValue);

      if (newValue.value !== '') {
        handleOptions(districtList, newValue, action);
      } else {
        setSelectedOption(parentStateObj);
        setSelectedLocation(prev => {
          return {
            ...prev,
            district: {},
          };
        });
        //handleOptions(stateList, parentStateObj, 'state');
      }
      props.handleAdvancedSearch({ district: newValue.value });
      if (_.isEmpty(compareState) || state?.value === compareState?.value) {
        handleCompareSelectedItem(newValue, action);
      }
    }
  };

  //handling selected location for compare
  const handleCompareSelectedItem = (newValue, action) => {
    if (action.name === 'country') {
      props.handleCompareSearch({ country: newValue.value, state: '', district: '' });
      setCompareCountry(newValue.value);
      handleCompareOptions(countryList, newValue, action);
      setCompareState(null);
      if (newValue.name === SOUTH_ASIA_CONST.name) {
        props.handleCompareSearch({ country: newValue.name });
        setDefaultCompareSelectedOption(newValue);
        setCompareSelectedOption(newValue);
        setCompareLocation(prev => {
          return {
            ...prev,
            [action.name]: newValue,
          };
        });
        setCompareStateList([]);
      }
      if (newValue.value === '') setCompareStateList([]);
    } else if (action.name === 'state') {
      setCompareState(newValue);
      setSelectedCompareDistrict(null);
      props.handleCompareSearch({ state: newValue.value, district: '' });
      handleCompareOptions(compareStateList, newValue, action);
      props.stateValue(newValue);
      if (newValue.value === '') setCompareDistrictList([]);
    } else if (action.name === 'district') {
      props.handleCompareSearch({ district: newValue.value });
      handleCompareOptions(compareDistrictList, newValue, action);
      setSelectedCompareDistrict(newValue);
    }
  };

  const handleCompareToggle = ({ target }) => {
    let compareData = isCompare;
    const nextCompare = compareData.map(item => {
      if (item.value === target.value) {
        return {
          ...item,
          selected: target.checked,
        };
      } else {
        return {
          ...item,
          selected: false,
        };
      }
    });
    setIsCompare(nextCompare);
    setCompareData(target.value);
    setCompareIRIForecastTime({
      year: null,
      month: null,
      lead_time: null,
    });
  };
  const renderCompareDates = isCompare => {
    let isGraphCompare = isCompare.find(item => item.name === 'graph');
    if (!isGraphCompare.selected) {
      return selectedDataSource?.secondDataSource[0]?.id !== DATA_SOURCES.IRI.id ? (
        <>
          <Input
            type="date"
            placeholder="Start Date"
            bsSize="sm"
            className="mt-1 my-3 input-style-width margin-date1"
            // value={finalSelectedDate.startDate}
            value={handleInitialCompareDate('startDate')}
            onChange={e => {
              if (selectedDataSource?.secondDataSource[0]?.label === 'GFS - NOAA') {
                setNoaaCompareMaxEndDate(true);
              }
              setFinalSelectedDate(prevdate => {
                return {
                  ...prevdate,
                  startDate: e.target.value,
                };
              });
              setUpdatedSelectedCompareDate(prevdate => {
                return {
                  ...prevdate,
                  startDate: e.target.value,
                };
              });
            }}
            min={handleInitialCompareDate('minStartDate')}
            max={handleInitialCompareDate('maxStartDate')}
            disabled={isOpenWeatherDataSource(selectedDataSource?.secondDataSource)}
          />
          <Input
            type="date"
            placeholder="End Date"
            bsSize="sm"
            className="mt-1 my-3 input-style-width margin-date1"
            // value={finalSelectedDate.endDate}
            value={
              noaaCompareMaxEndDate ? finalNoaaCompareEndDate : handleInitialCompareDate('endDate')
            }
            onChange={e => {
              setFinalNoaaCompareEndDate(e.target.value);
              setFinalSelectedDate(prevdate => {
                return {
                  ...prevdate,
                  endDate: e.target.value,
                };
              });
              if (selectedDataSource?.secondDataSource[0]?.label !== 'GFS - NOAA') {
                setUpdatedSelectedCompareDate(prevdate => {
                  return {
                    ...prevdate,
                    endDate: e.target.value,
                  };
                });
              }
            }}
            min={handleInitialCompareDate('startDate')}
            max={
              noaaCompareMaxEndDate
                ? finalNoaaCompareMaxEndDate
                : handleInitialCompareDate('maxEndDate')
            }
            disabled={isOpenWeatherDataSource(selectedDataSource?.secondDataSource)}
          />
        </>
      ) : (
        <>
          <Select
            placeholder="Select Forecast Year"
            className="my-3 filter-select-placeholder time-input"
            type="select"
            onChange={handleCompareForecastTimeYear}
            required={true}
            isMulti={false}
            options={renderIRIParameterOptions(
              compareIRIAvailableYearList,
              'compare_forecast_year_list',
            )}
            id="forecast_category"
          />

          <Select
            placeholder="Select Forecast Month"
            className="my-3 filter-select-placeholder time-input"
            type="select"
            onChange={handleCompareForecastTimeMonth}
            required={true}
            isMulti={false}
            value={compareIRIForecastTime?.month}
            options={renderIRIParameterOptions(
              compareIRIAvailableMonthList,
              'compare_forecast_month_list',
            )}
            id="forecast_category"
          />
          <Select
            placeholder="Select Forecast Lead Time"
            className="my-3 filter-select-placeholder time-input"
            type="select"
            onChange={handleCompareForecastLeadTimeChange}
            required={true}
            isMulti={false}
            value={compareIRIForecastTime?.lead_time}
            options={renderIRIParameterOptions(
              compareIRIAvailableLeadTimeList,
              'compare_forecast_lead_time_list',
            )}
            id="forecast_category"
          />
        </>
      );
    } else {
      if (selectedDataSource?.secondDataSource[0]?.id === DATA_SOURCES.IRI.id) {
        if (
          selectedDataSource?.firstDataSource[0]?.id !== selectedDataSource?.secondDataSource[0]?.id
        ) {
          return (
            <>
              <Select
                placeholder="Select Forecast Year"
                className="my-3 filter-select-placeholder time-input"
                type="select"
                onChange={handleCompareForecastTimeYear}
                required={true}
                isMulti={false}
                options={renderIRIParameterOptions(
                  compareIRIAvailableYearList,
                  'compare_forecast_year_list',
                )}
                id="forecast_category"
              />

              <Select
                placeholder="Select Forecast Month"
                className="my-3 filter-select-placeholder time-input"
                type="select"
                onChange={handleCompareForecastTimeMonth}
                required={true}
                isMulti={false}
                value={compareIRIForecastTime?.month}
                options={renderIRIParameterOptions(
                  compareIRIAvailableMonthList,
                  'compare_forecast_month_list',
                )}
                id="forecast_category"
              />
              <Select
                placeholder="Select Forecast Lead Time"
                className="my-3 filter-select-placeholder time-input"
                type="select"
                onChange={handleCompareForecastLeadTimeChange}
                required={true}
                isMulti={false}
                value={compareIRIForecastTime?.lead_time}
                options={renderIRIParameterOptions(
                  compareIRIAvailableLeadTimeList,
                  'compare_forecast_lead_time_list',
                )}
                id="forecast_category"
              />
            </>
          );
        }
      }
    }
  };

  const handleForecastCompareYearMonth = e => {
    forecastCompareYearMonthHandler(e.target.value);
  };

  const onMenuOpen = () => {
    setIsDataSourceOpen(true);
  };

  const onMenuClose = () => {
    setIsDataSourceOpen(false);
  };
  //used to add more date field on click of + icon
  const addMoreDateFieldWhenClickedOnPlus = () => {
    return (
      <>
        <span
          className="cursor-ponter orangeColor date-style"
          onClick={() => setIsClickedOnPlus(!isClickedOnPlus)}
        >
          <BiMinus size={20} />
          <span className="mx-2">{TRANSLATIONS.FILTER.COMPARE} </span>
        </span>
        <span>
          <FormGroup check>
            <Row className="compare-style">
              {isCompare.map(item => {
                return (
                  <>
                    <Col sm={4} lg={4} md={4} className="mt-2 label-item">
                      <Label check>
                        <Input
                          type="radio"
                          name="radio1"
                          value={item.value}
                          defaultChecked={item.selected}
                          onChange={handleCompareToggle}
                        />{' '}
                        {item.value}
                      </Label>
                    </Col>{' '}
                  </>
                );
              })}
            </Row>
          </FormGroup>
        </span>

        {handleCompareLocationRender()}
        <div className="mt-2 mb-3 data-source-field  margin-source-data">
          <Select
            id="multi-select-data-source"
            name="data-sources"
            placeholder="Select data source"
            defaultValue={selectedDataSource.secondDataSource[0]}
            options={dataSources.secondDataSources || []}
            value={selectedDataSource.secondDataSource[0] || []}
            onChange={handleCompareDataSourceChange}
            isMulti={false}
            required={true}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
          />
        </div>
        {renderCompareDates(isCompare)}
      </>
    );
  };
  const handleOnPlaceChange = event => {
    if (event && event?.target && event?.target?.value === '') {
      setLocation('');

      props.handleCountry(country);
      props.handleAdvancedSearch({
        state: state?.value || '',
        district: selectedDisctrict?.value || '',
      });
    } else {
      setLocation(event.target.value);
    }
  };

  // const handleInitialForecastYearMonth = type => {
  //   return moment(selectedForecastYearMonth?.map[type]).format('YYYY-MM');
  // };

  // const handleforecastYearMonth = e => {
  //   forecastYearMonthHandler(e.target.value);
  // };
  // const isFirstRun = useRef(true);

  const handleInitialDate = type => {
    if (isCompare?.length && isCompare.some(item => item.name === 'graph' && item.selected)) {
      return initialSelectedDate?.graph[type];
    } else {
      return initialSelectedDate?.map[type];
    }
  };

  useEffect(() => {
    if (selectedDataSource?.firstDataSource[0]?.label === 'GFS - NOAA') {
      const nwdate = new Date(updatedSelectedDate?.startDate);
      nwdate.setDate(nwdate.getDate() + 15);
      const x = moment(nwdate).format(DATE_FORMAT);
      if (new Date(x).getTime() < new Date(updatedSelectedDate?.maxEndDate).getTime()) {
        setSelectedDate(prev => {
          return {
            ...prev,
            map: {
              ...initialSelectedDate.map,
              // startDate: e.target.value,
              endDate: x,
            },
            graph: {
              ...initialSelectedDate.graph,
              // startDate: e.target.value,
              endDate: x,
            },
          };
        });
        setFinalNoaaEndDate(x);
        setFinalNoaaMaxEndDate(x);
      } else if (new Date(x).getTime() > new Date(updatedSelectedDate?.maxEndDate).getTime()) {
        setSelectedDate(prev => {
          return {
            ...prev,
            map: {
              ...initialSelectedDate.map,
              // startDate: e.target.value,
              endDate: updatedSelectedDate?.maxEndDate,
            },
            graph: {
              ...initialSelectedDate.graph,
              // startDate: e.target.value,
              endDate: updatedSelectedDate?.maxEndDate,
            },
          };
        });
        setFinalNoaaEndDate(updatedSelectedDate?.maxEndDate);
        setFinalNoaaMaxEndDate(updatedSelectedDate?.maxEndDate);
      } else {
        setSelectedDate(prev => {
          return {
            ...prev,
            map: {
              ...initialSelectedDate.map,
              // startDate: e.target.value,
              endDate: updatedSelectedDate['endDate'],
            },
            graph: {
              ...initialSelectedDate.graph,
              // startDate: e.target.value,
              endDate: updatedSelectedDate['endDate'],
            },
          };
        });
        setFinalNoaaEndDate(updatedSelectedDate['endDate']);
        setFinalNoaaMaxEndDate(updatedSelectedDate['endDate']);
      }
    }
  }, [updatedSelectedDate]);

  const renderToolTip = () => {
    return (
      <ReactTooltip id="view_graph" effect="solid" place="right" type="info" className="bg-dark">
        {TRANSLATIONS.FILTER.MEDIAN_VIEW_GRAPH_DISABLE}
      </ReactTooltip>
    );
  };

  const handleInitialCompareDate = type => {
    return finalSelectedDate[type];
  };

  useEffect(() => {
    if (selectedDataSource?.secondDataSource[0]?.label === 'GFS - NOAA') {
      const nwdate = new Date(updatedSelectedCompareDate?.startDate);
      nwdate.setDate(nwdate.getDate() + 15);
      const x = moment(nwdate).format(DATE_FORMAT);
      if (new Date(x).getTime() < new Date(updatedSelectedCompareDate?.maxEndDate).getTime()) {
        setFinalSelectedDate(prev => {
          return {
            ...prev,
            endDate: x,
          };
        });
        setFinalNoaaCompareEndDate(x);
        setFinalNoaaCompareMaxEndDate(x);
      } else if (
        new Date(x).getTime() > new Date(updatedSelectedCompareDate?.maxEndDate).getTime()
      ) {
        setFinalSelectedDate(prev => {
          return {
            ...prev,
            endDate: updatedSelectedCompareDate?.maxEndDate,
          };
        });
        setFinalNoaaCompareEndDate(updatedSelectedCompareDate?.maxEndDate);
        setFinalNoaaCompareMaxEndDate(updatedSelectedCompareDate?.maxEndDate);
      } else {
        setFinalSelectedDate(prev => {
          return {
            ...prev,
            endDate: updatedSelectedCompareDate['endDate'],
          };
        });
        setFinalNoaaCompareEndDate(updatedSelectedCompareDate['endDate']);
        setFinalNoaaCompareMaxEndDate(updatedSelectedCompareDate['endDate']);
      }
    }
  }, [updatedSelectedCompareDate]);

  const handleOnChangeBaseTime = selectedTime => {
    setSelectedBaseTime(selectedTime);
    // const validTimeArr = staticResourceData?.filter(
    //   (item) => item.base_time === selectedTime.value,
    // );

    if (SHOW_VALIDTIME_AND_LAYERS.includes(selectedEcmwfProducts?.slug)) {
      const modifiedArr = selectedTime?.validTime;

      setValidTimeOptions(modifiedArr); // by default selecting first element as options
      setSelectedValidTime(modifiedArr[0]); // by default selecting first element
      if (selectedEcmwfProducts?.slug === PARAMETER_SLUGS.SOIL_MOISTURE) {
        let sortedLayerOptions = [];
        sortedLayerOptions = staticResourceData?.find(
          each => each?.title === SOIL_MOISTURE_LAYER_ECMWF,
        );

        setValidLayerOptions(sortedLayerOptions?.values);
        setSelectedValidLayer(sortedLayerOptions?.values?.[0]);
      }
      if (selectedEcmwfProducts?.slug === PARAMETER_SLUGS.PRECEIPITATION_SEAS5) {
        let foreCastTypeOptions = [];
        const ForecastListData = staticResourceData?.find(
          each => each?.title === FORECAST_LIST_ECMWF,
        );

        foreCastTypeOptions = ForecastListData?.values;

        setForecastType({
          forecastTypeOptions: foreCastTypeOptions,
          selectedForecastType: foreCastTypeOptions?.[0],
        });

        // setForecastType({
        //   forecastTypeOptions: foreCastTypeOptions,
        //   selectedForecastType: foreCastTypeOptions?.[0],
        // });
      }
    }
    if (SHOW_DAY_AND_QUANTILE.includes(selectedEcmwfProducts?.slug)) {
      const ecmwfDayData = staticResourceData?.find(each => each.title === 'Day');

      const ecmwfQuantileData = staticResourceData?.find(each => each?.title === 'Quantile');

      if (ecmwfQuantileData) {
        setDaysData({
          daysOptions: ecmwfDayData?.values,
          selectedDay: ecmwfDayData?.values?.[0],
        });
      }

      setQuantileData({
        quantileOptions: ecmwfQuantileData?.values,
        selectedQuantile: ecmwfQuantileData?.values?.[0],
      });
    }
  };

  const handleOnChangeValidTime = validTime => {
    setSelectedValidTime(validTime);
    if (validTime?.layer) {
      let sortedLayerOptions = [];
      if (validTime?.layer) {
        sortedLayerOptions = _.sortBy(validTime?.layer, 'value');
      }
      setValidLayerOptions(sortedLayerOptions);
      setSelectedValidLayer(sortedLayerOptions?.[0]);
    }
    if (validTime?.forecastType) {
      let foreCastTypeOptions = [];
      if (validTime?.forecastType) {
        foreCastTypeOptions = _.sortBy(validTime?.forecastType, 'value');
      }
      setForecastType({
        forecastTypeOptions: foreCastTypeOptions,
        selectedForecastType: foreCastTypeOptions?.[0],
      });
    }
  };

  const handleOnChangeValidLayer = validlayer => {
    setSelectedValidLayer(validlayer);
  };
  const handleOnChangeForecastType = e => {
    setForecastType(oldState => ({
      ...oldState,
      selectedForecastType: e,
    }));
  };

  const handleOnChangeDays = selectedValue => {
    setDaysData(oldState => ({ ...oldState, selectedDay: selectedValue }));
    if (selectedValue?.quantile) {
      const sortedQuantile = selectedValue?.quantile
        .map(data => {
          return {
            ...data,
            qunatile: Number(data.value),
          };
        })
        .sort((a, b) => a.qunatile - b.qunatile);
      setQuantileData({
        quantileOptions: sortedQuantile,
        selectedQuantile: sortedQuantile?.[0],
      });
    }
  };
  const handleOnChangeQuantile = selectedQuantile => {
    setQuantileData(oldState => ({
      ...oldState,
      selectedQuantile,
    }));
  };
  return (
    <>
      {isHideBar ? (
        <div className="expand-bar">
          <span className="cursor-ponter navyBlueColor ">
            {t('FILTER.EXPAND_BAR')}
            <span>
              <MdKeyboardArrowLeft
                className="hide-icon expand-margin"
                onClick={() => props.setBar(!isHideBar)}
                size={25}
              />
            </span>
          </span>
        </div>
      ) : (
        <div className="filter-sidebar-section">
          <div className="filter-div">
            <Card className="card-style">
              <CardBody>
                <Form onSubmit={onSubmitHandler} className="filter-control-dp">
                  {' '}
                  <div className="search-container">
                    <div className="d-flex justify-content-end cursor-ponter navyBlueColor mb-3">
                      {' '}
                      <span className="hidebar-show">{TRANSLATIONS.FILTER.HIDE_BAR}</span>{' '}
                      <span>
                        <MdKeyboardArrowRight
                          className="hide-icon hide-margin"
                          onClick={() => props.setBar(!isHideBar)}
                          size={25}
                        />
                      </span>
                    </div>{' '}
                    {!fromStaticResources ? (
                      // <div className='search-tab-wather-forecast'>
                      // <InputGroup className="d-flex input-location">
                      //   <Autocomplete
                      //     apiKey={process.env.REACT_APP_LOCATION_API_KEY}
                      //     id="search-input"
                      //     className={fromStaticResources ? 'static-search-input' : 'search-input'}
                      //     ref={autoCompleteRef}
                      //     onPlaceSelected={handleSearch}
                      //     onChange={handleOnPlaceChange}
                      //     inputAutocompleteValue={location}
                      //     defaultValue={fromStaticResources ? defaultRegionName : location}
                      //     placeholder="Search for a location"
                      //     disabled={fromStaticResources}
                      //   />
                      //   {!fromStaticResources && (
                      //     <div>
                      //       <InputGroupAddon addonType="append">
                      //         <InputGroupText>
                      //           <BsSearch
                      //             id="inputIcon"
                      //             onClick={() => handleFilter(location, cordinates)}
                      //             className="cursor-ponter"
                      //             size={19}
                      //             disabled={fromStaticResources}
                      //           />
                      //         </InputGroupText>
                      //       </InputGroupAddon>
                      //     </div>
                      //   )}
                      // </InputGroup>
                      // </div>

                      <div className="search-tab-wather-forecast">
                        <InputGroup>
                          <Autocomplete
                            apiKey={process.env.REACT_APP_LOCATION_API_KEY}
                            id="search-input"
                            className={fromStaticResources ? 'static-search-input' : 'search-input'}
                            ref={autoCompleteRef}
                            value={location}
                            onPlaceSelected={handleSearch}
                            onChange={handleOnPlaceChange}
                            inputAutocompleteValue={location}
                            defaultValue={fromStaticResources ? defaultRegionName : location}
                            placeholder="Search for a location"
                            disabled={fromStaticResources}
                          />
                          {!fromStaticResources && (
                            <div className="wf-search-icon weather-forecat-icons">
                              <InputGroupAddon addonType="append">
                                <BsSearch
                                  id="inputIcon"
                                  onClick={() =>
                                    location && countryJson
                                      ? handleFilter(location, cordinates)
                                      : {}
                                  }
                                  // className={"cursor-ponter mr-1 weather-forecat-icons-margin"}
                                  className={
                                    countryJson !== ''
                                      ? 'cursor-ponter mr-1 weather-forecat-icons-margin'
                                      : 'serch-opacity mr-1 weather-forecat-icons-margin '
                                  }
                                  size={19}
                                  color="#2A547C"
                                  disabled={fromStaticResources}
                                />

                                {location ? (
                                  <RxCross2
                                    className="cursor-ponter "
                                    size={19}
                                    color="#656565"
                                    onClick={() => {
                                      setLocation('');
                                      setSearchMarker('');
                                    }}
                                  />
                                ) : (
                                  ''
                                )}
                              </InputGroupAddon>
                            </div>
                          )}
                        </InputGroup>
                      </div>
                    ) : (
                      defaultRegionName &&
                      (selectedDataSource?.firstDataSource?.[0]?.value !== 'ECMWF (Static)' ? (
                        <InputGroup className="d-flex input-location">
                          <Autocomplete
                            apiKey={process.env.REACT_APP_LOCATION_API_KEY}
                            id="search-input"
                            className={fromStaticResources ? 'static-search-input' : 'search-input'}
                            ref={autoCompleteRef}
                            onPlaceSelected={handleSearch}
                            onChange={handleOnPlaceChange}
                            inputAutocompleteValue={location}
                            defaultValue={fromStaticResources ? defaultRegionName : location}
                            value={fromStaticResources ? defaultRegionName : location}
                            placeholder="Search for a location"
                            disabled={fromStaticResources}
                          />
                        </InputGroup>
                      ) : (
                        ''
                      ))
                    )}
                  </div>
                  {countryJson === '' ? (
                    <div className="loading-message">
                      Please wait, data is loading
                      <div className="loading-dots">
                        <span>.</span>
                        <span>.</span>
                        <span>.</span>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {!fromStaticResources && countryJson !== '' ? (
                    // <div className="mb-3">
                    //   <span className="cursor-ponter orangeColor mx-3" onClick={getCurrentLocation}>
                    //     {' '}
                    //     <BiCurrentLocation size={20} />
                    //     <span className="mx-1">{TRANSLATIONS.FILTER.CHOOSE_CURRENT_LOCATION}</span>
                    //   </span>
                    // </div>
                    <div className="mb-3 mt-3 d-flex">
                      <div className="cursor-ponter orangeColor mx-3" onClick={getCurrentLocation}>
                        {' '}
                        <BiCurrentLocation size={20} />
                      </div>
                      <div
                        className="mx-1 d-inline-block orangeColor choose-current-location cursor-ponter"
                        onClick={getCurrentLocation}
                      >
                        {TRANSLATIONS.FILTER.CHOOSE_CURRENT_LOCATION}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {!isAdvanceSearch && !fromStaticResources ? (
                    <div className="mb-3">
                      <span
                        className="cursor-ponter orangeColor mx-3"
                        onClick={() => setAdvanceSearch(!isAdvanceSearch)}
                      >
                        {' '}
                        <AdvancedSearch />
                        <span className="mx-2">{TRANSLATIONS.FILTER.ADVANCE_SEARCH}</span>
                      </span>
                    </div>
                  ) : (
                    ''
                  )}
                  {isAdvanceSearch ? handleAdvanceSearchRender() : null}
                  {selectedDataSource?.firstDataSource?.[0]?.value === 'ECMWF (Static)' ? (
                    <div className="mx-3 mt-2 mb-3 wf-country">
                      <Select
                        id="multi-select-county"
                        name="country"
                        placeholder={TRANSLATIONS.FILTER.SELECT_COUNTRY}
                        options={renderAPIParameterOptions(countryList, 'country')}
                        // onChange={(e) => onHanldeEcmwfCountry(e)}
                        onChange={handleSelectedItem}
                        isMulti={false}
                        required={true}
                        value={defaultSelectedOption}
                        isDisabled={
                          !_.isEmpty(countryData) &&
                          countryData?.country_name !== SOUTH_ASIA_COUNTRY
                        }
                      />
                    </div>
                  ) : (
                    ''
                  )}
                  <div>
                    <div className="mx-3">
                      <Label className="region-label">{t('Data Source')}</Label>
                    </div>
                    <div className="mb-3 data-source-field-one icon-div">
                      <Select
                        id="multi-select-data-source"
                        name="data-sources"
                        placeholder="Select Data Source"
                        defaultValue={selectedDataSource.firstDataSource[0]}
                        options={dataSources.firstDataSources || []}
                        value={selectedDataSource.firstDataSource[0]}
                        onChange={handleOnChange}
                        isMulti={false}
                        required={true}
                        className="ow-width"
                        isLoading={
                          _.isEmpty(selectedDataSource.firstDataSource) &&
                          Weather.isWeatherForecastSourcesRequesting
                        }
                      />
                      <span className="info-style">
                        <i
                          className="cursor-ponter fa fa-info info-icon-edit-btn"
                          id="info-icon-tool-tip"
                          data-tip
                          data-for="info-icon-tool-tip"
                          onClick={handleInfo}
                        />
                        <ReactTooltip id="info-icon-tool-tip" effect="solid" place="top">
                          {t('DROUGHT_MONITORING.CLICK_HERE_TO_KNOW_MORE')}
                        </ReactTooltip>
                      </span>
                    </div>
                  </div>
                  {!fromStaticResources ? (
                    selectedDataSource?.firstDataSource[0]?.id === DATA_SOURCES.IRI.id ? (
                      <>
                        <div className="date-style">
                          <Select
                            placeholder="Select Forecast Time Year"
                            className=" my-3 filter-select-placeholder time-input"
                            type="select"
                            onChange={handleForecastTimeYear}
                            required={true}
                            isMulti={false}
                            value={IRIForecastTime?.year}
                            options={renderIRIParameterOptions(
                              IRIAvailableYearList,
                              'forecast_year_list',
                            )}
                            id="forecast_category"
                          />
                          <Select
                            placeholder="Select Forecast Time Month"
                            className=" my-3 filter-select-placeholder time-input"
                            type="select"
                            onChange={handleForecastTimeMonth}
                            required={true}
                            isMulti={false}
                            value={IRIForecastTime?.month}
                            options={renderIRIParameterOptions(
                              IRIAvailableMonthList,
                              'forecast_month_list',
                            )}
                            id="forecast_category"
                          />
                          <Select
                            placeholder="Select Forecast Lead Time"
                            className=" my-3 filter-select-placeholder time-input"
                            type="select"
                            onChange={handleForecastLeadTimeChange}
                            required={true}
                            isMulti={false}
                            value={IRIForecastTime?.lead_time}
                            options={renderIRIParameterOptions(
                              IRIAvailableLeadTimeList,
                              'forecast_lead_time_list',
                            )}
                            id="forecast_category"
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="date-style">
                          <Input
                            type="date"
                            name="startDate"
                            placeholder="Start Date"
                            bsSize="sm"
                            className="my-3 input-style-width margin-date1"
                            value={handleInitialDate('startDate')}
                            onChange={e => {
                              if (selectedDataSource?.firstDataSource[0]?.label === 'GFS - NOAA') {
                                setNoaaMaxEndDate(true);
                                // handleSetNoaaEndDate();
                              }
                              setSelectedDate(prev => {
                                return {
                                  ...prev,
                                  map: {
                                    ...initialSelectedDate.map,
                                    startDate: e.target.value,
                                  },
                                  graph: {
                                    ...initialSelectedDate.graph,
                                    startDate: e.target.value,
                                  },
                                };
                              });
                              setUpdatedSelectedDate(prevdate => {
                                return {
                                  ...prevdate,
                                  startDate: e.target.value,
                                };
                              });
                            }}
                            min={handleInitialDate('minStartDate')}
                            max={handleInitialDate('maxStartDate')}
                            disabled={isOpenWeatherDataSource(selectedDataSource?.firstDataSource)}
                          />
                          <Input
                            type="date"
                            name="endDate"
                            placeholder="End Date"
                            bsSize="sm"
                            className="my-2 input-style-width margin-date1"
                            value={noaaMaxEndDate ? finalNoaaEndDate : handleInitialDate('endDate')}
                            onChange={e => {
                              setFinalNoaaEndDate(e.target.value);
                              setSelectedDate(prev => {
                                return {
                                  ...prev,
                                  map: {
                                    ...initialSelectedDate.map,
                                    endDate: e.target.value,
                                  },
                                  graph: {
                                    ...initialSelectedDate.graph,
                                    endDate: e.target.value,
                                  },
                                };
                              });
                              if (selectedDataSource?.firstDataSource[0]?.label !== 'GFS - NOAA') {
                                setUpdatedSelectedDate(prevdate => {
                                  return {
                                    ...prevdate,
                                    endDate: e.target.value,
                                  };
                                });
                              }
                            }}
                            min={handleInitialDate('startDate')}
                            max={
                              noaaMaxEndDate ? finalNoaaMaxEndDate : handleInitialDate('maxEndDate')
                            }
                            disabled={isOpenWeatherDataSource(selectedDataSource?.firstDataSource)}
                          />
                        </div>
                      </>
                    )
                  ) : (
                    <>
                      {selectedDataSource?.firstDataSource?.[0]?.value === 'ECMWF (Static)' ? (
                        <div className="mx-3 mt-2 mb-3">
                          <Label className="region-label">{t('ECMWF (static)')}</Label>
                          <Select
                            id="multi-select-year"
                            name="static-resource-year"
                            options={ecmwfProducts || []}
                            value={selectedEcmwfProducts}
                            onChange={e => handleEcmwfProducts(e)}
                            isMulti={false}
                            required={true}
                          />
                        </div>
                      ) : (
                        ''
                      )}

                      {selectedDataSource?.firstDataSource?.[0]?.value === 'ECMWF (Static)' ? (
                        <div className="mx-3 mt-2 mb-3">
                          <Label className="region-label">{t('Base time')}</Label>
                          <Select
                            id="baseTime"
                            name="baseTime"
                            options={staticResourceBaseTimeData || []}
                            value={selectedBasetime}
                            onChange={e => handleOnChangeBaseTime(e)}
                            isMulti={false}
                            required={true}
                          />
                        </div>
                      ) : (
                        ''
                      )}

                      {SHOW_VALIDTIME_AND_LAYERS.includes(selectedEcmwfProducts?.slug) &&
                      selectedDataSource?.firstDataSource?.[0]?.value === 'ECMWF (Static)' ? (
                        <div className="mx-3 mt-2 mb-3">
                          <Label className="region-label">{t('Valid time')}</Label>
                          <Select
                            id="validTime"
                            name="validTime"
                            options={validTimeOptions || []}
                            value={selectedValidTime}
                            onChange={e => handleOnChangeValidTime(e)}
                            // onChange={e => handleEcmwfProducts(e)}
                            isMulti={false}
                            required={true}
                          />
                        </div>
                      ) : (
                        ''
                      )}

                      {selectedEcmwfProducts?.slug === PARAMETER_SLUGS.SOIL_MOISTURE &&
                      selectedDataSource?.firstDataSource?.[0]?.value === 'ECMWF (Static)' ? (
                        <div className="mx-3 mt-2 mb-3">
                          <Label className="region-label">{t('Layers')}</Label>
                          <Select
                            id="soli_moisture"
                            name="soli_moisture"
                            options={validLayerOptions || []}
                            value={selectedValidLayer}
                            onChange={e => handleOnChangeValidLayer(e)}
                            isMulti={false}
                            required={true}
                          />{' '}
                        </div>
                      ) : (
                        ''
                      )}

                      {selectedEcmwfProducts.slug === PARAMETER_SLUGS.PRECEIPITATION_SEAS5 &&
                      selectedDataSource?.firstDataSource?.[0]?.value === 'ECMWF (Static)' ? (
                        <div className="mx-3 mt-2 mb-3">
                          <Label className="region-label">{t('Forecast')} </Label>
                          <Select
                            id="precipitation_seas5"
                            name="precipitation_seas5"
                            options={forecastType?.forecastTypeOptions || []}
                            value={forecastType?.selectedForecastType}
                            onChange={e => handleOnChangeForecastType(e)}
                          />{' '}
                        </div>
                      ) : (
                        ''
                      )}

                      {SHOW_DAY_AND_QUANTILE.includes(selectedEcmwfProducts?.slug) &&
                      selectedDataSource?.firstDataSource?.[0]?.value === 'ECMWF (Static)' ? (
                        <div className="mx-3 mt-2 mb-3">
                          <Label className="region-label">{t('Days')} </Label>
                          <Select
                            id="days"
                            name="days"
                            options={daysData?.daysOptions || []}
                            value={daysData?.selectedDay}
                            onChange={e => handleOnChangeDays(e)}
                          />{' '}
                          <div className=" mt-2 ">
                            <Label className="region-label">{t('Quantile')}</Label>
                            <Select
                              id="quantil"
                              name="quantil"
                              options={quantileData?.quantileOptions || []}
                              value={quantileData?.selectedQuantile}
                              onChange={e => handleOnChangeQuantile(e)}
                            />{' '}
                          </div>
                        </div>
                      ) : (
                        ''
                      )}

                      {selectedDataSource?.firstDataSource?.[0]?.value === 'ERPAS (Static)' ? (
                        <div className="mx-3 mt-2 mb-3">
                          <Select
                            id="multi-select1"
                            name="date-range"
                            options={selectedDateRange || []}
                            defaultValue={selectedDefaultDateRange}
                            value={selectedDefaultDateRange}
                            onChange={e => handleDateSelectionChange(e)}
                            isMulti={false}
                            required={true}
                          />
                        </div>
                      ) : (
                        ''
                      )}

                      {selectedDataSource?.firstDataSource?.[0]?.value === 'ERPAS (Static)' ? (
                        <div className="mx-3 mt-2 mb-3">
                          <Select
                            id="multi-select-year"
                            name="static-resource-year"
                            options={selectedYearList || []}
                            value={selectedDefaultYear}
                            onChange={e => handleYearSelectionChange(e)}
                            isMulti={false}
                            required={true}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                  {!fromStaticResources &&
                  !isOpenWeather &&
                  selectedDataSource?.firstDataSource[0]?.name != 'IRI' ? (
                    <div className="date-style">
                      <div className="mt-3 temp-style">
                        <div className="mt-2 mb-3 margin-mean">
                          <Select
                            id="multi-select"
                            name="aggregation"
                            placeholder={TRANSLATIONS.FILTER.SELECT_TEMPORAL_AGGREGATION}
                            options={TEMPORAL_AGGREGATION}
                            value={temporalAggregation}
                            onChange={handleTemporalAggregation}
                            isMulti={false}
                            required={true}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {!fromStaticResources && (
                    <div className="date-style">
                      {isClickedOnPlus && !region.length ? (
                        addMoreDateFieldWhenClickedOnPlus()
                      ) : (
                        <span
                          className={
                            region?.length || !_.isEmpty(location)
                              ? 'orangeColor orangeFadeColor'
                              : 'cursor-ponter orangeColor'
                          }
                          onClick={setCompareToggle}
                        >
                          <BiPlus size={20} />
                          <span className="mx-2">{TRANSLATIONS.FILTER.COMPARE}</span>
                        </span>
                      )}
                    </div>
                  )}
                  {!fromStaticResources && (
                    <>
                      <div
                        className={
                          compareData == GRAPH && isDataSourceOpen
                            ? 'mx-3 filter-submit wf-filter-compare-data-source-open'
                            : 'mx-3 filter-submit wf-filter-margin'
                        }
                      >
                        <div
                          className="apply-layers"
                          id="apply_layers"
                          data-tip
                          data-for="apply_layers"
                        >
                          <Button
                            className="navyBlueBgColor m-auto submitBtnClass"
                            onClick={handleApplyLayers}
                            disabled={!isApplyLayer}
                          >
                            {TRANSLATIONS.FILTER.APPLY_LAYERS}
                          </Button>
                          {showToolTip && region?.length ? (
                            <ReactTooltip id="apply_layers" effect="solid" place="top">
                              {TRANSLATIONS.FILTER.PLEASE_REMOVE_MARKERS}
                            </ReactTooltip>
                          ) : (
                            ''
                          )}
                          {showMapPreferenceTooltip && !showToolTip ? (
                            <ReactTooltip
                              id="apply_layers"
                              effect="solid"
                              place="top"
                              type="info"
                              className="apply-layer-tooltip-st"
                            >
                              {TRANSLATIONS.FILTER.PLEASE_REMOVE_MAP_PREFERENCE_LAYER}
                            </ReactTooltip>
                          ) : (
                            ''
                          )}
                        </div>
                        <div
                          className="update-graph mt-3"
                          id="view_graph"
                          data-tip
                          data-for="view_graph"
                        >
                          <Button
                            outline
                            className="navyBlueBgColor-outline m-auto"
                            onClick={displayChart}
                            disabled={!isUpdateGraph}
                          >
                            {TRANSLATIONS.FILTER.VIEW_GRAPH}
                          </Button>
                          {!isUpdateGraph &&
                          isIriNotSelected &&
                          temporalAggregation.value == 'median' &&
                          !isClickedOnPlus
                            ? renderToolTip()
                            : isClickedOnPlus && compareData === GRAPH
                            ? selectedDataSource?.secondDataSource[0] && !isUpdateGraph
                              ? renderToolTip()
                              : ''
                            : ''}
                          {!hasSelectedParamChart ? (
                            <ReactTooltip id="view_graph" effect="solid" place="top" type="info">
                              {TRANSLATIONS.FILTER.NO_CHART_FOR_PARAM}
                            </ReactTooltip>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {selectedDataSource?.firstDataSource?.[0]?.value === 'ECMWF (Static)' ? (
                    <Button
                      className="navyBlueBgColor m-auto submitBtnClass ecmwf-submit-btn"
                      onClick={onHandleEcmwf}
                      disabled={isEcmwfControlsLoading}
                    >
                      {TRANSLATIONS.FILTER.APPLY_LAYERS}
                    </Button>
                  ) : (
                    ''
                  )}
                </Form>
              </CardBody>
            </Card>
          </div>
        </div>
      )}
    </>
  );
}

Filter.propTypes = {
  setBar: PropTypes.func.isRequired,
  isHideBar: PropTypes.bool.isRequired,
  setWhenClicked: PropTypes.func,
  handleCordinates: PropTypes.func,
  fromStaticResources: PropTypes.bool,
  handleParamData: PropTypes.func,
  handleRegionId: PropTypes.func,
  dates: PropTypes.object,
  handleSelectedDataSourceId: PropTypes.func,
};

export default Filter;
