import { Document, Font, Image, Link, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import NationalTiff from '../../assets/fonts/National/national-regular.ttf';

import BannerImage from '../../assets/Images/bulletin-bkg-img.png';
import { BULLETIN_INDICATORS_INFO } from '../../Components/constants';
import CGIAR_NEW from '../../assets/Images/cgiar-pdf-footer-logo.png';
import IWMI_NEW from '../../assets/Images/iwmi-pdf-footer-logo.png';

const styles = StyleSheet.create({
  // page: {
  //   paddingVertical: 8,
  // },
  page: {
    flexDirection: 'column',
    position: 'relative',
    marginBottom: 10,
    marginTop: 5,
  },
  marginBottomSection: {
    marginBottom: 10,
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '200',
  },
  bg_container: {
    position: 'relative',
    padding: 0,
    marginBottom: 10,
  },
  bannerDetailsContainer: {
    paddingVertical: 40,
    paddingHorizontal: 15,
    display: 'flex',
    justifyContent: 'space-between',
    color: '#FFFFFF',
    height: '200',
    fontFamily: 'National',
    fontWeight: 500,
  },
  mainHeader: {
    marginBottom: 20,
  },
  bulletinTypeText: {
    fontSize: 14,
    textTransform: 'uppercase',
  },
  mainTitle: {
    fontSize: 20,
  },
  createdUserText: {
    fontSize: 12,
  },

  bulletinUsageText: {
    backgroundColor: '#2A547C',
    color: '#FFFFFF',
    width: '40%',
    borderTopRightRadius: 24,
    borderBottomRightRadius: 24,
    fontSize: 16,
    paddingHorizontal: 15,
    paddingVertical: 6,
  },

  Description: {
    fontSize: 10,
    padding: 3,
    fontFamily: 'National',
  },
  description_container: {
    border: '1 solid #E8E7E7',
    borderRadius: 10,
  },

  indicatorInfoText: {
    color: '#065086',
    fontFamily: 'National',
    fontSize: 14,
    fontWeight: 500,
  },

  textUnderline: {
    textDecoration: 'underline',
    textDecorationColor: '#065086',
  },

  infoBoldText: {
    color: '#434343',
    fontSize: 12,
    fontWeight: 700,
    fontFamily: 'National',
  },

  mapImage: {
    height: '176px',
    width: '100%',
    objectFit: 'cover',
    marginBottom: 10,
  },

  disclaimFooterContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  disclaimerHeader: {
    marginRight: 5,
  },
  disclaimerText: {
    width: '100%',
  },
  disclaimContainer: {
    marginHorizontal: 15,
    display: 'flex',
    color: '#938c8c',
    fontSize: 10,
    fontFamily: 'National',
    fontWeight: 500,
    flexDirection: 'row',
    // justifyContent: 'space-between',
    marginBottom: 10,
  },

  footerContainer: {
    backgroundColor: '#2A547C',
    fontFamily: 'National',
    fontWeight: 500,
    color: '#FFFFFF',
    fontSize: 12,
  },
  footerClientSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 15,
    paddingHorizontal: 15,
    borderBottom: '1px solid #FFFFFF',
  },
  footerImageSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '50%',
  },
  footerIwmiImage: {
    maxWidth: '100%',
    width: 90,
    height: 57,
  },
  footerCgiarImage: {
    maxWidth: '100%',
    width: 50,
    height: 57,
  },
  footerCopyright: {
    textAlign: 'center',
    fontSize: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  footerCopyrightLink: {
    textDecoration: 'none',
    color: '#FFFFFF',
  },
  copyright: {
    color: 'white',
    fontSize: 8,
    marginLeft: 1,
    fontWeight: 'bold',
    fontFamily: 'National',
    textDecoration: 'none',
  },
  imageRow: {
    flexDirection: 'row',

    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 16,
  },
  imageItem: {
    height: 45,
    maxWidth: '100%',
    marginRight: 4,
  },
  section_margin: {
    marginTop: 6,
    marginHorizontal: 15,
  },
  map_margin: {
    marginTop: 4,
    marginHorizontal: 10,
  },
});

Font.register({
  family: 'National',
  fonts: [
    {
      src: NationalTiff, // URL to the National-Regular font file
    },
  ],
});

function DownloadPdfComponent({
  headerLogos,
  title,
  bulletinType,
  createdDate,
  userDetails,
  bulletUsageContent,
  mapImageUrls,
  indicatorsList,
  summaryData,
}) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.imageRow}>
          {headerLogos?.map(logo => (
            <Image key={logo} style={styles.imageItem} src={logo} alt={logo} />
          ))}
        </View>

        {/* Banner Image Section */}
        <View style={styles.bg_container}>
          <Image style={styles.backgroundImage} src={BannerImage} />
          <View style={styles.bannerDetailsContainer}>
            <View style={styles.mainHeader}>
              <Text style={styles.bulletinTypeText}>{bulletinType}</Text>
              <Text style={styles.mainTitle}>Bulletin Report: {title}</Text>
            </View>
            <View>
              <Text style={styles.createdUserText}>Prepared on : {createdDate}</Text>
              <Text style={styles.createdUserText}>Prepared by : {userDetails?.username}</Text>
            </View>
          </View>
        </View>

        {/* How to use Section */}
        <View style={styles.marginBottomSection}>
          <Text style={styles.bulletinUsageText}>How to use the bulletin</Text>
          <View style={styles?.section_margin}>
            <Text style={[styles.Description, styles.description_container]}>
              {bulletUsageContent}
            </Text>
          </View>
        </View>

        {/* Map View */}
        {mapImageUrls && Object.keys(mapImageUrls).length > 0
          ? Object.keys(indicatorsList).map(mapImageKey => (
              <View style={styles.marginBottomSection} key={mapImageKey}>
                <Text style={styles.bulletinUsageText}>Indicator summary</Text>
                <View style={styles.map_margin}>
                  {mapImageUrls[mapImageKey] && (
                    <Image
                      style={styles.mapImage}
                      src={mapImageUrls[mapImageKey]}
                      alt={mapImageUrls[mapImageKey]}
                    />
                  )}
                </View>

                {/* Indicator Info */}
                <View style={styles.section_margin}>
                  <Text style={styles.indicatorInfoText}>
                    <Text style={styles.textUnderline}>Indicator</Text> Basic Info:
                  </Text>
                  {indicatorsList[mapImageKey]?.map(indicators => (
                    <Text style={styles.infoBoldText} key={indicators.name}>
                      {indicators.name} :{' '}
                      <Text style={styles.Description}>
                        {BULLETIN_INDICATORS_INFO[indicators.name]}
                      </Text>
                    </Text>
                  ))}
                </View>

                {/* Summary Info */}
                {summaryData[mapImageKey]?.length ? (
                  <View style={styles.section_margin}>
                    <Text style={styles.indicatorInfoText}>
                      <Text style={styles.textUnderline}>Summary</Text> of current condition:
                    </Text>
                    <Text style={styles.infoBoldText}>
                      <Text style={styles.Description}>{summaryData[mapImageKey]}</Text>
                    </Text>
                  </View>
                ) : null}
              </View>
            ))
          : null}
        {/* <View style={styles.disclaimFooterContainer}> */}
        {/* Disclaimer */}
        <View style={styles.disclaimContainer} wrap>
          <Text style={styles.disclaimerHeader}>Disclaimer : </Text>
          <Text style={styles.disclaimerText}>
            {'  '}All content within this bulletin is based upon the most current available data. As
            the drought is a dynamic situation, the current realities may differ from what is
            depicted in this document. The product has not been validated and used only the weather
            forecast and remote sensing observation. We welcome the feedback from the end-users and
            request you to provide field observations and any other details which can improve the
            product quality and prediction skills in the near future.
          </Text>
        </View>
        {/* Footer */}
        <View style={styles.footerContainer} wrap={false}>
          <View style={styles.footerClientSection}>
            <Text style={styles.test}>
              For further information please contact:{' '}
              <Link src="mailto:wrd@iwmi.org" style={styles.footerCopyrightLink}>
                wrd@iwmi.org
              </Link>
            </Text>
            <View style={styles.footerImageSection}>
              <Image style={styles.footerIwmiImage} src={IWMI_NEW} alt="iwmi" />
              <Image style={styles.footerCgiarImage} src={CGIAR_NEW} alt="cgiar" />
            </View>
          </View>
          <View style={styles.footerCopyright}>
            <Text>&copy;</Text>
            <Link src="https://www.iwmi.cgiar.org/copyright/" style={styles.footerCopyrightLink}>
              2024 International Water Management Institute | Terms of use
            </Link>
          </View>
        </View>
        {/* </View> */}
      </Page>
    </Document>
  );
}

export default DownloadPdfComponent;
