import React, { useEffect, useState } from 'react';
import Select from 'react-select';

const ManagesidebarCrops = props => {
  const [countryList, setCountryList] = useState();
  const [stateList, setStateList] = useState();
  const [districtList, setDistrictList] = useState();
  const [formData, setFormData] = useState({
    country: '',
    state: '',
    district: '',
  });
  useEffect(() => {
    let list = [];
    if (props.countryList) {
      let default_option = { value: 'select', label: 'select' };
      list.push(default_option);
      props.countryList.map(i => {
        list.push({
          value: i.id,
          label: i.name,
        });
      });
      setCountryList(list);
    }
  }, [props.countryList]);

  useEffect(() => {
    if (formData) {
      props.handleBreadCrumbsData(formData);
    }
  }, [formData]);

  useEffect(() => {
    if (props.initialValues) {
      setFormData(props.initialValues);
      props.handleBreadCrumbsData(props.initialValues);
    }
  }, [props.initialValues]);

  useEffect(() => {
    let list = [];
    if (props.stateList) {
      let default_option = { value: 'select', label: 'select' };
      list.push(default_option);
      props.stateList.map(i => {
        list.push({
          value: i.id,
          label: i.name,
        });
      });
      setStateList(list);
    }
  }, [props.stateList]);

  useEffect(() => {
    let list = [];
    if (props.districtList) {
      let default_option = { value: 'select', label: 'select' };
      list.push(default_option);
      props.districtList.map(i => {
        list.push({
          value: i.id,
          label: i.name,
        });
      });
      setDistrictList(list);
    }
  }, [props.districtList]);

  const handleSelectedCountry = (newValue, action) => {
    if (newValue.value === 'select') {
      formData.country = newValue;
      formData.state = '';
      formData.district = '';
    } else {
      formData.country = newValue;
    }
    setFormData({ ...formData });
    props.handleSelectedCountry(newValue, action);
  };
  const handleSelectedState = (newValue, action) => {
    if (newValue.value === 'select') {
      formData.state = '';
      formData.district = '';
    } else {
      formData.state = newValue;
    }
    setFormData({ ...formData });
    props.handleSelectedState(newValue, action);
  };

  const handleSelectedDistrict = (newValue, action) => {
    if (newValue.value === 'select') {
      formData.district = '';
    } else {
      formData.district = newValue;
    }
    setFormData({ ...formData });
    props.handleSelectedDistrict(newValue, action);
  };

  return (
    <div className="advance-search-fields crop-sidebar-section">
      <div className="mb-3">
        <p className="sidebar-crop-location">Select Region</p>
        <div className="mx-3 mb-3">
          <Select
            id="multi-select"
            name="country"
            placeholder="Country"
            // value="country"
            className="crop-sidebar-input"
            options={countryList}
            onChange={handleSelectedCountry}
            value={props.selectedCountry ? props.selectedCountry.name : ''}
            required={true}
          />
        </div>
        <div className="mx-3 mt-2 mb-3">
          <Select
            id="multi-select"
            name="state"
            placeholder="State"
            // value="state"
            className="crop-sidebar-input"
            options={stateList}
            onChange={handleSelectedState}
            value={props.selectedState ? props.selectedState.name : ''}
            required={true}
          />
        </div>
        <div className="mx-3 mt-2 mb-3">
          <Select
            id="multi-select"
            name="district"
            placeholder="District"
            // value="District"
            className="crop-sidebar-input"
            options={districtList}
            onChange={handleSelectedDistrict}
            value={props.selectedDistrict ? props.selectedDistrict.name : ''}
            required={true}
          />
        </div>
      </div>
    </div>
  );
};
export default ManagesidebarCrops;
