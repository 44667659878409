import React from 'react';
import {
  Button,
  Col,
  Row,
} from 'reactstrap';
import Select from 'react-select';
import { t } from 'react-multi-lang';

const Header = (props) => {
  const handleCropTypeChange = (value) => {
    props.handleCropTypeChange(value);
  };

  const renderAPIParameterOptions = list => {
    if (list) {
      let params = [...list];
      let options = [];
      if (params.length) {
        let default_option = { value: '', label: 'select all', id: 'select all' };
        options.push(default_option);
        params.map(param => {
          options.push(param);
          return true;
        });
        return options;
      }
    }
  };

  return (
    <>
      <Row className="district-profile-row">
        <Col sm="5" md="7" lg="7" xl="8">
          <Button
            className="param-button"
            style={{
              backgroundColor: '#5389C4',
              borderColor: '#5389C4',
            }}
            onClick={props.handleContingencyMeasuresCloseModal}
          >
            {t('CONTINGENCY_PLAN.MAP')}
          </Button>
        </Col>
        {props?.cropTypeData?.length !== 0 &&
        <Col sm="7" md="5" lg="5" xl="4">
          <Select
            id="multi-select"
            name="crop"
            options={renderAPIParameterOptions(props.cropTypeData)|| []}
            defaultValue={props.selectedCrop}
            value={props.selectedCrop}
            onChange={(e) => handleCropTypeChange(e)}
            isLoading={props.isCropTypeLoading}
            isMulti={false}
            required={true}
            placeholder="Select Crop"
            className="crop-items"
          />
        </Col>
        }
      </Row>
    </>
  );
};
export default Header;
