import Alerts from '../Components/Alert';
import Charts from '../Components/Charts';
import ContactUsForm from '../pages/new-landing-page/ContactUsForm';
import MapPreferences from '../pages/WeatherForecast/MapPreferences';
import HomePage from '../pages/new-landing-page/index';
import NewsEvents from '../pages/new-landing-page/NewsEvents';
import PartnersAndDonors from '../pages/new-landing-page/Partners';
import AboutUs from '../pages/new-landing-page/AboutUs/Firstsection';
import Tools from '../pages/new-landing-page/Tools';
import CountrySpecificComponent from '../pages/new-landing-page/CountrySpecificTool';
import PublicApi from '../pages/public-api-page/public-api';
import Dashboard from '../pages/dashboard';
import DroughtMonitor from '../pages/drought-monitor';
import { ReactComponent as ContingencyPlanIcon } from '../assets/Images/contigencyPlan.svg';
import { ReactComponent as WeatherForecast } from '../assets/Images/WeatherForecast.svg';
import { ReactComponent as Home } from '../assets/Images/home.svg';
import { ReactComponent as PublicAPI } from '../assets/Images/publicAPI.svg';
import { ReactComponent as DroughtMonitorIcon } from '../assets/Images/DroughtMonitor.svg';
import Register from '../pages/account/Register';
import Login from '../pages/account/Login';
import Publicapikey from '../pages/public-api-page/api-key';
import ManageStaticData from '../pages/manage-static-data';
import TermsConditions from '../pages/landing-page/terms-conditions';
import ResetPassword from '../pages/account/ResetPassword';
import SettingsDashboard from '../Components/SettingsDashboard';
import ManageUserList from '../pages/user-list/index';
import ManageCrops from '../pages/Managecrops';
import ManageNewsFeed from '../pages/ManageNews/index';
import ContingencyPlan from '../pages/ContingencyPlan/index';
import NewsFeed from '../pages/NewsFeed/index';
import NewsFeedDetailsPage from '../pages/NewsFeed/NewsFeedDetailsPage';
// import BulletIn from '../pages/Bulletin/index';
import BackOffcieBulletin from '../pages/Bulletin/BackOfficeBulletin';
import UserGuide from '../pages/UserGuide/index';
import { ReactComponent as UserGuideIcon } from '../assets/Images/user-guide.svg';
import ManageUserGuide from '../pages/ManageUserGuide/index';
import CreateUserGuidePage from '../pages/ManageUserGuide/CreateUserGuidePage';
import UserGuideDetailPage from '../pages/UserGuide/UserGuideDetailPage';
import UserSearchContentPage from '../pages/UserGuide/UserSearchContentPage';
import ManageUserGuideForm from '../pages/ManageUserGuide/ManageForm';
import UserSearchDetailPage from '../pages/UserGuide/UserSearchDetailPage';
// import CreateTemplate from '../pages/Bulletin/CreateTemplate';
// import CreateBulletin from '../pages/Bulletin/CreateBulletin';
import ApproveEditor from '../pages/Bulletin/ApproveEditor';
// import AboutUs from '../pages/landing-page/aboutUs';
// import HomePageBulletin from '../pages/Bulletin/HomePageBulletin';
// import CreateBulletinPdf from '../pages/Bulletin/CreateBulletinPdf';
import ManageDmsLandingPage from '../pages/DmsLandingPage';
import OnlineBulletin from '../pages/onlineBulletin/index';
import CreateBulletin from '../pages/onlineBulletin/CreateBulletinPage/index';

const Routes = [
  {
    path: '/home',
    name: 'Home',
    icon: <Home />,
    component: HomePage,
    visible: true,
  },
  {
    path: '/news-events-reports',
    name: 'News, Events & Reports',
    component: NewsEvents,
    visible: false,
  },
  {
    path: '/partners-donors',
    name: 'Partners & Donors',
    component: PartnersAndDonors,
    visible: false,
  },
  {
    path: '/tools',
    name: 'Tools',
    component: Tools,
    visible: false,
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: ContactUsForm,
    visible: false,
  },
  {
    path: '/drought-management-system',
    name: 'Country Specific Tool',
    component: CountrySpecificComponent,
    visible: false,
  },
  {
    path: '/weather-forecast',
    name: 'Weather Forecast',
    icon: <WeatherForecast />,
    component: Dashboard,
    visible: true,
  },
  {
    path: '/public-api',
    name: 'Public API',
    icon: <PublicAPI />,
    component: PublicApi,
    visible: true,
  },
  {
    path: '/drought-monitor',
    name: 'Drought Management',
    icon: <DroughtMonitorIcon />,
    component: DroughtMonitor,
    visible: true,
  },
  {
    path: '/charts',
    name: 'Charts',
    component: Charts,
    visible: false,
  },
  {
    path: '/alerts',
    name: 'Alerts',
    component: Alerts,
    visible: false,
  },
  {
    path: '/mapPreferences',
    name: 'MapPreferences',
    component: MapPreferences,
    visible: false,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    visible: false,
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ResetPassword,
    visible: false,
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword,
    visible: false,
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: ResetPassword,
    visible: false,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    visible: false,
  },
  // {
  //   // path: '/online-bulletin',
  //   name: 'online bulletin',
  //   component: HomePageBulletin,
  // },
  {
    path: '/online-bulletin',
    name: 'online bulletin',
    component: OnlineBulletin,
  },
  {
    path: '/create-online-bulletin',
    name: 'Create Bulletin',
    component: CreateBulletin,
  },
  // {
  //   path: '/bulletin',
  //   name: 'BulletIn',
  //   component: BulletIn,
  // },
  {
    path: '/public-api-admin',
    name: 'Public Api Key',
    component: Publicapikey,
  },
  {
    path: '/manage-user-list',
    name: 'Manage User List',
    component: ManageUserList,
  },
  {
    path: '/terms-conditions',
    name: 'Terms-Conditions',
    component: TermsConditions,
  },
  {
    path: '/manageStaticData',
    name: 'manageStaticData',
    component: ManageStaticData,
  },
  {
    path: '/settingsDashboard',
    name: 'SettingsDashboard',
    component: SettingsDashboard,
  },
  {
    path: '/ManageCrops',
    name: 'ManageCrops',
    component: ManageCrops,
  },
  {
    path: '/manage-newsfeed',
    name: 'ManageNewsFeed',
    component: ManageNewsFeed,
  },

  {
    path: '/contingency-plan',
    name: 'Contingency Plan',
    icon: <ContingencyPlanIcon />,
    component: ContingencyPlan,
    visible: true,
  },
  {
    path: '/news-feed',
    name: 'NewsFeed',
    icon: <NewsFeed />,
    component: NewsFeed,
    visible: true,
  },
  {
    path: '/news-feed-details-page',
    name: 'NewsFeedDetailsPage',
    icon: <NewsFeedDetailsPage />,
    component: NewsFeedDetailsPage,
    //visible: true,
  },
  {
    path: '/back-office-bulletin/:currentTab',
    name: 'BackOffcieBulletin',
    component: BackOffcieBulletin,
    visible: true,
  },
  {
    path: '/user-guide',
    name: 'User Guide',
    component: UserGuide,
    icon: <UserGuideIcon />,
    visible: false,
  },
  {
    path: '/manage-userguide',
    name: 'ManageUserGuide',
    component: ManageUserGuide,
  },
  {
    path: '/user-guide-form',
    name: 'CreateUserGuidePage',
    component: CreateUserGuidePage,
  },
  {
    path: '/user-guide-details',
    name: 'UserGuideDetailsPage',
    component: UserGuideDetailPage,
    visible: false,
  },
  {
    path: '/manage-user-guide-form/:action',
    name: 'Manage User guide form',
    component: ManageUserGuideForm,
    visible: false,
  },
  {
    path: '/user-guide-search',
    name: 'UserSearchContentPage',
    component: UserSearchContentPage,
    visible: false,
  },
  {
    path: '/user-guide-detail-search-content',
    name: 'UserGuideDetailSearchContent',
    component: UserSearchDetailPage,
    visible: false,
  },
  // {
  //   path: '/create-template',
  //   name: 'CreateTemplate',
  //   component: CreateTemplate,
  // },
  // {
  //   path: '/create-bulletin',
  //   name: 'CreateBulletin',
  //   component: CreateBulletin,
  // },
  // {
  //   path: '/create-bulletinPdf',
  //   name: 'CreateBulletinPdf',
  //   component: CreateBulletinPdf,
  // },

  {
    path: '/approve-editor',
    name: 'ApproveEditor',
    component: ApproveEditor,
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: AboutUs,
    visible: false,
  },
  {
    path: '/manage-dms',
    name: 'ManageDmsLandingPage',
    component: ManageDmsLandingPage,
  },
];
export default Routes;
