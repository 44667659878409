import React from 'react';
import { Button } from 'reactstrap';

function EditModal({
  editValues,
  setSelectedRow,
  setEditValues,
  columnHeader,
  onHandleSaveEdit,
  selectedRow,
}) {
  const handleInputChange = (columnId, value) => {
    setEditValues({
      ...editValues,
      [columnId]: value,
    });
  };

  const onEnabletick = () => {
    const isRowEdited = Object.keys(editValues).reduce((acc, key) => {
      if (editValues[key] === selectedRow?.values?.[key]) {
        acc[key] = editValues[key];
      }
      return acc;
    }, {});
    return Object.keys(isRowEdited).length !== Object.keys(selectedRow?.values).length;
  };

  return (
    <div className="edit-contigency-modal">
      <div className="contigency-plan-edit-header cp-edit-align-center ">
        <p className="cp-edit-tile"> Edit Row </p>
      </div>
      <div className="contigency-plan-edit-body">
        {Object.entries(editValues).map(([key, value], index) => {
          return (
            <>
              {columnHeader?.values[`${key}`] ? (
                <p className="cp-title-color">
                  {' '}
                  {index + 1}. {columnHeader?.values[`${key}`]}
                </p>
              ) : (
                <p className="cp-title-color">
                  {' '}
                  {index + 1}.
                </p>
              )}
              <textarea
                value={value}
                onChange={e => handleInputChange(key, e.target.value)}
                className="w-100 contigency-plan-text-area"
              />
            </>
          );
        })}
      </div>
      <div className="cp-edit-btn-alignment contigency-plan-edit-header ">
        <Button onClick={() => setSelectedRow('')} className="cp-edit-cancel-btn mr-2">
          {' '}
          Cancel{' '}
        </Button>

        {onEnabletick() ? (
          <Button onClick={() => onHandleSaveEdit()} className="cp-edit-save-btn ml-2">
            {' '}
            Save{' '}
          </Button>
        ) : (
          <Button className="cp-edit-save-btn ml-2 disabled"> Save </Button>
        )}
      </div>
    </div>
  );
}

export default EditModal;
