import React, { useState, useEffect } from 'react';
import { t } from 'react-multi-lang';
import { useHistory } from 'react-router-dom';
import { Row, Col, Collapse } from 'reactstrap';
import '../../assets/CSS/bulletin.css';
import '../../assets/CSS/StaticSidebar.css';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineCaretUp, AiOutlineCaretDown } from 'react-icons/ai';
import '../../assets/CSS/static-page.css';
import RightArrow from '../../assets/Images/back-button.svg';
import '../../assets/CSS/UserGuideSearchContent.css';
import {
  getAllTopicsRequest,
  getAllTopicSuccess,
  getAllUserGuideRequest,
  getAllUserGuideSuccess,
  searchByCriteriaUserGuideRequest,
} from '../../redux/actions';
import Loader from '../../Components/common/loader';
import _ from 'lodash';
import Footer from '../landing-page/footer';

const UserGuideDetailPage = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [getAllTopicDataList, setGetAllTopicDataList] = useState([]);
  const [TopicHeading, setTopicHeading] = useState('');
  const [subTopicHeading, setSubTopicHeading] = useState([]);
  const [initialSubtopicHeading, setInitialSubtopicHeading] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [allData, setAllData] = useState([]);
  const [activeSubTopic, setActiveSubTopic] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState('');
  const [sideBarSearch, setSideBarSearch] = useState(false);
  const [searchEnteredSubTopics, setSearchEnteredSubTopics] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [searchContentWordEntered, setSearchContentWordEntered] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [secondpageNumber, setSecondPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [topicId, setTopicId] = useState();
  // const [sectionId, setSectionId] = useState();

  const {
    getAllTopicData,
    isGetAllUserGuideRequesting,
    getAllUserGuideData,
    searchByCriteriaUserGuideData,
  } = useSelector(state => ({
    getAllTopicData: state.UserGuide.getAllTopicData,
    getAllUserGuideData: state.UserGuide.getAllUserGuideData,
    isGetAllUserGuideRequesting: state.UserGuide.isGetAllUserGuideRequesting,
    searchByCriteriaUserGuideData: state.UserGuide.searchByCriteriaUserGuideData,
  }));

  useEffect(() => {
    setSubTopicHeading([]);
    let search = window.location.search;
    let URLParams = new URLSearchParams(search);
    let topicId = URLParams.get('topicId');
    setTopicId(topicId);
    if (topicId){
    dispatch(getAllTopicsRequest({ topic_id: topicId }));
    dispatch(
      getAllUserGuideRequest({
        page: pageNumber,
        fromBackOffice: false,
        data: { topic_id: topicId, status: 'True', is_delete: 'False', is_backoffice: "False" },
      }),
    );
  }
  window.scrollTo({ top: 0, behavior: 'instant' });
  return () => {
      setAllData([]);
      setNoResults(false);
      setSubTopicHeading([]);
      setGetAllTopicDataList([]);
      dispatch(searchByCriteriaUserGuideRequest({}));
      dispatch(getAllTopicSuccess({}));
      dispatch(getAllUserGuideSuccess({}));
    };
  }, []);

  useEffect(() => {
    if (getAllTopicData?.data) {
      const { data } = getAllTopicData.data;
      if (!_.isEmpty(data) && !_.isEmpty(data.result)) {
        setGetAllTopicDataList(data.result);
        setNoResults(false);
      }
    }
  }, [getAllTopicData]);

  useEffect(() => {
    if (getAllTopicDataList?.length) {
      setTopicHeading(getAllTopicDataList[0]?.name);
      let { sub_topic } = getAllTopicDataList[0];
      setInitialSubtopicHeading(sub_topic);
      if (!sub_topic?.length) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    }
  }, [getAllTopicDataList]);
  
  useEffect(() => {
    if (getAllUserGuideData?.data && !getAllUserGuideData?.fromBackOffice) {
      let new_list = [];
      const { result } = getAllUserGuideData?.data;
      if (result) {
        if (allData) {
          if (secondpageNumber > 1) {
            new_list = allData.concat(result.results);
          } else {
            new_list = result.results;
          }
        } else {
          new_list = result.results;
        }
        setAllData(new_list);
        setPageCount(result?.count);
      } else {
        setAllData([]);
      }
    }
  }, [getAllUserGuideData]);

  useEffect(() => {
    let hasMore = pageNumber * 10 < pageCount ? true : false;
    if (hasMore) {
      setSecondPageNumber(pageNumber + 1);
      recursion(pageNumber, pageCount);
    }
  }, [pageCount]);

  useEffect(() => {
    let a = [];
    if (allData && allData.length && initialSubtopicHeading.length) {
      for (const element of allData) {
        let allDataElement = element?.title;
        for (let j = 0; j < initialSubtopicHeading.length; j++) {
          if (initialSubtopicHeading[j]?.name?.trim() == allDataElement?.trim()) {
            a.push(initialSubtopicHeading[j]);
            break;
          }
        }
      }
      setSubTopicHeading(a);
    }
  }, [allData, initialSubtopicHeading]);

useEffect(()=>{
  let search = window.location.search;  
  let URLParams = new URLSearchParams(search);
  let searchSubTopicName = URLParams.get('sub-topic-name');
  let index;
  if (subTopicHeading.length >= pageCount-2 && subTopicHeading.length <= pageCount && searchSubTopicName){
    subTopicHeading.map((item,idx)=>{
        if (item.name === searchSubTopicName){
          index = idx
        }
    })
    if(index && searchSubTopicName){
      toggle(index,searchSubTopicName)
  }
} 
},[subTopicHeading?.length,allData?.length])

  const recursion = (pageNumber, pageCount) => {
    let pagenum = pageNumber;
    let hasMore = pagenum * 10 < pageCount ? true : false;
    if (hasMore && topicId) {
      dispatch(
        getAllUserGuideRequest({
          page: pagenum + 1,
          fromBackOffice: false,
          data: { topic_id: topicId, status: 'True', is_delete: 'False',is_backoffice: "False" },
        }),
      );
      recursion(pagenum + 1, pageCount);
    }
  };

  const handleFilter = e => {
    const searchWord = e.target.value;
    if (searchWord?.length === 0) {
      setNoResults(false);
      setFilteredData([]);
      setSearchEnteredSubTopics([]);
    }
    setWordEntered(searchWord);
  };
  const clearSearch = a => {
    setNoResults(false);
    setSideBarSearch(false);
    setSearchEnteredSubTopics([]);
    setFilteredData([]);
    setWordEntered('');
  };
  const handleSearchContent = e => {
    const searchContent = e.target.value;
    setSearchContentWordEntered(searchContent);
  };

  useEffect(() => {
    if (wordEntered?.length !== 0) {
      let search = window.location.search;
      let URLParams = new URLSearchParams(search);
      let topic_name = URLParams.get('topic_name');
      dispatch(searchByCriteriaUserGuideRequest({ topic_name: topic_name, name: wordEntered }));
    }
  }, [wordEntered]);

  useEffect(() => {
    let serachedSubTopicList = [];
    if (searchByCriteriaUserGuideData?.data) {
      const { data } = searchByCriteriaUserGuideData.data;
      if (!_.isEmpty(data) && !_.isEmpty(data.result)) {
        data.result.map(item => {
          serachedSubTopicList.push(item);
        });
        setNoResults(false);
        setSearchEnteredSubTopics(serachedSubTopicList);
      } else {
        setNoResults(true);
        setSearchEnteredSubTopics([]);
      }
    }
  }, [searchByCriteriaUserGuideData]);

  const toggle = (tab, id) => {
    setActiveSubTopic(tab);
    scrollToElement(id);
  };

  const scrollToElement = id => {
   
    const element = document.getElementById(
      id
        .toLowerCase()
        .trim()
        .split(' ')
        .join('_'),
    );

    if (!_.isEmpty(element)) 
    {
      element?.scrollIntoView({block: "start"});
    }else{ 
      window.scroll(0, 0);
    }

  };

  const handleManageCropsBackButton = () => {
   // history.push('/user-guide');
   if (window.history.length == 1) {
    window.close();
  } else {
    history.goBack();
  }
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter' && event.target.value?.length !== 0) {
      history.push({
        pathname: '/user-guide-search',
        search: `?q=${searchContentWordEntered}`,
      });
    }
  };
  const handleClick = () => {
    if (searchContentWordEntered?.length !== 0) {
      history.push({
        pathname: '/user-guide-search',
        search: `?q=${searchContentWordEntered}`,
      });
    }
  };

  return (
    <>
      <div className="bulletin-header detail-page" key={'str'}>
        {isGetAllUserGuideRequesting && <Loader />}
        <div className="sidebar-user-guide">
          <div className="sidebarContainer">
            <div className="search-box">
              <input
                className="inputField"
                type="text"
                placeholder="Search"
                value={wordEntered}
                onChange={handleFilter}
                onClick={() => setSideBarSearch(true)}
              />

              {wordEntered?.length ? (
                <i
                  className="fa fa-times fa-lg searchIcons user-guide-search-icon"
                  onClick={() => clearSearch()}
                ></i>
              ) : (
                <i className="fa fa-search fa-lg searchIcons user-guide-search-icon"></i>
              )}
            </div>

            <span
              className="drought-category-style"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <div className="sidebar-header-container">
                {TopicHeading ? (
                  <span>
                    {!isOpen ? (
                      <AiOutlineCaretDown size={15} className="collapse-icon-down" />
                    ) : (
                      <AiOutlineCaretUp size={15} className="collapse-icon-up" />
                    )}
                    <span className="topic-heading-text">{TopicHeading}</span>
                  </span>
                ) : (
                  ''
                )}
              </div>
            </span>

            {noResults ? (
              <Collapse isOpen={isOpen}>
                {' '}
                <p className="un-selected-item no-results-text">
                  {t('USER_GUIDE.NO_RESULTS_FOUND')}
                </p>
              </Collapse>
            ) : (
              <Collapse isOpen={isOpen}>
                {subTopicHeading?.length && searchEnteredSubTopics?.length === 0
                  ? subTopicHeading.map((i, index) => {
                      return (
                        <nav key={index}>
                          <ul className="un-order-item links">
                            <a
                              onClick={() => {
                                toggle(index, i.name);
                              }}
                              className={
                                activeSubTopic === index ? 'selected-item' : 'un-selected-item'
                              }
                            >
                              {i.name}
                            </a>
                          </ul>
                        </nav>
                      );
                    })
                  : ''}
                {subTopicHeading?.length && searchEnteredSubTopics?.length !== 0
                  ? searchEnteredSubTopics.map((i, id) => {
                      return (
                        <ul className="un-order-item" key={id}>
                          <a
                            onClick={() => {
                              toggle(id, i.name);
                            }}
                            className={activeSubTopic === id ? 'selected-item' : 'un-selected-item'}
                          >
                            {i.name}
                          </a>
                        </ul>
                      );
                    })
                  : ''}
              </Collapse>
            )}
          </div>
        </div>

        <div className="detail-main-page">
          <Row className="iconSearchContainer">
            <Col lg={1} sm={2} xs={3}>
              <img
                src={RightArrow}
                className="manage-static-header-back-button backarrow-position"
                onClick={() => handleManageCropsBackButton()}
              />
            </Col>
            <Col lg={11} sm={10} xs={9}>
              <center className="sidebar-collapse">
                <span className="span-sidebar">
                  <div className="sidebarContainer">
                    <div className="search-box common-search-bar">
                      <input
                        className="inputField input-box "
                        type="text"
                        placeholder="Looking for something specific?"
                        value={searchContentWordEntered}
                        onChange={handleSearchContent}
                        onKeyPress={handleKeyPress}
                      />
                      <i
                        onClick={handleClick}
                        className="fa fa-search fa-lg searchIcons user-guide-search-icon"
                      ></i>
                    </div>
                  </div>
                </span>
              </center>
            </Col>
          </Row>
          <div className="user-guide-headers mx-3 pt-3">{getAllTopicDataList[0]?.name}</div>
          <p className="mx-3 mb-2 mt-2">
          {getAllTopicDataList[0]?.description}
          </p>
          <hr className="m-3 mt-4"></hr>
          {allData
            ? allData.map((i, id) => {
                return (
                  <div className="list" key={id}>
                    <section
                      className="m-3"
                      id={`${i?.subtopic_name?.toLowerCase()}`
                        .trim()
                        .split(' ')
                        .join('_')}
                    >
                      <p className="user-guide-detailed-heading-text">{i.title}</p>
                      <div
                        className="search-content-img discription-text"
                        dangerouslySetInnerHTML={{ __html: i?.content }}
                      ></div>
                    </section>
                  </div>
                );
              })
            : ''}
        </div>
      </div>
      <div className="">
        <Footer publicFooter={false} />
      </div>
    </>
  );
};

export default UserGuideDetailPage;
