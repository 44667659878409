import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { t } from 'react-multi-lang';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

import Breadcrumb from '../../../Components/common/BreadCrumbsComponent';
import { getDmsPartnersDonorsRequest } from '../../../redux/home/homeActions';
import Footer from '../Footer';

const PartnersAndDonors = () => {
  const dispatch = useDispatch();
  const [investmentPartners, setInvestmentPartners] = useState([]);
  const [researchPartners, setResearchPartners] = useState([]);
  const [supportPartners, setSupportPartners] = useState([]);
  const [activeTab, setActiveTab] = useState('investment_partners');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const { LandingPageData } = useSelector(state => ({
    LandingPageData: state.Home,
  }));

  useEffect(() => {
    dispatch(getDmsPartnersDonorsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (LandingPageData.getDmsPartnersDonorsData) {
      const { result } = LandingPageData.getDmsPartnersDonorsData.data;
      if (result?.length) {
        const { investmentPartners, researchPartners, supportPartners } = result.reduce(
          (acc, item) => {
            if (item.category === 'Investment Partners') {
              acc.investmentPartners.push(item);
            } else if (item.category === 'Research Partners') {
              acc.researchPartners.push(item);
            } else if (item.category === 'Support partners') {
              acc.supportPartners.push(item);
            }
            return acc;
          },
          { investmentPartners: [], researchPartners: [], supportPartners: [] },
        );

        setInvestmentPartners(investmentPartners);
        setResearchPartners(researchPartners);
        setSupportPartners(supportPartners);
      }
    }
  }, [LandingPageData.getDmsPartnersDonorsData]);

  return (
    <div className="spacing-top">
      <Breadcrumb items={['Home', 'Partners & Donors']} />
      <div className="partners-banner-container">
        <h1>{t('LANDING_PAGE.PARTNER_MAIN_HEADER')}</h1>
        <p>{t('LANDING_PAGE.PARTNER_SUB_HEADER')}</p>
      </div>
      <Container fluid className="partners-and-donors-container m-0">
        <div className="text-center my-lg-5 my-sm-3">
          <p className="partners-and-donors-subtitle">{t('LANDING_PAGE.PARTNERS_DONORS_DESC')}</p>
        </div>
        <Row>
          <Col md={12}>
            <Nav tabs className="custom-tabs partners-tabs">
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'investment_partners' })}
                  onClick={() => {
                    toggle('investment_partners');
                  }}
                >
                  {t('LANDING_PAGE.PARTNER_INVESTMENT')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'research_partners' })}
                  onClick={() => {
                    toggle('research_partners');
                  }}
                >
                  {t('LANDING_PAGE.PARTNER_RESEARCH')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'support_partners' })}
                  onClick={() => {
                    toggle('support_partners');
                  }}
                >
                  {t('LANDING_PAGE.SUPPORT_PARTNER')}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab} className="my-3">
              <TabPane tabId="investment_partners">
                <ul>
                  {investmentPartners?.map(investment => (
                    <li className='partners-list-text'>{investment.name}</li>
                  ))}
                </ul>
              </TabPane>
              <TabPane tabId="research_partners">
                <ul>
                  {researchPartners?.map(research => (
                    <li  className='partners-list-text'>{research.name}</li>
                  ))}
                </ul>
              </TabPane>
              <TabPane tabId="support_partners">
                <ul>
                  {supportPartners?.map(research => (
                    <li  className='partners-list-text'>{research.name}</li>
                  ))}
                </ul>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
      <Footer backgroundColor="bg-white" />
    </div>
  );
};

export default PartnersAndDonors;
