import React from 'react';
import {
  Button,
  Col,
  Row,
} from 'reactstrap';
import Select from 'react-select';
import { t } from 'react-multi-lang';
import DistrictRainfallModal from './RainfallModal';
import _ from 'lodash';

const Header = props => {
  const handleCategoryChange = value => {
    props.handleCategoryChange(value);
  };

  const handleSubCategoryChange = value => {
    props.handleSubCategoryChange(value);
  };

  return (
    <>
      {props.showRainfallGraphModal && (
        <DistrictRainfallModal
          isOpen={props.showRainfallGraphModal}
          toggle={props.handleRainfallModalToggle}
          data={props.districtRainfallData}
          districtData={props.districtData}
          stateData={props.stateData}
        />
      )}
      <Row className="district-profile-row">
        <Col sm="4" md="4" lg="4">
          <Button
            className="param-button"
            style={{
              backgroundColor: '#5389C4',
              borderColor: '#5389C4',
            }}
            onClick={props.handleDistrictProfileCloseModal}
          >
            {t('CONTINGENCY_PLAN.MAP')}
          </Button>
        </Col>
        <Col sm="8" md="8" lg="8">
          <Row>
            {!_.isEmpty(props?.selectedSubCategory) &&
              props?.selectedSubCategory?.slug === t('CHARTS.RAINFALL_SLUG') && (
                <Col sm="4" md="4" lg="4">
                  <Button
                    style={{
                      backgroundColor: '#5389C4',
                      borderColor: '#5389C4',
                    }}
                    className="rainfall-graph-button"
                    onClick={props.handleRainfallModalToggle}
                  >
                    {t('CONTINGENCY_PLAN.MONTHLY_RAINFALL_GRAPH')}
                  </Button>
                </Col>
              )}
            <Col sm="4" md="4" lg="4">
              <Select
                id="multi-select"
                name="category"
                options={props.categoryList || []}
                defaultValue={props.selectedCategory}
                value={props.selectedCategory}
                onChange={e => handleCategoryChange(e)}
                isLoading={props.isCategoryLoader}
                isMulti={false}
                required={true}
                placeholder="Select Category"
              />
            </Col>
            <Col sm="4" md="4" lg="4">
              <Select
                id="multi-select"
                name="sub-category"
                placeholder="Select Sub-Category"
                options={props.subCategoryList || []}
                defaultValue={props.selectedSubCategory}
                value={props.selectedSubCategory}
                onChange={e => handleSubCategoryChange(e)}
                isLoading={props.isSubCategoryLoader}
                isMulti={false}
                required={true}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default Header;
