import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  AXIOS_INSTANCE,
  CONTINGENCY_PLAN_URL,
  CONTINGENCY_PLAN_EDIT_URL,
} from '../api/apiEndPoint';
import {
  CONTINGENCY_PLAN_RISK_CONST,
  CONTINGENCY_MEASURES_DATA_CONST,
  CONTINGENCY_MEASURES_CROP_TYPE_CONST,
  CONTINGENCY_PLAN_MONSOON_CONST,
  DISTRICT_PROFILE_CONST,
  CATEGORY_CONST,
  SYSTEM_CONST,
  STAGE_CONST,
  DROUGHT_INDICES_CONST,
  STRATEGY_CONST,
  DROUGHT_STAGES_TIME_PERIOD_CONST,
  SUB_CATEGORY_CONST,
  CONTINGENCY_DISTRICT_GRAPH_CONST,
  CONTINGENCY_PLAN_EDIT_DISTRICT_PROFILE,
  CONTINGENCY_MEASURES_EDIT_DATA_CONST,
  CONTINGENCY_PLAN_EDIT_LOGS,
} from './contingencyPlanConsts';
import {
  contingencyPlanRiskSuccess,
  contingencyPlanMeasuresDataSuccess,
  contingencyPlanMeasuresDataFailed,
  contingencyPlanRiskFailed,
  contingencyPlanCropTypeFailed,
  contingencyPlanCropTypeSuccess,
  contingencyPlanMonsoonSuccess,
  contingencyPlanMonsoonFailed,
  contingencyPlanStageSuccess,
  contingencyPlanStageFailed,
  contingencyPlanSystemSuccess,
  contingencyPlanSystemFailed,
  contingencyPlanDroughtIndicesSuccess,
  contingencyPlanDroughtIndicesFailed,
  contingencyPlanStrategyFailed,
  contingencyPlanStrategySuccess,
  contingencyPlanDroughtStageTimePeriodSuccess,
  contingencyPlanDroughtStageTimePeriodFailed,
  contingencyPlanCategorySuccess,
  contingencyPlanCategoryFailed,
  contingencyPlanSubCategoryFailed,
  contingencyPlanSubCategorySuccess,
  contingencyPlanDistrictProfileFailed,
  contingencyPlanDistrictProfileSuccess,
  contingencyDistrictGraphSuccess,
  contingencyDistrictGraphFailed,
  contingencyPlanDPEditSuccess,
  contingencyPlanDPEditFailed,
  contingencyPlanMeasuresEditDataFailed,
  contingencyPlanMeasuresEditDataSuccess,
  contingencyPlanLogsFailed,
  contingencyPlanLogsSuccess,
} from './contingencyPlanAction';

require('dotenv').config();

const contingencyPlanRiskApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.post(`${CONTINGENCY_PLAN_URL}/risk`, payload);
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const contingencyPlanMonsoonApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.post(`${CONTINGENCY_PLAN_URL}/monsoon`, payload);
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const contingencyPlanStageApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.post(
      `${CONTINGENCY_PLAN_URL}/stages_of_drought`,
      payload,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const contingencyPlanSystemApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.post(`${CONTINGENCY_PLAN_URL}/system`, payload);
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const contingencyPlanDroughtIndicesApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.post(
      `${CONTINGENCY_PLAN_URL}/drought-indices`,
      payload,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const contingencyPlanStrategyApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.post(`${CONTINGENCY_PLAN_URL}/strategy`, payload);
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const contingencyPlanDroughtStageTimePeriodApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.post(
      `${CONTINGENCY_PLAN_URL}/drought_stages_time_period`,
      payload,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const contingencyPlanCategoryApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.post(
      `${CONTINGENCY_PLAN_URL}/distirct-profile-category`,
      payload,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const contingencyPlanSubCategoryApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.post(
      `${CONTINGENCY_PLAN_URL}/distirct-profile-category`,
      payload,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const contingencyPlanDistrictProfileApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.post(
      `${CONTINGENCY_PLAN_URL}/distirct-profile`,
      payload,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const contingencyPlanCropTypeApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.post(`${CONTINGENCY_PLAN_URL}/crop-type`, payload);
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const contingencyPlanMeasuresDataApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.post(`${CONTINGENCY_PLAN_URL}/measures`, payload);
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const contingencyPlanMeasuresEditDataApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.put(
      `${CONTINGENCY_PLAN_EDIT_URL}/edit/contingency_measures`,
      payload,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const contingencyPlanDistrictGraphApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.post(
      `${CONTINGENCY_PLAN_URL}/monthly-rainfall-graph`,
      payload,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* contingencyPlanRiskRequest(action) {
  try {
    const apiResponse = yield call(contingencyPlanRiskApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(contingencyPlanRiskSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(contingencyPlanRiskFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(contingencyPlanRiskFailed(errorData));
  }
}

function* contingencyPlanMonsoonRequest(action) {
  try {
    const apiResponse = yield call(contingencyPlanMonsoonApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(contingencyPlanMonsoonSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(contingencyPlanMonsoonFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(contingencyPlanMonsoonFailed(errorData));
  }
}

function* contingencyPlanStageRequest(action) {
  try {
    const apiResponse = yield call(contingencyPlanStageApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(contingencyPlanStageSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(contingencyPlanStageFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(contingencyPlanStageFailed(errorData));
  }
}

function* contingencyPlanSystemRequest(action) {
  try {
    const apiResponse = yield call(contingencyPlanSystemApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(contingencyPlanSystemSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(contingencyPlanSystemFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(contingencyPlanSystemFailed(errorData));
  }
}

function* contingencyPlanDroughtIndicesRequest(action) {
  try {
    const apiResponse = yield call(contingencyPlanDroughtIndicesApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(contingencyPlanDroughtIndicesSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(contingencyPlanDroughtIndicesFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(contingencyPlanDroughtIndicesFailed(errorData));
  }
}

function* contingencyPlanStrategyRequest(action) {
  try {
    const apiResponse = yield call(contingencyPlanStrategyApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(contingencyPlanStrategySuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(contingencyPlanStrategyFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(contingencyPlanStrategyFailed(errorData));
  }
}

function* contingencyPlanDroughtStageTimePeriodRequest(action) {
  try {
    const apiResponse = yield call(contingencyPlanDroughtStageTimePeriodApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(contingencyPlanDroughtStageTimePeriodSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(contingencyPlanDroughtStageTimePeriodFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(contingencyPlanDroughtStageTimePeriodFailed(errorData));
  }
}

function* contingencyPlanCateoryRequest(action) {
  try {
    const apiResponse = yield call(contingencyPlanCategoryApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(contingencyPlanCategorySuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(contingencyPlanCategoryFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(contingencyPlanCategoryFailed(errorData));
  }
}

function* contingencyPlanSubCateoryRequest(action) {
  try {
    const apiResponse = yield call(contingencyPlanSubCategoryApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(contingencyPlanSubCategorySuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(contingencyPlanSubCategoryFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(contingencyPlanSubCategoryFailed(errorData));
  }
}

function* contingencyPlanDistrictProfileRequest(action) {
  try {
    const apiResponse = yield call(contingencyPlanDistrictProfileApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(contingencyPlanDistrictProfileSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(contingencyPlanDistrictProfileFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(contingencyPlanDistrictProfileFailed(errorData));
  }
}

function* contingencyPlanCropTypeRequest(action) {
  try {
    const apiResponse = yield call(contingencyPlanCropTypeApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(contingencyPlanCropTypeSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(contingencyPlanCropTypeFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(contingencyPlanCropTypeFailed(errorData));
  }
}

function* contingencyPlanMeasuresDataRequest(action) {
  try {
    const apiResponse = yield call(contingencyPlanMeasuresDataApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(contingencyPlanMeasuresDataSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(contingencyPlanMeasuresDataFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(contingencyPlanMeasuresDataFailed(errorData));
  }
}

function* contingencyPlanMeasuresEditDataRequest(action) {
  try {
    const apiResponse = yield call(contingencyPlanMeasuresEditDataApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(contingencyPlanMeasuresEditDataSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(contingencyPlanMeasuresEditDataFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(contingencyPlanMeasuresEditDataFailed(errorData));
  }
}

function* contingencyPlanDistrictGraphRequest(action) {
  try {
    const apiResponse = yield call(contingencyPlanDistrictGraphApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(contingencyDistrictGraphSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(contingencyDistrictGraphFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(contingencyDistrictGraphFailed(errorData));
  }
}

// edit Contigency Distrct profile

const contingencyPlanEditProfileApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.put(
      `${CONTINGENCY_PLAN_EDIT_URL}/edit/district-profile`,
      payload,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* contingencyPlanEditProfileRequest(action) {
  try {
    const apiResponse = yield call(contingencyPlanEditProfileApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(contingencyPlanDPEditSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(contingencyPlanDPEditFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(contingencyPlanDPEditFailed(errorData));
  }
}

// LOGS
const contingencyPlanLogsApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.get(`${CONTINGENCY_PLAN_EDIT_URL}/logs`, payload);
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};
function* contingencyPlanLogsRequest(action) {
  try {
    const apiResponse = yield call(contingencyPlanLogsApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(contingencyPlanLogsSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(contingencyPlanLogsFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(contingencyPlanLogsFailed(errorData));
  }
}

export function* watchContingencyPlanSaga() {
  yield takeEvery(
    CONTINGENCY_PLAN_RISK_CONST.CONTINGENCY_PLAN_RISK_REQUEST,
    contingencyPlanRiskRequest,
  );
  yield takeEvery(
    CONTINGENCY_PLAN_MONSOON_CONST.CONTINGENCY_PLAN_MONSOON_REQUEST,
    contingencyPlanMonsoonRequest,
  );
  yield takeEvery(STAGE_CONST.STAGE_REQUEST, contingencyPlanStageRequest);
  yield takeEvery(SYSTEM_CONST.SYSTEM_REQUEST, contingencyPlanSystemRequest);
  yield takeEvery(
    DROUGHT_INDICES_CONST.DROUGHT_INDICES_REQUEST,
    contingencyPlanDroughtIndicesRequest,
  );
  yield takeEvery(STRATEGY_CONST.STRATEGY_REQUEST, contingencyPlanStrategyRequest);
  yield takeEvery(
    DROUGHT_STAGES_TIME_PERIOD_CONST.DROUGHT_STAGES_TIME_PERIOD_REQUEST,
    contingencyPlanDroughtStageTimePeriodRequest,
  );
  yield takeEvery(CATEGORY_CONST.CATEGORY_REQUEST, contingencyPlanCateoryRequest);
  yield takeEvery(SUB_CATEGORY_CONST.SUB_CATEGORY_REQUEST, contingencyPlanSubCateoryRequest);
  yield takeEvery(
    DISTRICT_PROFILE_CONST.DISTRICT_PROFILE_REQUEST,
    contingencyPlanDistrictProfileRequest,
  );
  yield takeEvery(
    CONTINGENCY_MEASURES_CROP_TYPE_CONST.CONTINGENCY_MEASURES_CROP_TYPE_REQUEST,
    contingencyPlanCropTypeRequest,
  );
  yield takeEvery(
    CONTINGENCY_MEASURES_DATA_CONST.CONTINGENCY_MEASURES_DATA_REQUEST,
    contingencyPlanMeasuresDataRequest,
  );

  yield takeEvery(
    CONTINGENCY_DISTRICT_GRAPH_CONST.CONTINGENCY_DISTRICT_GRAPH_REQUEST,
    contingencyPlanDistrictGraphRequest,
  );

  yield takeEvery(
    CONTINGENCY_PLAN_EDIT_DISTRICT_PROFILE.CONTINGENCY_PLAN_DP_EDIT_REQUEST,
    contingencyPlanEditProfileRequest,
  );

  yield takeEvery(
    CONTINGENCY_MEASURES_EDIT_DATA_CONST.CONTINGENCY_MEASURES_EDIT_DATA_REQUEST,
    contingencyPlanMeasuresEditDataRequest,
  );

  yield takeEvery(
    CONTINGENCY_PLAN_EDIT_LOGS.CONTINGENCY_PLAN_LOGS_REQUEST,
    contingencyPlanLogsRequest,
  );
}

function* contingencyPlanSaga() {
  yield all([fork(watchContingencyPlanSaga)]);
}

export default contingencyPlanSaga;
