const MAP_PREFERENCE_CONST = {
  GET_MAP_PREFERENCE_REQUEST: 'MAP_PREFERENCE_REQUEST',
  GET_MAP_PREFERENCE_SUCCESS: 'MAP_PREFERENCE_SUCCESS',
  GET_MAP_PREFERENCE_FAILED: 'MAP_PREFERENCE_FAILED',

  GET_MAP_PREFERENCE_BOUNDARIES_REQUEST: 'MAP_PREFERENCE_BOUNDARIES_REQUEST',
  GET_MAP_PREFERENCE_BOUNDARIES_SUCCESS: 'MAP_PREFERENCE_BOUNDARIES_SUCCESS',
  GET_MAP_PREFERENCE_BOUNDARIES_FAILED: 'MAP_PREFERENCE_BOUNDARIES_FAILED',

  GET_MAP_PREFERENCES_REQUEST: 'MAP_PREFERENCES_REQUEST',
  GET_MAP_PREFERENCES_SUCCESS: 'MAP_PREFERENCES_SUCCESS',
  GET_MAP_PREFERENCES_FAILED: 'MAP_PREFERENCES_FAILED',

};

export default MAP_PREFERENCE_CONST;
