import React from 'react';
import { Chrono } from 'react-chrono';
import JourneyIcon from '../../../assets/Images/journey-timeline-icon.svg';
import Image2014 from '../../../assets/Images/2014_image.png';
import Image2019 from '../../../assets/Images/2019_image.png';
import Image2022 from '../../../assets/Images/2022_image.png';
import Image2023 from '../../../assets/Images/2023_image.png';
import './Ourjourney.css'; // Import the CSS file

const Ourjourney = () => {
  const items = [
    {
      title: <span className="my-jounrey-title">2004</span>,
      cardTitle:
        'IWMI develops the Southwest Asia Drought monitor to provide drought monitoring and earlAy warning information for the region.',
    },
    {
      title: (
        <div className="border-0 card-img-container">
          <img
            src={Image2014}
            alt="reddit"
            width={376}
            height={172}
            style={{ marginRight: '20px' }}
          />
          <span className="my-jounrey-title">2014</span>
        </div>
      ),
      cardTitle:
        'IWMI launches the Mali Drought Monitoring System (MDMS) portal through the Integrated Drought management Program (IDMP) and Indian Council of Agricultural Research (ICAR), a web-based platform providing access to operational drought-related information.',
    },
    {
      title: <span className="my-jounrey-title">2015</span>,
      cardTitle:
        'IWMI expands its drought management work to include the development of drought resilience plans, drought index insurance, and drought action plans.',
    },
    {
      title: <span className="my-jounrey-title">2015</span>,
      cardTitle:
        'IWMI partners with the CGIAR Research Program on Climate Change, Agriculture and Food Security (CCAFS) to launch the bundled climate smart solutions (BICSA), which aims to scale up the use of index insurance for drought risk management.',
    },
    {
      title: (
        <div className="border-0 card-img-container card-img-continer-reverse">
          <span className="my-jounrey-title">2019</span>
          <img
            src={Image2019}
            alt="reddit"
            width={376}
            height={172}
            style={{ marginLeft: '20px' }}
          />
        </div>
      ),
      cardTitle:
        'IWMI launches the MENADrought program to promote integrated drought management efforts in Jordan, Morocco.',
    },
    {
      title: <span className="my-jounrey-title">2020</span>,
      cardTitle:
        'IWMI partners with the World Bank to launch the Southern Africa Drought Resilience Initiative (SADRI), which aims to strengthen drought resilience in the region.',
    },
    {
      title: <span className="my-jounrey-title">2020s</span>,
      cardTitle:
        'IWMI received the Geospatial World Excellence Award for its innovative work using remote sensing technology to help nations monitor and mitigate the impacts of drought.',
    },
    {
      title: <span className="my-jounrey-title">2021</span>,
      cardTitle:
        'IWMI expands continental scale Africa drought monitoring information and evaluate the impact of drought to water, agriculture and nutritional risks with The Leona M. and Harry B. Helmsley Charitable Trust.',
    },
    {
      title: (
        <div className="card-img-container border-0 card-img-continer-reverse">
          <span className="my-jounrey-title">2022</span>
          <img
            src={Image2022}
            alt="reddit"
            width={376}
            height={172}
            style={{ marginLeft: '20px' }}
          />
        </div>
      ),
      cardTitle:
        'IWMI launches next generation of MDMS platform to promote integrated end-to-end drought monitoring and management system for Mali in collaboration with SAARC Disaster Management Center and supported by Indian Council of Agricultural Research (ICAR) and CGIAR initiatives on Nexus Gains.',
    },
    {
      title: (
        <div className="card-img-continer-reverse">
          <span className="my-jounrey-title">2023</span>
          <img src={Image2023} alt="reddit" />
        </div>
      ),
      cardTitle:
        'IWMI launches the CGIAR initiatives on Climate Resilience AWARE platform, a global platform in promoting anticipatory action mechanism to climate shocks such as drought, floods and landslides for timely early action.',
    },
    {
      title: <span className="my-jounrey-title">2023s</span>,
      cardTitle:
        'IWMI continues to advance its drought management work through the development of innovative tools and approaches, such as the use of artificial intelligence and machine learning for drought prediction and the integration of drought risk management into national policies and plans.',
    },
  ];

  return (
    <div className="journey-container">
      <h1 className="journey-header">Our Journey</h1>
      <div className="my-3 h-100">
        <Chrono
          items={items}
          mode="VERTICAL_ALTERNATING"
          hideControls={true}
          disableNavOnKey={true}
          useReadMore={false}
          disableInteraction={true}
          disableToolbar={true}
          enableBreakPoint
          theme={{
            primary: '#27638F',
            cardBgColor: '#FFFFFF',
          }}
          classNames={{
            card: 'my-jounrey-card',
            cardTitle: 'my-jounrey-card-title',
            cardText: 'my-card-text',
          }}
          flipLayout
          style={{ height: 'auto' }}
        >
          <div className="chrono-icons">
            {items.map(img => (
              <img
                src={JourneyIcon}
                alt={img.title}
                height={42}
                width={42}
                className="journey-timeline-icon"
                key={img.cardTitle}
              />
            ))}
          </div>
        </Chrono>
      </div>
    </div>
  );
};

export default Ourjourney;
