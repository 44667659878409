import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import PropTypes from 'prop-types';
import ZoomInIcon from '../../assets/Images/Zoom_In.svg';
import ZoomOutIcon from '../../assets/Images/Zoom-Out.svg';
import ParameterList from '../../Components/Parameters';
import Download from '../../assets/Images/download.png';
import { saveAs } from 'file-saver';
import { Collapse, Button, Row, Col } from 'reactstrap';
import Loader from '../../Components/common/loader';
import { DATA_SOURCES } from '../../Components/constants';
import { t } from 'react-multi-lang';
export const StaticResource = props => {
  const {
    params,
    handleParameters,
    dataSourceId,
    handleDataSourceId,
    staticResourceWeekData,
    dataSource,
    // setStaticResourceSelectedLocation
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStaticResourceData, setSelectedStaticResourceData] = useState({
    staticResourceImage: null,
    staticResourceTitle: '',
    selectedStaticResourceId: '',
    category: '',
  }); // new state added for category by Ramya.S on 5/9/2022
  const [orderedWeekData, setOrderedWeekData] = useState();
  const nextProps = useSelector(state => ({
    isStaticResourceRequesting: state.staticResource.isStaticResourceRequesting,
    isStaticResourceYearRequesting: state.staticResource.isStaticResourceYearRequesting,
    ecmwfDataByControlData: state?.Weather?.isEcmwfDataByControlData,
    isEcmwfDataByControlLoading: state?.Weather?.isEcmwfDataByControlLoading,
  }));

  useEffect(() => {
    setIsOpen(true);
  }, []);

  useEffect(() => {
    if (nextProps.isStaticResourceRequesting) {
      selectedStaticResourceData.staticResourceImage = null;
      setSelectedStaticResourceData({ ...selectedStaticResourceData });
    } else {
      if (staticResourceWeekData?.length) {
        let ordered_list = staticResourceWeekData.sort((x, y) => {
          return x?.week_wise?.slice(4) - y?.week_wise?.slice(4);
        });
        setOrderedWeekData(ordered_list);
        selectedStaticResourceData.selectedStaticResourceId = ordered_list[0].id;
        selectedStaticResourceData.staticResourceImage = ordered_list[0].upload_image;
        selectedStaticResourceData.category = ordered_list[0].forecast_data_source_category_name;
        selectedStaticResourceData.staticResourceTitle = ordered_list[0].forecast_data_source_name;
        setSelectedStaticResourceData({ ...selectedStaticResourceData });
      } else if (Array.isArray(staticResourceWeekData) && staticResourceWeekData !== null) {
        selectedStaticResourceData.selectedStaticResourceId = '';
        selectedStaticResourceData.staticResourceImage = '';
        selectedStaticResourceData.staticResourceTitle = '';
        selectedStaticResourceData.category = '';
        setSelectedStaticResourceData({ ...selectedStaticResourceData });
      }
    }
  }, [staticResourceWeekData, nextProps.isStaticResourceRequesting]);

  // useEffect(() => {
  //   if (nextProps?.ecmwfDataByControlData?.statusCode === 200) {
  //     selectedStaticResourceData.selectedStaticResourceId = '';
  //     selectedStaticResourceData.staticResourceImage =
  //       nextProps?.ecmwfDataByControlData?.data?.result;
  //     selectedStaticResourceData.category = dataSource?.[0]?.category;
  //     selectedStaticResourceData.staticResourceTitle = 'ECMWF (Static)';
  //     setSelectedStaticResourceData({ ...selectedStaticResourceData });
  //   }
  // }, [nextProps?.ecmwfDataByControlData]);

  useEffect(() => {
    if (nextProps.isEcmwfDataByControlLoading) {
      selectedStaticResourceData.staticResourceImage = null;
      setSelectedStaticResourceData({ ...selectedStaticResourceData });
    } else {
      if (nextProps?.ecmwfDataByControlData?.statusCode === 200) {
        selectedStaticResourceData.selectedStaticResourceId = '';
        selectedStaticResourceData.staticResourceImage =
          nextProps?.ecmwfDataByControlData?.data?.result;
        selectedStaticResourceData.category = dataSource?.[0]?.category;
        selectedStaticResourceData.staticResourceTitle = 'ECMWF (Static)';
        setSelectedStaticResourceData({ ...selectedStaticResourceData });
      }
    }
  }, [nextProps?.isEcmwfDataByControlLoading, nextProps?.ecmwfDataByControlData]);

  const handleDownload = () => {
    saveAs(selectedStaticResourceData.staticResourceImage, 'image.gif');
  };

  const handleSelectedWeek = data => {
    selectedStaticResourceData.selectedStaticResourceId = data.id;
    selectedStaticResourceData.staticResourceImage = data.upload_image;
    selectedStaticResourceData.staticResourceTitle = data.forecast_data_source_name;
    setSelectedStaticResourceData({ ...selectedStaticResourceData });
  };
  return (
    <>
      <div className="static-resource-container col-lg-10">
        <div className="static-resource-section">
          {params?.length ? (
            <div className="paramList parameter-ml">
              <ParameterList
                initialParams={params}
                handleParameters={handleParameters}
                handleDataSourceId={handleDataSourceId}
                dataSourceId={dataSourceId}
              />
            </div>
          ) : (
            ''
          )}
          {nextProps.isStaticResourceRequesting === true ||
          nextProps.isStaticResourceYearRequesting ||
          nextProps?.isEcmwfDataByControlLoading ? (
            <Loader />
          ) : (
            <>
              {selectedStaticResourceData?.staticResourceTitle &&
                selectedStaticResourceData?.category && (
                  <h6 className="static-resource-title">
                    {selectedStaticResourceData?.category} | Data Source:{' '}
                    {selectedStaticResourceData?.staticResourceTitle}
                  </h6>
                )}
              <TransformWrapper>
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                  <React.Fragment>
                    {selectedStaticResourceData.staticResourceImage?.length ? (
                      <div className="tools">
                        <img
                          className="download-img"
                          src={Download}
                          alt={'not-available'}
                          onClick={() => handleDownload()}
                        />
                        <span>
                          <img src={ZoomInIcon} alt={'not-available'} onClick={() => zoomIn()} />
                        </span>
                        <span>
                          <img src={ZoomOutIcon} alt={'not-available'} onClick={() => zoomOut()} />
                        </span>
                      </div>
                    ) : (
                      ''
                    )}
                    <Row>
                      <Col
                        sm="10"
                        lg="10"
                        md="10"
                        style={
                          dataSource?.length && dataSource[0]?.name === DATA_SOURCES.CPC.name
                            ? { marginLeft: '10%' }
                            : {}
                        }
                      >
                        <TransformComponent>
                          {selectedStaticResourceData.staticResourceImage?.length ? (
                            <img
                              width={
                                selectedStaticResourceData.staticResourceTitle === 'ERPAS (Static)'
                                  ? '80%'
                                  : '80%'
                              }
                              src={selectedStaticResourceData.staticResourceImage}
                              alt={'not-available'}
                            />
                          ) : (
                            <>
                              {selectedStaticResourceData.staticResourceImage !== null ? (
                                <p className="no-static-record-found">
                                  {t('FILTER.NO_DATA_MESSAGE_FOR_STATIC_SOURCE')}
                                </p>
                              ) : (
                                ''
                              )}
                            </>
                          )}
                        </TransformComponent>
                      </Col>
                      <Col sm="2" lg="2" md="2">
                        <div className="params-list">
                          {selectedStaticResourceData.staticResourceTitle === 'ERPAS (Static)' && (
                            <Collapse isOpen={isOpen}>
                              {orderedWeekData?.length &&
                                orderedWeekData.map((item, index) => {
                                  return (
                                    <Button
                                      className={
                                        selectedStaticResourceData.selectedStaticResourceId ===
                                        item.id
                                          ? 'selected-parent-parameter week-options'
                                          : 'param-options week-options'
                                      }
                                      key={index}
                                      onClick={() => handleSelectedWeek(item)}
                                    >
                                      {item.week_wise?.charAt(0).toUpperCase() +
                                        item.week_wise?.slice(1, 4) +
                                        '  ' +
                                        item.week_wise?.charAt(4)}
                                    </Button>
                                  );
                                })}
                            </Collapse>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </TransformWrapper>
            </>
          )}
        </div>
      </div>
    </>
  );
};
StaticResource.propTypes = {
  staticResourceImage: PropTypes.string,
  params: PropTypes.array,
  handleParameters: PropTypes.func,
  staticResourceTitle: PropTypes.string,
  dataSourceId: PropTypes.object,
};
