import React, { useEffect } from 'react';
import './Firstsection.css';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import Achievement from '../AboutUs/Achievemeents';
import Ourjourney from '../AboutUs/Ourjourney';
import Footer from '../Footer';
import Breadcrumb from '../../../Components/common/BreadCrumbsComponent';

const Firstsection = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="spacing-top">
      <Breadcrumb items={['Home', 'About Us']} />
      <Container fluid className="aboutus-main-container">
        <Row className="about-header-section">
          <Col xs={12}>
            <h1>
              Integrated Drought Management:{' '}
              <span className="highlight">IWMI and Partners in Action</span>
            </h1>
            <p>
              IWMI has been at the forefront of drought management for over two decades, leveraging
              technology and data to mitigate the impact of drought in vulnerable regions.
            </p>
          </Col>
        </Row>
        {/* <div className="about-content-section">
          <Card className="about-card-item">
            <CardBody>
              <h3>Early Efforts (2004)</h3>
              <p>
                A prototype regional Drought Monitoring System (DMS) for South-West Asia was
                developed, showcasing the potential of remote sensing for drought assessment.
              </p>
            </CardBody>
          </Card>
          <Card className="about-card-item">
            <CardBody>
              <h3>South Asia Drought Monitoring System (SADMS) (2014)</h3>
              <p>
                A major breakthrough, SADMS provided weekly maps of drought conditions across South
                Asia, enabling timely action at national, state, and district levels. This has
                significantly helped smallholder farmers in India and Sri Lanka cope with prolonged
                dry spells.
              </p>
            </CardBody>
          </Card>
          <Card className="about-card-item">
            <CardBody>
              <h3>Expanding Impact</h3>
              <p>
                Beyond South Asia, IWMI's work has informed drought response systems in countries
                like Afghanistan, Morocco, Jordan, Zambia and several others. The institute has also
                partnered with the World Bank on the Next Generation Drought Index project, aiming
                to trigger financial responses to drought conditions in Senegal. Recently through
                the CGIAR initiatives on Nexus Gains, Climate Resilience, and Ukama Ustawi and The
                Leona M. and Harry B. Helmsley Charitable Trust expanded to more than 15 countries
                globally.
              </p>
            </CardBody>
          </Card>
          <Card className="about-card-item">
            <CardBody>
              <h3>Continued Innovation</h3>
              <p>
                Today, IWMI continues to develop advanced drought indices and decision-support
                tools, utilizing technologies like satellite imagery, machine learning, and
                on-the-ground data collection for drought management efforts.
              </p>
            </CardBody>
          </Card>
        </div> */}
      </Container>
      <Achievement />
      <Ourjourney />
      <Footer backgroundColor="bg-white" />
    </div>
  );
};

export default Firstsection;
