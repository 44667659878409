import React, { useEffect, useRef, useState } from 'react';
import { t } from 'react-multi-lang';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, CardText, CardTitle, Col, Container, Row } from 'reactstrap';

import { showError, showSuccess } from '../../Components/Notifications';
import Loader from '../../Components/common/TextLoader';
import { isSuperAdmin } from '../../Components/common/utils';
import ActiveUsers from '../../assets/Images/active-users.svg';
import ActiveWarning from '../../assets/Images/active-warning.svg';
import DCPIcon from '../../assets/Images/dcp.svg';
import DroughtEarlyWarning from '../../assets/Images/drought-early-warning.svg';
import { ReactComponent as EditIcon } from '../../assets/Images/edit-icon.svg';
import Investment from '../../assets/Images/investment.svg';
import {
  editLandingPageRequest,
  editLandingPageSuccess,
  getDmsStatisticsRequest,
  getDmsStatisticsSuccess,
} from '../../redux/home/homeActions';

const mapStatisticsToImpactData = statisticsData => {
  const impactData = [
    {
      icon: DroughtEarlyWarning,
      description: 'Drought Early Warning Systems',
      value:
        statisticsData.find(item => item.name === 'Drought Early Warning Systems')?.value || 'N/A',
      label: 'Countries',
      id: statisticsData.find(item => item.name === 'Drought Early Warning Systems')?.id || 'N/A',
      name:
        statisticsData.find(item => item.name === 'Drought Early Warning Systems')?.name || 'N/A',
    },
    {
      icon: ActiveUsers,
      description: 'Platform visitors',
      value: statisticsData.find(item => item.name === 'Platform visitors')?.value || 'N/A',
      label: 'since 2022',
      id: statisticsData.find(item => item.name === 'Platform visitors')?.id || 'N/A',
      name: statisticsData.find(item => item.name === 'Platform visitors')?.name || 'N/A',
    },
    {
      icon: ActiveWarning,
      description: 'Indirect Beneficiaries',
      value: statisticsData.find(item => item.name === 'Indirect Beneficiaries')?.value || 'N/A',
      label: 'farmers',
      id: statisticsData.find(item => item.name === 'Indirect Beneficiaries')?.id || 'N/A',
      name: statisticsData.find(item => item.name === 'Indirect Beneficiaries')?.name || 'N/A',
    },
    {
      icon: Investment,
      description: 'Digital Contingency Plans',
      value: statisticsData.find(item => item.name === 'Digital Contingency Plans')?.value || 'N/A',
      label: '',
      id: statisticsData.find(item => item.name === 'Digital Contingency Plans')?.id || 'N/A',
      name: statisticsData.find(item => item.name === 'Digital Contingency Plans')?.name || 'N/A',
    },
    {
      icon: DCPIcon,
      description: 'Investment',
      value: statisticsData.find(item => item.name === 'Investment')?.value || 'N/A',
      label: '',
      id: statisticsData.find(item => item.name === 'Investment')?.id || 'N/A',
      name: statisticsData.find(item => item.name === 'Investment')?.name || 'N/A',
    },
  ];

  return impactData;
};

function ManageImpacts({ title, userPermisions }) {
  const dispatch = useDispatch();
  const [statisticsData, setStatisticsData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValues, setInputValues] = useState({});

  const { LandingPageData } = useSelector(state => ({
    LandingPageData: state.Home,
  }));

  useEffect(() => {
    dispatch(getDmsStatisticsRequest({}));
    return () => {
      dispatch(editLandingPageSuccess({}));
      dispatch(getDmsStatisticsSuccess({}));
    };
  }, [dispatch]);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (LandingPageData.isGetDmsStatisticsRequesting) {
      setIsLoading(true);
    } else if (LandingPageData.getDmsStatisticsData) {
      const { result } = LandingPageData.getDmsStatisticsData.data;

      const impactData = result.length ? mapStatisticsToImpactData(result) : [];

      setStatisticsData(impactData);
      // Initialize input values state
      const initialValues = impactData.reduce((acc, data, index) => {
        acc[index] = data.value;
        return acc;
      }, {});
      setInputValues(initialValues);
      setIsLoading(false);
    } else if (
      LandingPageData.getDmsStatisticsDataError ||
      LandingPageData.getDmsStatisticsDataNetworkError
    ) {
      showError(t('LANDING_PAGE.SOMETHING_WENT_WORNG'));
      setIsLoading(false);
    }
  }, [
    LandingPageData.getDmsStatisticsData,
    LandingPageData.getDmsStatisticsDataError,
    LandingPageData.getDmsStatisticsDataNetworkError,
    LandingPageData.isGetDmsStatisticsRequesting,
  ]);

  const isEditRef = useRef(true);
  useEffect(() => {
    if (isEditRef.current) {
      isEditRef.current = false;
      return;
    }
    if (LandingPageData.iseditLandingPageRequesting) {
      setIsLoading(true);
      setIsEdit(false);
    } else if (LandingPageData?.editLandingPageData?.data) {
      showSuccess(LandingPageData?.editLandingPageData?.data?.result);
      dispatch(getDmsStatisticsRequest({}));
      setIsLoading(false);
      setIsEdit(false);
      setInputValues({});
    } else if (
      LandingPageData.editLandingPageDataError ||
      LandingPageData.editLandingPageDataNetworkError
    ) {
      const errorMessageArray =
        LandingPageData.editLandingPageDataError?.response?.data?.response?.data?.message || [];
      const [errorMessage] = errorMessageArray;

      showError(errorMessage);
      setIsLoading(false);
      setIsEdit(false);
      setInputValues({});
    }
  }, [
    LandingPageData.editLandingPageData,
    LandingPageData.iseditLandingPageRequesting,
    LandingPageData.editLandingPageDataError,
    LandingPageData.editLandingPageDataNetworkError,
    dispatch,
  ]);

  const handleInputChange = (index, value) => {
    setInputValues(prevValues => ({
      ...prevValues,
      [index]: value,
    }));
  };

  const saveChanges = () => {
    const payloadData = [...statisticsData];
    const submissionData = payloadData.map((data, index) => ({
      value: inputValues[index],
      id: data.id,
      name: data.name,
    }));

    // * MAKE SURE TO SEND IT AS submissionData
    dispatch(
      editLandingPageRequest({
        submissionData: {
          statistics: submissionData,
        },
        end_point: 'update_statistics',
      }),
    );
    setIsEdit(false);
  };

  const isSaveDisabled = () => {
    return Object.values(inputValues).some(value => value.trim() === '');
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className="dms-title-container">
        <h1 className="dms-title">{title.label}</h1>
        {isSuperAdmin() || userPermisions.includes('change_dmsstatistics') ? (
          <div className={isEdit ? 'dms-edit-btn-container' : ''}>
            {isEdit ? (
              <Button
                className="dms-cancel-btn"
                onClick={() => {
                  setIsEdit(false);
                }}
              >
                {t('LANDING_PAGE.CANCEL')}
              </Button>
            ) : null}
            <Button
              className="dms-add-edit-btn"
              onClick={() => {
                if (isEdit) {
                  saveChanges();
                } else {
                  setIsEdit(true);
                }
              }}
              disabled={isEdit && isSaveDisabled()}
            >
              {isEdit ? (
                <>{t('LANDING_PAGE.SAVE')}</>
              ) : (
                <>
                  {t('LANDING_PAGE.EDIT')} <EditIcon height={20} width={20} />
                </>
              )}
            </Button>
          </div>
        ) : null}
      </div>
      <Container fluid>
        <Row className="justify-content-center text-center mb-4 dms-impact-container">
          {statisticsData.map((data, index) => (
            <Col md="5" sm="12" className="mb-4" key={index}>
              <Card className="stat-card text-white">
                <CardBody className="d-flex ">
                  <div className="icon-container">
                    <img src={data.icon} alt="icon" className="icon" />
                  </div>
                  <div className="card-stats-container">
                    <CardTitle className="stat-description m-0">{data.description}</CardTitle>
                    <CardText className="d-flex align-items-baseline">
                      {isEdit ? (
                        <input
                          type="text"
                          className="dms-edit-input"
                          value={inputValues[index]}
                          onChange={e => handleInputChange(index, e.target.value)}
                        />
                      ) : (
                        <span className="stat-value">{data.value}</span>
                      )}
                      <span className="stat-label">{data.label}</span>
                    </CardText>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}

export default ManageImpacts;
