const WEATHER_CONST = {
  FETCH_REQUEST: 'FETCH_REQUEST',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILED: 'FETCH_FAILED',

  //get forest data sources
  GET_FORECAST_DATA_SOURCES_REQUEST: 'GET_FORECAST_DATA_SOURCES_REQUEST',
  GET_FORECAST_DATA_SOURCES_SUCCESS: 'GET_FORECAST_DATA_SOURCES_SUCCESS',
  GET_FORECAST_DATA_SOURCES_FAILED: 'GET_FORECAST_DATA_SOURCES_FAILED',

  GET_FORECAST_DATA_REQUEST: 'GET_FORECAST_DATA_REQUEST',
  GET_FORECAST_DATA_SUCCESS: 'GET_FORECAST_DATA_SUCCESS',
  GET_FORECAST_DATA_FAILED: 'GET_FORECAST_DATA_FAILED',

  // NOAA TIMESERIES
  NOAA_TIMESERIES_REQUEST: 'NOAA_TIMESERIES_REQUEST',
  NOAA_TIMESERIES_SUCCESS: 'NOAA_TIMESERIES_SUCCESS',
  NOAA_TIMESERIES_FAILED: 'NOAA_TIMESERIES_FAILED',

  // ERPAS TIMESERIES
  ERPAS_TIMESERIES_REQUEST: 'ERPAS_TIMESERIES_REQUEST',
  ERPAS_TIMESERIES_SUCCESS: 'ERPAS_TIMESERIES_SUCCESS',
  ERPAS_TIMESERIES_FAILED: 'ERPAS_TIMESERIES_FAILED',

  // IRI TIMESERIES
  IRI_TIMESERIES_REQUEST: 'IRI_TIMESERIES_REQUEST',
  IRI_TIMESERIES_SUCCESS: 'IRI_TIMESERIES_SUCCESS',
  IRI_TIMESERIES_FAILED: 'IRI_TIMESERIES_FAILED',

  // IRI AVAILABLE_DATES
  IRI_AVAILABLE_DATES_REQUEST: 'IRI_AVAILABLE_DATES_REQUEST',
  IRI_AVAILABLE_DATES_SUCCESS: 'IRI_AVAILABLE_DATES_SUCCESS',
  IRI_AVAILABLE_DATES_FAILED: 'IRI_AVAILABLE_DATES_FAILED',

  // ECMWF PRODUCTS
  ECMWF_PRODUCTS_REQUEST: 'ECMWF_PRODUCTS_REQUEST',
  ECMWF_PRODUCTS_SUCCESS: 'ECMWF_PRODUCTS_SUCCESS',
  ECMWF_PRODUCTS_FAILED: 'ECMWF_PRODUCTS_FAILED',

  // ECMWF controls
  ECMWF_CONTROLS_REQUEST: 'ECMWF_CONTROLS_REQUEST',
  ECMWF_CONTROLS_SUCCESS: 'ECMWF_CONTROLS_SUCCESS',
  ECMWF_CONTROLS_FAILED: 'ECMWF_CONTROLS_FAILED',


  // ECMWF controls
  ECMWF_DATA_BY_CONTROLS_REQUEST: 'ECMWF_DATA_BY_CONTROLS_REQUEST',
  ECMWF_DATA_BY_CONTROLS_SUCCESS: 'ECMWF_DATA_BY_CONTROLS_SUCCESS',
  ECMWF_DATA_BY_CONTROLS_FAILED: 'ECMWF_DATA_BY_CONTROLS_FAILED',
};

export default WEATHER_CONST;
