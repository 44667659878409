import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import MAP_PREFERENCE_CONST from './mapPreferenceConst';
import {
  getMapPreferenceSuccess,
  getMapPreferenceFailed,
  getMapPreferenceBoundariesSuccess,
  getMapPreferenceBoundariesFailed,
  getMapPreferencesSuccess,
  getMapPreferencesFailed,
} from './mapPreferenceActions';
import { AXIOS_INSTANCE , BASE_LAYER, WEATHER_MAP_PREFERENCE, MASTER_SERVER_BASE_URL, AXIOS_INSTANCE_MASTER_SERVER} from '../api/apiEndPoint';

require('dotenv').config();

const getMapPreferenceRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.post(BASE_LAYER, payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getMapPreference(action) {
  try {
    const apiResponse = yield call(getMapPreferenceRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getMapPreferenceSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getMapPreferenceFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getMapPreferenceFailed(errorData));
  }
}

const getMapPreferenceBoundariesRequest = async (payload) => {
  let response = {};
  try {
    // const data = await AXIOS_INSTANCE.post(payload?.isMasterServer  ?  `${MASTER_SERVER_BASE_URL}/${payload?.apiUrl}`  :   WEATHER_MAP_PREFERENCE, payload.requestObj);

    const data =payload?.isMasterServer ?  await AXIOS_INSTANCE_MASTER_SERVER.post( `${MASTER_SERVER_BASE_URL}/${payload?.apiUrl}`, payload.requestObj) : await AXIOS_INSTANCE.post(   WEATHER_MAP_PREFERENCE, payload.requestObj);


    response = {
      data: data.data,
      payload,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

//get map preferences boundaries
function* getMapPreferenceBoundaries(action) {
  try {
    const apiResponse = yield call(getMapPreferenceBoundariesRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
          payload: apiResponse.payload
        },
      };
      yield put(getMapPreferenceBoundariesSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(getMapPreferenceBoundariesFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getMapPreferenceBoundariesFailed(errorData));
  }
}

/**
 * Weather Map Preferences
 */
 const getMapPreferencesAPIRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.post(WEATHER_MAP_PREFERENCE, { boundarytype_id: payload.boundarytype_id, country_name: payload.country_name });
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getMapPreferencesRequest(action) {
  try {
    const apiResponse = yield call(getMapPreferencesAPIRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
          payload: action.payload,
        },
      };
      yield put(getMapPreferencesSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getMapPreferencesFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getMapPreferencesFailed(errorData));
  }
}

export function* watchMapPreferences() {
  yield takeEvery(MAP_PREFERENCE_CONST.GET_MAP_PREFERENCES_REQUEST, getMapPreferencesRequest);
}
 
export function* watchMapPreference() {
  yield takeEvery(MAP_PREFERENCE_CONST.GET_MAP_PREFERENCE_REQUEST, getMapPreference);
}

export function* watchMapPreferenceBoundaries() {
  yield takeEvery(
    MAP_PREFERENCE_CONST.GET_MAP_PREFERENCE_BOUNDARIES_REQUEST,
    getMapPreferenceBoundaries,
  );
}

function* mapPreferenceSaga() {
  yield all([fork(watchMapPreference), fork(watchMapPreferenceBoundaries), fork(watchMapPreferences)]);
}

export default mapPreferenceSaga;
