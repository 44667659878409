/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Chart from 'react-apexcharts';
import { renderChartTime,  renderGenericDateFormat } from '../common/utils';
import {
  CHART_LABEL_COLORS,
  DATA_SOURCES,
  NOAA_PARAMETER_SLUG,
  ERPAS_PARAMETER_SLUG,
  IRI_PARAMETER_SLUG
} from '../constants/index';
import closeIcon from '../../assets/Images/closeIcon.svg';

function GEECharts(props) {
  const { chartData, parametersData, renderCharts, dataSource, initialSelectedDate,country,state,district,region, setIsHideToolBar } = props;

  const [series, setSeries] = useState([]);
  const [chartEvents] = useState({
    legendClick: function( seriesIndex) {
      // ...
      return seriesIndex;
    }
  });

  const [YAxisOptions, setYAxisOptions] = useState([
    {
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: (CHART_LABEL_COLORS && CHART_LABEL_COLORS[0]) || '#008FFB',
      },
      labels: {
        style: {
          colors: (CHART_LABEL_COLORS && CHART_LABEL_COLORS[0]) || '#008FFB',
          fontSize: '12px',
          fontFamily: 'National',
        },
        formatter: function (value) {
          if (!value == null || !isNaN(value)) {
            if (value % 1 != 0) {
              return value.toFixed(2);
            } else {
              return value;
            }
          }
        },
      },
      title: {
        text: parametersData ? parametersData[0]?.name : '',
        style: {
          color: (CHART_LABEL_COLORS && CHART_LABEL_COLORS[0]) || '#008FFB',
        },
      },
      tooltip: {
        enabled: true,
      },
      scaleLabel: {
        display: true,
        labelString: parametersData ? parametersData[0]?.name : '',
      },
      ticks: {
        callback: function (value) {
          return value;
        },
      },
    },
  ]);
  const [options, setOptions] = useState({
    chart: {
      type: 'column',
      stacked: false,
      zoom: {
        enabled: true,
        type: 'x',
      },
      toolbar: {
        show: true,
        export: {
          csv: {
            filename: dataSource[0]?.name,
            columnDelimiter: ',',
            headerCategory: 'Date',
            headerValue: 'value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename:dataSource[0]?.name,
            columnDelimiter: ',',
            headerCategory: 'Date',
            headerValue: 'value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          png: {
            filename:dataSource[0]?.name,
            columnDelimiter: ',',
            headerCategory: 'Date',
            headerValue: 'value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
        },
        tools: {
          customIcons: [
            {
              icon: `<img src=${closeIcon} width="20">`,
              index: 6,
              title: 'Close',
              class: 'chart-close-icon',
              click: function (chart, options, e) {
                renderCharts();
              },
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1, 2, 3],
    },
    stroke: {
      // width: [1, 1, 4],
      width: 4,
    },
    title: {
      text: 'Data Sources',
      align: 'left',
      offsetX: 110,
      style: {
        fontSize: '14px',
        fontWeight: 'normal',
        fontFamily: 'National',
        color: '#2A547C',
      },
    },
    plotOptions: {
      bar: {
        columnWidth: dataSource[0]?.name ==='IRI'?'5%':'55%',
        barHeight: '70%',
      },
    },
    xaxis: {
      categories: [],
      labels: {
        showDuplicates: false,
        style: {
          fontSize: '12px',
          fontFamily: 'National',
        },
        crossShair: {
          stroke: {
            curve: 'straight',
          },
        },
      },
    },
    yaxis: YAxisOptions,
    tooltip: {
      fixed: {
        enabled: true,
        position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60,
      },
    },
    legend: {
      horizontalAlign: 'left',
      offsetX: 40,
    },
    events: chartEvents
  });

  const handleSubTitle = () => {
    let datesList;
    let formattedDates;
    let date;
    if(dataSource[0].id === DATA_SOURCES.IRI.id){
      datesList = chartData?.Precipitation?.millis;
      if (!_.isEmpty(datesList)) {
        formattedDates = datesList?.length
          ? datesList.map(item => {
              let dateFormat = new Date(item);
              return renderGenericDateFormat(dateFormat);
            })
          : '';
      }
      date = formattedDates
    }
    return date?.[0];
}

  useEffect(() => {
    if (dataSource.length && initialSelectedDate) {
      const startDate = renderChartTime(initialSelectedDate?.graph.startDate);
      const endDate = renderChartTime(initialSelectedDate?.graph.endDate);
      if(dataSource?.[0]?.id === DATA_SOURCES.IRI.id){
      setOptions((prev) => ({
        ...prev,
        title: {
          ...options.title,
          text: `Data Source: ${dataSource[0].name}`,
          align: 'middle',
          floating: false,
          offsetX: 20
        },
        subtitle:  {
          text: `Forecast Lead Time: ${handleSubTitle()}`,
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '16px',
            fontWeight: 'normal',
            fontFamily: 'National',
            color: '#363636',
          },
        },
      }))
    }
    else{
      setOptions((prev) => ({
        ...prev,
        title: {
          ...options.title,
          text: `Data Source: ${dataSource[0].name}`,
          align: 'middle',
          floating: false,
          offsetX: 20
        },
        subtitle:  {
          text: `Time: ${startDate} to ${endDate}`,
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '16px',
            fontWeight: 'normal',
            fontFamily: 'National',
            color: '#363636',
          },
        },
      }));
    }
    }
  }, [dataSource, initialSelectedDate]);


  useEffect(() => {
    let a
    if (region.length && region){
       if(region?.length == 1){
        a =  "(" + parseFloat(region[0].latlngs[1]).toFixed(2).toString() + "," + parseFloat(region[0].latlngs[0  ]).toFixed(2).toString() + ")"
      }
    }
    else{
      if (district){
          a = district
      }
      else if (state) {
          a = state
      }
      else{
          a = country
      }
    }
    if(parametersData) {a = a + "-" + handleFileName()}

    let seriesData = [];
    let YAxisoptionsData = YAxisOptions;
    if (!_.isEmpty(chartData)) {
      let dates;
      let datesList;
      let formattedDates;
      if(dataSource[0].id === DATA_SOURCES.IRI.id){
        datesList = chartData?.Precipitation?.millis;
        if (!_.isEmpty(datesList)) {
          formattedDates = datesList?.length
            ? datesList.map(item => {
                let dateFormat = new Date(item);
                return renderGenericDateFormat(dateFormat);
              })
            : '';
        }
        dates = formattedDates
      }
      else{
        dates = chartData?.Precipitation.Timestamp?.map((item) => renderGenericDateFormat((item)));
      }
      
      const parameterSlugConst = JSON.parse(
        JSON.stringify(
          dataSource[0].id === DATA_SOURCES.GFS_NOAA.id
            ? NOAA_PARAMETER_SLUG
            : dataSource[0].id === DATA_SOURCES.IRI.id ? IRI_PARAMETER_SLUG : ERPAS_PARAMETER_SLUG,
        ),
      );
      setOptions((prev) => ({
        ...prev,
        xaxis: {
          ...options.xaxis,
          type: 'datetime',
          categories: dates,
          tickPlacement: 'on',
          min: dates && dates[0],
          max: dates && dates[dates.length - 1],
          labels: {
            showDuplicates: false,
            hideOverlappingLabels: true,
            format: 'M/DD @ hA',
          },
          //range: 30,
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1, 2, 3],
        },
        chart: {
          type: 'column',
          stacked: false,
          zoom: {
            enabled: true,
            type: 'x',
          },
          toolbar: {
            show: true,
            export: {
              csv: {
                filename: dataSource[0]?.name +'-'+ a,
                columnDelimiter: ',',
                headerCategory: 'Date',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
              svg: {
                filename: dataSource[0]?.name +'-'+ a,
                columnDelimiter: ',',
                headerCategory: 'Date',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
              png: {
                filename: dataSource[0]?.name +'-'+ a,
                columnDelimiter: ',',
                headerCategory: 'Date',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
            },
            tools: {
              customIcons: [
                {
                  icon: `<img src=${closeIcon} width="20">`,
                  index: 6,
                  title: 'Close',
                  class: 'chart-close-icon',
                  click: function (chart, options, e) {
                    renderCharts();
                  },
                },
              ],
            },
          },
        },
      }));

      const YAxisOption1 = parametersData.find((item) => item.checked === true);
      if (!_.isEmpty(YAxisOption1) && YAxisoptionsData.length) {
        let accessItem = YAxisOption1.name.replace(/ /g, '_');
        let measure = chartData[accessItem]?.Units[0];
        if (!_.isEmpty(YAxisoptionsData[0])) {
          if(dataSource[0].id === DATA_SOURCES.IRI.id && chartData?.Precipitation?.data?.every(i => i < 0)){
            YAxisoptionsData[0].max =  0
            YAxisoptionsData[0].tickAmount = 1
          }
          YAxisoptionsData[0] = {
            ...YAxisoptionsData[0],
            labels: {
              style: {
                colors: (CHART_LABEL_COLORS && CHART_LABEL_COLORS[0]) || '#008FFB',
                fontSize: '12px',
                fontFamily: 'National',
              },
              formatter: function (value) { 
                if (!value == null || !isNaN(value)) {
                  if (value % 1 != 0) {
                    return value.toFixed(2)
                  } else {
                    return value
                  }
                }
              },
            },
            title: {
              text: YAxisOption1.name + ' (' + measure + ')',
              style: {
                color: (CHART_LABEL_COLORS && CHART_LABEL_COLORS[0]) || '#008FFB',
              },
            },
            scaleLabel: {
              display: true,
              labelString: YAxisOption1.name + measure,
            },
          };
        }
        const duplicateYAxisIndex = YAxisoptionsData.findIndex(	
          (item) => item?.seriesName === YAxisOption1.name + ' (' + measure + ')',	
        );
        if (duplicateYAxisIndex >= 0) {
          YAxisoptionsData.splice(duplicateYAxisIndex, 1);
        }
      }

      if (parametersData) {
        const chartParamData = _.uniqBy(parametersData, 'name');
        chartParamData.map((item, itemIndex) => {
          let accessItem = item.name.replace(/ /g, '_');
          let measure = chartData[accessItem]?.Units[0];
          const itemMeasure =  (item.name+' (' + measure + ')')
          const index = seriesData?.findIndex((data) => data.name === itemMeasure);
          const yIndex = YAxisoptionsData.findIndex(	
            (findItem) => findItem?.title?.text === itemMeasure||	
            findItem?.seriesName === itemMeasure,	
          );
          const checkedParams = parametersData.filter((i) => i.checked === true);
          let checkedIndex = _.findIndex(checkedParams, { name: item.name });
          let parameter = parameterSlugConst?.find((x) => x.value === item.name);
          if ((!seriesData.length || index === -1) && !_.isEmpty(parameter)) {
            if (item.checked) {
              seriesData.push({ 
                name: item.name,
                type: item.name === 'Precipitation' ? 'column' : 'line',
                data: chartData[parameter?.name].data.map((item) => item.toFixed(2)),
              });
            }
          }
          if (itemIndex > 0) {
            if (!YAxisoptionsData.length || yIndex === -1) {
              let accessItem = item.name.replace(/ /g, '_');
              let measure = chartData[accessItem]?.Units[0];
              let obj={
                seriesName: item.name + ' (' + measure + ')',
                opposite: true,
                axisTicks: {
                  show: true,
                },
                axisBorder: {
                  show: true,
                  color: CHART_LABEL_COLORS[checkedIndex],
                },
                labels: {
                  style: {
                    colors: CHART_LABEL_COLORS[checkedIndex],
                  },
                  formatter: function (value) {
                    if (!value == null || !isNaN(value)) {
                    
                      if (value % 1 != 0) {
                        return value.toFixed(2)
                      } else {
                        return value
                      }
                    }
                  },
                },
                title: {
                  text: item.name + ' (' + measure + ')',
                  style: {
                    color: CHART_LABEL_COLORS[checkedIndex],
                  },
                },
              }
              if(dataSource[0].id === DATA_SOURCES.IRI.id && chartData[parameter?.name].data?.every(i => i < 0)){
                obj.max =  0
                obj.tickAmount = 1
              }
              YAxisoptionsData.push(obj);
            }
          }
        });
        chartParamData?.map((items) => {
          let accessItem = items.name.replace(/ /g, '_');
          let measure = chartData[accessItem]?.Units[0];
          const itemMeasure =  (items.name+' (' + measure + ')')
          const unCheckedIndex = YAxisoptionsData?.findIndex(
            (data) =>  data?.seriesName === itemMeasure && items.checked === false,
          );
          let parameter = parameterSlugConst?.find((x) => x.value === items?.name);

          if (unCheckedIndex >= 0) {
            YAxisoptionsData.splice(unCheckedIndex, 1);
            parametersData.map((paramdata) => {
              let accessItem = paramdata.name.replace(/ /g, '_');
               let measure = chartData[accessItem]?.Units[0];
              const itemMeasure =  (paramdata.name+' (' + measure + ')')
              if (paramdata.checked === true) {
                const paramIndex = YAxisoptionsData.findIndex((x) =>
                x?.seriesName === itemMeasure,
                );
                if (paramIndex >= 0) {
                  if(dataSource[0].id === DATA_SOURCES.IRI.id && chartData[parameter?.name].data?.every(i => i < 0)){
                    YAxisoptionsData[paramIndex].max =  0
                    YAxisoptionsData[paramIndex].tickAmount = 1
                  }
                  YAxisoptionsData[paramIndex] = {
                    ...YAxisoptionsData[paramIndex],
                    axisBorder: {
                      ...YAxisoptionsData[paramIndex].axisBorder,
                      color: CHART_LABEL_COLORS[paramIndex],
                    },
                    labels: {
                      ...YAxisoptionsData[paramIndex].labels,
                      style: {
                        colors: CHART_LABEL_COLORS[paramIndex],
                      },
                    },
                    title: {
                      ...YAxisoptionsData[paramIndex].title,
                      style: {
                        color: CHART_LABEL_COLORS[paramIndex],
                      },
                    },
                  };
                }
              }
            });
          }
        });

        setSeries(seriesData);
        setYAxisOptions(YAxisoptionsData);
      }
    } else {
      seriesData.push({
        name: 'Precipitation',
        data: chartData,
      });
    }
  }, [chartData, parametersData,region,country,state,district]);

  useEffect(() => {
    setOptions((prev) => ({
      ...prev,
      yaxis: YAxisOptions,
    }));
  }, [YAxisOptions]);

  useEffect(()=>{
    if(parametersData?.length && parametersData?.every(i=>!i.checked)){
      setYAxisOptions([
        {
          seriesName: '',
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: (CHART_LABEL_COLORS && CHART_LABEL_COLORS[0]) || '#008FFB',
          },
          labels: {
            show: true,
            style: {
              colors: (CHART_LABEL_COLORS && CHART_LABEL_COLORS[0]) || '#008FFB',
              fontSize: '12px',
              fontFamily: 'National',
            },
            formatter: function (value) {
              if (!value == null || !isNaN(value)) {
                if (value % 1 != 0) {
                  return value.toFixed(2)
                } else {
                  return value
                }
              }
            },
          },
          title: {
            text: ''
          },
          tooltip: {
            enabled: true,
          },
          scaleLabel: {
            display: true,
          },
          ticks: {
            callback: function (value, index, values) {
              return value;
            },
          },
        },
      ])
    }
  },[parametersData])

/** TODO: useeffect for legend click event handling */
  // useEffect(()=>{
  //   if(!_.isEmpty(options)){
  //    setOptions((prev)=>({
  //     ...prev,
  //     chart: {
  //       ...options.chart,
  //       events: {
  //         ...options.chart.events,
  //         legendClick: (chartContext, seriesIndex, data ) => {
  //           if(data.globals?.allSeriesCollapsed){
  //             setIsHideToolBar(true)
  //           }
  //           else{
  //             setIsHideToolBar(false);
  //           }
  //         }
  //       }
  //     },
  //    }))
  //   }
  // },[chartEvents])

  // const renderDataLabels = (chartContext, seriesIndex, { config, globals} ) => {
  //   if(globals?.allSeriesCollapsed){
  //     setIsHideToolBar(true)
  //   }
  //   else{
  //     setIsHideToolBar(false);
  //   }
  // }

  const handleFileName = () =>{
    let a = []
    if(parametersData){
    parametersData.forEach(element => {
      if (element.checked == true) {
          a.push(element.name)
      }
    });
    return a.join(' ')
  }
  }

  return (
    <div>
      {!_.isEmpty(chartData) && (series.length || !_.isEmpty(options)) ? (
        <>
          <div className="row">
            <div className="chart">
              <Chart
                id="chart"
                options={options}
                series={series}
                type="line"
                width="100%"
                height="300px"
              />
            </div>
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  );
}

export default GEECharts;
